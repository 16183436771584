import React, { useCallback, useMemo } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company from "../../models/company";
import Companies from "./Index";
interface MatchParams {
  id: string;
  tab?: string;
}
interface Props extends RouteComponentProps<MatchParams> {}

const CompanyIndex: React.FC<Props> = ({ match }) => {
  const companyId = useMemo(() => +match.params.id, [match.params.id]);
  const request = useCallback(
    (signal: AbortSignal) => Company.Read(companyId, signal),
    [companyId]
  );

  
  const [loading, setLoading] = useLoading();
  const [company] = usePromise(
    request,
    undefined,
    setLoading
  );

  if (loading) return <Spinner animation="border" />;
  if (!company)
    return <Badge variant="warning">Kunne ikke hente virksomheden</Badge>;
  return <Companies.Details initialTab={match.params.tab} company={company} />;
};

export default CompanyIndex;

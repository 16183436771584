import { deleteFetch, readFetch, updateFetch } from "../helpers/fetchers";
import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { IServiceStatus } from "./serviceStatus";

export interface IEmulatedUnit {
  id: number;
  trackerId: string;
  fileName: string;
  isActive: boolean;
}
export type EmulatedUnitProps = "trackerId" | "fileName" | "isActive";
class EmulatedUnit implements IEmulatedUnit, ISortable<EmulatedUnitProps>, ISearchable<EmulatedUnitProps> {
  id: number;
  trackerId: string;
  fileName: string;
  isActive: boolean;
  constructor(value: IEmulatedUnit){
    this.id = value.id;
    this.trackerId = value.trackerId;
    this.fileName = value.fileName;
    this.isActive = value.isActive;
  }
  search = (value: any, type: EmulatedUnitProps): number | undefined => 
    basicSearch(this, value, type, false);
  compare = (value: ISortable<EmulatedUnitProps>, primary: EmulatedUnitProps, secondary?: "trackerId" | "fileName" | "isActive" | undefined): number => 
    basicSecSort(this, value, primary, secondary);
  
  static Read = async (id: number, abort?: AbortSignal): Promise<IEmulatedUnit> =>
    (await readFetch("api/Emulator/" + id, abort)) as IEmulatedUnit;

  static ReadAll = async (abort?: AbortSignal): Promise<IEmulatedUnit[]> =>
    (await readFetch("api/Emulator/", abort)) as IEmulatedUnit[];

  static Delete = async (emulator: IEmulatedUnit, abort?: AbortSignal): Promise<void> => {
    await deleteFetch("api/Emulator/" + emulator.id, abort);
  };

  static SetStatus = async (
    emulator: IEmulatedUnit,
    status: IServiceStatus,
    abort?: AbortSignal
  ): Promise<IServiceStatus | undefined> =>
    await updateFetch("api/Emulator/" + emulator.id, status, abort);
}

export default EmulatedUnit;

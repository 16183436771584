import DateSelector from "components/Coordinates/DateSelector";
import React, { Fragment, useState, useEffect } from "react";
import { Table, Spinner } from "react-bootstrap";
import useSortedSearch from "../../hooks/useSortedSearch";
import { IVehicleResource, VehicleProps, VehicleResource } from "../../models/vehicle";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import VehicleKilometerCounter from "./VehicleKilometer";

enum SelectedType {
  Daily,
  Hours24,
  Midnight,
  Total,
  TotalByDates,
}

interface Props {
  vehicles: IVehicleResource[];
}

const VehiclesDrivenDashboard: React.FC<Props> = ({ vehicles }) => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(SelectedType.Hours24);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  useEffect(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    setFrom(date);
    setLoading(false);
  }, []);

  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<VehicleResource, VehicleProps>(
    vehicles.map(v => new VehicleResource(v)),
    undefined,
    {
      type: "alias",
      fallback: "licensePlate",
      asc: true,
    }
  );

  const setMidnight = () => {
    const nfrom = new Date();
    nfrom.setHours(0, 0, 0, 0);
    setFrom(nfrom);
    setTo(new Date());
    setSelected(SelectedType.Midnight);
  };
  const set24Hours = () => {
    const nfrom = new Date(to.getTime());
    nfrom.setDate(nfrom.getDate() - 1);
    setFrom(nfrom);
    setTo(new Date());
    setSelected(SelectedType.Hours24);
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Fragment>
      <div className="dashboard-item-top">
        <label>
          <input
            type="radio"
            checked={selected === SelectedType.Hours24}
            onChange={() => set24Hours()}
          />
          Seneste 24 timer
        </label>
        <br />
        <label>
          <input
            type="radio"
            checked={selected === SelectedType.Midnight}
            onChange={() => setMidnight()}
          />
          Siden midnat
        </label>
        <br />
        <label>
          <input
            type="radio"
            checked={selected === SelectedType.Daily}
            onChange={() => setSelected(SelectedType.Daily)}
          />
          Siden sidste daglige hvil
        </label>
        <br />
        <label>
          <input
            type="radio"
            checked={selected === SelectedType.Total}
            onChange={() => setSelected(SelectedType.Total)}
          />
          Total
        </label>
        <br />
        <label>
          <input
            type="radio"
            checked={selected === SelectedType.TotalByDates}
            onChange={() => setSelected(SelectedType.TotalByDates)}
          />
          Total mellem datoer
        </label>

        {selected === SelectedType.TotalByDates && (
          <DateSelector dateOnly from={from} to={to} onChange={(f, t) => {
            setFrom(f);
            setTo(t);
          }} />
        )}
      </div>

      <Table size="sm" hover responsive>
        <thead>
          <tr>
            <SearchableHeader
              value={search}
              types={["alias", "licensePlate"]}
              onSearch={setSearch}
            />
            <th></th>
          </tr>
          <tr>
            <SortableHeader
              value={sort}
              onChange={setSort}
              type="alias"
              fallback="licensePlate"
            >
              Køretøj
            </SortableHeader>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <PassivePagination
            items={sorted}
            showPages={2}
            renderItem={(vehicle) => (
              <tr key={vehicle.id}>
                <td>{vehicle.alias || vehicle.licensePlate}</td>
                <td>
                  <VehicleKilometerCounter
                    vehicle={vehicle}
                    daily={selected === SelectedType.Daily}
                    total={selected === SelectedType.Total}
                    totalByDates={selected === SelectedType.TotalByDates}
                    from={from}
                    to={to}
                  />
                </td>
              </tr>
            )}
          />
        </tbody>
      </Table>
    </Fragment>
  );
};

export default VehiclesDrivenDashboard;

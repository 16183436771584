import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { ICompanyResource } from "../../../models/company";
import SEGESVehicle, {
  ISEGESVehicleBinding,
  ISEGESVehicleResource,
} from "../../../models/segesVehicle";
import Companies from "../../Companies/Index";
import TypeSelector from "./TypeSelector";

interface Props {
  onCreated: (vehicle: ISEGESVehicleResource) => void;
}

const defaultBinding: ISEGESVehicleBinding = {
  type: 1,
  vehicle: undefined,
};

const Create = ({ onCreated }: Props) => {
  const [binding, setBinding] = useState(defaultBinding);

  const [company, setCompany] = useState<ICompanyResource>();

  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);

  const saveChanges = async () => {
    try {
      if (!binding.vehicle) return;
      setLoading(true);
      const result = await SEGESVehicle.Create(binding);
      result.vehicle = binding.vehicle;
      setLoading(false);
      setShown(false);
      onCreated && onCreated(result);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  };
  /**
   * Save when the form is submitted
   * @param event
   */
  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    await saveChanges();
  };

  return (
    <Fragment>
      <Button title="Opret SEGES køretøj" onClick={() => setShown(true)}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>

      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Opret SEGES køretøj</Modal.Title>
          <Modal.Body>
            <Companies.Selector
              value={company}
              onChange={(company) => {
                setCompany(company);
                setBinding((prev) => ({ ...prev, vehicle: undefined }));
              }}
            />
            {company && (
              <Fragment>
                <hr />
                <Companies.VehicleSelector
                  company={company}
                  value={binding.vehicle}
                  onChange={(vehicle) =>
                    setBinding((prev) => ({ ...prev, vehicle }))
                  }
                />
              </Fragment>
            )}

            {binding.vehicle && (
              <Fragment>
                <hr />
                <Form.Group>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Type</InputGroup.Text>
                    </InputGroup.Prepend>
                    <TypeSelector
                      value={binding.type}
                      onChange={(type) =>
                        setBinding((prev) => ({ ...prev, type }))
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Fragment>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={loading} type="submit">
              Opret {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setBinding(defaultBinding);
                setCompany(undefined);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default Create;

import User, { IUserResource } from "../../models/user";
import { useState, useCallback } from "react";
import useAbort from "../../hooks/useAbort";
import { Spinner, Button } from "react-bootstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";

interface Props {
  user: IUserResource;
}

const UserResendActivation: React.FC<Props> = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const abortSignal = useAbort();

  const resend = useCallback(async () => {
    try {
      setLoading(true);
      await User.ResendActivation(user, abortSignal);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [user, abortSignal]);

  if (loading) return <Spinner size="sm" animation="border" />;

  return(
      <Button variant="primary" size="sm" title="Gensend aktivering" onClick={resend}>
          <FontAwesomeIcon icon={faEnvelopeSquare} />
      </Button>
  )
};

export default UserResendActivation;

import React, { useEffect, useState, Fragment } from "react";
import packageJson from "../../package.json";
import { Version } from "../Version";
import { Spinner } from "react-bootstrap";

/**
 * Compares the cached version and the latest version from the server, if server's is never, a refresh is forced.
 * Renders its children when done comparing versions
 * @param param0
 */
const CacheBuster: React.FC = ({ children }) => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    BustTheCache(setReady);
  }, []);

  return (
    <Fragment>{ready ? children : <Spinner animation="border" />}</Fragment>
  );
};

export default CacheBuster;

export const BustTheCache = (setReady?: (ready: boolean) => void ) => {
  const fetchMeta = async (setReady?: (ready: boolean) => void ) => {
    try {
      //Load the meta version from server
      const metaResponse = await fetch(`/meta.json?c=${new Date().getTime()}`, {
        cache: "no-cache",
      });
      const meta = await metaResponse.json();

      //Load the current (cached) version
      const currentVersion = packageJson.version;
      const latestVersion = meta.version;

      //Determine if cache should be bust
      const shouldCacheBust =
        new Version(latestVersion).compareTo(new Version(currentVersion)) === 1;
      //If up to date, go ready (render children)
      if (!shouldCacheBust) {
        console.info("Version is up to date");
        if(setReady){
          setReady(true);
        }
        return;
      }

      console.info(
        `Latest version ${latestVersion} is greater than current ${currentVersion}`
      );
      //Check for cache bust loops
      if (sessionStorage.getItem("is_reloaded")) {
        console.log("Cache bust loop detected, stopping...");
        if(setReady){
          setReady(true);
        }
        return;
      }
      //Clear service worker cache if it's there
      if (caches) {
        caches.keys().then(function (names) {
          for (let name of names) caches.delete(name);
        });
      }

      sessionStorage.setItem("is_reloaded", "true");

      //Reload and clear browser cache
      window.location.reload(true);
    } catch (error) {
      console.error(error);
      if(setReady){
        setReady(true);
      }
      console.log("Could not cachebust");
    }
  };
  fetchMeta(setReady);
};

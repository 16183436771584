import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";

interface Props {
  title?: string;
}

const Descriptor = ({ title }: Props) => {
  const style: React.CSSProperties = {
    display: "inline-block",
    marginLeft: "0.2em"
  };
  return (
    <div style={style}>
      <FontAwesomeIcon
        icon={faQuestionCircle}
        title={title}
      />
    </div>
  );
};

export default Descriptor;

const AdvancedDescriptor: React.FC<Props> = ({ title, children }) => {
  const [shown, setShown] = useState(false);
  const style: React.CSSProperties = {
    display: "inline-block",
    marginLeft: "0.2em"
  };
  return (
    <div style={style}>
      <FontAwesomeIcon
        color={shown ? "green" : undefined}
        icon={faQuestionCircle}
        onClick={() => setShown((prev) => !prev)}
        title={title}
      />
      {shown && <div className="descriptor" onClick={() => setShown(false)}>{children}</div>}
    </div>
  );
};

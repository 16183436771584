import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { ICompanyBinding, ICompanyResource } from "../../models/company";
import Companies from "./Index";

interface Props {
  value?: ICompanyResource;
  defaultCreate?: boolean;
  error?: Error;
  onChange: (company?: ICompanyResource) => void;
  createValue?: ICompanyBinding;
  onCreateChange: (company?: ICompanyBinding) => void;
}

const defaultCompany: ICompanyBinding = {
  name: "",
  demo: false,
};

const CompanySelectorCreate: React.FC<Props> = ({
  value,
  defaultCreate,
  error,
  onChange,
  createValue,
  onCreateChange
}) => {
  const [create, setCreate] = useState(defaultCreate);

  const toggleCreate = useCallback(() => {
    const flip = !create;
    if(flip){
      onCreateChange(undefined);
    }
    else{
      onChange(undefined);
    }
    setCreate(flip);
    
  }, [onCreateChange, onChange, create]);

  if (create) {
    return (
      <Fragment>
        <Button title="Luk oprettelse" onClick={() => toggleCreate()}>
          <FontAwesomeIcon icon={faMinus} />
        </Button>
        <div className="companies">
          <b>Opret virksomhed</b>
          <Companies.Form
            value={createValue || defaultCompany}
            error={error}
            onChange={onCreateChange}
          />
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Companies.Selector value={value} onChange={onChange} />
      <Button title="Opret virksomhed" onClick={() => toggleCreate()}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </Fragment>
  );
};

export default CompanySelectorCreate;

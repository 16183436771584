import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import { ITrackingUnitResource } from "../../models/trackingUnit";
import TrackingUnitQR from "../../models/trackingUnitQR";
import QRViewer from "./QRViewer";

interface Props {
  trackingUnit: ITrackingUnitResource;
}

const TrackingUnitQRCode: React.FC<Props> = ({ trackingUnit }) => {
  const request = useCallback(
    (signal: AbortSignal) => TrackingUnitQR.Read(trackingUnit.id, signal),
    [trackingUnit.id]
  );
  const [loading, setLoading] = useLoading();
  const [unitQR, , setUnitQR] = usePromise(request, undefined, setLoading);

  const regenerate = async () => {
    try {
      const qr = await TrackingUnitQR.Create(trackingUnit);
      setUnitQR(qr);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div>
      Enhed: <b>{trackingUnit.id}</b>
      {unitQR && <QRViewer qr={unitQR} />}
      <hr />
      <Button title="Generer ny" variant="primary" onClick={regenerate}>
        <FontAwesomeIcon icon={faRedo} />
      </Button>
      <br />
      <Alert variant="warning">
        Generering af ny QR kode, gør enhedens forrige QR kode ugyldig
      </Alert>
      <br />
      <Alert variant="warning">
        Bemærk at koden er ugyldig hvis en af disse er sande:
        <ul>
          <li>Enheden ikke er tildelt en virksomhed</li>
          <li>Enheden allerede er forbundet til et køretøj</li>
        </ul>
        Koden bliver gyldig igen hvis konflikten bliver løst.
      </Alert>
    </div>
  );
};

export default TrackingUnitQRCode;

import {
  faChevronDown,
  faChevronUp,
  faCrosshairs,
  faForward,
  faPause,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Alert, Button, Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { fancyTimeFormat } from "../../helpers/utils";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import { IHistoryPeriod } from "../../models/historyPeriod";
import TimeSpan from "../../models/timespan";
import Vehicle, { IVehicleResource } from "../../models/vehicle";
import SpeedChart from "../Charts/SpeedChart";
import HistoryRowCoordinates from "../Coordinates/HistoryRowCoordinates";
import Coordinates from "../Coordinates/Index";
import { VehicleHistoryExport } from "../DataExports/VehicleHistoryExport";
import DigiFleetLogo from "../Images/DigiFleet-logo.png";
import KilometerLabel from "../KilometerLabel";
import PassivePagination from "../Pagination";
import PositionModal from "./PositionModal";

interface Props {
  vehicle: IVehicleResource;
  from: Date;
  to: Date;
  minDuration: TimeSpan;
  maxDistance: number;
}

const VehiclePeriodicHistory: React.FC<Props> = ({
  vehicle,
  from,
  to,
  minDuration,
  maxDistance,
}) => {
  const request = useCallback(
    (signal: AbortSignal) =>
      Vehicle.ReadPeriodicHistory(
        vehicle,
        from,
        to,
        minDuration,
        maxDistance,
        signal
      ),
    [vehicle, from, to, minDuration, maxDistance]
  );
  const [loading, setLoading] = useLoading();
  const [periods] = usePromise(request, undefined, setLoading);

  const [pdfLoading, setPdfLoading] = useLoading(false);

  const days = useMemo(
    () => Math.abs((from.getTime() - to.getTime()) / (86400 * 1000)),
    [from, to]
  );

  const speeds = useMemo(() => {
    if (!periods) return [];
    return periods.periods.map((p) => p.speeds).flat();
  }, [periods]);

  if (loading || !periods) return <Spinner animation="border" />;
  if (periods.periods.length === 0) {
    return <h4>Ingen historik for perioden</h4>;
  }

  const printPDF = async () => {
    setPdfLoading(true);
    try {
      const scale = 1;

      const fromMoment = moment(from);
      const toMoment = moment(to);
      const table = document.getElementById("print-history-table");
      if (!table) return;

      const margin = 20;
      const lineSpacing = 10;
      var y = margin;
      var x = margin;
      const canvas = await html2canvas(table, { scale });

      const subject = `${vehicle.licensePlate} historik`;
      const title = `Tabel historik for ${vehicle.licensePlate}`;
      const pdf = new jsPDF("p", "px", "A4");
      pdf.setProperties({
        title,
        creator: "DigiFleet",
        author: "DigiFleet",
        subject,
      });
      pdf.setFontSize(14);

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const actualWeirdAproxPdfHeight =
        pdf.internal.pageSize.getHeight() * 1.75;
      const actualWeirdAproxPdfWidth = pdfWidth * 1.7;

      // Print the header
      const logoWidth = 500;
      const logoHeight = 272;
      const logoScale = 0.085;

      // Print the canvas, on multiple pages if needed

      // The ratio between the width of the canvas and the pdf, width of canvas is constant - while the height isn't
      const canvasWidthRatio = canvas.width / actualWeirdAproxPdfWidth;

      var canvasOffset = 0;
      var heightLeft = canvas.height;
      while (heightLeft > 0) {
        // Print header
        pdf.addImage(
          DigiFleetLogo,
          "PNG",
          x,
          y,
          logoWidth * logoScale,
          logoHeight * logoScale
        );

        x += logoWidth * logoScale + margin;

        y += lineSpacing;
        pdf.setFont("", "bold");
        pdf.text(`Køretøj:`, x, y);
        pdf.setFont("", "normal");
        pdf.text(vehicle.licensePlate, x + 50, y);

        y += lineSpacing;
        const fromFormatted = fromMoment.format("DD-MM-YYYY HH:mm");
        const toFormatted = toMoment.format("DD-MM-YYYY HH:mm");

        pdf.setFont("", "bold");
        pdf.text(`Periode:`, x, y);
        pdf.setFont("", "normal");
        pdf.text(`${fromFormatted} til ${toFormatted}`, x + 50, y);

        y = margin;
        x = margin;
        y += logoHeight * logoScale;

        y += margin / 2;
        pdf.line(margin, y, pdfWidth - margin, y);
        y += margin / 2;

        const imageCanvas = document.createElement("canvas");
        const resultHeight =
          (actualWeirdAproxPdfHeight - (y + margin)) * canvasWidthRatio;
        // Height of output canvas will be exactly what it can fit
        imageCanvas.height = Math.min(resultHeight, heightLeft);
        imageCanvas.width = canvas.width;

        const imageContext = imageCanvas.getContext("2d");
        if (!imageContext) break;
        // Draw the wanted part of the canvas to the output image canvas
        imageContext.drawImage(
          canvas,
          0,
          canvasOffset,
          imageCanvas.width,
          imageCanvas.height,
          0,
          0,
          imageCanvas.width,
          imageCanvas.height
        );

        const imgData = imageCanvas.toDataURL("image/png", 1);
        pdf.addImage(
          imgData,
          "PNG",
          x,
          y,
          pdf.internal.pageSize.getWidth() - margin * 2,
          0
        );

        heightLeft -= imageCanvas.height;
        canvasOffset += imageCanvas.height;
        if (heightLeft > 0) {
          y = margin;
          pdf.addPage();
        }
      }

      var pageCount = pdf.internal.pages.length - 1;
      for (var i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        const pageInfo = pdf.getCurrentPageInfo();

        const pageNum = pageInfo.pageNumber;
        pdf.text(
          `Side ${pageNum} af ${pageCount}`,
          350,
          margin + lineSpacing * 2
        );
      }

      pdf.save(`${vehicle.licensePlate} historik.pdf`);
    } catch (error) {
      console.error(error);
    } finally {
      setPdfLoading(false);
    }
  };

  return (
    <div>
      {days <= 5 && (
        <div>
          <VehicleHistoryExport vehicle={vehicle} from={from} to={to} />
        </div>
      )}
      <hr />
      <Button variant="primary" onClick={printPDF} disabled={pdfLoading}>
        {pdfLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <FontAwesomeIcon icon={faPrint} />
        )}
        Gem PDF
      </Button>
      <div id="periodic-history-total-km">
        <Alert variant="success" title="Antallet af kilometer der er kørt i mellem fra- og tildato.">
          <span>Total kørte KM i perioden: </span>
          <b>
            <KilometerLabel kilometers={periods?.distance / 1000} />
          </b>
        </Alert>
      </div>
      <Table size="sm" striped responsive id="print-history-table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Start tidspunkt</th>
            <th>Slut tidspunkt</th>
            <th>Start adresse</th>
            <th>Slut adresse</th>
            <th>Tid</th>
            <th>Kørte KM</th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<IHistoryPeriod>
            id="vehicle-history-table"
            sizeSelector={true}
            items={periods.periods}
            renderItem={(period, index) => (
              <PeriodicHistoryRow
                key={index}
                period={period}
                vehicle={vehicle}
              />
            )}
          />
        </tbody>
      </Table>
      <hr />
      <SpeedChart speeds={speeds} />
    </div>
  );
};

export default VehiclePeriodicHistory;

const duration = (from: string, to: string) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const diff = Math.abs(toDate.getTime() - fromDate.getTime());
  return fancyTimeFormat(diff);
};

interface RowProps {
  period: IHistoryPeriod;
  vehicle: IVehicleResource;
}

const PeriodicHistoryRow = ({
  period,
  vehicle,
}: RowProps): React.ReactElement => {
  const [open, setOpen] = useState(false);

  const fromDate = useMemo(
    () => new Date(period.start.timestamp),
    [period.start]
  );
  const toDate = useMemo(() => new Date(period.stop.timestamp), [period.stop]);

  return (
    <Fragment>
      <tr>
        <td>{vehicle.licensePlate}</td>
        <td>
          <FontAwesomeIcon
            title={period.isMoving ? "Kørsel" : "Pause"}
            icon={period.isMoving ? faForward : faPause}
          />
        </td>
        <td>
          <Link
            to={`/map/history/${vehicle.id}/${period.start.timestamp}/${period.stop.timestamp}`}
          >
            <Button title="Vis på grafisk" variant="secondary" size="sm">
              <FontAwesomeIcon icon={faCrosshairs} />
            </Button>
          </Link>
          <br />
          <Button
            title="Vis koordinater"
            variant="secondary"
            size="sm"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </Button>
        </td>
        <td>
          <Moment local utc format="DD-MM-YYYY H:mm:ss">
            {period.start.timestamp}
          </Moment>
        </td>
        <td>
          <Moment local utc format="DD-MM-YYYY H:mm:ss">
            {period.stop.timestamp}
          </Moment>
        </td>
        <td>
          <PositionModal position={period.start} />
          <Coordinates.Address coordinate={period.start} />
        </td>
        <td>
          <PositionModal position={period.stop} />
          {period.isMoving ? (
            <Coordinates.Address coordinate={period.stop} />
          ) : (
            <Coordinates.Address coordinate={period.start} />
          )}
        </td>
        <td>
          {period.start.timestamp &&
            period.stop.timestamp &&
            duration(period.start.timestamp, period.stop.timestamp)}
        </td>
        <td>
          <KilometerLabel kilometers={period.distance / 1000} />
        </td>
      </tr>
      {open && (
        <tr>
          <td colSpan={8}>
            <HistoryRowCoordinates
              vehicle={vehicle}
              from={fromDate}
              to={toDate}
            />
          </td>
        </tr>
      )}
    </Fragment>
  );
};

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  FormEvent,
  Fragment,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Alert,
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyResource } from "../../models/company";
import FlexVehicle, { IFlexVehicleResource } from "../../models/flexVehicle";
import Vehicle, { IVehicleResource } from "../../models/vehicle";
import FormControlFeedback from "../FormControlFeedback";
import Vehicles from "../Vehicles/Index";

interface Props {
  company: ICompanyResource;
  onCreated: (flexVehicle: IFlexVehicleResource) => void;
  exceptVehicles: IVehicleResource[];
}

const CreateCompanyFlexVehicle: React.FC<Props> = ({
  company,
  onCreated,
  exceptVehicles,
}) => {
  const vehiclesRequest = useCallback(
    (abort: AbortSignal) => Company.ReadVehicles(company, abort),
    []
  );
  const [loadingVehicles, setLoadingVehicles] = useLoading();
  const [vehicles] = usePromise(vehiclesRequest, [], setLoadingVehicles);
  const [shown, setShown] = useState(false);
  const [vehicle, setVehicle] = useState<IVehicleResource>();
  const [flexId, setFlexId] = useState<number>();
  const [loading, setLoading] = useLoading(false);
  const [error, setError] = useState<Error>();

  const availableVehicles = useMemo(
    () =>
      vehicles.filter(
        (v) => !exceptVehicles.filter((exv) => exv.id === v.id).length
      ),
    [vehicles, exceptVehicles]
  );

  const completed = useMemo(() => !!(vehicle ?? flexId), [vehicle, flexId]);
  const saveChanges = async () => {
    try {
      if (vehicle && flexId) {
        var model = {
          vehicleId: vehicle.id,
          flexId,
        };
        if (error) setError(undefined);
        setLoading(true);
        const result = await FlexVehicle.Create(model);
        onCreated({ ...result, vehicle });
        clear();
        setShown(false);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const clear = () => {
    setFlexId(undefined);
    setVehicle(undefined);
  };

  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    await saveChanges();
  };

  return (
    <Fragment>
      <Button
        title="Opret FlexDanmark forbindelse"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>

      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>
            Opret DigiFleet {"->"} FlexDanmark forbindelse
          </Modal.Title>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Køretøj</InputGroup.Text>
                </InputGroup.Prepend>
                <Vehicles.Selector
                  vehicles={availableVehicles}
                  value={vehicle}
                  onChange={setVehicle}
                />
              </InputGroup>
              {loadingVehicles && <Spinner animation="border" />}
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>FlexDanmark ID</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  type="number"
                  errors={error}
                  field="flexId, vehicleId"
                  value={flexId}
                  onChange={(id: string) => setFlexId(+id)}
                />
              </InputGroup>
            </Form.Group>

            {completed && (
              <Alert variant="warning">
                <b>
                  Køretøjets indkomne koordinater vil blive sendt videre til
                  FlexDanmark
                </b>
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              disabled={loading || !completed}
              type="submit"
            >
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setVehicle(undefined);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default CreateCompanyFlexVehicle;

import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { ICompanyResource } from "../../models/company";
import DataExport from "../../models/export";
import DateSelector from "../Coordinates/DateSelector";

interface IndexProps {
  company: ICompanyResource;
}

const CompanyExportIndex: React.FC<IndexProps> = ({ company }) => {
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  return (
    <div>
      <DateSelector
        from={from}
        to={to}
        onChange={(from, to) => {
          setFrom(from);
          setTo(to);
        }}
      />
      <CompanyExport company={company} from={from} to={to} />
    </div>
  );
};
export default CompanyExportIndex;

interface Props {
  company: ICompanyResource;
  from: Date;
  to: Date;
}
const CompanyExport: React.FC<Props> = ({ company, from, to }) => {
  const [loading, setLoading] = useState(false);
  const downloadExcel = async () => {
    setLoading(true);
    try {
      const downloaded = await DataExport.CompanyVehiclesExcel(
        company,
        from,
        to
      );
      if (downloaded) {
        saveFile(downloaded.fileName, downloaded.blob);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const downloadCSV = async () => {
    setLoading(true);
    try {
      const downloaded = await DataExport.CompanyVehiclesCSV(
        company,
        from,
        to
      );
      if (downloaded) {
        saveFile(downloaded.fileName, downloaded.blob);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Spinner animation="border" />;

  return (
    <div className="export-buttons">
      <Button variant="primary" onClick={downloadExcel}>
        Download Excel (.xlsx)
      </Button>
      <Button variant="primary" onClick={downloadCSV}>
        Download CSV (.csv)
      </Button>
    </div>
  );
};

function saveFile(name: string, data: Blob) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(data, name);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = name;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

import React from "react";


const style: React.CSSProperties = {
    border: "1px solid lightgrey",
    borderRadius: "0.5em",
    padding: "1em"
}
interface Props{
    /**
     * Whether the container should shrink to fit the content
     */
    shrinkToFit?: boolean
}
const Bordered: React.FC<Props> = ({shrinkToFit, children}) => (
    <div style={shrinkToFit ? {...style, display: "table"} : style}>
        {children}
    </div>
)
export default Bordered;
import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import useSorting from "../../hooks/useSorting";
import { ISpeedCoordinate, SpeedCoordinate, SpeedCoordinateProps } from "../../models/gpsCoordinate";
import PassivePagination from "../Pagination";
import SortableHeader from "../SortableHeader";
import PositionModal from "../Tracking/PositionModal";
import SpeedLabel from "../Tracking/SpeedLabel";
import Coordinates from "./Index";

interface Props {
  coordinates: ISpeedCoordinate[];
}

const CoordinateTable: React.FC<Props> = ({ coordinates }) => {
  const [sorted, sortValue, setSort] = useSorting<SpeedCoordinate, SpeedCoordinateProps>(coordinates.map(v => new SpeedCoordinate(v)), {
    type: "timestamp",
    asc: false,
    });

  return (
    <Table responsive striped bordered>
      <thead>
        <tr>
          <th></th>
          <th>Adresse</th>
          <SortableHeader value={sortValue} type="timestamp" onChange={setSort}>
            Tidspunkt
          </SortableHeader>
          <SortableHeader value={sortValue} type="accOn" onChange={setSort}>
            Tænding
          </SortableHeader>
          <SortableHeader value={sortValue} type="speed" onChange={setSort}>
            Hastighed
          </SortableHeader>
        </tr>
      </thead>
      <tbody>
        <PassivePagination
          id="coordinates-table"
          sizeSelector={true}
          items={sorted}
          renderItem={(coordinate) => (
            <tr key={coordinate.id}>
              <td>
                <PositionModal position={coordinate} />
              </td>
              <td>
                <Coordinates.Address coordinate={coordinate} />
              </td>
              <td>
                <Moment local utc format="DD-MM-YYYY H:mm:ss">
                  {coordinate.timestamp}
                </Moment>
              </td>
              <td>{coordinate.accOn ? "Ja" : "Nej"}</td>
              <td>
                {coordinate.speed !== undefined &&
                  coordinate.speed !== null && (
                    <SpeedLabel speed={coordinate.speed} />
                  )}
              </td>
            </tr>
          )}
        />
      </tbody>
    </Table>
  );
};

export default CoordinateTable;

export const CoordinateTableNoAddress: React.FC<Props> = ({ coordinates }) => {
  const [sorted, sortValue, setSort] = useSorting<SpeedCoordinate, SpeedCoordinateProps>(coordinates.map(v => new SpeedCoordinate(v)), {
    type: "timestamp",
    asc: false,
    });

  return (
    <Table responsive striped>
      <thead>
        <tr>
          <th></th>
          <th>Længdegrad</th>
          <th>Breddegrad</th>
          <SortableHeader value={sortValue} type="timestamp" onChange={setSort}>
            Tidspunkt
          </SortableHeader>
          <SortableHeader value={sortValue} type="accOn" onChange={setSort}>
            Tænding
          </SortableHeader>
          <SortableHeader value={sortValue} type="speed" onChange={setSort}>
            Hastighed
          </SortableHeader>
        </tr>
      </thead>
      <tbody>
        <PassivePagination
          id="coordinates-table"
          sizeSelector={true}
          items={sorted}
          renderItem={(coordinate, index) => (
            <tr key={index}>
              <td>
                <PositionModal position={coordinate} />
              </td>
              <td>
                <span>{coordinate.longitude}</span>
              </td>
              <td>
                <span>{coordinate.latitude}</span>
              </td>
              <td>
                <Moment local utc format="DD-MM-YYYY H:mm:ss">
                  {coordinate.timestamp}
                </Moment>
              </td>
              <td>{coordinate.accOn ? "Ja" : "Nej"}</td>
              <td>
                {coordinate.speed !== undefined &&
                  coordinate.speed !== null && (
                    <SpeedLabel speed={coordinate.speed} acc={coordinate.accOn} />
                  )}
              </td>
            </tr>
          )}
        />
      </tbody>
    </Table>
  );
};

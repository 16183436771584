import React, { useEffect, useReducer } from "react";
import { Table } from "react-bootstrap";
import useSortedSearch from "../../hooks/useSortedSearch";
import { IUserResource, UserProps, UserResource } from "../../models/user";
import { LoginAttemptsSummary } from "../Account/LoginAttempts";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import Users from "./Index";

interface Props {
  users: IUserResource[];
}

type Add = {
  readonly type: "ADD";
  user: IUserResource;
};
type Remove = {
  readonly type: "REMOVE";
  user: IUserResource;
};
type Update = {
  readonly type: "UPDATE";
  user: IUserResource;
};
type Replace = {
  readonly type: "REPLACE";
  users: IUserResource[];
};

type Actions = Add | Remove | Update | Replace;

const reducer = (users: IUserResource[], action: Actions): IUserResource[] => {
  switch (action.type) {
    case "ADD":
      return [...users, action.user];
    case "REMOVE":
      return users.filter((v) => v.id !== action.user.id);
    case "UPDATE":
      const index = users.findIndex(
        (u: IUserResource) => u.id === action.user.id
      );
      return [...users.slice(0, index), action.user, ...users.slice(index + 1)];
    case "REPLACE":
      return action.users;
  }
};

const UsersTable: React.FC<Props> = ({ users: usrs }) => {
  const [users, dispatch] = useReducer(reducer, usrs);

  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    UserResource, UserProps
  >(users.map(v => new UserResource(v)), undefined, {
    type: "firstName",
    fallback: "active",
    asc: true,
  });

  useEffect(() => dispatch({ type: "REPLACE", users: usrs }), [usrs]);

  return (
    <div className="users">
      <Table striped responsive bordered>
        <thead>
          <tr>
            <SearchableHeader
              value={search}
              types={["companies.name"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["firstName"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["lastName"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["email"]}
              onSearch={setSearch}
            />
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>Virksomheder</th>
            <SortableHeader type="firstName" value={sort} onChange={setSort}>
              Fornavn
            </SortableHeader>
            <SortableHeader type="lastName" value={sort} onChange={setSort}>
              Efternavn
            </SortableHeader>
            <SortableHeader type="email" value={sort} onChange={setSort}>
              Email
            </SortableHeader>
            <SortableHeader type="isAdmin" value={sort} onChange={setSort}>
              Admin
            </SortableHeader>
            <SortableHeader type="active" value={sort} onChange={setSort}>
              Aktiv
            </SortableHeader>
            <th>Logins</th>
            <th></th>
            <th>
              <Users.Create
                onCreated={(user) => dispatch({ type: "ADD", user })}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<IUserResource>
            items={sorted}
            id="users"
            sizeSelector={true}
            renderItem={(item) => (
              <tr key={item.id}>
                <td>
                  <div>
                    {item.companies?.map((company) => (
                      <div key={company.id}>{company.name}</div>
                    ))}
                  </div>
                </td>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.email}</td>
                <td>{item.isAdmin ? "Ja" : "Nej"}</td>
                <td>{item.active ? "Ja" : "Nej"}</td>
                <td>
                  <LoginAttemptsSummary user={item} />
                </td>
                <td>
                  <Users.ForceLogout user={item} />
                  {!item.active && <Users.ResendActivation user={item} />}
                </td>
                <td>
                  <Users.Edit
                    user={item}
                    onUpdated={(user) => dispatch({ type: "UPDATE", user })}
                  />
                  <Users.Delete
                    user={item}
                    onDeleted={(user) => dispatch({ type: "REMOVE", user })}
                  />
                </td>
              </tr>
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default UsersTable;

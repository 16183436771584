import React from "react";
import { EmailTemplateUsedBy } from "../../models/emailTemplate";

interface LabelProps {
  usedBy?: EmailTemplateUsedBy;
}

export const EmailTemplateTypeLabel: React.FC<LabelProps> = ({ usedBy }) => {
    var text = "";
    switch(usedBy)
    {
        case EmailTemplateUsedBy.None:
            text = "Ingen";
        break;
        case EmailTemplateUsedBy.UserCreated:
            text = "Bruger oprettet";
        break;
        case EmailTemplateUsedBy.ForgotPassword:
            text = "Glemt password";
        break;
        case EmailTemplateUsedBy.PasswordWasReset:
            text = "Password nulstillet";
        break;
    }
  return <div>{text}</div>;
};

interface SelectorProps {
  value?: EmailTemplateUsedBy;
  onChange: (value: EmailTemplateUsedBy) => void;
}
export const EmailTemplateTypeSelector: React.FC<SelectorProps> = ({
  value,
  onChange,
}) => {
  const types = [EmailTemplateUsedBy.None, EmailTemplateUsedBy.ForgotPassword, EmailTemplateUsedBy.PasswordWasReset, EmailTemplateUsedBy.UserCreated];
  return (
    <select
      className="form-control w-auto"
      value={value}
      onChange={(e) => {
        const num = +e.target.value;
        
        onChange(types[num]);
      }}
    >
      <option value={EmailTemplateUsedBy.None}>Ingen</option>
      <option value={EmailTemplateUsedBy.ForgotPassword}>Glemt password</option>
      <option value={EmailTemplateUsedBy.PasswordWasReset}>Password nulstillet</option>
      <option value={EmailTemplateUsedBy.UserCreated} >Bruger oprettet</option>
    </select>
  );
};

import React, { useEffect, useReducer } from "react";
import { Table } from "react-bootstrap";
import useSortedSearch from "../../hooks/useSortedSearch";
import EmailTemplate, { EmailTemplateProps, IEmailTemplate } from "../../models/emailTemplate";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import EmailTemplates from "./Index";

interface Props {
  emailTemplates: IEmailTemplate[];
}

type Add = {
  readonly type: "ADD";
  emailTemplate: IEmailTemplate;
};
type Remove = {
  readonly type: "REMOVE";
  emailTemplate: IEmailTemplate;
};
type Update = {
  readonly type: "UPDATE";
  emailTemplate: IEmailTemplate;
};

type Replace = {
  readonly type: "REPLACE";
  emailTemplates: IEmailTemplate[];
};

type Actions = Add | Remove | Update | Replace;

const reducer = (
  emailTemplates: IEmailTemplate[],
  action: Actions
): IEmailTemplate[] => {
  switch (action.type) {
    case "ADD":
      return [...emailTemplates, action.emailTemplate];
    case "REMOVE":
      return emailTemplates.filter((e) => e.id !== action.emailTemplate.id);
    case "UPDATE":
      const index = emailTemplates.findIndex(
        (e: IEmailTemplate) => e.id === action.emailTemplate.id
      );
      return [
        ...emailTemplates.slice(0, index),
        action.emailTemplate,
        ...emailTemplates.slice(index + 1),
      ];

    case "REPLACE":
      return action.emailTemplates;
  }
};

const EmailTemplateTable: React.FC<Props> = ({
  emailTemplates: emu,
}) => {
  const [emailTemplates, dispatch] = useReducer(reducer, emu);
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    EmailTemplate, EmailTemplateProps
  >(emailTemplates.map(v => new EmailTemplate(v)), undefined, {
    type: "usedBy",
    asc: true,
  });

  useEffect(() => dispatch({ type: "REPLACE", emailTemplates: emu }), [emu]);
  return (
    <div className="email-templates">
      <Table striped responsive bordered>
        <thead>
          <tr>
            <SearchableHeader value={search} types={["title"]} onSearch={setSearch} />
            <SearchableHeader value={search} types={["subject"]} onSearch={setSearch} />
            <SearchableHeader value={search} types={["body"]} onSearch={setSearch} />
            <th></th>
            <th>
              <EmailTemplates.Create
                onCreated={(template) =>
                  dispatch({ type: "ADD", emailTemplate: template })
                }
              />
            </th>
          </tr>
          <tr>
            <SortableHeader type="usedBy" value={sort} onChange={setSort}>
              Bruges af
            </SortableHeader>
            <SortableHeader type="title" value={sort} onChange={setSort}>
              Title
            </SortableHeader>
            <SortableHeader type="subject" value={sort} onChange={setSort}>
              Emne
            </SortableHeader>
            <SortableHeader type="body" value={sort} onChange={setSort}>
              Indhold
            </SortableHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<IEmailTemplate>
            items={sorted}
            id="email-templates"
            sizeSelector={true}
            renderItem={(emailTemplate) => (
              <tr key={emailTemplate.id}>
                <td>
                  <EmailTemplates.TypeLabel usedBy={emailTemplate.usedBy} />
                </td>
                <td>{emailTemplate.title}</td>
                <td>{emailTemplate.subject}</td>
                <td>{emailTemplate.body}</td>
                <td>
                  <EmailTemplates.Edit
                    template={emailTemplate}
                    onUpdated={(template) =>
                      dispatch({ type: "UPDATE", emailTemplate: template })
                    }
                  />
                  <EmailTemplates.Delete
                    template={emailTemplate}
                    onDeleted={(template) =>
                      dispatch({ type: "REMOVE", emailTemplate: template })
                    }
                  />
                </td>
              </tr>
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default EmailTemplateTable;

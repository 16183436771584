import React, { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../contexts/userContext";
import ContactDetails from "./ContactDetails";
import AdminDashboard from "./Dashboard/AdminDashboard";
import Dashboard from "./Dashboard/Dashboard";
import DigiFleet_frontpage from "./Images/DigiFleet_frontpage.png";

const Home: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user && user.identity.admin) return <AdminDashboard />;
  else if (user) return <Dashboard />;

  return (
    <div>
      <Link to="/login">
        <img
          src={DigiFleet_frontpage}
          className="digifleet-front"
          alt="DigiFleet front page"
        />
      </Link>
      <ContactDetails />
    </div>
  );
};


export default Home;

import {
  faEye,
  faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Vehicle, { IVehicleResource } from "../../models/vehicle";

interface Props {
  vehicle: IVehicleResource;
  onChange: (vehicle: IVehicleResource) => void;
}
const SetVehicleActive: React.FC<Props> = ({ vehicle, onChange }) => {
  const [loading, setLoading] = useState(false);

  const activate = async () => {
    try {
      await Vehicle.SetActive(vehicle, true);
      onChange({ ...vehicle, inActive: !vehicle.inActive });
    } catch (error) {
      console.error(error);
      alert("Fejl");
    } finally {
      setLoading(false);
    }
  };
  const deactivate = async () => {
    try {
      await Vehicle.SetActive(vehicle, false);
      onChange({ ...vehicle, inActive: !vehicle.inActive });
    } catch (error) {
      console.error(error);
      alert("Fejl");
    } finally {
      setLoading(false);
    }
  };

  if (vehicle.inActive) {
    return (
      <Button
        variant="warning"
        onClick={activate}
        title="Deaktiveret"
        size="sm"
      >
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <FontAwesomeIcon icon={faEyeSlash} />
        )}
      </Button>
    );
  }
  return (
    <Button variant="success" onClick={deactivate} title="Aktiveret" size="sm">
      {loading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <FontAwesomeIcon icon={faEye} />
      )}
    </Button>
  );
};

export default SetVehicleActive;

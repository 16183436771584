import React from "react";
import { useCallback } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import useLoading from "../../../hooks/useLoading";
import usePromise from "../../../hooks/usePromise";
import SEGESVehicle, {
  ISEGESVehicleResource,
} from "../../../models/segesVehicle";
import ContentLoading from "../../ContentLoading";
import Table from "./Table";

const All = () => {
  const [loading, setLoading] = useLoading();

  const request = useCallback(
    (signal: AbortSignal) => SEGESVehicle.ReadAll(signal),
    []
  );
  const [values, , setValues] = usePromise(request, [], setLoading);

  const created = (value: ISEGESVehicleResource) => {
    setValues((prev) => [...prev, value]);
  };
  const deleted = (value: ISEGESVehicleResource) => {
    setValues((prev) => prev.filter((v) => v.vehicleId !== value.vehicleId));
  };

  return (
    <ContentLoading loading={loading}>
      <h3>Køretøjer der sender koordinater til Landbrug og Fødevarer SEGES</h3>
      <Alert variant="warning">
        Bemærk: Køretøjets virksomhed skal være oprettet som <Link to="/seges/companies">SEGES virksomhed</Link>
      </Alert>
      <div className="vehicles">
        <Table values={values} onCreated={created} onDeleted={deleted} />
      </div>
    </ContentLoading>
  );
};
export default All;

import { HubConnection } from "@microsoft/signalr";
import React, { useCallback, useState } from "react";
import { useLiveCoordinatesFromConnection } from "../../hooks/useLiveCoordinates";
import { IGPSCoordinate } from "../../models/gpsCoordinate";
import { IVehicleResource } from "../../models/vehicle";

interface Props{
    vehicle: IVehicleResource,
    connection?: HubConnection,
    onUpdate?: (online: boolean) => void;
}


const OnlineStatus = ({vehicle, connection, onUpdate}: Props): React.ReactElement => {
    const [online, setOnline] = useState(vehicle.online);

    const changeCallback = useCallback((coordinate: IGPSCoordinate) => {
        if(coordinate.vehicleId === vehicle.id){
            setOnline(coordinate.accOn);
            if(onUpdate){
                onUpdate(coordinate.accOn);
            }
        }
    }, [])
    useLiveCoordinatesFromConnection(connection, changeCallback);

    return(
        <span>
            {online ? "Ja" : "Nej"}
        </span>
    )
}

export default OnlineStatus;
import React, { useCallback, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import TrackingUnit from "../../models/trackingUnit";
import QR from "./Index";

interface MatchParams {
  id: string;
}
interface Props extends RouteComponentProps<MatchParams> {}

const TrackingUnitQRIndex: React.FC<Props> = ({ match }) => {
  const unitId = useMemo(() => match.params.id, [match.params.id]);
  const request = useCallback(
    (signal: AbortSignal) => TrackingUnit.Read(unitId, signal),
    [unitId]
  );
  const [loading, setLoading] = useLoading();
  const [trackingUnit] = usePromise(request, undefined, setLoading);

  if (loading || !trackingUnit) {
    return <Spinner animation="border" />;
  }

  return <QR.UnitQR trackingUnit={trackingUnit} />;
};

export default TrackingUnitQRIndex;

import React from "react";

const Truck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 415.74799 226.772"
    id="svg2"
    version="1.1"
  >
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,226.772)" id="g10">
      <g id="g12">
        <g id="g14">
          <g transform="translate(112.4167,109.3348)" id="g20">
            <path
              id="path22"
              style={{
                fill: "currentColor",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "currentColor",
                strokeWidth: 4,
              }}
              d="m 0,0 v 39.5 h 169 v -79 H 0 Z m 139.67,-65.833 c -0.366,0.367 -0.667,1.622 -0.667,2.789 0,2.182 -2.186,6.955 -5.402,11.794 l -1.828,2.75 h 37.326 l -0.298,-6.25 -0.298,-6.25 -8,-2.684 c -7.613,-2.555 -19.23,-3.753 -20.833,-2.149 m -193.19,42.889 c 8.813,0.856 17.037,1.971 18.274,2.479 2.193,0.9 2.25,1.306 2.25,15.976 0,12.524 -0.254,15.15 -1.515,15.634 -2.764,1.061 -30.66,-1.39 -32.069,-2.817 -2.399,-2.43 -11.591,-27.994 -10.964,-30.492 0.723,-2.88 1.973,-2.92 24.024,-0.78 z m -27.726,-42.473 c -1.576,0.917 -1.75,3.04 -1.75,21.347 v 20.33 l 6.549,17.37 c 3.601,9.554 7.198,18.362 7.992,19.574 0.799,1.219 2.899,2.452 4.701,2.76 4.704,0.803 47.805,3.157 48.322,2.64 0.24,-0.24 0.436,-15.163 0.436,-33.163 0,-28.92 0.183,-32.797 1.581,-33.333 0.871,-0.333 24.496,-0.607 52.5,-0.607 28.543,0 50.919,-0.385 50.919,-0.877 0,-0.483 -0.664,-1.428 -1.476,-2.102 -0.811,-0.673 -2.476,-4.332 -3.699,-8.132 l -2.225,-6.906 -51.55,0.259 -51.55,0.258 -1.676,5.235 c -3.616,11.285 -12.963,19.363 -24.184,20.902 -7.919,1.085 -19.952,-4.097 -24.854,-10.706 -1.049,-1.413 -2.948,-5.444 -4.22,-8.958 -2.478,-6.843 -3.098,-7.471 -5.816,-5.891 z m 183.75,-19.205 c -6.675,4.16 -10.505,10.161 -10.494,16.438 0.021,11.928 8.991,20.356 20.684,19.438 12.334,-0.968 20.99,-13.103 17.307,-24.264 -1.558,-4.718 -6.58,-10.417 -10.699,-12.138 -4.741,-1.981 -13.2,-1.716 -16.798,0.526 m -156.8,-1.413 c -8.639,2.252 -13.704,9.103 -13.704,18.536 0,6.357 1.847,10.852 5.838,14.211 9.636,8.108 24.808,5.35 30.04,-5.461 3.269,-6.751 2.883,-13.384 -1.128,-19.386 -4.947,-7.402 -12.328,-10.172 -21.046,-7.9 z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Truck;
import AllCompanies from "./All";
import CompanyTable from "./Table";
import CreateCompany from "./Create";
import DeleteCompany from "./Delete";
import EditCompany from "./Edit";
import CreateCompanyUser from "./CreateCompanyUser";
import CompanyUsers from "./Users";
import CompanyUserTable from "./UserTable";
import CompanyVehicles from "./Vehicles";
import { CompanyVehicleSelector } from "../Vehicles/Selector";
import CompanyTrackingUnits from "./TrackingUnits";
import CompanyDetails from "./Details";
import CompanySelector from "./Selector";
import { CompanyVehicleSelectorCreate } from "../Vehicles/SelectorCreate";
import CompanyForm from "./Form";
import CompanyIndex from "./CompanyIndex";
import CompanySelectorCreate from "./SelectorCreate";
import CompanyVehicleTable from "./VehicleTable";

/**
 * React components related to companies
 */
class Companies {
  static Index = CompanyIndex;
  static All = AllCompanies;
  static Table = CompanyTable;
  static Create = CreateCompany;
  static Edit = EditCompany;
  static Delete = DeleteCompany;
  static CreateUser = CreateCompanyUser;
  static Users = CompanyUsers;
  static UsersTable = CompanyUserTable;
  static Vehicles = CompanyVehicles;
  static VehiclesTable = CompanyVehicleTable;
  static VehicleSelector = CompanyVehicleSelector;
  static VehicleSelectorCreate = CompanyVehicleSelectorCreate;
  static TrackingUnits = CompanyTrackingUnits;
  static Details = CompanyDetails;
  static Selector = CompanySelector;
  static SelectorCreate = CompanySelectorCreate;
  static Form = CompanyForm;
}
export default Companies;
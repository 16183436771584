import React, { FormEvent, useContext, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import UserContext from "../../contexts/userContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import useSessionStorage from "../../hooks/useSessionStorage";
import Account, {
  IAccountLogin as IAccountCredentials,
} from "../../models/account";
import { jwtResponse } from "../../models/jwtResponse";
import { IUserLogin } from "../../models/user";
import { parseJwtPayload } from "../Account/JwtAuthenticator";
import Centered from "./Centered";

const defaultCredentials = {
  email: "",
  password: "",
  rememberMe: true,
};
interface ValidationModel {
  email: boolean;
  password: boolean;
}
const defaultValidationModel: ValidationModel = {
  email: true,
  password: true,
};
const Login: React.FC = () => {
  const [credentials, setCredentials] =
    useState<IUserLogin>(defaultCredentials);
  const [loading, setLoading] = useState(false);
  const [showHome, setShowHome] = useState(false);
  const [valid, setValid] = useState<ValidationModel>(defaultValidationModel);
  const { setUser } = useContext(UserContext);

  const [, setSesUser] = useSessionStorage<jwtResponse | undefined>(
    "user",
    undefined
  );
  const [, setLocUser] = useLocalStorage<jwtResponse | undefined>(
    "user",
    undefined
  );

  const login = async () => {
    try {
      setLoading(true);
      setValid(defaultValidationModel);
      const creds: IAccountCredentials = {
        grant_type: "password",
        username: credentials.email,
        password: credentials.password,
      };
      const tokens = await Account.Login(creds);
      if (tokens) {
        const identity = parseJwtPayload(tokens.access_token);
        if (!identity) return;

        setLocUser(tokens);
        setSesUser(undefined);
        setUser({ identity, tokens, remember: true });
      }
      if (window.location.pathname === "/login") setShowHome(true);
    } catch (error) {
      setValid({
        email: false,
        password: false,
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await login();
  };

  if (showHome) {
    return <Redirect to="/" />;
  }

  const handleControlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    switch (event.target.name) {
      case "email":
        return setCredentials((prev) => ({ ...prev, email: value }));
      case "password":
        return setCredentials((prev) => ({ ...prev, password: value }));
    }
  };

  return (
    <Centered>
      <div className="mobile-login-form">
        <h4>Log ind</h4>
        <Form onSubmit={formSubmit}>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              autoComplete="email"
              placeholder="Indtast email addresse"
              value={credentials.email}
              onChange={handleControlChange}
              isInvalid={!valid.email}
            />
            <Form.Control.Feedback type="invalid">
              Forkert email
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Adgangskode</Form.Label>
            <Form.Control
              type="password"
              name="password"
              autoComplete="current-password"
              placeholder="Indtast adgangskode"
              value={credentials.password}
              onChange={handleControlChange}
              isInvalid={!valid.password}
            />
            <Form.Control.Feedback type="invalid">
              Forkert password
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant="success" type="submit">
            Log ind
            {loading && <Spinner size="sm" animation="border" />}
          </Button>
        </Form>
        <br />
        <Link to="/forgot">Glemt adgangskode</Link>
      </div>
    </Centered>
  );
};

export default Login;

import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { ITimedCoordinate } from "./gpsCoordinate";

export interface ITrackingUnitMount{
    id: number,
    unitId: string,
    initial: boolean,
    mountInfo: string,
    timestamp: string,
    position: ITimedCoordinate
}
export type TrackingUnitMountProps = "unitId" | "timestamp" | "initial" | "mountInfo";
export class TrackingUnitMount implements ITrackingUnitMount, ISortable<TrackingUnitMountProps>, ISearchable<TrackingUnitMountProps>{
    id: number;
    unitId: string;
    initial: boolean;
    mountInfo: string;
    timestamp: string;
    position: ITimedCoordinate;
    constructor(value: ITrackingUnitMount){
        this.id = value.id;
        this.unitId = value.unitId;
        this.initial = value.initial;
        this.mountInfo = value.mountInfo;
        this.timestamp = value.timestamp;
        this.position = value.position;
    }
    search = (value: any, type: TrackingUnitMountProps): number | undefined => 
        basicSearch(this, value, type, false);
    compare = (value: ISortable<TrackingUnitMountProps>, primary: TrackingUnitMountProps, secondary?: "unitId" | "timestamp" | "initial" | "mountInfo" | undefined): number => 
        basicSecSort(this, value, primary, secondary);

}
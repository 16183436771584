import useSortedSearch from "../../hooks/useSortedSearch";
import {
  IVehicleTachoResource,
  VehicleTachoProps,
  VehicleTachoResource,
} from "../../models/vehicleTacho";
import { Table as ReactTable } from "react-bootstrap";
import React from "react";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import PassivePagination from "../Pagination";
import Row from "./Row";

interface Props {
  values: IVehicleTachoResource[];
}

const Table = ({ values }: Props) => {
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    VehicleTachoResource,
    VehicleTachoProps
  >(
    values.map((v) => new VehicleTachoResource(v)),
    undefined,
    {
      type: "vehicle.alias",
      fallback: "vehicle.licensePlate",
      asc: true,
    }
  );

  return (
    <ReactTable bordered striped responsive>
      <thead>
        <tr>
          <SearchableHeader
            value={search}
            types={["vehicle.alias", "vehicle.licensePlate"]}
            onSearch={setSearch}
          />
          <th></th>
          <th></th>
          <SearchableHeader
            value={search}
            types={["tachograph.driver1Name"]}
            onSearch={setSearch}
          />
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <SortableHeader
            value={sort}
            type="vehicle.alias"
            fallback="vehicle.licensePlate"
            onChange={setSort}
          >
            Køretøj
          </SortableHeader>
          <th></th>
          <SortableHeader value={sort} type="tachograph.UpdatedAt" onChange={setSort}>
              Opdateret
          </SortableHeader>
          <SortableHeader value={sort} type="tachograph.driver1Name" onChange={setSort}>
              Chauffør
          </SortableHeader>
          <SortableHeader value={sort} type="tachograph.driver1ContinuousDrivingTime" onChange={setSort}>
              Brugt køreperiode (4:30)
          </SortableHeader>
          <SortableHeader value={sort} type="tachograph.driver1CumulativeBreakTime" onChange={setSort}>
              Afholdt pause (4:30)
          </SortableHeader>
          <SortableHeader value={sort} type="tachograph.driver1CurrentDailyDrivingTime" onChange={setSort}>
              Brugt daglig køretid
          </SortableHeader>
          <SortableHeader value={sort} type="tachograph.driver1CurrentWeeklyDrivingTime" onChange={setSort}>
              Brugt ugentlig køretid
          </SortableHeader>
        </tr>
      </thead>
      <tbody>
          <PassivePagination items={sorted} id="tachograph-vehicles" sizeSelector renderItem={item => (
              <Row key={item.vehicle.id} value={item} />
          )} />
      </tbody>
    </ReactTable>
  );
};

export default Table;

import React, { Fragment } from "react";
import { Form, FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { colourNameToHex } from "../../helpers/utils";
import { IVehicleBinding, IVehicleErrorBinding } from "../../models/vehicle";
import FormControlFeedback from "../FormControlFeedback";
import FormFeedback from "../FormFeedback";
import IconSelector from "../Icons/IconSelector";

interface Props {
  value: IVehicleBinding;
  onChange: (vehicle: IVehicleBinding) => void;
  error?: IVehicleErrorBinding;
}

const VehicleForm = ({ value, error, onChange }: Props) => {
  const handleControlChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if(!onChange) return;
    const controlValue = event.target.value;
    switch(event.target.name){
      case "licensePlate":
        return onChange({...value, licensePlate: controlValue});
      case "alias":
        return onChange({...value, alias: controlValue});
      case "color":
        return onChange({...value, color: controlValue});
    }
  };
  return (
    <Fragment>
      <Form.Group>
        <Form.Label>Indregisterings nr.</Form.Label>
        <Form.Control
          name="licensePlate"
          value={value.licensePlate}
          onChange={handleControlChange}
          isInvalid={error?.licensePlate !== undefined}
        />
        <FormFeedback type="invalid" values={error?.licensePlate} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Kaldenavn</Form.Label>
        <Form.Control
          name="alias"
          value={value.alias}
          onChange={handleControlChange}
          isInvalid={error?.alias !== undefined}
        />
        <FormFeedback type="invalid" values={error?.alias} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Ikon</Form.Label>

        <IconSelector
          value={value.icon}
          color={value.color}
          onChange={(icon) => {
            onChange &&
              onChange({
                ...value,
                icon,
              });
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Farve</Form.Label>

        <FormControl
          name="color"
          type="color"
          value={value.color}
          onChange={handleControlChange}
        />
      </Form.Group>
    </Fragment>
  );
};

export default VehicleForm;

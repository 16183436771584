import AllEmailTemplates from "./All";
import CreateEmailTemplate from "./Create";
import EditEmailTemplate from "./Edit";
import { EmailTemplateTypeLabel, EmailTemplateTypeSelector } from "./Types";
import EmailTemplateTable from "./Table";
import DeleteEmailTemplate from "./Delete";


class EmailTemplates{
    static All = AllEmailTemplates;
    static Create = CreateEmailTemplate;
    static Edit = EditEmailTemplate;
    static Delete = DeleteEmailTemplate;
    static Table = EmailTemplateTable;
    static TypeLabel = EmailTemplateTypeLabel;
    static TypeSelector = EmailTemplateTypeSelector;
}

export default EmailTemplates;
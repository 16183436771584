import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, FormGroup, FormLabel, InputGroup, Modal, Spinner } from "react-bootstrap";
import { BadRequestState } from "../../HttpErrors";
import Vehicle, {
  IVehicleBinding,
  IVehicleErrorBinding,
  IVehicleResource,
} from "../../models/vehicle";
import AdminContent from "../AdminContent";
import { TerminalIDSelector } from "../TachoSafe/Companies/TerminalSelector";
import Form from "./Form";

// export default withEdit<IVehicleResource, IVehicleBinding, FormProps<IVehicleBinding>>(Form, "Rediger køretøj", veh => veh, binding => binding.licensePlate !== "");
interface Props {
  vehicle: IVehicleResource;
  onUpdated?: (vehicle: IVehicleResource) => void;
}

const EditVehicle: React.FC<Props> = ({ vehicle: veh, onUpdated }) => {
  const [vehicle, setVehicle] = useState<IVehicleBinding>(veh);
  const [error, setError] = useState<IVehicleErrorBinding>();
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);
  const handleSuccess = (result: IVehicleBinding) => {
    setShown(false);
    if (onUpdated) {
      onUpdated({
        ...veh,
        alias: vehicle.alias,
        icon: vehicle.icon,
        color: vehicle.color,
        licensePlate: vehicle.licensePlate,
      });
    }
  };
  const handleModelError = (error: IVehicleErrorBinding) => {
    setError(error);
  };
  const saveChanges = async () => {
    try {
      setLoading(true);
      const [result, error] = await Vehicle.Update(veh.id, vehicle);
      if (result) handleSuccess(result);
      else if (error) handleModelError(error);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };
  return (
    <Fragment>
      <button
        title="Rediger køretøj"
        className="blank-button"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>

      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Rediger køretøj</Modal.Title>
          <Modal.Body>
            <Form error={error} value={vehicle} onChange={setVehicle} />
            <AdminContent>
              {veh.company && veh.canBeRemote && (
                <FormGroup>
                  <FormLabel>Terminal</FormLabel>
                  <TerminalIDSelector
                    value={vehicle.remote}
                    company={veh.company}
                    onChange={(term) =>
                      setVehicle((v) => ({ ...v, remote: term?.rduId }))
                    }
                  />
                </FormGroup>
              )}
            </AdminContent>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={loading} type="submit">
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setVehicle(vehicle);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default EditVehicle;

import React from "react";
import { Form } from "react-bootstrap";
import { FeedbackProps } from "react-bootstrap/esm/Feedback";

interface Props extends FeedbackProps{
    values?: string[]
}

const FormFeedback = ({values, ...rest}: Props) => (
    <Form.Control.Feedback {...rest}>
        {values && values.map((value, index) => (
            <span key={index}>{value}</span>
        ))}
    </Form.Control.Feedback>
)
export default FormFeedback;
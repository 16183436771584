import { Home } from "./Home";
import Login from "./Login";
import Navboard from "./Navboard";
import { NotFound } from "./NotFound";
import Routes, { MapRoutes } from "./Routes";


export default class Mobile{
    static Home = Home;
    static Login = Login;
    static Navboard = Navboard;
    static Routes = Routes;
    static MapRoutes = MapRoutes;
    static NotFound = NotFound;
}
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Moment from "react-moment";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import { IRemainingDrive } from "../../models/remainingDrive";
import Vehicle, { IVehicleResource } from "../../models/vehicle";
import { VehicleDisplay } from "../TachoSafe/TachoDisplay";
import { DrivingPeriodDisplay } from "./DrivingPeriodDisplay";
// import { DrivingPeriodDisplay } from "./DrivingPeriodDisplay";
import SpeedLabel from "./SpeedLabel";

interface Props {
  speed: number;
  online: boolean;
  vehicle: IVehicleResource;
  acc?: boolean;
}
const VehiclePopup: React.FC<Props> = ({ vehicle, online, speed, acc }) => {
  const request = useCallback(() => Vehicle.ReadRemainingDailyDrive(vehicle), [
    vehicle,
  ]);
  const [loading, setLoading] = useLoading(false);

  const [estimate, setEstimate] = useState<IRemainingDrive>();

  useEffect(() => {
    if (!vehicle.remote) {
      try {
        setLoading(true);
        const load = async () => {
          try {
            const remain = await request();
            setEstimate(remain);
          } catch (error) {
            console.error(error);
          }
        };
        load();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, [vehicle, request]);

  return (
    <div className="vehicle-popup">
      <div>
        <b>
          {vehicle.alias} - {vehicle.licensePlate}
        </b>
      </div>
      <div>
        Tænding: <b>{online ? "Ja" : "Nej"}</b>
      </div>
      <div>
        Hastighed: <SpeedLabel speed={speed} acc={acc} />
      </div>
      <div>
        <DrivingPeriodDisplay vehicle={vehicle} />
      </div>
      {loading ? (
        <Spinner animation="border" size="sm" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <Fragment>
          <br />
          {vehicle.remote && <VehicleDisplay value={vehicle} />}
          {estimate && (
            <Fragment>
              <b>
                <u>Anslået kørehviletid</u>
              </b>
              <div className="drive-rest">
                <div>
                  Resterende daglig køretid: <b>{estimate.remaining}</b>
                </div>
                {estimate.startOfDay && (
                  <div>
                    Køredøgns start:{" "}
                    <b>
                      <Moment local utc format="DD-MM-YYYY H:mm">
                        {estimate.startOfDay}
                      </Moment>
                    </b>
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default VehiclePopup;

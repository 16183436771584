import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import UserContext from "contexts/userContext";
import { useContext, useEffect, useRef, useState } from "react";
import { IJWTContent } from "../models/jwtContent";
import { jwtResponse } from "../models/jwtResponse";

export const createUserConnection = (url: string): HubConnection => {
  const protocol = new JsonHubProtocol();
  const transport = HttpTransportType.ServerSentEvents;
  const options = {
    transport,
    logMessageContent: false,
    accessTokenFactory: () => {
      var tokensJson =
        sessionStorage.getItem("user") || localStorage.getItem("user");
      if (!tokensJson) return "";
      const user: jwtResponse = JSON.parse(tokensJson) as jwtResponse;
      var token = user.access_token;
      token = token.replace(/['"]+/g, "");

      return token;
    },
  };

  let connection = new HubConnectionBuilder()
    .withUrl(url, options)
    .withHubProtocol(protocol)
    .configureLogging(LogLevel.Error)
    .withAutomaticReconnect()
    .build();
  return connection;
};

/**
 * Use a SignalR connection
 * @param url The URl to the SignalR hub
 * @param user The user to authenticate as
 */
const useSignalRConnection = (
  url: string
): [HubConnection | undefined, boolean] => {
  // const [connection, setConnection] = useState<HubConnection | undefined>();
  const { user } = useContext(UserContext);
  const [connection, setConnection] = useState<HubConnection>();
  const [started, setStarted] = useState(false);
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (user) {
      const con = createUserConnection(url);
      setStarted(false);
      setConnection(con);
    }
  }, [url, user]);

  const startConnection = async (connection: HubConnection) => {
    try {
      if(!mounted.current) return;
      await connection.start();
      console.info("Connection started");
      setStarted(true);
    } catch (error) {
      console.error("Failed to start connection", error);
      setTimeout(() => startConnection(connection), 5000);
    }
  };
  const stopConnection = async (connection: HubConnection) => {
    try {
      await connection.stop();
      console.info("Connection stopped");
      if (mounted.current) setStarted(false);
    } catch (error) {
      console.error("Failed to stop connection", error);
      setTimeout(() => stopConnection(connection), 5000);
    }
  };

  useEffect(() => {
    if (connection) {
      startConnection(connection);
    }

    return () => {
      if (connection) {
        stopConnection(connection);
      }
    };
  }, [connection]);

  return [connection, started];
};

export default useSignalRConnection;

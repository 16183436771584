import {
  faFastBackward,
  faFastForward,
  faStepBackward,
  faStepForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, ReactElement, useMemo, useState } from "react";
import usePageSize from "../hooks/usePageSize";
import PageSizeSelector from "./PageSizeSelector";

interface Props<T> {
  /**
   * The items to page
   */
  items: T[];
  /**
   * Function to render items on page
   */
  renderItem: (item: T, index: number) => ReactElement;
  /**
   * Whether or not a page size selector should be shown
   */
  id?: string;
  sizeSelector?: boolean;
  defaultPageSize?: number;
  showPages?: number;
  [x: string]: any;
}

const PassivePagination = <T extends any>({
  items,
  renderItem,
  id,
  sizeSelector,
  defaultPageSize,
  showPages,
  ...rest
}: Props<T>): ReactElement => {
  const [page, setPage] = useState<number>(0);

  const [pageSize, setPageSize] = usePageSize(defaultPageSize || 10, id);

  //Memorize the real page, eg the page that can be shown.
  //Unexpected side effect is that changing page size does not move the state away from the page
  //and therefore returns to the previous page if user changes page size back
  const realPage = useMemo(() => {
    const skip = page * pageSize;
    return skip > items.length ? Math.floor(items.length / pageSize) : page;
  }, [page, pageSize, items]);

  const pageItems = useMemo(() => {
    const skip = realPage * pageSize;
    return items.slice(skip, skip + pageSize);
  }, [realPage, pageSize, items]);

  const totalPages = useMemo(() => Math.ceil(items.length / pageSize), [
    items,
    pageSize,
  ]);
  return (
    <Fragment>
      {pageItems.map((item, index) =>
        renderItem(item, realPage * pageSize + index)
      )}
      <tr className="pager-row" {...rest}>
        <td colSpan={100}>
          {sizeSelector && (
            <div className="float-left pager-control">
              <PageSizeSelector
                value={pageSize}
                onChange={(size: number) => setPageSize(size)}
                rowCount={items.length}
              />
            </div>
          )}
          <div>
            <RenderPager
              showPages={showPages}
              totalPages={totalPages}
              currentPage={realPage}
              onChange={setPage}
            />
          </div>
        </td>
      </tr>
    </Fragment>
  );
};

export default PassivePagination;

interface PagerProps {
  /**
   * The total number of pages
   */
  totalPages: number;
  /**
   * The current page
   */
  currentPage: number;
  /**
   * Fires when another page is selected, currentPage should be updated as effect of this, otherwise the page will not change
   */
  onChange: (page: number) => void;
  showPages?: number;
}

/**
 * Renders a page selector
 * @param param0
 */
const RenderPager: React.FC<PagerProps> = ({
  currentPage,
  totalPages,
  onChange,
  showPages,
}) => {
  let pages = [];
  //Get page numbers, 3 behind and 3 in front of current
  for (let index = 0; index < totalPages; index++) {
    if (index === currentPage) {
      pages.push(index);
    } else if (
      index > currentPage - (showPages || 3) &&
      index < currentPage + (showPages || 3)
    ) {
      pages.push(index);
    }
  }
  return (
    <nav aria-label="Side skifter">
      <ul className="pagination justify-content-center">
        {/* first page control should be drawn when at page 1 or greater */}
        {currentPage > 1 && (
          <li className="page-item">
            <button
              className="page-link"
              title="Første"
              onClick={() => onChange(0)}
            >
              <div className="page-back-icon">
                <FontAwesomeIcon icon={faFastBackward} />
              </div>
              <span>1</span>
            </button>
          </li>
        )}
        {/* prev page control should be drawn when at page 0 or greater */}
        {currentPage > 0 && (
          <li className="page-item">
            <button
              className="page-link"
              title="Forrige"
              onClick={() => onChange(currentPage - 1)}
            >
              <FontAwesomeIcon icon={faStepBackward} />
            </button>
          </li>
        )}

        {pages.map((page) => (
          <li
            key={page}
            className={page === currentPage ? "page-item active" : "page-item"}
          >
            <button
              className="page-link"
              key={page}
              onClick={() => onChange(page)}
            >
              {page + 1}
              {page === currentPage && (
                <span className="sr-only">(nuværende)</span>
              )}
            </button>
          </li>
        ))}

        {/* next page control should be drawn when at lastpage -1 or less */}
        {currentPage < totalPages - 1 && (
          <li className="page-item">
            <button
              className="page-link"
              title="Næste"
              onClick={() => onChange(currentPage + 1)}
            >
              <FontAwesomeIcon icon={faStepForward} />
            </button>
          </li>
        )}
        {/* last page control should be drawn when at lastpage -2 or less */}
        {currentPage < totalPages - 2 && (
          <li className="page-item">
            <button
              className="page-link"
              title="Sidste"
              onClick={() => onChange(totalPages - 1)}
            >
              <span>{totalPages}</span>
              <div className="page-next-icon">
                <FontAwesomeIcon icon={faFastForward} />
              </div>
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

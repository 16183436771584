import { readFetch, updateFetch } from "../helpers/fetchers";

export interface IServiceStatus {
  online: boolean;
}

export interface IIPCServiceStatus {
  ipc: IServiceStatus;
  restarter: IServiceStatus;
  client: IServiceStatus;
  queue: IServiceStatus;
}

export class ServiceStatus {
  static ReadIPCStatus = async (abort?: AbortSignal): Promise<IIPCServiceStatus> =>
    (await readFetch(`api/Status/services/ipc`, abort)) as IIPCServiceStatus;

  static ReadEmulatorStatus = async (
    abort?: AbortSignal
  ): Promise<IServiceStatus> =>
    (await readFetch(`api/Status/services/emulators`, abort)) as IServiceStatus;

  static ReadAuthenticationStatus = async (
    abort?: AbortSignal
  ): Promise<IServiceStatus> =>
    (await readFetch(
      `api/Status/services/authentication`,
      abort
    )) as IServiceStatus;
  static ReadDrivingStatus = async (
    abort?: AbortSignal
  ): Promise<IServiceStatus> =>
    (await readFetch(`api/Status/services/driving`, abort)) as IServiceStatus;

  static SetIPCStatus = async (
    status: IServiceStatus,
    abort?: AbortSignal
  ): Promise<IServiceStatus> =>
    (await updateFetch(
      `api/Status/services/ipc`,
      status,
      abort
    )) as IServiceStatus;

  static SetEmulatorStatus = async (
    status: IServiceStatus,
    abort?: AbortSignal
  ): Promise<IServiceStatus> =>
    (await updateFetch(
      `api/Status/services/emulators`,
      status,
      abort
    )) as IServiceStatus;

  static SetAuthenticationStatus = async (
    status: IServiceStatus,
    abort?: AbortSignal
  ): Promise<IServiceStatus> =>
    (await updateFetch(
      `api/Status/services/authentication`,
      status,
      abort
    )) as IServiceStatus;
  static SetDrivingStatus = async (
    status: IServiceStatus,
    abort?: AbortSignal
  ): Promise<IServiceStatus> =>
    (await updateFetch(
      `api/Status/services/driving`,
      status,
      abort
    )) as IServiceStatus;

  static ReadTotals = async (abort?: AbortSignal): Promise<IServiceTotals> =>
    (await readFetch(`api/Status/totals`, abort)) as IServiceTotals;
}

export interface IServiceTotals {
  users: number;
  vehicles: number;
  companies: number;
  trackingUnits: number;
  gpsCoordinates: number;
  connectedTrackers: number;
}


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faForward, faPause } from "@fortawesome/free-solid-svg-icons";
import { IVehicleResource } from "../../models/vehicle";


interface Props{
    vehicle: IVehicleResource
}

const VehicleOnlineIcon:React.FC<Props> = ({vehicle}) => {
    if(vehicle.online){
        return <FontAwesomeIcon title="Tændt" icon={faForward} style={{ color: "green" }} />;
    }
    return <FontAwesomeIcon title="Slukket" icon={faPause} style={{color: "red"}} />
}

export default VehicleOnlineIcon;
import React from "react";
import { IJWTContent } from "../models/jwtContent";

export interface UserContextValue {
  user?: IJWTContent;
  setUser: (user: IJWTContent | undefined) => void;
}

const UserContext = React.createContext<UserContextValue>({
  user: undefined,
  setUser: () => {},
});

export default UserContext;

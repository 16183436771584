import React from "react";
import { useMemo } from "react";
import TimeSpan from "../models/timespan";

type DisplayFormat = "hours-minutes" | "hours-minutes-seconds" | "minutes-seconds" | "auto";
interface Props{
  value: TimeSpan,
  format: DisplayFormat
}

const TimeLabel = ({value, format}: Props) => {
  

  const formatSeconds = useMemo(() => TimeSpan.TwoDigitLeadZeroFormat(value.seconds), [value.seconds]);
  const formatMinutes = useMemo(() => TimeSpan.TwoDigitLeadZeroFormat(value.minutes), [value.minutes]);

  if(format === "auto"){
    if(value.hours > 0) format = "hours-minutes-seconds";
    else format = "minutes-seconds";
  }
  
  switch(format){
    case "hours-minutes": return <label>{value.hours}:{formatMinutes}</label>
    case "hours-minutes-seconds": return <label>{value.toString()}</label>
    case "minutes-seconds": return <label>{formatMinutes}:{formatSeconds}</label>
  }
}
export default TimeLabel;
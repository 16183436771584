import {
  faChevronDown,
  faChevronUp,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import UserContext from "../../contexts/userContext";
import CompanyDashboard from "./CompanyDashboard";
import useTitle from "../../hooks/useTitle";
import Bordered from "../Bordered";
import ContactDetails from "../ContactDetails";

const Dashboard: React.FC = () => {
  const { user } = useContext(UserContext);
  useTitle("DigiFleet - Dashboard");
  return (
    <Fragment>
      
      <Tabs
        defaultActiveKey="company-0"
        id="company-dash-tabs"
        unmountOnExit={true}
      >
        {user &&
          user.identity.companies.map((company, index) => (
            <Tab
              key={company.id}
              eventKey={`company-${index}`}
              title={company.name}
            >
              <CompanyDashboard company={company} />
            </Tab>
          ))}
      </Tabs>
      <hr />
      <Bordered shrinkToFit>
        <ContactDetails />
      </Bordered>
    </Fragment>
  );
};

export default Dashboard;

interface ItemProps {
  dashTitle: string;
  defaultOpen?: boolean;
}
export const DashboardItem: React.FC<ItemProps> = ({
  children,
  dashTitle,
  defaultOpen,
}) => {
  const [shown, setShown] = React.useState(defaultOpen || false);

  if (shown) {
    return (
      <div className="dashboard-item bordered">
        <div className="dashboard-item-title active">
          {dashTitle}
          <Button size="sm" onClick={() => setShown(false)}>
            <FontAwesomeIcon icon={faChevronUp} />
          </Button>
        </div>
        <div className="dashboard-item-content">{children}</div>
      </div>
    );
  }
  return (
    <div className="dashboard-item bordered">
      <div className="dashboard-item-title">
        {dashTitle}
        <Button size="sm" onClick={() => setShown(true)}>
          <FontAwesomeIcon icon={faChevronDown} />
        </Button>
      </div>
    </div>
  );
};

interface IconProps extends ItemProps {
  icon: IconDefinition;
}

export const DashboardItemWithIcon: React.FC<IconProps> = ({
  icon,
  dashTitle,
  children,
  defaultOpen,
}) => {
  const [shown, setShown] = React.useState(defaultOpen || false);

  if (shown) {
    return (
      <div className="dashboard-item bordered">
        <div className="dashboard-title-container active">
          <div className="dashboard-icon">
            <FontAwesomeIcon size="lg" icon={icon} />
          </div>
          <div className="dashboard-icon-title">
            {dashTitle}
            <Button size="sm" onClick={() => setShown(false)}>
              <FontAwesomeIcon icon={faChevronUp} />
            </Button>
          </div>
        </div>
        <div className="dashboard-item-content">{children}</div>
      </div>
    );
  }
  return (
    <div className="dashboard-item bordered">
      <div className="dashboard-title-container">
        <div className="dashboard-icon">
          <FontAwesomeIcon size="lg" icon={icon} />
        </div>
        <div className="dashboard-icon-title">
          {dashTitle}
          <Button size="sm" onClick={() => setShown(true)}>
            <FontAwesomeIcon icon={faChevronDown} />
          </Button>
        </div>
      </div>
    </div>
  );
};

import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Moment from "react-moment";
import useLoading from "hooks/useLoading";
import usePromise from "hooks/usePromise";
import { IRemainingDrive } from "models/remainingDrive";
import Vehicle, { IVehicleResource } from "models/vehicle";
import SpeedLabel from "components/Tracking/SpeedLabel";
import Label from "components/Vehicles/Label";
import { DrivingPeriodDisplay } from "components/Tracking/DrivingPeriodDisplay";
// import { VehicleDisplay } from "../TachoSafe/TachoDisplay";
// import SpeedLabel from "./SpeedLabel";

interface Props {
  speed: number;
  online: boolean;
  vehicle: IVehicleResource;
  acc?: boolean;
}
const VehiclePopup: React.FC<Props> = ({ vehicle, online, speed, acc }) => {
  return (
    <div className="mobile-vehicle-popup">
      <p>
        <b>
          <Label value={vehicle} />
        </b>
      </p>
      <hr />
      <SpeedLabel speed={speed} acc={acc} />
      <DrivingPeriodDisplay vehicle={vehicle} />
      <p>{acc ? "Tændt" : "Slukket"}</p>
    </div>
  );
};

export default VehiclePopup;

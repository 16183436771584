import { createFetch, deleteFetch, readFetch, updateFetch } from "../helpers/fetchers";
import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { IVehicleResource } from "./vehicle";

export interface IFlexVehicleResource{
    vehicleId: number,
    flexId: number,
    vehicle?: IVehicleResource
};

type VehicleProps = "vehicle.alias" | "vehicle.licensePlate";
export type FlexVehicleProps = "vehicleId" | "flexId" | VehicleProps;
export class FlexVehicleResource implements IFlexVehicleResource, ISortable<FlexVehicleProps>, ISearchable<FlexVehicleProps>{
  vehicleId: number;
  flexId: number;
  vehicle?: IVehicleResource;

  constructor(value: IFlexVehicleResource){
    this.vehicleId = value.vehicleId;
    this.flexId = value.flexId;
    this.vehicle = value.vehicle;
  }
  search = (value: any, type: FlexVehicleProps): number | undefined => 
    basicSearch(this, value, type, false);
  compare = (value: ISortable<FlexVehicleProps>, primary: FlexVehicleProps, secondary?: FlexVehicleProps): number => 
    basicSecSort(this, value, primary, secondary);

}
export interface IFlexVehicleBinding{
    vehicleId: number,
    flexId: number
};


class FlexVehicle{
    static Create = async (
        flexVehicle: IFlexVehicleBinding,
        abort?: AbortSignal
      ): Promise<IFlexVehicleResource> =>
        (await createFetch("api/FlexVehicles/", flexVehicle, abort)) as IFlexVehicleResource;
    
      static Read = async (
        id: number,
        abort?: AbortSignal
      ): Promise<IFlexVehicleResource> =>
        (await readFetch("api/FlexVehicles/" + id, abort)) as IFlexVehicleResource;
    
      static ReadAll = async (abort?: AbortSignal): Promise<IFlexVehicleResource[]> =>
        (await readFetch("api/FlexVehicles", abort)) as IFlexVehicleResource[];
    
      static Update = async (
        id: number,
        flexVehicle: IFlexVehicleBinding,
        abort?: AbortSignal
      ): Promise<void> => {
        await updateFetch(`api/FlexVehicles/${id}`, flexVehicle, abort);
      };
      static Delete = async (
        flexVehicle: IFlexVehicleResource,
        abort?: AbortSignal
      ): Promise<void> => {
        await deleteFetch(`api/FlexVehicles/${flexVehicle.vehicleId}`, abort);
      };
}

export default FlexVehicle;
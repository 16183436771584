import L from "leaflet";
import React, { useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Marker } from "react-leaflet";
import { ICoordinate } from "../../models/gpsCoordinate";
import Bike from "./Bike";
import Bus from "./Bus";
import Bus2 from "./Bus2";
import { IconNames } from "./Index";
import Trailer from "./Trailer";
import Truck from "./Truck";
import Van from "./Van";

interface Props {
  name: IconNames;
}

const Icon = ({ name }: Props) => {
  switch (name) {
    case "bike":
      return <Bike />;
    case "bus":
      return <Bus />;
    case "bus2":
      return <Bus2 />;
    case "trailer":
      return <Trailer />;
    case "truck":
      return <Truck />;
    case "van":
      return <Van />;
  }
};

export default Icon;

interface VehicleProps extends Props {
  color?: string;
  online?: boolean;
  still?: boolean;
}
export const VehicleIcon = ({ name, color, online, still }: VehicleProps) => {
  const className = useMemo(
    () =>
      online
        ? still
          ? "vehicle-icon active still"
          : "vehicle-icon active"
        : "vehicle-icon",
    [online, still]
  );
  return (
    <div className={className} style={{ color: color }}>
      <Icon name={name} />
    </div>
  );
};
interface MapProps extends VehicleProps {
  position: ICoordinate;
  angle: number;
  scale?: number;
  zoom: number;
}

const scaleModifer = 1.083;

export const MapVehicleIcon: React.FC<MapProps> = ({
  position,
  angle,
  online,
  still,
  scale,
  zoom,
  name,
  color,
  children,
}) => {
  const flip = useMemo(() => angle > 180, [angle]);
  const scalePercent = scale || 1;
  const size = useMemo(
    () => Math.pow(zoom, scaleModifer) * scalePercent * 1.6,
    [zoom, scalePercent]
  );

  const iconHtml = useMemo(
    () =>
      renderToStaticMarkup(
        <VehicleIcon name={name} color={color} online={online} still={still} />
      ),
    [name, color]
  );

  const flipY = flip ? -1 : 1;
  const divIcon = useMemo(() => {
    return L.divIcon({
      html: `<div class='map-icon-container'><div style='transform: rotate(${angle - 90}deg) scale(-1, ${flipY});'><span>${iconHtml}</span></div></div>`,
      iconSize: [size, size],

      iconAnchor: [size / 2, size / 2],
      className: "",
    });
  }, [angle, size, flipY, iconHtml]);
  const mapPos: [number, number] = [position.latitude, position.longitude];

  return (
    <Marker position={mapPos} icon={divIcon}>
      {children}
    </Marker>
  );
};

const CalculateSize = (size: number, length: number): number => {
  return (size * length) / 2.2;
};

interface TextProps {
  value: string;
  online?: boolean;
  still?: boolean;
  position: ICoordinate;
  scale?: number;
  zoom: number;
  angle: number;
}

export const TextVehicleIcon: React.FC<TextProps> = ({
  value,
  children,
  online,
  still,
  position,
  scale,
  zoom,
  angle,
}) => {
  const scalePercent = scale || 1;
  const size = useMemo(
    () => Math.pow(zoom, scaleModifer) * scalePercent * 1.6,
    [zoom, scalePercent]
  );
  const className = useMemo(
    () =>
      online
        ? still
          ? "vehicle-icon active still"
          : "vehicle-icon active"
        : "vehicle-icon",
    [online, still]
  );
  const mAngle = angle % 360;
  const direction = useMemo(() => {
    if (mAngle >= 315 || mAngle < 45) return "up";
    else if (mAngle >= 45 && mAngle < 135) return "right";
    else if (mAngle >= 135 && mAngle < 225) return "down";
    else return "left";
  }, [mAngle]);

  const arrowHtml = useMemo(() => {
    if (zoom > 9) {
      return `<div class='icon-director ${direction}'><div style='transform: rotate(${mAngle}deg)' class='icon-rotator ${direction}'><div class='icon-arrow'><div></div></div></div></div>`;
    }
    return "";
  }, [mAngle, direction, zoom]);
  const iconHtml = useMemo(
    () =>
      `<div class='map-icon-container text'>${arrowHtml}<div class='${className}' style='font-size: ${
        size / 2
      }px'><span>${value}</span></div>
      </div>`,
    [className, size, value, arrowHtml]
  );

  const computedSize = useMemo(() => CalculateSize(size, value.length), [
    size,
    value,
  ]);

  const divIcon = useMemo(() => {
    return L.divIcon({
      html: iconHtml,
      iconSize: [computedSize, size],
      iconAnchor: [computedSize / 2, size / 2],
      className: "",
    });
  }, [size, iconHtml, computedSize]);
  const mapPos: [number, number] = [position.latitude, position.longitude];

  return (
    <Marker position={mapPos} icon={divIcon}>
      {children}
    </Marker>
  );
};

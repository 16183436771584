import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, FormControl, InputGroup, Modal, Spinner } from "react-bootstrap";
import Company, { ICompanyResource } from "../../models/company";
interface Props {
  company: ICompanyResource;
  onDeleted?: (company: ICompanyResource) => void;
}
const DeleteCompany: React.FC<Props> = ({ company, onDeleted }) => {
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);
  const deleteVehicle = async () => {
    try {
      setLoading(true);
      await Company.Delete(company)
      setLoading(false);
      setShown(false);
      if (onDeleted) {
        onDeleted(company);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const formDelete = async (event: FormEvent) => {
    event.preventDefault();
    await deleteVehicle();
  };
  return (
    <Fragment>
      <button title="Slet virksomhed" className="blank-button" onClick={() => setShown(true)}>
        <FontAwesomeIcon icon={faTrash} />
      </button>

      <Modal show={shown} onHide={() => setShown(true)}>
        <form onSubmit={formDelete}>
          <Modal.Title>Slet virksomhed</Modal.Title>
          <Modal.Body>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Navn</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly={true}
                defaultValue={company.name}
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" disabled={loading} type="submit">
              Slet {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShown(false)}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default DeleteCompany;

import React from "react";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NotFound = () => (
  <>
    <Alert variant="warning">
      <b>Ups!</b> Siden blev ikke fundet
    </Alert>
    <Link to="/">
        <Button>Til start side</Button>
    </Link>
  </>
);

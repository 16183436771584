import React from "react";
import { useCallback, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import Company, { ICompanyResource } from "../../models/company";
import { IVehicleResource } from "../../models/vehicle";

interface Props {
  company: ICompanyResource;
  onCreated: (values: IVehicleResource[]) => void;
}

const SyncRemote = ({ company, onCreated }: Props) => {
  const [create, setCreate] = useState(false);
  const request = useCallback(() => Company.SyncRemote(company, create), [
    company,
    create,
  ]);
  const [loading, setLoading] = useLoading(false);

  const sync = async () => {
    try {
      setLoading(true);
      const created = await request();
      onCreated(created);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button variant="warning" onClick={sync}>
        Synkroniser remote køretøjer
      </Button>
      {loading && <Spinner animation="border" />}
      <br />
      <label>
        <input
          type="checkbox"
          checked={create}
          onClick={() => setCreate((prev) => !prev)}
        />
        Opret remote køretøjer som DigiFleet køretøjer
      </label>
    </div>
  );
};

export default SyncRemote;

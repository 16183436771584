import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useMemo } from "react";
import { renderToString } from "react-dom/server";
import L from "leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Marker } from "react-leaflet";

interface Props{
    icon: IconProp;
    position: L.LatLngExpression
}

const MapFAIcon:React.FC<Props> = ({icon, position}) => {
    
    const iconHTML = useMemo(
        () => renderToString(<FontAwesomeIcon icon={icon} />),
        [icon]
      );

      const divIcon = L.divIcon({
        html: `<div>${iconHTML}</div>`,
        // iconAnchor: [Math.sqrt(iconSize), (iconSize / 2)],
        className: "",
      });
    return <Marker icon={divIcon} position={position} />
}

export default MapFAIcon;
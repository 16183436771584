import { HubConnection } from "@microsoft/signalr";
import { LatLngBounds } from "leaflet";
import React, { Component, Fragment } from "react";
import TrackLoader from "models/trackLoader";
import Vehicle, { IVehicleResource } from "models/vehicle";
import { VehicleTrack2 } from "./VehicleTrack";

interface Props {
  vehicles: IVehicleResource[];
  pathsToShow: IVehicleResource[];
  connection: HubConnection;
  bounds: LatLngBounds;
  scale?: number;
  zoom: number;
  showTooltip: boolean;
  onLoadTracks?: (loading: boolean) => void;
}

interface State {
  tracks: TrackLoader[];
  pathsToShow: IVehicleResource[];
  bounds: LatLngBounds;
}

export default class TrackRenderer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tracks: [],
      pathsToShow: props.pathsToShow,
      bounds: props.bounds,
    };
  }

  loadTracks = async (vehicles: IVehicleResource[]) => {
    if(this.props.onLoadTracks) this.props.onLoadTracks(true);
    var tracks: TrackLoader[] = [];

    const mapped = await Vehicle.ReadManyTracking(vehicles);
    for (const vehicle of vehicles) {
      const track = new TrackLoader(vehicle);
      const map = mapped.find(m => m.id === vehicle.id);
      if(map) track.coordinates = map.coordinates;
      else track.ReadCoordinates();
      
      track.SetConnection(this.props.connection);
      tracks = [...tracks, track];
    }
    this.setState((cur) => ({ ...cur, tracks: [...cur.tracks, ...tracks] }));
    if(this.props.onLoadTracks) this.props.onLoadTracks(false);
  };

  componentDidMount = async () => {
    return this.loadTracks(this.props.vehicles);
  };

  componentDidUpdate = async (prevProps: Props) => {
    if (prevProps.vehicles.length !== this.props.vehicles.length) {
      const removed = prevProps.vehicles.filter(
        (v1) => this.props.vehicles.findIndex((v2) => v2.id === v1.id) === -1
      );
      const added = this.props.vehicles.filter(
        (v1) => prevProps.vehicles.findIndex((v2) => v2.id === v1.id) === -1
      );
      if (removed.length > 0) {
        const newTracks = this.state.tracks.filter(
          (t) => removed.findIndex((vr) => vr.id === t.vehicle.id) === -1
        );
        this.setState({ tracks: newTracks });
      } else if (added.length > 0) {
        this.loadTracks(added);
      }
    }
    if(prevProps.connection !== this.props.connection){
      for(const track of this.state.tracks){
        track.SetConnection(this.props.connection);
      }
    }
  };

  static getDerivedStateFromProps = (props: Props, state: State) => {
    if (props.bounds !== state.bounds) {
      return { ...state, bounds: props.bounds };
    }
    if(props.pathsToShow !== state.pathsToShow){
      return {...state, pathsToShow: props.pathsToShow};
    }
    return null;
  };

  private shouldShowPath = (vehicle: IVehicleResource) => {
    return this.props.pathsToShow.findIndex((v) => v.id === vehicle.id) !== -1;
  };

  render = () => {
    return this.state.tracks
      .filter((t) => t.InBounds(this.state.bounds))
      .map((t, index) => (
        <Fragment key={t.vehicle.id}>
          <VehicleTrack2
            loader={t}
            path={this.shouldShowPath(t.vehicle)}
            showTooltip={this.props.showTooltip}
            zoom={this.props.zoom}
          />
        </Fragment>
      ));
  };
}

import React, { FormEvent, useState, useCallback } from "react";
import {
  Alert,
  Badge,
  Button,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import useSuccess from "../../hooks/useSuccess";
import TrackingUnitQR from "../../models/trackingUnitQR";
import { IVehicleBinding, IVehicleResource } from "../../models/vehicle";
import FormControlFeedback from "../FormControlFeedback";
import Vehicles from "../Vehicles/Index";

interface Props {
  token: string;
}
const QRVehicleAssign: React.FC<Props> = ({ token }) => {
  const request = useCallback(
    (signal: AbortSignal) => TrackingUnitQR.ReadVehicles(token, signal),
    [token]
  );
  const [loading, setLoading] = useLoading();
  const [vehicles] = usePromise(request, [], setLoading);

  const [vehicle, setVehicle] = useState<IVehicleResource | undefined>();
  const [mountInfo, setMountInfo] = useState("");

  const [vehicleCreate, setVehicleCreate] = useState<
    IVehicleBinding | undefined
  >();

  const [error, setError] = useState<Error | undefined>();
  const [assignLoading, setAssignLoading] = useState(false);
  const [success, succeed] = useSuccess(3000);
  const [redirectSuccess, redirect] = useSuccess(6000);

  const submit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      setAssignLoading(true);
      var assignVehicle: IVehicleResource;

      if (vehicleCreate) {
        assignVehicle = await TrackingUnitQR.CreateVehicle(
          token,
          vehicleCreate
        );
      } else if (vehicle) {
        assignVehicle = vehicle;
      } else {
        return;
      }
      await TrackingUnitQR.AssignVehicle(token, assignVehicle, mountInfo);
      succeed();
      redirect();
    } catch (error) {
      setError(error);
      console.log(error);
    } finally {
      setAssignLoading(false);
    }
  };

  if (success) {
    return (
      <Badge variant="success">Enheden og køretøjet er nu forbundet!</Badge>
    );
  }
  if (redirectSuccess) {
    return <Redirect to="/" />;
  }

  if (loading || !vehicles) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="qr-code-assign">
      <Alert variant="success">
        Vælg et køretøj i listen eller tryk på plusset for at oprette et nyt
        køretøj hvis det ikke findes i listen
      </Alert>
      <hr />
      <form onSubmit={submit}>
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Køretøj</InputGroup.Text>
            </InputGroup.Prepend>
            <Vehicles.SelectorCreate
              vehicles={vehicles}
              value={vehicle}
              error={error}
              onChange={setVehicle}
              createValue={vehicleCreate}
              onCreateChange={setVehicleCreate}
            />
          </InputGroup>
          <hr />
          <Alert variant="success">
            Evt. andre oplysninger om monteringen, f.eks hvor boksen ligger i
            køretøjet
          </Alert>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Info</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControlFeedback
              errors={error}
              field="mountInfo"
              value={mountInfo}
              onChange={(mountInfo: string) => setMountInfo(mountInfo)}
            />
          </InputGroup>
        </Form.Group>
        <Button
          variant="success"
          disabled={assignLoading || !vehicles}
          type="submit"
        >
          Gem {assignLoading && <Spinner animation="border" size="sm" />}
        </Button>
      </form>
    </div>
  );
};

export default QRVehicleAssign;

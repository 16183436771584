import React, { Fragment, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { geoFeatureToCoordinate } from "../../helpers/utils";
import { GeoFeature } from "../../models/geoAddress";
import { ICoordinate } from "../../models/gpsCoordinate";
import AddressSelector from "../AddressSelector";
import DateSelector from "../Coordinates/DateSelector";

interface Props {
  onChange: (value: ProximityFormContent) => void;
  value: ProximityFormContent;
}

export interface ProximityFormContent {
  from: Date;
  to: Date;
  coordinate?: ICoordinate;
}

const ProximityForm: React.FC<Props> = ({ value, onChange }) => {
  const [address, setAddress] = useState<GeoFeature>();

  const changeAddress = (addr: GeoFeature) => {
    setAddress(addr);
    onChange({ ...value, coordinate: geoFeatureToCoordinate(addr) });
  }
  return (
    <Fragment>
      <FormGroup>
        <DateSelector
          from={value.from}
          to={value.to}
          onChange={(from, to) => {
            onChange({ ...value, from, to });
          }}
        />
      </FormGroup>
      <FormGroup>
        <AddressSelector
          onChange={changeAddress}
        />
        {address && (
        <div>
          <span>Adresse: <b>{address.place_name}</b></span>
        </div>
      )}
      </FormGroup>
      
    </Fragment>
  );
};

export default ProximityForm;

import React, { useState } from "react";
import FullScreen from "react-full-screen";
import TrackingMap from "./TrackingMap";
import useTitle from "../../hooks/useTitle";
import { RouteComponentProps } from "react-router-dom";
import LiveMap from "./LiveMap";

const Tracking: React.FC = () => {
  const [fullscreen, setFullscreen] = useState(false);
  useTitle("DigiFleet - Live");

  return (
    <FullScreen
      enabled={fullscreen}
      onChange={(isFull) => setFullscreen(isFull)}
    >
      <LiveMap isFullscreen={fullscreen} fullscreenChange={setFullscreen} />
    </FullScreen>
  );
};

export default Tracking;

interface MatchParams {
  id: string;
}
interface ParamProps extends RouteComponentProps<MatchParams> {}

export const TrackingPreselected: React.FC<ParamProps> = ({ match }) => {
  const id = +match.params.id;
  const [fullscreen, setFullscreen] = useState(false);
  useTitle("DigiFleet - Live");

  return (
    <FullScreen
      enabled={fullscreen}
      onChange={(isFull) => setFullscreen(isFull)}
    >
      <LiveMap
        preselected={id}
        isFullscreen={fullscreen}
        fullscreenChange={setFullscreen}
      />
    </FullScreen>
  );
};

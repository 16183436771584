import { faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IVehicleResourceWithUnit } from "../../models/vehicle";
import { VehicleIcon } from "../Icons/Icon";
import Vehicles from "./Index";

interface Props {
  value: IVehicleResourceWithUnit;
  onUpdate: (updated: IVehicleResourceWithUnit) => void;
  onDelete: (deleted: IVehicleResourceWithUnit) => void;
}

const VehicleRow = ({ value, onUpdate, onDelete }: Props) => {
  const className = useMemo(() => (value.inActive ? "inactive" : ""), [value]);

  return (
    <tr className={className}>
      <td>{value.icon && <VehicleIcon name={value.icon} color={value.color} />}</td>
      <td>
        <Link title="Vis på kort" to={`/map/live/${value.id}`}>
          <Button size="sm" variant="secondary">
            <FontAwesomeIcon icon={faSearchLocation} />
          </Button>
        </Link>
      </td>
      <td>{value.alias}</td>
      <td>{value.licensePlate}</td>
      <td>{value.unit ? `Ja: ${value.unit.id}`: "Nej"}</td>
      <td>
        <Vehicles.OnlineIcon vehicle={value} />
      </td>
      <td>
        <Vehicles.SetActive vehicle={value} onChange={onUpdate} />
      </td>
      <td>
        <Vehicles.Edit vehicle={value} onUpdated={onUpdate} />
        <Vehicles.Delete vehicle={value} onDeleted={onDelete} />
      </td>
    </tr>
  );
};

export default VehicleRow;

export const VehicleCompanyRow = ({ value, onUpdate, onDelete }: Props) => {
  const className = useMemo(() => (value.inActive ? "inactive" : ""), [value]);

  return (
    <tr className={className}>
      <td>{value.icon && <VehicleIcon name={value.icon} color={value.color} />}</td>
      <td>
        <Link title="Vis på kort" to={`/map/live/${value.id}`}>
          <Button size="sm" variant="secondary">
            <FontAwesomeIcon icon={faSearchLocation} />
          </Button>
        </Link>
      </td>
      <td>{value.company?.name || ""}</td>
      <td>{value.alias}</td>
      <td>{value.licensePlate}</td>
      <td>{value.unit ? `Ja: ${value.unit.id}`: "Nej"}</td>
      <td>{value.remote ? `Ja: ${value.remote}, ${value.remoteReg || ""}`: "Nej"}</td>
      <td>
        <Vehicles.OnlineIcon vehicle={value} />
      </td>
      <td>
        <Vehicles.SetActive vehicle={value} onChange={onUpdate} />
      </td>
      <td>
        <Vehicles.Edit vehicle={value} onUpdated={onUpdate} />
        <Vehicles.Delete vehicle={value} onDeleted={onDelete} />
      </td>
    </tr>
  );
};

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useMemo, useState } from "react";
import { Alert, Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import CoordinateReflector, { ICoordinateReflectorBinding, ICoordinateReflectorResource } from "../../models/coordinateReflector";
import Vehicles from "../Vehicles/Index";
import CoordinateReflectors from "./Index";

interface Props {
    onCreated: (reflector: ICoordinateReflectorResource) => void;
  }
  const defaultReflector: ICoordinateReflectorBinding = {
    from: undefined,
    to: undefined,
  };
  const Create = ({ onCreated }: Props) => {
    const [reflector, setReflector] = useState<ICoordinateReflectorBinding>(defaultReflector);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useLoading(false);
    const [shown, setShown] = useState(false);
  
    /**
     * Save the changes, creates the vehicle
     */
    const saveChanges = async () => {
      try {
        setLoading(true); //Show loading while the vehicle is being created
        const created = await CoordinateReflector.Create(reflector);
        setLoading(false);
        setShown(false);
        onCreated(created);
      } catch (error) {
        setLoading(false);
        setError(error);
        console.error(error);
      }
    };
    /**
     * Save when the form is submitted
     * @param event
     */
    const formSave = async (event: FormEvent) => {
      event.preventDefault();
      event.stopPropagation();
      await saveChanges();
    };

    const complete = useMemo(() => reflector.from !== undefined && reflector.to !== undefined, [reflector])
    return (
      <Fragment>
        <Button title="Opret koordinat reflektor" onClick={() => setShown(true)}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
  
        <Modal show={shown} onHide={() => setShown(false)}>
          <form onSubmit={formSave}>
            <Modal.Title>Opret koordinat reflektor</Modal.Title>
            <Modal.Body>
                <CoordinateReflectors.Form value={reflector} onChange={setReflector} />
                <Alert variant="warning">
                  Forespørgsler på koordinater for <b>'til'</b> køretøjet vil i stedet returnere koordinater for <b>'fra'</b> køretøjet
                </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" disabled={loading || !complete} type="submit">
                Opret {loading && <Spinner animation="border" size="sm" />}
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setReflector(defaultReflector);
                  setShown(false);
                }}
              >
                Luk
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </Fragment>
    );
  };

  export default Create;
import React from "react";
import SearchBox from "./SearchBox";
export interface SearchValue<T> {
  value: any;
  types: T[];
  exact?: boolean;
}
interface Props<T> {
  types: T[];
  value?: SearchValue<T>;
  exact?: boolean;
  onSearch: (value: SearchValue<T>) => void;
}

const SearchableHeader = <T extends any>({ value, types, onSearch, exact }: Props<T>) => {
  return (
    <th>
      <SearchBox
        className="form-control"
        placeholder="Søg"
        onChange={(event) => onSearch({ value: event.target.value, types, exact })}
      />
    </th>
  );
};

export default SearchableHeader;

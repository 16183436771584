import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { FormEvent, useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from "react-bootstrap";
import SEGESCompany, { ISEGESCompanyResource } from "../../../models/segesCompany";

interface Props {
    company: ISEGESCompanyResource;
    onDeleted?: (company: ISEGESCompanyResource) => void;
  }
  const Delete: React.FC<Props> = ({ company, onDeleted }) => {
    const [loading, setLoading] = useState(false);
    const [shown, setShown] = useState(false);
    const deleteVehicle = async () => {
      try {
        setLoading(true);
        await SEGESCompany.Delete(company)
        setLoading(false);
        setShown(false);
        if (onDeleted) {
          onDeleted(company);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const formDelete = async (event: FormEvent) => {
      event.preventDefault();
      await deleteVehicle();
    };
    return (
      <Fragment>
        <button
          title="Slet SEGES virksomhed"
          className="blank-button"
          onClick={() => setShown(true)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
  
        <Modal show={shown} onHide={() => setShown(true)}>
          <form onSubmit={formDelete}>
            <Modal.Title>Slet SEGES virksomhed</Modal.Title>
            <Modal.Body>
              {company.company && (
                  <Form.Group>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Virksomhed</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl readOnly={true} defaultValue={company.company.name} />
                  </InputGroup>
                </Form.Group>
              )}
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Type</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl readOnly={true} defaultValue={company.authorizationNumber} />
                </InputGroup>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="warning" disabled={loading} type="submit">
                Slet {loading && <Spinner animation="border" size="sm" />}
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setShown(false);
                }}
              >
                Luk
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </Fragment>
    );
  };
  
  export default Delete;
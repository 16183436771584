import { HubConnection } from "@microsoft/signalr";
import { LatLngBounds } from "leaflet";
import { IGPSCoordinate } from "./gpsCoordinate";
import Vehicle, { IVehicleResource } from "./vehicle";

export default class TrackLoader {
  vehicle: IVehicleResource;
  coordinates: IGPSCoordinate[];
  private connection?: HubConnection;
  onCoordinateArrived?: (coordinate: IGPSCoordinate) => void;

  constructor(vehicle: IVehicleResource) {
    this.vehicle = vehicle;
    this.coordinates = [];
  }

  ReadCoordinates = async (signal?: AbortSignal) => {
    try {
      this.coordinates = await Vehicle.ReadTracking(this.vehicle, signal);
      if(!this.coordinates) this.coordinates = [];
    } catch (error) {
      console.error(error);
      this.coordinates = [];
    }
  };

  private CoordinateArrived = (coordinate: IGPSCoordinate) => {
    if (coordinate.vehicleId === this.vehicle.id) {
      if(this.onCoordinateArrived) this.onCoordinateArrived(coordinate);
      this.coordinates = [coordinate, ...this.coordinates];
    }
  };
  SetConnection = (connection?: HubConnection) => {
    this.connection = connection;
    if (this.connection) {
      this.connection.on("CoordinateArrived", this.CoordinateArrived);
    }
  };

  InBounds = (bounds: LatLngBounds): boolean => {
    for (const coord of this.coordinates) {
      const leafCoord: [number, number] = [coord.latitude, coord.longitude];
      if (bounds.contains(leafCoord)) return true;
    }
    return false;
  };

  GetLeafletCoordinates = (): [number, number][] => {
    return this.coordinates.map((coord) => [coord.latitude, coord.longitude]);
  };
}

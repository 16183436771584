import { useEffect, useRef, useCallback } from "react";

const usePageVisibility = (onChange: (visible: boolean) => void) => {
  const hidden = useRef<boolean>(document.hidden);

  const callback = useCallback(() => {
    if (hidden.current !== document.hidden) {
      if (document.hidden) {
        onChange(false);
      } else {
        onChange(true);
      }

      hidden.current = document.hidden;
    }
  }, [onChange]);

  useEffect(() => {

    document.addEventListener("visibilitychange", callback);

    return () => {
      document.removeEventListener("visibilitychange", callback);
    };
  }, [callback]);
};

export default usePageVisibility;

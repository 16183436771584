import All from "./All";
import Create from "./Create";
import Delete from "./Delete";
import Form from "./Form";
import Table from "./Table";


export default class CoordinateReflectors{
    static All = All;
    static Delete = Delete;
    static Table = Table;
    static Form = Form;
    static Create = Create;
}
import html2canvas from "html2canvas";
import { LatLngBounds, LayersControlEvent } from "leaflet";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import { LayersControl, Map, MapProps, TileLayer } from "react-leaflet";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ICoordinate } from "../../models/gpsCoordinate";

interface Props {
  center: ICoordinate;
  onPan: (position: ICoordinate) => void;
  zoom: number;
  onZoom: (zoom: number) => void;
  onBoundsChanged?: (bounds: LatLngBounds) => void;
  tap?: boolean;
  zoomControl?: boolean;
}

const TrackingMap: React.FC<Props> = ({
  center,
  onPan,
  zoom,
  onZoom,
  onBoundsChanged,
  tap,
  zoomControl,
  children,
}) => {
  const [layer, setLayer] = useLocalStorage<string>("map-layer", "Streets");
  const mapRef = useRef<Map<MapProps, L.Map>>(null);
  const panBlockRef = useRef<boolean>(false);
  const printRef = useRef(false);

  const [leafletCenter, setLeafletCenter] = useState<[number, number]>([
    center.latitude,
    center.longitude,
  ]);

  // const leafletCenter: [number, number] = useMemo(
  //   () => [center.latitude, center.longitude],
  //   [center]
  // );

  /**
   * Helps to block pan events when center is moved
   */
  useEffect(() => {
    panBlockRef.current = true;
    const timeout = setTimeout(() => {
      panBlockRef.current = false;
    }, 500);

    setLeafletCenter([center.latitude, center.longitude]);
    return () => clearTimeout(timeout);
  }, [center]);

  useEffect(() => {
    const bounds = mapRef.current?.leafletElement.getBounds();
    if (bounds && onBoundsChanged) onBoundsChanged(bounds);

    document.addEventListener("keydown", onKeyDown);
    window.addEventListener("beforeprint", onBeforePrint);
    
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("beforeprint", onBeforePrint);
    }
  }, []);

  const onKeyDown = async (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === "p") {
      event.stopPropagation();
      event.preventDefault();
      printRef.current = true;

      const mapElement = document.getElementById("tracker-map");
      const mapElement2 = document.getElementsByClassName(
        "map-content-container"
      )[0] as HTMLElement;
      if (!mapElement || !mapElement2) return;

      const scale = 1;
      const canvas = await html2canvas(mapElement2, { scale, useCORS: true });

      const dataUrl = canvas.toDataURL();

      let printContent = "<!DOCTYPE html />";
      printContent += "<head><title>Kort print</title></head>";
      printContent += "<body>";
      printContent += '<img src="' + dataUrl + '">';
      printContent += "</body>";
      printContent += "</html>";

      const printWindow = window.open("");
      if (!printWindow) return;
      printWindow.document.write(printContent);

      printWindow.document.addEventListener(
        "load",
        function () {
          printWindow.focus();
          printWindow.print();
          printWindow.document.close();
          printWindow.close();
          printRef.current = false;
        },
        true
      );
    }
  };

  const onBeforePrint = () => {
    const isBrowserPrint = printRef.current === false;
    
    if(isBrowserPrint){
      alert("Brug CTRL + P til at printe kortet ellers er siden blank!")
    }
  }

  return (
    <Fragment>
      <div id="tracker-map" className={`map-content-container ${layer}`}>
        <Map
          ref={mapRef}
          preferCanvas={true}
          onbaselayerchange={(event: LayersControlEvent) =>
            setLayer(event.name)
          }
          attributionControl={false}
          center={leafletCenter}
          zoomControl={zoomControl === undefined || zoomControl}
          zoom={zoom}
          tap={tap}
          onViewportChanged={(viewport) => {
            try {
              if (viewport.zoom && viewport.zoom !== zoom) {
                onZoom(viewport.zoom);
              }
              if (viewport.center && viewport.center !== leafletCenter) {
                const coord = {
                  latitude: viewport.center[0],
                  longitude: viewport.center[1],
                };
                if (!panBlockRef.current) onPan(coord);
                if (onBoundsChanged && mapRef.current) {
                  const mBounds = mapRef.current.leafletElement.getBounds();
                  onBoundsChanged(mBounds);
                }
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          <LayersControl position="bottomright">
            <LayersControl.BaseLayer
              checked={layer == "Standard"}
              name="Standard"
            >
              <TileLayer url="https://api.mapbox.com/styles/v1/brianmose/ck9vbbwk20nhr1il5wkc9fjoz/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYnJpYW5tb3NlIiwiYSI6ImNrMnU5dGV1ZTA2bzAzY3FtY2U4ZTFoMXYifQ.fKvvV85REkfdJ6vqtWF66Q" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer
              checked={layer == "Streets"}
              name="Streets"
            >
              <TileLayer url="https://api.mapbox.com/styles/v1/brianmose/ckhltuf8r0h8619powj005ie7/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYnJpYW5tb3NlIiwiYSI6ImNrMnU5dGV1ZTA2bzAzY3FtY2U4ZTFoMXYifQ.fKvvV85REkfdJ6vqtWF66Q" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer
              checked={layer == "Satellit"}
              name="Satellit"
            >
              <TileLayer url="https://api.mapbox.com/styles/v1/brianmose/ckanv0ym564xz1ilksgqxiaic/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYnJpYW5tb3NlIiwiYSI6ImNrMnU5dGV1ZTA2bzAzY3FtY2U4ZTFoMXYifQ.fKvvV85REkfdJ6vqtWF66Q" />
            </LayersControl.BaseLayer>
          </LayersControl>

          {children}
        </Map>
      </div>
    </Fragment>
  );
};

export default TrackingMap;

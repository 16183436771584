import React from "react";
import { Route, Switch } from "react-router-dom";
import Accounts from "../Account/Index";
import AuthenticatedRoute from "../AuthenticatedRoute";
import Companies from "../Companies/Index";
import Dashboard from "../Dashboard/Dashboard";
import EmailTemplates from "../EmailTemplates/Index";
import Emulators from "../Emulators/Emulators";
import Home from "../Home";
import Layouts from "../Layouts/Index";
import UserProximity from "../Proximity/UserProximity";
import QR from "../QRCodes/Index";
import Quotas from "../Quotas/All";
import VehicleHistoryTable from "../Tracking/VehicleHistoryTable";
import TrackingUnits from "../TrackingUnits/Index";
import Users from "../Users/Index";
import Vehicles from "../Vehicles/Index";
import UserExportIndex from "../DataExports/UserExportIndex";
import ConnectedUnitIndex from "../TrackingUnits/ConnectedUnitIndex";
import AllFlexVehicles from "../FlexVehicles/All";
import CoordinateReflectors from "../CoordinateReflectors/Index";
import SEGES from "../SEGES/Index";

const DefaultRoutes: React.FC = () => {
  return (
    <Layouts.Default>
      <Switch>
        <Route exact path="/" component={Home} />
        {/* Account */}
        <Route path="/login" component={Accounts.Login} />
        <Route path="/forgot" component={Accounts.ForgotPassword} />
        <Route path="/reset/:token" component={Accounts.ResetPassword} />
        <AuthenticatedRoute
          exact
          path="/account/profile"
          isAuthorized={() => true}
          component={Accounts.MyProfile}
        />
        <AuthenticatedRoute
          exact
          path="/account/profile/:tab"
          isAuthorized={() => true}
          component={Accounts.MyProfile}
        />
        <AuthenticatedRoute
          path="/account/profile/:id"
          isAuthorized={(user) => user.admin}
          component={Accounts.ProfileIndex}
        />
        <Route
          path="/finish_registration/:token"
          component={Accounts.FinishRegistration}
        />
        {/* QR code unit assignment */}
        <Route path="/unit/register/:token" component={QR.AssignIndex} />
        {/* Dashboard */}
        <AuthenticatedRoute exact path="/dash" isAuthorized={() => true}>
          <Dashboard />
        </AuthenticatedRoute>
        {/*Companies */}
        <AuthenticatedRoute
          exact
          path="/company"
          isAuthorized={(user) => user.admin}
        >
          <Companies.All />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path="/company/:id"
          exact
          isAuthorized={() => true}
          component={Companies.Index}
        />
        <AuthenticatedRoute
          path="/company/:id/:tab"
          exact
          isAuthorized={() => true}
          component={Companies.Index}
        />
        {/* Tracking units */}
        <AuthenticatedRoute
          path="/unit"
          exact
          isAuthorized={(user) => user.admin}
        >
          <TrackingUnits.All />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path="/unit/active"
          isAuthorized={(user) => user.admin}
        >
          <TrackingUnits.ActiveAll />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/unit/connected" isAuthorized={(user) => user.admin}>
          <ConnectedUnitIndex />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path="/unit/print/qr"
          isAuthorized={(user) => user.admin}
        >
          <QR.PrintMany />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path="/unit/qr/:id"
          isAuthorized={(user) => user.admin}
          component={QR.UnitQRIndex}
        />
        {/* Users */}
        <AuthenticatedRoute
          path="/user"
          exact
          isAuthorized={(user) => user.admin}
        >
          <Users.All />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path="/user/logins/:id"
          isAuthorized={(user) => user.admin}
          component={Accounts.LoginIndex}
        />
        {/* Email templates */}
        <AuthenticatedRoute path="/email" isAuthorized={(user) => user.admin}>
          <EmailTemplates.All />
        </AuthenticatedRoute>
        {/* Vehicles */}
        <AuthenticatedRoute exact path="/vehicle" isAuthorized={(user) => user.admin}>
          <Vehicles.All />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/vehicle/flex" isAuthorized={(user) => user.admin}>
          <AllFlexVehicles />
        </AuthenticatedRoute>

        <AuthenticatedRoute
          exact
          path="/history-table"
          isAuthorized={() => true}
        >
          <VehicleHistoryTable />
        </AuthenticatedRoute>
        {/* Tracking unit emulators */}
        <AuthenticatedRoute
          path="/emulators"
          isAuthorized={(user) => user.admin}
        >
          <Emulators />
        </AuthenticatedRoute>
        {/* Quotas */}
        <AuthenticatedRoute path="/quota" isAuthorized={(user) => user.admin}>
          <Quotas />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/proximity" isAuthorized={() => true}>
          <UserProximity />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/exports" isAuthorized={() => true}>
          <UserExportIndex />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/reflectors" isAuthorized={(user) => user.admin}>
          <CoordinateReflectors.All />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/seges/vehicles" isAuthorized={(user) => user.admin}>
          <SEGES.Vehicles.All />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/seges/companies" isAuthorized={(user) => user.admin}>
          <SEGES.Companies.All />
        </AuthenticatedRoute>
      </Switch>
    </Layouts.Default>
  );
};

export default DefaultRoutes;

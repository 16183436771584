import React, { useCallback } from "react";
import { Spinner } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import TrackingUnit, { ITrackingUnitResource } from "../../models/trackingUnit";
import MountsTable from "./MountsTable";

interface Props {
  trackingUnit: ITrackingUnitResource;
}

const Mounts: React.FC<Props> = ({ trackingUnit }) => {
  const request = useCallback(
    (signal: AbortSignal) => TrackingUnit.ReadMounts(trackingUnit.id, signal),
    [trackingUnit.id]
  );
  const [loading, setLoading] = useLoading();
  const [mounts] = usePromise(request, [], setLoading);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return <MountsTable mounts={mounts} />;
};

export default Mounts;

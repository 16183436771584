import { createFetch } from "../helpers/fetchers";

export interface jwtResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  refresh_token_expires: number;
}

export class JwtResponse {
  static Reauthenticate = (
    tokens: jwtResponse,
    abort?: AbortSignal
  ): Promise<jwtResponse | undefined> => {
    const model = {
      grant_type: "refresh_token",
      refresh_token: tokens.refresh_token,
      access_token: tokens.access_token,
    };

    return createFetch<jwtResponse>("api/Account/login", model, abort);
  };
}

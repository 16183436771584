//Client errors
export type BadRequest = {
  code: 400;
  body: any;
};
type Unauthorized = {
  code: 401;
};
type Forbidden = {
  code: 403;
};

type NotFound = {
  code: 404;
};

export type HttpClientErrors = BadRequest | Unauthorized | Forbidden | NotFound;

//Server errors
type InternalServerError = {
  code: 500;
};
type NotImplemented = {
  code: 501;
};
type BadGateway = {
  code: 502;
};
type ServiceUnavailable = {
  code: 503;
};

export type HttpServerErrors =
  | InternalServerError
  | NotImplemented
  | BadGateway
  | ServiceUnavailable;

type HttpError = HttpClientErrors | HttpServerErrors;
export default HttpError;


export class HttpTypeError extends Error{
  code: number;
  constructor(code: number, message?: string){
    super(message)
    this.code = code;
  }
}
export class HttpCodeError extends Error{
  code: number;
  constructor(error: number, message?: string){
    super(message)
    this.code = error;
  }
}

export class NotFoundError extends Error{
  code: number;
  constructor(error: BadRequest, message?: string){
    super(message)
    this.code = error.code;
  }
}

export class BadRequestError extends Error{
  code: number;
  body: any;
  constructor(error: BadRequest, message?: string){
    super(message)
    this.code = error.code;
    this.body = error.body;
  }
}


export class BadRequestState<TModel> extends Error{
  state: TModel;
  constructor(state: TModel, message?: string){
    super(message);
    this.state = state;
  }
}

import React, { useEffect, useReducer } from "react";
import { Table } from "react-bootstrap";
import useSortedSearch from "../../hooks/useSortedSearch";
import EmulatedUnit, { EmulatedUnitProps, IEmulatedUnit } from "../../models/emulatedUnit";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import CreateEmulator from "./CreateEmulator";
import { ServiceControlButton } from "../Dashboard/ServiceStatus";
import DeleteEmulator from "./DeleteEmulator";

interface Props {
  emulators: IEmulatedUnit[];
  displayCompany?: boolean;
}

type Add = {
  readonly type: "ADD";
  emulator: IEmulatedUnit;
};
type Remove = {
  readonly type: "REMOVE";
  emulator: IEmulatedUnit;
};
type Update = {
  readonly type: "UPDATE";
  emulator: IEmulatedUnit;
};

type Replace = {
  readonly type: "REPLACE";
  emulators: IEmulatedUnit[];
};

type Actions = Add | Remove | Update | Replace;

const reducer = (
  emulators: IEmulatedUnit[],
  action: Actions
): IEmulatedUnit[] => {
  switch (action.type) {
    case "ADD":
      return [...emulators, action.emulator];
    case "REMOVE":
      return emulators.filter((e) => e.id !== action.emulator.id);
    case "UPDATE":
      const index = emulators.findIndex(
        (e: IEmulatedUnit) => e.id === action.emulator.id
      );
      return [
        ...emulators.slice(0, index),
        action.emulator,
        ...emulators.slice(index + 1),
      ];

    case "REPLACE":
      return action.emulators;
  }
};

export const EmulatorTable: React.FC<Props> = ({
  emulators: emu,
  displayCompany,
}) => {
  const [emulators, dispatch] = useReducer(reducer, emu);
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    EmulatedUnit, EmulatedUnitProps
  >(emulators.map(v => new EmulatedUnit(v)), undefined, {
    type: "trackerId",
    asc: true,
  });

  useEffect(() => dispatch({ type: "REPLACE", emulators: emu }), [emu]);
  return (
    <div className="emulators">
      <Table striped responsive>
        <thead>
          <tr>
            <SearchableHeader value={search} types={["trackerId"]} onSearch={setSearch} />
            <SearchableHeader value={search} types={["fileName"]} onSearch={setSearch} />
            <th></th>
            <th>
              <CreateEmulator
                onCreated={(emulator) => dispatch({ type: "ADD", emulator })}
              />
            </th>
          </tr>
          <tr>
            <SortableHeader type="trackerId" value={sort} onChange={setSort}>
              Track ID
            </SortableHeader>
            <SortableHeader type="fileName" value={sort} onChange={setSort}>
              Filnavn
            </SortableHeader>
            <SortableHeader type="isActive" value={sort} onChange={setSort}>
              Aktiv
            </SortableHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<IEmulatedUnit>
            items={sorted}
            id="emulators"
            sizeSelector={true}
            renderItem={(emulator) => (
              <tr key={emulator.id}>
                <td>{emulator.trackerId}</td>
                <td>{emulator.fileName}</td>
                <td>
                  {emulator.isActive ? "Ja" : "Nej"}
                  <ServiceControlButton
                    url={`api/Emulator/${emulator.id}/status`}
                    status={{online: emulator.isActive}}
                    onStatusChange={(status) =>
                      dispatch({type: "UPDATE", emulator: {...emulator, isActive: status.online}})
                    }
                  />
                </td>
                <td>
                  <DeleteEmulator emulator={emulator} onDeleted={emulator => dispatch({type: "REMOVE", emulator})} />
                </td>
              </tr>
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

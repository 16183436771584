import Cookies from "js-cookie";
import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import { useMediaQuery } from "react-responsive";
import { Route, Router, Switch } from "react-router-dom";
import Accounts from "./components/Account/Index";
import CacheBuster from "./components/CacheBuster";
import NavMenu from "./components/NavMenu";
import Routes from "./components/Routes/Index";
import ExcludeContext, {
  DefaultExcludeContent,
  ExcludeContent,
  ExcludeContextValue,
} from "./contexts/excludeContext";
import UserContext, { UserContextValue } from "./contexts/userContext";
import "./custom.css";
import ErrorBoundary from "./ErrorBoundary";
import { history } from "./helpers/history";
import { IJWTContent } from "./models/jwtContent";
import Mobile from "./components/Mobile";
import { IJWTCompany } from "models/jwtUser";
import SelectedCompanyContext, {
  SelectedCompanyContextValue,
} from "contexts/selectedCompanyContext";
import useSessionStorage from "hooks/useSessionStorage";
import { MobileContext, MobileContextValue } from "contexts/mobileContext";

const gaEnabled = false;
const gaTrackId = "";

if (gaEnabled) {
  history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
  });
}

const App = () => {
  const [overrideMobile, setOverrideMobile] = useState(false);
  const isMobileDevice = useMediaQuery({ query: "(max-width: 1224px)" });
  const isNotPrinting = useMediaQuery({query: "not print"});

  // Ensure the device is actually a mobile and not just printing.
  const isActuallyMobile = useMemo(() => isMobileDevice && isNotPrinting, [isMobileDevice, isNotPrinting]);

  const memoMobile = useMemo(() => isActuallyMobile, [isActuallyMobile]);

  const contextValue: MobileContextValue = useMemo(() => ({
    isMobile: memoMobile,
    overrideMobile,
    setOverrideMobile
  }), [memoMobile, overrideMobile]);

  return(
    <MobileContext.Provider value={contextValue}>
      <AppWrapper />
    </MobileContext.Provider>
  )

  // if (memoMobile) {
  //   return <AppMobile />
  // } else return <AppDesktop />;
};

const AppWrapper = () => {
  const {isMobile, overrideMobile} = useContext(MobileContext);
  const showMobile = useMemo(() => isMobile && !overrideMobile, [isMobile, overrideMobile]);

  if(showMobile) return <AppMobile />
  else return <AppDesktop />
}

const AppDesktop = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  useEffect(() => {
    const consent = Cookies.get("CookieConsent");
    if (consent && consent === "true") {
      setCookiesAccepted(true);
    }
  }, []);
  useEffect(() => {
    if (cookiesAccepted && gaEnabled) {
      ReactGA.initialize(gaTrackId);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [cookiesAccepted]);
  const [user, setUser] = useState<IJWTContent | undefined>();
  const [excludes, setExcludes] = useState<ExcludeContent>(
    DefaultExcludeContent
  );
  const contextValue: UserContextValue = { user, setUser };
  const excludeContextValue: ExcludeContextValue = { excludes, setExcludes };
  return (
    <ErrorBoundary>
      <CacheBuster>
        <ExcludeContext.Provider value={excludeContextValue}>
          <UserContext.Provider value={contextValue}>
            <Accounts.JwtAuthenticator>
              <Router history={history}>
                <Fragment>
                  <NavMenu />

                  <Switch>
                    <Route path="/map" component={Routes.Maps} />
                    <Route path="/" component={Routes.Default} />
                  </Switch>

                  {gaEnabled && (
                    <CookieConsent
                      onAccept={() => {
                        setCookiesAccepted(true);
                      }}
                      buttonText="Okay"
                    >
                      Vi anvender cookies til analyse og til at forbedre
                      oplevelsen
                    </CookieConsent>
                  )}
                </Fragment>
              </Router>
            </Accounts.JwtAuthenticator>
          </UserContext.Provider>
        </ExcludeContext.Provider>
      </CacheBuster>
    </ErrorBoundary>
  );
};

const AppMobile = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  useEffect(() => {
    const consent = Cookies.get("CookieConsent");
    if (consent && consent === "true") {
      setCookiesAccepted(true);
    }
  }, []);
  useEffect(() => {
    if (cookiesAccepted && gaEnabled) {
      ReactGA.initialize(gaTrackId);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [cookiesAccepted]);
  const [user, setUser] = useState<IJWTContent | undefined>();
  const [company, setCompany] = useSessionStorage<IJWTCompany | undefined>("mobile-company", undefined);
  const [excludes, setExcludes] = useState<ExcludeContent>(
    DefaultExcludeContent
  );

  const contextValue: UserContextValue = { user, setUser };
  const companyContextValue: SelectedCompanyContextValue = {
    company,
    setCompany,
  };
  const excludeContextValue: ExcludeContextValue = { excludes, setExcludes };

  useEffect(() => {
    const companies = user?.identity?.companies;
    if(companies && companies.length === 1){
      setCompany(companies[0]);
    }
    else if(user && !user.identity.admin){
      setCompany(undefined);
    }
  }, [user])
  return (
    <ErrorBoundary>
      <CacheBuster>
        <ExcludeContext.Provider value={excludeContextValue}>
          <UserContext.Provider value={contextValue}>
            <SelectedCompanyContext.Provider value={companyContextValue}>
              <Accounts.JwtAuthenticator>
                <Router history={history}>
                  <Fragment>
                    <Switch>
                      <Route path="/map" component={Mobile.MapRoutes} />
                      <Route path="/" component={Mobile.Routes} />
                    </Switch>

                    {gaEnabled && (
                      <CookieConsent
                        onAccept={() => {
                          setCookiesAccepted(true);
                        }}
                        buttonText="Okay"
                      >
                        Vi anvender cookies til analyse og til at forbedre
                        oplevelsen
                      </CookieConsent>
                    )}
                  </Fragment>
                </Router>
              </Accounts.JwtAuthenticator>
            </SelectedCompanyContext.Provider>
          </UserContext.Provider>
        </ExcludeContext.Provider>
      </CacheBuster>
    </ErrorBoundary>
  );
};

export default App;

import React, { Fragment, useMemo } from "react";

interface Props {
  number: number;
  seperator?: string;
}
export const NumberWithSeperator: React.FC<Props> = ({
  number,
  seperator = ".",
}) => {
  const result = useMemo(
    () => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, seperator || "."),
    [number, seperator]
  );
  if (typeof number === "undefined") return null;

  return <Fragment>{result}</Fragment>;
};

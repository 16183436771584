import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import EmailTemplate, {
  EmailTemplateUsedBy,
  IEmailTemplate,
} from "../../models/emailTemplate";
import FormControlFeedback from "../FormControlFeedback";
import EmailTemplates from "./Index";
import FeedbackWrapper from "../FeedbackWrapper";

interface Props {
  onCreated: (template: IEmailTemplate) => void;
}

const defaultTemplate = {
  id: 0,
  title: "",
  subject: "",
  body: "",
  usedBy: EmailTemplateUsedBy.None,
};
const CreateEmailTemplate: React.FC<Props> = ({ onCreated }) => {
  const [template, setTemplate] = useState<IEmailTemplate>(defaultTemplate);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [shown, setShown] = useState(false);

  const saveChanges = async () => {
    try {
      setLoading(true);
      const temp = await EmailTemplate.Create(template);
      setTemplate(defaultTemplate);
      setShown(false);
      onCreated(temp);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };

  return (
    <Fragment>
      <Button
        title="Opret email skabelon"
        variant="success"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>

      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Opret email skabelon</Modal.Title>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Title</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  errors={error}
                  field="title"
                  value={template.title}
                  onChange={(value: string) =>
                    setTemplate({ ...template, title: value })
                  }
                />
              </InputGroup>
              <sub>Ikke en del af sendte emails</sub>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Emne</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  errors={error}
                  field="subject"
                  value={template.subject}
                  onChange={(value: string) =>
                    setTemplate({ ...template, subject: value })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Indhold</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  as="textarea"
                  errors={error}
                  field="body"
                  value={template.body}
                  onChange={(value: string) =>
                    setTemplate({ ...template, body: value })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Bruges af</InputGroup.Text>
                </InputGroup.Prepend>
                <FeedbackWrapper field="usedBy" errors={error}>
                  <EmailTemplates.TypeSelector
                    onChange={(type) =>
                      setTemplate({ ...template, usedBy: type })
                    }
                    value={template.usedBy}
                  />
                </FeedbackWrapper>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={loading} type="submit">
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setTemplate(defaultTemplate);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};
export default CreateEmailTemplate;

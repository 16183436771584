import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useMemo, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import TrackingUnit, { ITrackingUnitResource } from "../../models/trackingUnit";
import Vehicle, {
  IVehicleBinding,
  IVehicleResource,
} from "../../models/vehicle";
import Companies from "../Companies/Index";

interface Props {
  trackingUnit: ITrackingUnitResource;
  excludeVehicles?: IVehicleResource[];
  onUpdated: (trackingUnit: ITrackingUnitResource) => void;
}

const AssignVehicle: React.FC<Props> = ({
  trackingUnit,
  excludeVehicles,
  onUpdated,
}) => {
  const [vehicle, setVehicle] = useState<IVehicleResource | undefined>();
  const [loading, setLoading] = useState(false);

  const company = trackingUnit.company;

  const [vehicleCreate, setVehicleCreate] = useState<
    IVehicleBinding | undefined
  >();

  const [error, setError] = useState<Error | undefined>();

  const [shown, setShown] = useState(false);

  const completed = useMemo(() => !!(vehicle || vehicleCreate), [
    vehicle,
    vehicleCreate,
  ]);

  const saveChanges = async () => {
    try {
      setLoading(true);
      var assignVehicle: IVehicleResource;
      if (vehicleCreate && company) {
        const [veh, vehError] = await Vehicle.Create(company, vehicleCreate);
        if(veh) assignVehicle = veh;
        else return;
      } else if (vehicle) {
        assignVehicle = vehicle;
      } else {
        return;
      }
      await TrackingUnit.AssignVehicle(trackingUnit, assignVehicle);
      setLoading(false);
      setShown(false);
      if (onUpdated) {
        onUpdated({ ...trackingUnit, vehicle: assignVehicle });
      }
    } catch (error) {
      setError(error);
      setLoading(false);
      console.error(error);
    } finally {
    }
  };
  const formSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };

  return (
    <Fragment>
      <button
        title="Tilføj til køretøj"
        className="blank-button"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSubmit}>
          <Modal.Header>Tilføj enhed til køretøj</Modal.Header>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Køretøj</InputGroup.Text>
                </InputGroup.Prepend>
                {company && (
                  <Companies.VehicleSelectorCreate
                    exclude={excludeVehicles}
                    value={vehicle}
                    company={company}
                    error={error}
                    onChange={setVehicle}
                    createValue={vehicleCreate}
                    onCreateChange={setVehicleCreate}
                  />
                )}
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              disabled={loading || !completed}
              type="submit"
            >
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};
export default AssignVehicle;

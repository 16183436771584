import React, { useCallback } from "react";
import usePromise from "../../hooks/usePromise";
import useTitle from "../../hooks/useTitle";
import User from "../../models/user";
import UsersTable from "./UsersTable";
import TableError from "../TableError";
import useLoading from "../../hooks/useLoading";

const AllUsers: React.FC = () => {
  const request = useCallback(
    (signal: AbortSignal) => User.ReadAll(signal),
    []
  );
  const [loading, setLoading] = useLoading();
  const [users, error] = usePromise(request, [], setLoading);

  useTitle("DigiFleet - Brugere");
  
  if (loading) return <em>Loading...</em>;
  else if (error) return <TableError />;

  return <UsersTable users={users} />;
};

export default AllUsers;

import { useMemo, Fragment } from "react";
import { NotFoundError } from "../HttpErrors";
import React from "react";

interface Props {
  error?: Error;
}
/**
 * Displays it's children if the error is a HTTP NotFound
 * @param param0
 */
const NotFoundMessage: React.FC<Props> = ({ error, children }) => {
  const isNotFound = useMemo(() => error && error instanceof NotFoundError, [
    error,
  ]);

  if (isNotFound) {
    return <Fragment>{children}</Fragment>;
  }
  return null;
};

export default NotFoundMessage;

import React from "react";

const Van = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1792 1792"
    version="1.1"
    id="svg4"
  >
    <defs id="defs8" />
    <path
      d="m 480,512 c -18,0 -37.5,4.33333 -58.5,13 -21,8.66667 -37.83333,19.33333 -50.5,32 L 173,755 c -8.66667,8.66667 -16.16667,18.66667 -22.5,30 -6.33333,11.33333 -11,21.5 -14,30.5 -3,9 -5.16667,21.33333 -6.5,37 -1.33333,15.66667 -2.16667,27.16667 -2.5,34.5 -0.33333,7.33333 -0.33333,20 0,38 0.33333,18 0.5,29.66667 0.5,35 v 320 c -17.33333,0 -32.333333,6.3333 -45,19 -12.666667,12.6667 -19,27.6667 -19,45 0,10 1.333333,18.8333 4,26.5 2.666667,7.6667 7.166667,13.8333 13.5,18.5 6.333333,4.6667 11.833333,8.5 16.5,11.5 4.66667,3 12.5,5 23.5,6 11,1 18.5,1.6667 22.5,2 4,0.3333 12.5,0.3333 25.5,0 13,-0.3333 20.5,-0.5 22.5,-0.5 h 54.89453 c -0.0452,-1.0844 -0.0836,-2.169 -0.11523,-3.2539 -3.9e-4,-144.6804 117.28636,-261.9672 261.96679,-261.9668 144.67967,7e-4 261.96524,117.2871 261.96485,261.9668 -0.0473,1.085 -0.10133,2.1696 -0.16211,3.2539 h 380.04097 c 2.3672,-142.8985 118.8319,-257.5287 261.75,-257.627 143.021,0.063 259.5582,114.8231 261.8184,257.8262 3.4457,0.08 7.1241,0.167 12.3418,0.3008 13,0.3333 21.5,0.3333 25.5,0 4,-0.3333 11.5,-1 22.5,-2 11,-1 18.8333,-3 23.5,-6 4.6667,-3 10.1667,-6.8333 16.5,-11.5 6.3333,-4.6667 10.8333,-10.8333 13.5,-18.5 2.6667,-7.6667 4,-16.5 4,-26.5 l 0,-810.81787 c 0,-17.33333 -1.113,-20.03626 -17.5763,-19.88937 C 1350.8528,513.29276 853.61386,511.95335 480,512 Z m 2,128 H 640 V 896 H 256 v -30 c 0,-8.66667 3,-16 9,-22 L 460,649 c 6,-6 13.33333,-9 22,-9 z"
      id="path2"
      fill="currentColor"
    />
    <circle
      style={{ fill: "currentColor", strokeWidth: 1 }}
      id="path31"
      cx="508.74576"
      cy="1404.7457"
      r="189.83051"
    />
    <circle
      style={{ fill: "currentColor", strokeWidth: 1 }}
      id="path31-5"
      cx="1412.339"
      cy="1412.339"
      r="189.83051"
    />
  </svg>
);
export default Van;

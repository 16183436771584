import React, { useEffect, useState } from "react";
import { GeoFeature } from "../models/geoAddress";
import GPSCoordinate from "../models/gpsCoordinate";
import SearchBox from "./SearchBox";

interface Props {
  onChange: (feature: GeoFeature) => void;
}

const AddressSelector: React.FC<Props> = ({ onChange }) => {
  const [userPosition, setUserPosition] = useState<
    [number, number] | undefined
  >();
  const [addresses, setAddresses] = useState<GeoFeature[]>([]);

  useEffect(() => {
    //Get users position
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) =>
        setUserPosition([position.coords.latitude, position.coords.longitude])
      );
    }
  }, []);

  const fetchAddresses = async (address: string) => {
    if (!address) {
      setAddresses([]);
      return;
    }
    if (userPosition) {
      const geo = await GPSCoordinate.GetAddresses(userPosition, address);
      if (geo) {
        setAddresses(geo.features);
      }
    }
  };
  const selectAddress = (feature: GeoFeature) => {
    setAddresses([]);
    onChange(feature);
  };


  return (
    <div className="route-search">
      <SearchBox
        placeholder="Tast adresse"
        className="form-control"
        onChange={(event) => fetchAddresses(event.target.value)}
      />
      <table className="address-table">
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {addresses.map((addr, index) => (
            <tr key={index}>
              <td>
                <button
                  className="btn btn-default"
                  title={addr.place_name}
                  onClick={() => selectAddress(addr)}
                >
                  <span>{addr.place_name}</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddressSelector;

import React, { useContext, useMemo } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import UserContext from "../contexts/userContext";
import { IJWTUser } from "../models/jwtUser";
import Login from "./Account/Login";

interface Props {
  /**
   * Return whether or not the user is authorized.
   * Always returning true, eg '() => true',  is equivalent to allowing all registered users.
   * Always returning false, eg '() => false', is equivalent to not allowing anyone
   */
  isAuthorized: (user: IJWTUser) => boolean;
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  [x: string]: any;
}

/**
 * An authenticated route that requires the user meet a authorization criteria, otherwise it shows a login form
 */
const AuthenticatedRoute: React.FC<Props> = ({
  isAuthorized,
  children,
  component,
  ...rest
}) => {
  const { user } = useContext(UserContext);

  //Check that the user is set (registered) and meets the authentication criteria
  const authorized = useMemo(() => user && isAuthorized(user.identity), [
    user,
    isAuthorized,
  ]);
  if (authorized) {
    return (
      <Route component={component} {...rest}>
        {children}
      </Route>
    );
  } else {
    return (
      <Route {...rest}>
        <Login />
      </Route>
    );
  }
};

export default AuthenticatedRoute;

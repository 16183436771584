import { Route, Switch } from "react-router-dom";
import React from "react";
import Navboard from "./Navboard";
import { Home } from "./Home";
import Login from "./Login";
import Layout, { MapLayout } from "./Layout";
import { Map } from "./Live/Map";
import { ChooseCompany } from "./ChooseCompany";
import { NotFound } from "./NotFound";
import { Periodic } from "./History/Periodic";
import { TachoIndex } from "./DriveRest/TachoIndex";
import { HistorySelect } from "./History/HistorySelect";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import FinishRegistration from "./FinishRegistration";
import Driving from "./Driving";

export const Routes = () => (
  <Layout>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot" component={ForgotPassword} />
      <Route path="/reset/:token" component={ResetPassword} />
      <Route
        path="/finish_registration/:token"
        component={FinishRegistration}
      />
      <ChooseCompany>
        <Route exact path="/nav" component={Navboard} />
        <Route path="/history" exact component={HistorySelect} />
        <Route path="/history/periodic/:id" component={Periodic} />
        <Route path="/driverest" component={TachoIndex} />
        <Route path="/driving" component={Driving} />
        {/* <Route component={NotFound} /> */}
      </ChooseCompany>
    </Switch>
  </Layout>
);

export default Routes;

export const MapRoutes = () => (
  <MapLayout>
    <Switch>
      <ChooseCompany>
        <Route exact path="/map/live" component={Map} />
        {/* <Route component={NotFound} /> */}
      </ChooseCompany>
    </Switch>
  </MapLayout>
);

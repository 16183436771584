import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import UserContext from "../../contexts/userContext";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import User, { IUserResource } from "../../models/user";
import ChangeName from "./ChangeName";
import ChangePassword from "./ChangePassword";
import ChangeSettings from "./ChangeSettings";

interface Props {
  user: IUserResource;
  initialTab?: string;
}
const Profile: React.FC<Props> = ({ user: usr, initialTab }) => {
  const [tab, setTab] = useState<string | undefined>(initialTab || "names");
  return (
    <Tabs
      activeKey={tab}
      unmountOnExit={true}
      onSelect={(k: string | null) => setTab(k || undefined)}
      id="account-tabs"
    >
      <Tab title="Navn" eventKey="names">
        <h4>Skift navn</h4>
        <ChangeName user={usr} />
      </Tab>
      <Tab title="Password" eventKey="password">
        <h4>Skift password</h4>
        <ChangePassword user={usr} />
      </Tab>
      <Tab title="Indstillinger" eventKey="preferences">
        <h4>Indstillinger</h4>
        <ChangeSettings />
      </Tab>
    </Tabs>
  );
};

export default Profile;

interface MatchParams {
  id: string;
  tab?: string;
}
interface MatchProps extends RouteComponentProps<MatchParams> {}

export const ProfileIndex: React.FC<MatchProps> = ({ match }) => {
  const userId = useMemo(() => +match.params.id, [match.params.id]);
  const request = useCallback((signal: AbortSignal) => User.Read(userId), [
    userId,
  ]);

  const [loading, setLoading] = useLoading();
  const [user] = usePromise(request, undefined, setLoading);

  if (loading || !user) {
    return <Spinner animation="border" />;
  }

  return <Profile initialTab={match.params.tab} user={user} />;
};

interface PersonalParams {
  tab?: string;
}
interface PersonalProps extends RouteComponentProps<PersonalParams> {}
export const MyProfile: React.FC<PersonalProps> = ({ match }) => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState<IUserResource | undefined>();

  useEffect(() => {
    if (user) {
      const fetchUser = async () => {
        try {
          const account = await User.Read(user.identity.id);
          setAccount(account);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
      fetchUser();
    }
  }, [user]);

  if (loading || !account) {
    return <Spinner animation="border" />;
  }

  return (
    <div>
      <h3>Din profil</h3>
      <Profile initialTab={match.params.tab} user={account} />
    </div>
  );
};

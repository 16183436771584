import React from "react";
import { UnitType } from "../models/trackingUnit";

interface LabelProps {
  unitType?: UnitType;
}
export const UnitTypeLabel: React.FC<LabelProps> = ({ unitType }) => {
  return <div>{unitType ? UnitType[unitType] : "Ukendt"}</div>;
};

interface SelectorProps {
  value?: UnitType;
  onChange: (value: UnitType) => void;
}
export const UnitTypeSelector: React.FC<SelectorProps> = ({
  value,
  onChange,
}) => {
  const types = [UnitType.VT600, UnitType.VT100];
  return (
    <select
      className="form-control w-auto"
      onChange={(e) => {
        const num = +e.target.value;
        onChange(types[num]);
      }}
    >
      <option>Vælg enhedstype</option>
      {types.map((type, index) => (
        <option key={index} value={index} selected={value && value === type}>
          {UnitType[type]}
        </option>
      ))}
    </select>
  );
};

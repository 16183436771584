import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { ISpeed } from "../../models/speed";

interface Props {
  speeds: ISpeed[];
}
const SpeedChart: React.FC<Props> = ({ speeds }) => {
  const speedData = useMemo(
    () =>
      speeds.map((speed) => ({
        t: new Date(speed.timestamp),
        y: speed.speed * 3.6,
      })),
    [speeds]
  );

  const data = {
    labels: speeds.map((speed) => speed.timestamp),

    datasets: [
      {
        label: "Hastighed",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: speedData,
      },
    ],
  };

  const options = {
    bezierCurve: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Hastighed (KM/T)",
          },
          type: "linear",
          position: "left",
          ticks: {
            min: 0, //minimum tick
            max: 140, //maximum tick
            callback: function (value: any, index: number, values: any) {
              return Number(value.toString()); //pass tick values as a string into Number function
            },
          },
          afterBuildTicks: function (chartObj: any) {
            //Build ticks labelling as per your need
            chartObj.ticks = [];
            chartObj.ticks.push(0);
            chartObj.ticks.push(20);
            chartObj.ticks.push(40);
            chartObj.ticks.push(60);
            chartObj.ticks.push(80);
            chartObj.ticks.push(100);
            chartObj.ticks.push(120);
            chartObj.ticks.push(140);
          },
        },
      ],
      xAxes: [
        {
          type: "time",

          time: {
            tooltipFormat: "DD-MM-YYYY HH:mm",
            unit: "minute",
            unitStepSize: 30,
            displayFormats: {
              minute: "HH:mm",
            },
          },
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Tidspunkt",
          },
        },
      ],
    },

    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          var label = data.datasets[tooltipItem.datasetIndex].label || "";

          if (label) {
            label += ": ";
          }
          label += Math.round(tooltipItem.yLabel * 100) / 100;
          return label;
        },
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default SpeedChart;

import {
  faArrowRight,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faMapMarkedAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LatLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from "react";
import { Button, Spinner } from "react-bootstrap";
import Control from "react-leaflet-control";
import UserContext from "../../contexts/userContext";
import { useLiveCoordinatesConnection } from "../../hooks/useLiveCoordinates";
import useLoading from "../../hooks/useLoading";
import useLocalStorage from "../../hooks/useLocalStorage";
import usePreferences from "../../hooks/usePreferences";
import usePromise from "../../hooks/usePromise";
import useSortedSearch from "../../hooks/useSortedSearch";
import { ICoordinate } from "../../models/gpsCoordinate";
import Preferences from "../../models/preferences";
import GeoRoute from "../../models/route";
import User from "../../models/user";
import Vehicle, { IVehicleResource, VehicleProps } from "../../models/vehicle";
import Companies from "../Companies/Index";
import ButtonControl from "./MapControls/ButtonControl";
import ControlContent from "./MapControls/ControlContent";
import MapControls from "./MapControls/Index";
import MapVehicleSelector, { ISelectedVehicle, SelectedVehicle } from "./MapVehicleSelector";
import RouteDirections from "./RouteDirections";
import RouteSelector from "./RouteSelector";
import RouteTrack from "./RouteTrack";
import TrackingMap from "./TrackingMap";
import TrackRenderer from "./TrackRenderer";
import VehicleTrack from "./VehicleTrack";

type Update = {
  readonly type: "UPDATE";
  vehicle: ISelectedVehicle;
};
type Replace = {
  readonly type: "REPLACE";
  vehicles: ISelectedVehicle[];
};
type ShowAll = {
  readonly type: "SHOWALL";
};
type HideAll = {
  readonly type: "HIDEALL";
};
type ShowAllPaths = {
  readonly type: "SHOWALLPATHS";
};
type HideAllPaths = {
  readonly type: "HIDEALLPATHS";
};

type Actions =
  | Update
  | Replace
  | ShowAll
  | HideAll
  | ShowAllPaths
  | HideAllPaths;

const reducer = (
  vehicles: ISelectedVehicle[],
  action: Actions
): ISelectedVehicle[] => {
  switch (action.type) {
    case "UPDATE":
      const index = vehicles.findIndex(
        (v: ISelectedVehicle) => v.id === action.vehicle.id
      );
      return [
        ...vehicles.slice(0, index),
        action.vehicle,
        ...vehicles.slice(index + 1),
      ];
    case "REPLACE":
      return action.vehicles;
    case "SHOWALL":
      return vehicles.map((vehicle) => ({ ...vehicle, shown: true }));
    case "HIDEALL":
      return vehicles.map((vehicle) => ({
        ...vehicle,
        shown: false,
        pathShown: false,
      }));
    case "SHOWALLPATHS":
      return vehicles.map((vehicle) => ({
        ...vehicle,
        pathShown: true,
        shown: true,
      }));
    case "HIDEALLPATHS":
      return vehicles.map((vehicle) => ({ ...vehicle, pathShown: false }));
  }
};

interface Props {
  isFullscreen: boolean;
  fullscreenChange: (fullscreen: boolean) => void;
  preselected?: number;
}

const LiveMap: React.FC<Props> = ({
  isFullscreen,
  fullscreenChange,
  preselected,
}) => {
  const [bounds, setBounds] = useState<LatLngBounds>();
  const [preselectedVehicles, setPreselectedVehicles] = useLocalStorage<
    number[]
  >("selected-vehicles", []);

  const { user } = useContext(UserContext);
  const connection = useLiveCoordinatesConnection();

  const [selectedVehicles, dispatch] = useReducer(reducer, []);
  const [vehiclesOpen, setVehiclesOpen] = useState(false);

  const [preferences] = usePreferences();
  const [showTooltips, setShowTooltips] = useState(
    preferences.map.showNamesByDefault
  );

  const [userPosition, setUserPosition] = useState<ICoordinate>({
    latitude: 55.403756,
    longitude: 10.40237,
  });

  const [route, setRoute] = useState<GeoRoute | undefined>();

  const [zoom, setZoom] = useState(preferences.map.zoomLevel || Preferences.Default.map.zoomLevel);

  const [sorted, , , , changeSearch] = useSortedSearch<SelectedVehicle, VehicleProps>(
    selectedVehicles.map(v => new SelectedVehicle(v)),
    undefined,
    {
      asc: true,
      type: "alias",
      fallback: "licensePlate",
    }
  );
  /**
   * Zooms in on the vehicle
   * @param vehicle
   */
  const getVehicleLocation = async (vehicle: ISelectedVehicle) => {
    try {
      const position = await Vehicle.ReadLocation(vehicle);
      if(!position) return;
      setZoom(preferences.map.vehicleZoomLevel || Preferences.Default.map.vehicleZoomLevel); 
      setUserPosition(position);
    } catch (error) {
      console.error(error);
    }
  };
  /**
   * Zooms in on the vehicle
   * @param id Id of the vehicle
   */
  const goToVehicle = async (id: number) => {
    try {
      const position = await Vehicle.ReadLocation({
        id,
        licensePlate: "",
        alias: "",
        online: false,
        canBeRemote: false
      });
      if(!position) return;
      setZoom(preferences.map.vehicleZoomLevel || Preferences.Default.map.vehicleZoomLevel); 
      setUserPosition(position);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (preselected) {
      // Go to preselected vehicle location
      goToVehicle(preselected);
    } else {
      // Go to user's location
      navigator.geolocation.getCurrentPosition((position) => {
        setUserPosition(position.coords);
      });
    }
  }, [preselected]);

  /**
   * The user's id
   */
  const userId = useMemo(() => (user ? user.identity.id : undefined), [user]);
  /**
   * Whether or not the user is admin
   */
  const userAdmin = useMemo(() => (user ? user.identity.admin : false), [user]);

  /**
   * Request to fetch vehicles
   */
  const request = useCallback(
    (signal: AbortSignal) => {
      return userAdmin
        ? Vehicle.ReadAll(signal)
        : User.ReadVehicles(userId || 0, signal);
    },
    [userId, userAdmin]
  );

  // Perform the fetch
  const [loading, setLoading] = useLoading();
  const [vehicles] = usePromise(request, [], setLoading);

  const [loadingTracks, setLoadingTracks] = useLoading(false);

  /**
   * Checks if vehicle is preselected
   */
  const isPreselected = useCallback(
    (vehicle: IVehicleResource) => {
      if (vehicle.id === preselected) return true;
      return preselectedVehicles.find((pv) => pv === vehicle.id) !== undefined;
    },
    [preselected, preselectedVehicles]
  );

  const isPathShown = useCallback(
    (vehicle: IVehicleResource) => {
      const found = selectedVehicles.find((sv) => sv.id === vehicle.id);
      if (!found) return false;
      return found.pathShown;
    },
    [selectedVehicles]
  );

  /**
   * Applies preselected state
   */
  useEffect(() => {
    const mapped = vehicles.map((vehicle) => ({
      ...vehicle,
      shown: isPreselected(vehicle),
      pathShown: isPathShown(vehicle),
    }));

    dispatch({
      type: "REPLACE",
      vehicles: mapped,
    });
  }, [vehicles, isPreselected]);

  /**
   * Whether any vehicles are shown
   */
  const anyShown = useMemo(() => selectedVehicles.some((v) => v.shown), [
    selectedVehicles,
  ]);
  /**
   * Whether any paths are shown
   */
  const anyPathShown = useMemo(
    () => selectedVehicles.some((v) => v.pathShown),
    [selectedVehicles]
  );

  /**
   * Shows all vehicles
   */
  const showAll = () => {
    dispatch({ type: "SHOWALL" });
    const mapped = selectedVehicles.map((v) => v.id);
    const newVehs = mapped.filter(
      (v) => !preselectedVehicles.find((pv) => pv === v)
    );
    setPreselectedVehicles([...preselectedVehicles, ...newVehs]);
  };
  /**
   * Hides all vehicles
   */
  const hideAll = () => {
    if (preselectedVehicles) {
      setPreselectedVehicles([]);
    }
    dispatch({ type: "HIDEALL" });
  };
  /**
   * Shows all vehicles and their paths
   */
  const showAllPaths = () => {
    dispatch({ type: "SHOWALLPATHS" });
    const mapped = selectedVehicles.map((v) => v.id);
    const newVehs = mapped.filter(
      (v) => !preselectedVehicles.find((pv) => pv === v)
    );
    setPreselectedVehicles([...preselectedVehicles, ...newVehs]);
  };
  /**
   * Hides all vehicles and their paths
   */
  const hideAllPaths = () => {
    if (preselectedVehicles) {
      setPreselectedVehicles([]);
    }
    dispatch({ type: "HIDEALLPATHS" });
  };

  /**
   * Shows or hides a single vehicle
   * @param vehicle Vehicle to show/hide
   */
  const chooseVehicle = (vehicle: ISelectedVehicle) => {
    if (vehicle.shown) {
      if (!preselectedVehicles.find((pv) => pv === vehicle.id)) {
        setPreselectedVehicles([...preselectedVehicles, vehicle.id]);
      }
    } else {
      setPreselectedVehicles(
        preselectedVehicles.filter((v) => v != vehicle.id)
      );
    }

    dispatch({ type: "UPDATE", vehicle });
  };

  const toShow = selectedVehicles.filter((v) => v.shown).map((v) => v);
  const toShowPath = selectedVehicles
    .filter((v) => v.pathShown)
    .map((v) => v);

  const tracks = !preferences.map.disableOptimization ? (
    <>
      {connection && bounds && (
        <TrackRenderer
          bounds={bounds}
          connection={connection}
          vehicles={toShow}
          pathsToShow={toShowPath}
          showTooltip={showTooltips}
          zoom={zoom}
          onLoadTracks={setLoadingTracks}
        />
      )}
    </>
  ) : (
    selectedVehicles
      .filter((v) => v.shown)
      .map((vehicle) => (
        <VehicleTrack
          key={vehicle.id}
          vehicle={vehicle}
          scale={preferences.map.iconSizeScale}
          showTooltip={showTooltips}
          connection={connection}
          zoom={zoom}
        />
      ))
  );

  return (
    <div className="map-content-container">
      <TrackingMap
        zoom={zoom}
        center={userPosition}
        onZoom={setZoom}
        onPan={setUserPosition}
        onBoundsChanged={(bnd) => setBounds(bnd)}
      >
        {tracks}

        <ButtonControl
          icon={faMapMarkedAlt}
          show={vehiclesOpen}
          variant={vehiclesOpen ? "secondary" : "success"}
          onClick={() => setVehiclesOpen(!vehiclesOpen)}
          position="topright"
          dontUnmount={true}
        >
          <ControlContent corner="top-right">
            <div className="map-control-grid">
              <div className="map-vehicles-control control-buttons">
                <div className="map-row-right">
                  <MapControls.Selected
                    disabled={loadingTracks}
                    value={anyShown}
                    onChange={(show) => {
                      if (show) {
                        showAll();
                      } else {
                        hideAll();
                      }
                    }}
                  />
                </div>
                <div className="map-row-right">
                  <MapControls.Paths
                    disabled={loadingTracks}
                    value={anyPathShown}
                    onChange={(show) => {
                      if (show) {
                        showAllPaths();
                      } else {
                        hideAllPaths();
                      }
                    }}
                  />
                </div>
                <div className="map-row-right">
                  <MapControls.Names
                    value={showTooltips}
                    onChange={(show) => setShowTooltips(show)}
                  />
                </div>
              </div>
              <div className="map-vehicles-control close-button">
                <Button
                  size="sm"
                  variant="secondary"
                  title="Luk"
                  onClick={() => setVehiclesOpen(false)}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </div>

            <hr />
            {user &&
              (user.identity.companies.length > 1 || user.identity.admin) && (
                <Companies.Selector
                  onChange={(company) =>
                    changeSearch(
                      company
                        ? {
                            types: ["companyId"],
                            value: company.id,
                            exact: true,
                          }
                        : undefined
                    )
                  }
                />
              )}
            {(loading || loadingTracks) && <Spinner animation="border" />}
            <MapVehicleSelector
              values={sorted}
              onRouteOpen={() => fullscreenChange(false)}
              onRouteSelected={(route) => setRoute(route)}
              onChange={(vehicle) => chooseVehicle(vehicle)}
              onZoom={(vehicle) => {
                getVehicleLocation(vehicle);
                chooseVehicle({ ...vehicle, shown: true });
              }}
            />
          </ControlContent>
        </ButtonControl>

        {/*Route planner */}
        <Control position="topright">
          {route ? (
            <ControlContent>
              <RouteDirections
                route={route.routes[0]}
                onRemove={() => setRoute(undefined)}
              />
            </ControlContent>
          ) : (
            <RouteSelector
              onOpen={() => fullscreenChange(false)}
              onSelect={(route) => setRoute(route)}
            />
          )}
        </Control>
        {route && <RouteTrack route={route.routes[0]} color="red" />}
        <Control position="bottomleft">
          <button
            title={isFullscreen ? "Luk fuld skærm" : "Åben fuld skærm"}
            className="btn btn-default"
            style={{ backgroundColor: "white" }}
            onClick={() => fullscreenChange(!isFullscreen)}
          >
            <FontAwesomeIcon
              icon={isFullscreen ? faCompressArrowsAlt : faExpandArrowsAlt}
            />
          </button>
        </Control>
      </TrackingMap>
    </div>
  );
};

export default LiveMap;

import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState
} from "react";
import { Spinner, Table } from "react-bootstrap";
import useLiveCoordinates from "../../hooks/useLiveCoordinates";
import useSortedSearch from "../../hooks/useSortedSearch";
import Company, { ICompanyIdentifiable } from "../../models/company";
import { IGPSCoordinate } from "../../models/gpsCoordinate";
import { IVehicleResource, VehicleProps, VehicleResource } from "../../models/vehicle";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";

interface VehiclesOnlineProps {
  company: ICompanyIdentifiable;
}

type Add = {
  readonly type: "ADD";
  vehicle: IVehicleResource;
};
type Remove = {
  readonly type: "REMOVE";
  vehicle: IVehicleResource;
};
type Update = {
  readonly type: "UPDATE";
  vehicle: IVehicleResource;
};
type Replace = {
  readonly type: "REPLACE";
  vehicles: IVehicleResource[];
};

type SetOnline = {
  readonly type: "SETONLINE";
  vehicleId: number;
  online: boolean;
};

type Actions = Add | Remove | Update | Replace | SetOnline;

const reducer = (vehicles: IVehicleResource[], action: Actions): IVehicleResource[] => {
  switch (action.type) {
    case "ADD":
      return [...vehicles, action.vehicle];
    case "REMOVE":
      return vehicles.filter((v) => v.id !== action.vehicle.id);
    case "UPDATE":
      const index = vehicles.findIndex(
        (v: IVehicleResource) => v.id === action.vehicle.id
      );
      return [
        ...vehicles.slice(0, index),
        action.vehicle,
        ...vehicles.slice(index + 1),
      ];
    case "SETONLINE":
      const vehicle = vehicles.find((v) => v.id === action.vehicleId);
      if (vehicle) {
        vehicle.online = action.online;
        return reducer(vehicles, { type: "UPDATE", vehicle });
      }
      return vehicles;
    case "REPLACE":
      return action.vehicles;
  }
};

const VehiclesOnlineDashboard: React.FC<VehiclesOnlineProps> = ({
  company,
}) => {
  const [loading, setLoading] = useState(true);
  const [vehicles, dispatch] = useReducer(reducer, []);
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<VehicleResource, VehicleProps>(
    vehicles.map(v => new VehicleResource(v)),
    undefined,
    {
      type: "alias",
      fallback: "licensePlate",
      asc: true,
    }
  );

  // const handleCoordinateUpdate = useCallback((coordinate: IGPSCoordinate) => {
  //   const online = coordinate.accOn;
  //   dispatch({ type: "SETONLINE", vehicleId: coordinate.vehicleId, online });
  // }, []);

  // useLiveCoordinates(handleCoordinateUpdate);

  useEffect(() => {
    const loadVehicles = async () => {
      const vehicles = await Company.ReadVehicles(company);
      dispatch({ type: "REPLACE", vehicles });
      setLoading(false);
    };

    loadVehicles();
  }, [company]);

  const online = vehicles.filter((v) => v.online).length;
  const offline = vehicles.length - online;

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Fragment>
      <div className="dashboard-item-top">
        <label>Tændte: {online}</label>
        <br />
        <label>Slukkede: {offline}</label>
      </div>
      <Table size="sm" hover responsive>
        <thead>
          <tr>
            <SearchableHeader
              value={search}
              types={["alias", "licensePlate"]}
              onSearch={setSearch}
            />
            <th></th>
          </tr>
          <tr>
            <SortableHeader
              value={sort}
              onChange={setSort}
              type="alias"
              fallback="licensePlate"
            >
              Køretøj
            </SortableHeader>
            <SortableHeader
              value={sort}
              onChange={setSort}
              type="online"
              invert={true}
            >
              Tændt
            </SortableHeader>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<IVehicleResource>
            items={sorted}
            renderItem={(vehicle) => (
              <tr key={vehicle.id}>
                <td>{vehicle.alias || vehicle.licensePlate}</td>
                <td>{vehicle.online ? "Ja" : "Nej"}</td>
              </tr>
            )}
          />
        </tbody>
      </Table>
    </Fragment>
  );
};

export default VehiclesOnlineDashboard;

import React from "react";

interface Props {
  value?: number;
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
  [x: string]: any;
}

const NumberInput = ({ value, onChange, min, max, ...rest }: Props) => (
  <input
    type="number"
    value={value}
    onChange={(e) => onChange && onChange(+e.target.value)}
    min={min}
    max={max}
  />
);

export default NumberInput;

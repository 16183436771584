import React, { Fragment, useState } from "react";
import { Badge, Button, Modal, Spinner, Table } from "react-bootstrap";
import { authHeader } from "../../helpers/auth-header";
import { IServiceStatus } from "../../models/serviceStatus";
import { ServiceControlButton, ServiceStatusLabel } from "./ServiceStatus";
interface Props {
  status?: IServiceStatus;
  setStatus: (status: IServiceStatus) => void;
}
const AuthServiceModal: React.FC<Props> = ({ status, setStatus }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const resetAuthKey = async () => {
    try {
      setLoading(true);
      await fetch("api/Status/services/authentication/key", authHeader("POST"));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button
        className="service-button"
        size="sm"
        onClick={() => setOpen(true)}
      >
        Åben
      </Button>

      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Title>Autentificering service</Modal.Title>
        <Modal.Body>
          <Table size="sm" hover responsive>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Status:</td>
                <td>
                  <ServiceStatusLabel status={status} />
                </td>
                <td>
                  <ServiceControlButton
                    className="service-button"
                    status={status}
                    onStatusChange={setStatus}
                    url="api/Status/services/authentication"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <hr />
          <br />
          <Button variant="warning" onClick={() => resetAuthKey()}>
            Nulstil refresh nøgle
            {loading && <Spinner animation="border" size="sm" />}
          </Button>
          <hr />
          <Badge variant="warning">
            Nulstilling af refresh nøgle:
            <br />
            Nulstiller nøglen der kryptere refresh-tokens og
            <br />
            derfor annulleres alle "refresh-sessions".
            <br />
            Alle der er logget ind vil blive logget ud efter maks. 15 minutter
          </Badge>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Luk
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AuthServiceModal;

import React from "react"

export type MobileContextValue = {
  isMobile: boolean,
  overrideMobile: boolean,
  setOverrideMobile: (value: boolean) => void,
}

export const MobileContext = React.createContext<MobileContextValue>({
  isMobile: false,
  overrideMobile: false,
  setOverrideMobile: () => {}
});


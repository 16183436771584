import Create from "./Create";
import Edit from "./Edit";
import Delete from "./Delete";
import UnitTable, { UnitActiveTable, UnitCompanyTable } from "./Table";
import UnassignVehicle from "./UnassignVehicle";
import UnassignCompany from "./UnassignCompany";
import AssignCompany from "./AssignCompany";
import AssignVehicle from "./AssignVehicle";
import All, {ActiveAll} from "./All";
import Details from "./Details";
import TrackingUnitIndex from "./TrackingUnitIndex";
import Configuration from "./Configuration";
import Mounts from "./Mounts";
import MountsTable from "./MountsTable";

class TrackingUnits {
    static Index = TrackingUnitIndex;
    static All = All;
    static ActiveAll = ActiveAll;
    static Create = Create;
    static Edit = Edit;
    static Delete = Delete;
    static Details = Details;
    static Table = UnitTable;
    static ActiveTable = UnitActiveTable;
    static CompanyTable = UnitCompanyTable;
    static AssignVehicle = AssignVehicle;
    static AssignCompany = AssignCompany;
    static UnassignVehicle = UnassignVehicle;
    static UnassignCompany = UnassignCompany;
    static Config = Configuration;
    static Mounts = Mounts;
    static MountsTable = MountsTable;
}

export default TrackingUnits;
import { ICompanyResource } from "./company";
import {
  createFetch,
  readFetch,
  updateFetch,
  deleteFetch,
} from "../helpers/fetchers";
import { IVehicleResource } from "./vehicle";
import { IFinishRegistration } from "./finishRegistration";
import { IChangeName } from "./changeName";
import { ILoginAttempt, ILoginAttemptSummary } from "./loginAttempt";
import ISortable from "../ISortable";
import ISearchable from "../ISearchable";
import { basicSearch, basicSortCompare } from "../helpers/utils";

export interface IUserResource {
  id: number;
  email: string;
  active: boolean;
  isAdmin: boolean;
  firstName: string;
  lastName: string;
  companies?: ICompanyResource[];
}
export interface IUserBinding {
  email: string;
  active: boolean;
  isAdmin: boolean;
  firstName: string;
  lastName: string;
}
export type UserProps = "id" | "email" | "active" | "isAdmin" | "firstName" | "lastName" | "companies.name";
export class UserResource implements IUserResource, ISortable<UserProps>, ISearchable<UserProps>{
  id: number;
  email: string;
  active: boolean;
  isAdmin: boolean;
  firstName: string;
  lastName: string;
  companies?: ICompanyResource[];
  constructor(value: IUserResource){
    this.id = value.id;
    this.email = value.email;
    this.active = value.active;
    this.isAdmin = value.isAdmin;
    this.firstName = value.firstName;
    this.lastName = value.lastName;
    this.companies = value.companies;
  }
  search = (value: any, type: UserProps): number | undefined => {
    return basicSearch(this, value, type, false);
  };
  compare = (value: ISortable<UserProps>, primary: UserProps, secondary?: UserProps): number => {
    const prim = basicSortCompare(this, value, primary);
    if(prim !== undefined) return prim;
    else if(secondary){
      const sec = basicSortCompare(this, value, secondary);
      if(sec !== undefined) return sec;
      else return 0;
    }
    else return 0;
  };

}

export default class User {
  static ToBinding = (resource: IUserResource): IUserBinding => {
    return {
      email: resource.email,
      active: resource.active,
      isAdmin: resource.isAdmin,
      firstName: resource.firstName,
      lastName: resource.lastName,
    };
  };

  static ToResource(binding: IUserBinding, initial: IUserResource) {
    return {
      ...initial,
      email: binding.email,
      active: binding.active,
      isAdmin: binding.isAdmin,
      firstName: binding.firstName,
      lastName: binding.lastName,
    };
  }

  static Create = async (
    user: IUserBinding,
    abort?: AbortSignal
  ): Promise<IUserResource> =>
    (await createFetch("api/Users", user, abort)) as IUserResource;

  static Read = async (
    id: number,
    abort?: AbortSignal
  ): Promise<IUserResource> =>
    (await readFetch("api/Users/" + id, abort)) as IUserResource;

  static ReadAll = async (abort?: AbortSignal): Promise<IUserResource[]> =>
    (await readFetch("api/Users", abort)) as IUserResource[];
  static Update = async (
    id: number,
    user: IUserBinding,
    abort?: AbortSignal
  ): Promise<void> => {
    updateFetch("api/Users/" + id, user, abort);
  };

  static Delete = async (user: IUserResource, abort?: AbortSignal) => {
    deleteFetch("api/Users/" + user.id, abort);
  };

  static AddAndAssign = async (
    user: IUserResource,
    company: ICompanyResource,
    abort?: AbortSignal
  ): Promise<IUserResource> =>
    (await createFetch(
      `api/Companies/${company.id}/Users/AddAssign`,
      user,
      abort
    )) as IUserResource;

  /**
   * Fetches a specific user's companies
   */
  static ReadCompanies = async (
    id: number,
    abort?: AbortSignal
  ): Promise<ICompanyResource[]> =>
    (await readFetch(`api/Users/${id}/companies`, abort)) as ICompanyResource[];

  /**
   * Fetches a specific user's vehicles
   */
  static ReadVehicles = async (
    id: number,
    abort?: AbortSignal
  ): Promise<IVehicleResource[]> =>
    (await readFetch(`api/Users/${id}/vehicles`, abort)) as IVehicleResource[];

  static FinishRegistration = async (
    token: string,
    registration: IFinishRegistration,
    abort?: AbortSignal
  ): Promise<void> => {
    await createFetch(`api/Account/finish/${token}`, registration, abort);
  };

  static ForgotPassword = async (
    email: string,
    abort?: AbortSignal
  ): Promise<void> => {
    await createFetch("api/Account/forgot", email, abort);
  };

  static ResetPassword = async (
    password: string,
    token: string,
    abort?: AbortSignal
  ): Promise<void> => {
    await createFetch("api/Account/reset", { password, token }, abort);
  };
  static ResendActivation = async (
    user: IUserResource,
    abort?: AbortSignal
  ): Promise<void> => {
    await createFetch(`api/Users/${user.id}/activation/resend`, undefined, abort);
  };

  static ChangeName = async (
    id: number,
    names: IChangeName,
    abort?: AbortSignal
  ): Promise<void> => {
    await updateFetch(`api/Account/${id}/name`, names, abort);
  };

  static ChangePassword = async (
    id: number,
    password: string,
    newPassword: string,
    abort?: AbortSignal
  ): Promise<void> => {
    await updateFetch(
      `api/Account/${id}/password`,
      { password, newPassword },
      abort
    );
  };

  static ReadLogins = async (
    id: number,
    abort?: AbortSignal
  ): Promise<ILoginAttempt[]> =>
    (await readFetch(`api/Users/${id}/logins`, abort)) as ILoginAttempt[];
  static ReadLoginSummary = async (
    id: number,
    abort?: AbortSignal
  ): Promise<ILoginAttemptSummary> =>
    (await readFetch(
      `api/Users/${id}/logins/summary`,
      abort
    )) as ILoginAttemptSummary;
}

export interface IUserLogin {
  email: string;
  password: string;
  rememberMe: boolean;
}

import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Badge, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { IMeiTrackIdentification } from "../../models/meiTrack";
import TrackingUnit, {
  IInterval,
  ITrackingUnitResource,
  UnitType
} from "../../models/trackingUnit";
import { NotFoundError, BadRequestError } from "../../HttpErrors";

interface ConfigProps {
  trackingUnit: ITrackingUnitResource;
}
const Configuration: React.FC<ConfigProps> = ({ trackingUnit }) => {
  switch (trackingUnit.unitType) {
    case UnitType.VT600:
      return (
        <Fragment>
          {/* <UnitConfigModal title="Enheds ID">
            <MeiTrackIdentification trackingUnit={trackingUnit} />
          </UnitConfigModal> */}
          <UnitConfigModal title="Enheds interval">
            <UnitInterval trackingUnit={trackingUnit} />
          </UnitConfigModal>
          {/* <UnitConfigModal title="Genstart">
            <DeviceReboot trackingUnit={trackingUnit} />
          </UnitConfigModal> */}
        </Fragment>
      );
  }
  return null;
};

export default Configuration;

interface ModalProps {
  title: string;
}
const UnitConfigModal: React.FC<ModalProps> = ({ title, children }) => {
  const [shown, setShown] = useState(false);

  return (
    <div>
      <Button
        variant={shown ? "secondary" : "primary"}
        onClick={() => setShown(!shown)}
      >
        {title}
      </Button>
      {shown && <div className="unit-config">{children}</div>}
    </div>
  );
};

const DeviceReboot: React.FC<ConfigProps> = ({ trackingUnit }) => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState<Error | undefined>();

  const deviceReboot = async () => {
    try {
      setLoading(true);
      await TrackingUnit.RebootDevice(trackingUnit);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  };

  const gsmReboot = async () => {
    try {
      setLoading(true);
      await TrackingUnit.RebootGSM(trackingUnit);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  };

  const gpsReboot = async () => {
    try {
      setLoading(true);
      await TrackingUnit.RebootGPS(trackingUnit);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  };

  return (
    <div>
      {loading && <Spinner animation="border" />}
      <Button variant="success" onClick={deviceReboot}>
        Genstart enhed
      </Button>
      <br />
      <Button variant="success" onClick={gpsReboot}>
        Genstart GPS
      </Button>
      <br />
      <Button variant="success" onClick={gsmReboot}>
        Genstart GSM
      </Button>
    </div>
  );
};

const MeiTrackIdentification: React.FC<ConfigProps> = ({ trackingUnit }) => {
  const [loading, setLoading] = useState(false);
  const [identification, setIdentification] = useState<
    IMeiTrackIdentification | undefined
  >();
  const [error, setError] = useState<Error | undefined>();

  const loadIdentification = useCallback(async () => {
    try {
      setError(undefined);
      setLoading(true);
      const identification = await TrackingUnit.ReadMeiIdentification(
        trackingUnit
      );
      setLoading(false);
      setIdentification(identification);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  }, [trackingUnit]);

  useEffect(() => {
    loadIdentification();
  }, [loadIdentification]);

  if (error && error instanceof NotFoundError) {
    return (
      <div>
        <Badge variant="warning">Kunne ikke hente enheds info</Badge>
        <Button variant="primary" onClick={loadIdentification}>
          Prøv igen
        </Button>
      </div>
    );
  }

  return (
    <div>
      {loading && <Spinner animation="border" />}
      {identification && (
        <div>
          <p>
            <b>IMEI</b>
            {identification.imei}
          </p>
          <p>
            <b>Serie nr.</b>
            {identification.serialNumber}
          </p>
          <p>
            <b>Firmware</b>
            {identification.firmware}
          </p>
        </div>
      )}
    </div>
  );
};

const UnitInterval: React.FC<ConfigProps> = ({ trackingUnit }) => {
  const [loading, setLoading] = useState(false);
  const [interval, setInterval] = useState<IInterval | undefined>();
  const [error, setError] = useState<Error | undefined>();

  const loadInterval = useCallback(async () => {
    try {
      setError(undefined);
      setLoading(true);
      const interval = await TrackingUnit.ReadInterval(trackingUnit);
      setLoading(false);
      setInterval(interval);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  }, [trackingUnit]);

  useEffect(() => {
    loadInterval();
  }, [loadInterval]);


  const setUnitInterval = async () => {
    try {
      if (!interval) return;
      const rounded = Math.ceil(interval.interval / 10) * 10;
      setLoading(true);
      await TrackingUnit.SetInterval(trackingUnit, {interval: rounded});
      setLoading(false);
      setInterval({interval: rounded});
    } catch (error) {
      setLoading(false);
      window.alert("Fejl");
      console.error(error);
    }
  };
  if (error && error instanceof BadRequestError) {
    return (
      <div>
        <Badge variant="warning">Kunne ikke hente enheds interval</Badge>
        <Button variant="primary" onClick={loadInterval}>
          Prøv igen
        </Button>
      </div>
    );
  }

  return (
    <div>
      {loading && <Spinner animation="border" />}
      {interval && (
        <div>
          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Interval i sekunder</InputGroup.Text>
              </InputGroup.Prepend>
              <input
                type="number"
                min="0"
                max="655350"
                step="10"
                className="form-control"
                value={interval ? interval.interval : 0}
                onChange={(e) => setInterval({interval: +e.target.value})}
              />
            </InputGroup>
          </Form.Group>
          <Button variant="success" onClick={setUnitInterval}>
            Gem
          </Button>
        </div>
      )}
    </div>
  );
};

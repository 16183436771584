import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import SEGESCompany, {
  ISEGESCompanyBinding,
  ISEGESCompanyResource,
} from "../../../models/segesCompany";
import Companies from "../../Companies/Index";

interface Props {
  onCreated: (vehicle: ISEGESCompanyResource) => void;
}

const defaultBinding: ISEGESCompanyBinding = {
  authorizationNumber: "",
  company: undefined,
};

const Create = ({ onCreated }: Props) => {
  const [binding, setBinding] = useState(defaultBinding);

  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);

  const saveChanges = async () => {
    try {
      if (!binding.company) return;
      setLoading(true);
      const result = await SEGESCompany.Create(binding);
      result.company = binding.company;
      setLoading(false);
      setShown(false);
      onCreated && onCreated(result);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  };
  /**
   * Save when the form is submitted
   * @param event
   */
  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    await saveChanges();
  };

  const complete = binding.company && binding.authorizationNumber !== "";

  return (
    <Fragment>
      <Button title="Opret SEGES virksomhed" onClick={() => setShown(true)}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>

      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Opret SEGES virksomhed</Modal.Title>
          <Modal.Body>
            <Companies.Selector
              value={binding.company}
              onChange={(company) =>
                setBinding((prev) => ({ ...prev, company }))
              }
            />

            {binding.company && (
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      Autorisationsnummer (VVVO)
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    value={binding.authorizationNumber}
                    onChange={(event) =>
                      setBinding({
                        ...binding,
                        authorizationNumber: event.target.value,
                      })
                    }
                  />
                </InputGroup>
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              disabled={loading || !complete}
              type="submit"
            >
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setBinding(defaultBinding);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default Create;

import React from "react";
import { useCallback } from "react";
import { Spinner } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyResource } from "../../models/company";
import TableError from "../TableError";
import CompanyFlexVehicleTable from "./FlexTable";

interface Props {
  company: ICompanyResource;
}

const CompanyFlexVehicles: React.FC<Props> = ({ company }) => {
  const request = useCallback(
    (abort: AbortSignal) => Company.ReadFlexVehicles(company, abort),
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [flexVehicles, error] = usePromise(request, [], setLoading);

  if (loading) return <em>Loading...</em>;
  else if (error) return <TableError />;

  return (
    <CompanyFlexVehicleTable company={company} flexVehicles={flexVehicles} />
  );
};

export default CompanyFlexVehicles;

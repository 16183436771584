import React, { useMemo } from "react";

interface Props{
    corner?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
}
const ControlContent: React.FC<Props> = ({corner, children}) => {
    const className = corner ? `map-control-content ${corner}` : "map-control-content";
    return(
        <div className={className}>
            {children}
        </div>
    )
}
export default ControlContent;
import All from "./All";
import Create from "./Create";
import Delete from "./Delete";
import Table from "./Table";

export default class Vehicles{
    static Create = Create;
    static Delete = Delete;
    static Table = Table;
    static All = All
}
import { HubConnection } from "@microsoft/signalr";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Polyline, Popup, Tooltip } from "react-leaflet";
import { bearing, CalculateDistance, spaceship } from "../../helpers/utils";
import { useLiveCoordinatesFromConnection } from "../../hooks/useLiveCoordinates";
import useLoading from "../../hooks/useLoading";
import usePreferences from "../../hooks/usePreferences";
import usePromise from "../../hooks/usePromise";
import { IGPSCoordinate } from "../../models/gpsCoordinate";
import TrackLoader from "../../models/trackLoader";
import Vehicle from "../../models/vehicle";
import { MapVehicleIcon, TextVehicleIcon } from "../Icons/Icon";
import { ISelectedVehicle } from "./MapVehicleSelector";
import VehiclePopup from "./VehiclePopup";

interface Props {
  connection?: HubConnection;
  vehicle: ISelectedVehicle;
  scale?: number;
  zoom: number;
  showTooltip: boolean;
}

const VehicleTrack: React.FC<Props> = ({
  vehicle,
  connection,
  scale,
  zoom,
  showTooltip,
}) => {
  const [preferences] = usePreferences();
  const request = useCallback(
    (signal: AbortSignal) => Vehicle.ReadTracking(vehicle, signal),
    [vehicle]
  );
  const [loading, setLoading] = useLoading();
  const [coordinates, , setCoordinates] = usePromise(request, [], setLoading);
  const addLiveCoordinate = useCallback(
    (coordinate: IGPSCoordinate) => {
      if (coordinate.vehicleId === vehicle.id) {
        setCoordinates((live) => [coordinate, ...live]);
      }
    },
    [vehicle, setCoordinates]
  );

  useLiveCoordinatesFromConnection(connection, addLiveCoordinate);

  const realCoordinates = useMemo(() => {
    return coordinates.sort((a, b) => spaceship(a.timestamp, b.timestamp) * -1);
  }, [coordinates]);

  const angle = useMemo(() => findAngle(realCoordinates) || 0, [
    realCoordinates,
  ]);
  const speed = useMemo(() => {
    if (realCoordinates.length >= 2) {
      const coord1 = realCoordinates[0];
      const coord2 = realCoordinates[1];
      const distance = Math.abs(
        CalculateDistance(
          coord1.latitude,
          coord1.longitude,
          coord2.latitude,
          coord2.longitude
        )
      );
      let coord1Time = new Date(coord1.timestamp).getTime() / 1000;
      let coord2Time = new Date(coord2.timestamp).getTime() / 1000;
      const duration = Math.abs(coord1Time - coord2Time);

      return distance / duration;
    }
    return 0;
  }, [realCoordinates]);

  const still = speed <= 0.27;

  const online = useMemo(() => {
    const coordOnline = realCoordinates[0] && realCoordinates[0].accOn;
    const assumeOnline = speed > 15;
    return coordOnline || assumeOnline;
  }, [realCoordinates, speed]);

  const mapCoords = useMemo(
    () =>
      realCoordinates.map((coordinate) => ({
        lat: coordinate.latitude,
        lng: coordinate.longitude,
      })),
    [realCoordinates]
  );

  if (loading || coordinates.length === 0) return null;

  const toolTip = showTooltip ? (
    <Tooltip className="vehicle-tooltip" permanent={true} opacity={0.7}>
      {vehicle.alias ? vehicle.alias : vehicle.alias}
    </Tooltip>
  ) : (
    <Tooltip
      direction="bottom"
      className="vehicle-tooltip"
      permanent={false}
      opacity={0.7}
    >
      {vehicle.alias
        ? vehicle.alias
        : vehicle.licensePlate}
    </Tooltip>
  );

  return (
    <Fragment>
      {vehicle.pathShown && <Polyline positions={mapCoords} />}
      {preferences.map.useAliasAsIcon || !vehicle.icon ? (
        <TextVehicleIcon
          zoom={zoom}
          position={realCoordinates[0]}
          online={online}
          still={still}
          scale={scale}
          value={vehicle.alias}
          angle={angle}
        >
          {toolTip}
        </TextVehicleIcon>
      ) : (
        <MapVehicleIcon
          zoom={zoom}
          position={realCoordinates[0]}
          name={vehicle.icon}
          color={vehicle.color}
          online={online}
          angle={angle}
          still={still}
          scale={scale}
        >
          <Popup autoPan={false}>
            <VehiclePopup
              vehicle={vehicle}
              speed={speed}
              online={online}
              acc={vehicle.online}
            />
          </Popup>
          {toolTip}
        </MapVehicleIcon>
      )}
    </Fragment>
  );
};

export default VehicleTrack;

interface LoaderProps {
  loader: TrackLoader;
  zoom: number;
  showTooltip: boolean;
  path: boolean;
}
export const VehicleTrack2 = ({
  loader,
  zoom,
  showTooltip,
  path,
}: LoaderProps) => {
  const [preferences] = usePreferences();
  const [coordinates, setCoordinates] = useState(loader.coordinates);

  useEffect(() => {
    loader.onCoordinateArrived = (coordinate) => {
      setCoordinates((cur) => [coordinate, ...cur]);
    };

    setCoordinates(loader.coordinates);

    return () => {
      loader.onCoordinateArrived = undefined;
    };
  }, [loader]);

  const scale = preferences.map.iconSizeScale;
  const speed = useMemo(() => {
    if (coordinates.length >= 2) {
      const coord1 = coordinates[0];
      const coord2 = coordinates[1];
      const distance = Math.abs(
        CalculateDistance(
          coord1.latitude,
          coord1.longitude,
          coord2.latitude,
          coord2.longitude
        )
      );
      let coord1Time = new Date(coord1.timestamp).getTime() / 1000;
      let coord2Time = new Date(coord2.timestamp).getTime() / 1000;
      const duration = Math.abs(coord1Time - coord2Time);

      return distance / duration;
    }
    return 0;
  }, [coordinates]);
  const still = speed <= 0.27;

  const online = useMemo(() => {
    const coordOnline = coordinates[0] && coordinates[0].accOn;
    const assumeOnline = speed > 15;
    return coordOnline || assumeOnline;
  }, [coordinates, speed]);

  const angle = useMemo(() => findAngle(coordinates) || 90, [coordinates]);

  const toolTip = useMemo(() => showTooltip ? (
    <Tooltip key="0" className="vehicle-tooltip" permanent={true} opacity={0.7}>
      {loader.vehicle.alias ? loader.vehicle.alias : loader.vehicle.alias}
    </Tooltip>
  ) : (
    <Tooltip
    key="1"
      className="vehicle-tooltip"
      permanent={false}
      opacity={0.7}
    >
      {loader.vehicle.alias
        ? loader.vehicle.alias
        : loader.vehicle.licensePlate}
    </Tooltip>
  ), [showTooltip]);

  const popup = (
    <Popup autoPan={false}>
      <VehiclePopup
        vehicle={loader.vehicle}
        speed={speed}
        online={online}
        acc={loader.vehicle.online}
      />
    </Popup>
  );

  const leafletCoordinates: [number, number][] = useMemo(
    () => coordinates.map((c) => [c.latitude, c.longitude]),
    [coordinates]
  );

  return (
    <Fragment>
      {path && <Polyline positions={leafletCoordinates} />}
      {preferences.map.useAliasAsIcon || !loader.vehicle.icon ? (
        <TextVehicleIcon
          zoom={zoom}
          position={coordinates[0]}
          online={online}
          still={still}
          scale={scale}
          value={loader.vehicle.alias}
          angle={angle}
        >
          {popup}
        </TextVehicleIcon>
      ) : (
        <MapVehicleIcon
          zoom={zoom}
          position={coordinates[0]}
          name={loader.vehicle.icon}
          color={loader.vehicle.color}
          online={online}
          angle={angle}
          still={still}
          scale={scale}
        >
          {popup}
          {toolTip}
        </MapVehicleIcon>
      )}
    </Fragment>
  );
};

const findAngle = (coordinates: IGPSCoordinate[]) => {
  const minDistance = 10;
  if (coordinates.length > 1) {
    const first = coordinates[0];
    for (let i in coordinates) {
      let current = coordinates[i];

      let distance = CalculateDistance(
        first.latitude,
        first.longitude,
        current.latitude,
        current.longitude
      );
      if (distance > minDistance) {
        return (
          bearing(
            current.latitude,
            current.longitude,
            first.latitude,
            first.longitude
          )
        );
      }
    }
  }
};

import { faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useReducer } from "react";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import useLiveCoordinates from "../../hooks/useLiveCoordinates";
import useSortedSearch from "../../hooks/useSortedSearch";
import { ICompanyResource } from "../../models/company";
import { IGPSCoordinate } from "../../models/gpsCoordinate";
import { IVehicleResource, VehicleProps, VehicleResource } from "../../models/vehicle";
import { VehicleIcon } from "../Icons/Icon";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import Vehicles from "../Vehicles/Index";

interface Props {
  vehicles: IVehicleResource[];
  company: ICompanyResource;
}

type Add = {
  readonly type: "ADD";
  vehicle: IVehicleResource;
};
type Remove = {
  readonly type: "REMOVE";
  vehicle: IVehicleResource;
};
type Update = {
  readonly type: "UPDATE";
  vehicle: IVehicleResource;
};

type Replace = {
  readonly type: "REPLACE";
  vehicles: IVehicleResource[];
};
type SetOnline = {
  readonly type: "ONLINE";
  vehicleId: number;
  online: boolean;
};

type Actions = Add | Remove | Update | Replace | SetOnline;

const reducer = (
  vehicles: IVehicleResource[],
  action: Actions
): IVehicleResource[] => {
  switch (action.type) {
    case "ADD":
      return [...vehicles, action.vehicle];
    case "REMOVE":
      return vehicles.filter((v) => v.id !== action.vehicle.id);
    case "UPDATE":
      const index = vehicles.findIndex(
        (v: IVehicleResource) => v.id === action.vehicle.id
      );
      return [
        ...vehicles.slice(0, index),
        action.vehicle,
        ...vehicles.slice(index + 1),
      ];
    case "ONLINE":
      const vehicle = vehicles.find((v) => v.id === action.vehicleId);
      if (vehicle) {
        vehicle.online = action.online;
        return reducer(vehicles, { type: "UPDATE", vehicle });
      }
      return vehicles;
    case "REPLACE":
      return action.vehicles;
  }
};

const CompanyVehicleTable: React.FC<Props> = ({ vehicles: veh, company }) => {
  const [vehicles, dispatch] = useReducer(reducer, veh);
  const [
    sorted,
    search,
    sort,
    setSort,
    setSearch,
  ] = useSortedSearch<VehicleResource, VehicleProps>(vehicles.map(v => new VehicleResource(v)), undefined, {
    type: "alias",
    fallback: "licensePlate",
    asc: true,
  });

  // const handleCoordinateUpdate = useCallback((coordinate: IGPSCoordinate) => {
  //   const online = coordinate.accOn;
  //   dispatch({ type: "ONLINE", vehicleId: coordinate.vehicleId, online });
  // }, []);

  // useLiveCoordinates(handleCoordinateUpdate);

  return (
    <div className="vehicles">
      <Table striped responsive bordered >
        <thead>
          <tr>
            <th colSpan={2}></th>
            <SearchableHeader
              value={search}
              types={["alias"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["licensePlate"]}
              onSearch={setSearch}
            />
            <th></th>
            <th>
              <Vehicles.Create
                company={company}
                onCreated={(vehicle) => dispatch({ type: "ADD", vehicle })}
              />
            </th>
          </tr>
          <tr>
            <th colSpan={2}></th>
            <SortableHeader type="alias" value={sort} onChange={setSort}>
              Kaldenavn
            </SortableHeader>
            <SortableHeader type="licensePlate" value={sort} onChange={setSort}>
              Indregisterings nr.
            </SortableHeader>
            <SortableHeader type="online" value={sort} onChange={setSort}>
              Tændt
            </SortableHeader>
            <th colSpan={2}></th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<IVehicleResource>
            items={sorted}
            id="vehicles"
            sizeSelector={true}
            renderItem={(item) => (
              <tr key={item.id}>
                <td>{item.icon && <VehicleIcon name={item.icon} color={item.color} />}</td>
                <td>
                  <Link title="Vis på kort" to={`/map/live/${item.id}`}>
                    <Button size="sm" variant="secondary">
                      <FontAwesomeIcon icon={faSearchLocation} />
                    </Button>
                  </Link>
                </td>
                <td>{item.alias}</td>
                <td>{item.licensePlate}</td>
                <td>
                  <Vehicles.OnlineIcon vehicle={item} />
                </td>
                <td>
                  <Vehicles.Edit
                    vehicle={item}
                    onUpdated={(vehicle) =>
                      dispatch({ type: "UPDATE", vehicle })
                    }
                  />
                  <Vehicles.Delete
                    vehicle={item}
                    onDeleted={(vehicle) =>
                      dispatch({ type: "REMOVE", vehicle })
                    }
                  />
                </td>
              </tr>
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default CompanyVehicleTable;

import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/esm/types";

interface Props {
  value: string;
  title?: string;
  variant: ButtonVariant;
  initialShown?: boolean;
  unmountOnHidden?: boolean;
  onToggled?: (shown: boolean) => void;
}

const ToggleSection: React.FC<Props> = ({
  value,
  title,
  variant,
  initialShown,
  unmountOnHidden,
  onToggled,
  children,
}) => {
  const [shown, setShown] = useState(initialShown);
  const toggle = () => {
    const togShown = !shown;
    setShown(togShown);
    onToggled && onToggled(togShown);
  };
  const contentClass = shown
    ? "toggle-section-content active"
    : "toggle-section-content";
  const shouldRender = !unmountOnHidden || shown;
  const icon = shown ? faChevronDown : faChevronRight;
  return (
    <div className="toggle-section">
      <div className="toggle-section-top">
        <Button title={title} variant={variant} onClick={() => toggle()}>
          {value}
          <div className="divider" />
          <FontAwesomeIcon icon={icon} />
        </Button>
      </div>
      <div className={contentClass}>
        {shouldRender && <Fragment>{children}</Fragment>}
      </div>
    </div>
  );
};

export default ToggleSection;

import React, { FormEvent, useContext, useState } from "react";
import { Badge, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import UserContext from "../../contexts/userContext";
import useSuccess from "../../hooks/useSuccess";
import { IChangeName } from "../../models/changeName";
import User, { IUserResource } from "../../models/user";
import FormControlFeedback from "../FormControlFeedback";
interface Props {
  user: IUserResource;
}
const ChangeName: React.FC<Props> = ({ user: usr }) => {
  const { user, setUser } = useContext(UserContext);
  const [name, setName] = useState<IChangeName>({
    firstName: usr.firstName,
    lastName: usr.lastName,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  const [success, succeed] = useSuccess(1000);

  const saveChanges = async () => {
    if (user && name) {
      try {
        setLoading(true);
        await User.ChangeName(usr.id, name);
        succeed();
        if (usr.id === user.identity.id) {
          setUser({
            ...user,
            identity: { ...user.identity, name: name.firstName },
          });
        }
      } catch (error) {
        setError(error);
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };

  return (
    <form onSubmit={formSave}>
      <Form.Group>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>Fornavn</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControlFeedback
            errors={error}
            field="firstName"
            defaultValue={name.firstName}
            onChange={(value: string) => setName({ ...name, firstName: value })}
          />
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>Efternavn</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControlFeedback
            errors={error}
            field="lastName"
            defaultValue={name.lastName}
            onChange={(value: string) => setName({ ...name, lastName: value })}
          />
        </InputGroup>
      </Form.Group>
      {success && <Badge variant="success">Navn er blevet ændret</Badge>}
      <br />
      <Button variant="success" type="submit">
        Gem
        {loading && <Spinner size="sm" animation="border" />}
      </Button>
    </form>
  );
};

export default ChangeName;

import { Badge } from "react-bootstrap"
import React from "react"

interface Props{
    available: number,
    used: number,
    assigned: number
}
const QuotaUsage:React.FC<Props> = ({available, used, assigned}) => {
    
    return(
        <Badge variant={available + assigned >= used ? "success" : "danger"}>
            {used} af {available} + {assigned}
        </Badge>
    )
}

export default QuotaUsage;
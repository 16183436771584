import { faAsterisk, faDirections, faForward, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Leg, Route, Step } from "../../models/route";

interface Props {
  route: Route;
  onRemove: () => void;
}

const RouteDirections: React.FC<Props> = ({ route, onRemove }) => {
  const [expand, setExpand] = useState(false);

  return (
    <div>
      <div className={`route-${expand ? "active" : "collapsed"} route-planner`}>
        <span>
          {Math.round(route.distance / 1000)} KM,{" "}
          {Math.round(route.duration / 60)} min{" "}
        </span>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => setExpand(!expand)}
        >
          <FontAwesomeIcon icon={faDirections} size="lg" />
        </Button>
        {onRemove && (
          <Button variant="warning" size="sm" onClick={() => onRemove()}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </Button>
        )}
      </div>
      {expand && (
        <div>
          {route.legs.map((leg, index) => (
            <DirectionsLeg key={index} leg={leg} />
          ))}
        </div>
      )}
    </div>
  );
};

export default RouteDirections;

interface LegProps {
  leg: Leg;
}
const DirectionsLeg: React.FC<LegProps> = ({ leg }) => {
  return (
    <div className="route-leg">
      <FontAwesomeIcon icon={faForward} size="lg" />
      {leg.summary}
      {leg.steps.map((step, index) => (
        <DirectionsStep step={step} key={index} />
      ))}
    </div>
  );
};

interface StepProps {
  step: Step;
}
const DirectionsStep: React.FC<StepProps> = ({ step }) => {
  if (step.maneuver.type === "depart") {
    return (
      <div className="route-step">
        <b>Afgang</b>
        <br />
      </div>
    );
  }
  //Show distance as KM when greater than 10 km
  const dis = step.distance > 10000 ? `${Math.round(step.distance / 1000)} Km` : `${step.distance} Meter`;
  return (
    <div className="route-step">
      <span>{`${step.name} - ${dis}`}</span>
      <br />
      <div className="route-step-instruction">
        <span>
          <FontAwesomeIcon icon={faAsterisk} />
          {step.maneuver.instruction}
        </span>
        <br />
      </div>
    </div>
  );
};

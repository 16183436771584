import React, { useMemo, useCallback } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import TrackingUnit from "../../models/trackingUnit";
import TrackingUnits from "./Index";
interface MatchParams {
  id: string;
  tab?: string;
}
interface Props extends RouteComponentProps<MatchParams> {}

const TrackingUnitIndex: React.FC<Props> = ({ match }) => {
  const unitId = useMemo(() => match.params.id, [match.params.id]);

  const request = useCallback(
    (signal: AbortSignal) => TrackingUnit.Read(unitId, signal),
    [unitId]
  );
  const [loading, setLoading] = useLoading();
  const [trackingUnit] = usePromise(request, undefined, setLoading);

  if (loading) return <Spinner animation="border" />;
  if (!trackingUnit)
    return <Badge variant="warning">Kunne ikke hente enheden</Badge>;
  return (
    <TrackingUnits.Details
      initialTab={match.params.tab}
      trackingUnit={trackingUnit}
    />
  );
};

export default TrackingUnitIndex;

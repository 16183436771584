import { readFetch } from "../helpers/fetchers";
import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";

export interface ILoginAttempt {
  id: number;
  ip: string;
  success: boolean;
  timeOfLogin: string;
  loginType: number;
  userAgent: string;
}
export type LoginAttemptProps = "ip" | "success" | "timeOfLogin" | "loginType" | "userAgent";

export interface ILoginAttemptSummary {
  passwordLogins: number;
  refreshLogins: number;
}

export default class LoginAttempt implements ILoginAttempt, ISortable<LoginAttemptProps>, ISearchable<LoginAttemptProps> {
  id: number;
  ip: string;
  success: boolean;
  timeOfLogin: string;
  loginType: number;
  userAgent: string;
  constructor(value: ILoginAttempt){
    this.id = value.id;
    this.ip = value.ip;
    this.success = value.success;
    this.timeOfLogin = value.timeOfLogin;
    this.loginType = value.loginType;
    this.userAgent = value.userAgent;
  }
  search = (value: any, type: LoginAttemptProps): number | undefined => 
    basicSearch(this, value, type, false);
  compare = (value: ISortable<LoginAttemptProps>, primary: LoginAttemptProps, secondary?: LoginAttemptProps): number => 
    basicSecSort(this, value, primary, secondary);

  static Read = async (
    id: number,
    abort: AbortSignal
  ): Promise<ILoginAttempt[]> =>
    (await readFetch(`api/Users/${id}/logins`, abort)) as ILoginAttempt[];

  static ReadSummary = async (
    id: number,
    abort: AbortSignal
  ): Promise<ILoginAttemptSummary> =>
    (await readFetch(
      `api/Users/${id}/logins/summary`,
      abort
    )) as ILoginAttemptSummary;
}

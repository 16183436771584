import { Button } from "react-bootstrap";
import React, { Fragment } from "react";

interface Props {
  onChange: (from: Date, to: Date) => void;
  [x: string]: any;
}

export const ButtonToday: React.FC<Props> = ({ onChange, ...rest }) => {
  const submit = () => {
    var today = new Date();
    var midnight = new Date();
    midnight.setHours(0, 0, 0, 0);
    onChange(midnight, today);
  };

  return (
    <Button size="sm" {...rest} onClick={submit}>
      I dag
    </Button>
  );
};

export const ButtonThisWeek: React.FC<Props> = ({ onChange, ...rest }) => {
  const submit = () => {
    var today = new Date();
    var prevMonday = new Date();
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
    prevMonday.setHours(0, 0, 0, 0);
    onChange(prevMonday, today);
  };

  return (
    <Button size="sm" {...rest} onClick={submit}>
      Denne uge
    </Button>
  );
};


const DateSelectorButtons: React.FC<Props> = ({ onChange, ...rest }) => {
  return (
    <Fragment>
      <ButtonToday onChange={onChange} />
      <div className="divider" />
      <ButtonThisWeek onChange={onChange} />
    </Fragment>
  );
};

export default DateSelectorButtons;

import { getHTML5DateTimeStringsFromDate } from "../../helpers/utils";
import React from "react";

interface Props {
  from: Date;
  to: Date;
  onChange: (from: Date, to: Date) => void;
  dateOnly?: boolean;
}

const DateSelector: React.FC<Props> = ({ from, to, onChange, dateOnly }) => {
  const changeFrom = (value: Date) => {
    onChange(value, to);
  };
  const changeTo = (value: Date) => {
    onChange(from, value);
  };

  return (
    <div className="date-selector">
      <label>
        Fra dato
        <input
          type={dateOnly ? "date" : "datetime-local"}
          max={getHTML5DateTimeStringsFromDate(to)}
          defaultValue={getHTML5DateTimeStringsFromDate(from)}
          className="form-control"
          onChange={(e) => changeFrom(new Date(e.target.value))}
        />
      </label>
      <label>
        Til dato
        <input
          type={dateOnly ? "date" : "datetime-local"}
          min={getHTML5DateTimeStringsFromDate(from)}
          defaultValue={getHTML5DateTimeStringsFromDate(to)}
          className="form-control"
          onChange={(e) => changeTo(new Date(e.target.value))}
        />
      </label>
    </div>
  );
};

export default DateSelector;

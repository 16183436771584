import SelectedCompanyContext from "contexts/selectedCompanyContext";
import React from "react";
import { useContext } from "react";
import { Tachographs } from "./Tachographs";

export const TachoIndex = () => {
  const { company } = useContext(SelectedCompanyContext);

  if (!company) return null;

  return <Tachographs company={company} />;
};

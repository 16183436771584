import { authHeader, jsonHeader } from "./auth-header";
import {
  HttpTypeError,
  BadRequestError,
  HttpCodeError,
  BadRequestState,
} from "../HttpErrors";
import contentDisposition from "content-disposition";
/**
 * Creates the item at the URL
 * @param url URL to create the item at
 * @param item The item to create
 */
export const createFetch = async <T>(
  url: string,
  item: any | undefined,
  abort?: AbortSignal
): Promise<T | undefined> => {
  try {
    const response = await fetch(
      url,
      (item
        ? jsonHeader("POST", item, abort)
        : authHeader("POST", undefined, abort)) as RequestInit
    );
    switch (response.status) {
      case 200:
      case 201:
        return (await response.json()) as T;
      case 204:
        return undefined;
      case 400:
        throw new BadRequestError({
          code: response.status,
          body: await response.json(),
        });
      default:
        throw new HttpTypeError(response.status);
    }
  } catch (error) {
    throw error;
  }
};
export const createFetch2 = async <TModel, TErrorModel>(
  url: string,
  item: any | undefined,
  abort?: AbortSignal
): Promise<[TModel?, TErrorModel?]> => {
  try {
    const response = await fetch(
      url,
      (item
        ? jsonHeader("POST", item, abort)
        : authHeader("POST", undefined, abort)) as RequestInit
    );
    switch (response.status) {
      case 200:
      case 201:
        const result = (await response.json()) as TModel;
        return [result];
      case 204:
        return [];
      case 400:
        const error = (await response.json()) as TErrorModel;
        return [, error];
      default:
        throw new HttpTypeError(response.status);
    }
  } catch (error) {
    throw error;
  }
};

/**
 * Read the resource at the URL
 * @param url URL to create the item at
 */
export const readFetch = async <T>(
  url: string,
  abort?: AbortSignal
): Promise<T> => {
  try {
    const response = await fetch(
      url,
      authHeader(undefined, undefined, abort) as RequestInit
    );
    switch (response.status) {
      case 200:
        return (await response.json()) as T;
      case 400:
        throw new BadRequestError({
          code: response.status,
          body: await response.json(),
        });
      default:
        throw new HttpCodeError(response.status);
    }
  } catch (error) {
    throw error;
  }
};

/**
 * Updates the item at the URL
 * @param url URL of the item to update
 * @param item The updated item
 */
export const updateFetch = async <T>(
  url: string,
  item: T,
  abort?: AbortSignal
): Promise<T | undefined> => {
  try {
    const response = await fetch(
      url,
      jsonHeader("PUT", item, abort) as RequestInit
    );
    switch (response.status) {
      case 200:
      case 201:
        return (await response.json()) as T;
      case 204:
        return item;
      case 400:
        throw new BadRequestError({
          code: response.status,
          body: await response.json(),
        });
      default:
        throw new HttpTypeError(response.status);
    }
  } catch (error) {
    throw error;
  }
};
/**
 * Updates the item at the URL
 * @param url URL of the item to update
 * @param item The updated item
 */
export const updateFetch2 = async <TModel, TErrorModel>(
  url: string,
  item: TModel,
  abort?: AbortSignal
): Promise<[TModel?, TErrorModel?]> => {
  try {
    const response = await fetch(
      url,
      jsonHeader("PUT", item, abort) as RequestInit
    );
    switch (response.status) {
      case 200:
      case 201:
        const result = (await response.json()) as TModel;
        return [result];
      case 204:
        return [item];
      case 400:
        const error = (await response.json()) as TErrorModel;
        return [, error];
      default:
        throw new HttpTypeError(response.status);
    }
  } catch (error) {
    throw error;
  }
};

/**
 * Delete the resource at the URL
 * @param url URL of the resource to delete
 */
export const deleteFetch = async (
  url: string,
  abort?: AbortSignal
): Promise<void> => {
  try {
    const response = await fetch(
      url,
      authHeader("DELETE", undefined, abort) as RequestInit
    );
    switch (response.status) {
      case 200:
      case 204:
        return;
      default:
        throw new HttpTypeError(response.status);
    }
  } catch (error) {
    throw error;
  }
};

export const uploadFetch = async <T>(
  url: string,
  form: FormData
): Promise<T | undefined> => {
  try {
    const head = authHeader("POST", form);

    const response = await fetch(url, head);
    switch (response.status) {
      case 200:
      case 201:
        return (await response.json()) as T;
      case 204:
        return undefined;

      case 400:
        throw new BadRequestError({
          code: response.status,
          body: await response.json(),
        });
      default:
        throw new HttpTypeError(response.status);
    }
  } catch (error) {
    throw error;
  }
};

interface IDownloadedFile {
  fileName: string;
  blob: Blob;
}

export const downloadFileFetch = async (
  url: string,
  abort?: AbortSignal
): Promise<IDownloadedFile | undefined> => {
  try {
    const head = authHeader("GET", undefined, abort);

    const response = await fetch(url, head);
    switch (response.status) {
      case 200:
      case 201:
        const cHeader = response.headers.get("Content-Disposition");
        if (cHeader) {
          const disp = contentDisposition.parse(cHeader);
          const { filename } = disp.parameters;
          if (filename) {
            return { fileName: filename, blob: await response.blob() };
          }
        }
        return undefined;
      case 204:
        return undefined;

      case 400:
        throw new BadRequestError({
          code: response.status,
          body: await response.json(),
        });
      default:
        throw new HttpTypeError(response.status);
    }
  } catch (error) {
    throw error;
  }
};
export const downloadFilePostFetch = async (
  url: string,
  body: any,
  abort?: AbortSignal
): Promise<IDownloadedFile | undefined> => {
  try {
    const head = jsonHeader("POST", body, abort);

    const response = await fetch(url, head);
    switch (response.status) {
      case 200:
      case 201:
        const cHeader = response.headers.get("Content-Disposition");
        if (cHeader) {
          const disp = contentDisposition.parse(cHeader);
          const { filename } = disp.parameters;
          if (filename) {
            return { fileName: filename, blob: await response.blob() };
          }
        }
        return undefined;
      case 204:
        return undefined;

      case 400:
        throw new BadRequestError({
          code: response.status,
          body: await response.json(),
        });
      default:
        throw new HttpTypeError(response.status);
    }
  } catch (error) {
    throw error;
  }
};

export const downloadFetch = async (
  url: string,
  abort?: AbortSignal
): Promise<Blob | undefined> => {
  try {
    const head = authHeader("GET", undefined, abort);

    const response = await fetch(url, head);
    switch (response.status) {
      case 200:
      case 201:
        return response.blob();
      case 204:
        return undefined;

      case 400:
        throw new BadRequestError({
          code: response.status,
          body: await response.json(),
        });
      default:
        throw new HttpTypeError(response.status);
    }
  } catch (error) {
    throw error;
  }
};

export const convertBlobToBase64 = (blob: Blob) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

import { IJWTCompany } from "models/jwtUser";
import React from "react";

export interface SelectedCompanyContextValue {
  company?: IJWTCompany;
  setCompany: (company?: IJWTCompany) => void;
}


const SelectedCompanyContext = React.createContext<SelectedCompanyContextValue>({
    company: undefined,
    setCompany: () => {},
  });
  
  export default SelectedCompanyContext;
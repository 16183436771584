import React, { Fragment } from "react";
import TimeSpan from "../models/timespan";
import NumberInput from "./NumberInput";

interface Props {
  value: TimeSpan;
  onChange: (value: TimeSpan) => void;
  disableHours?: boolean;
}

const TimeSpanInput = ({ value, onChange, disableHours }: Props) => (
  <div className="timespan-input">
    {!disableHours && (
      <Fragment>
        <NumberInput
          title="Timer"
          min={0}
          value={value.hours}
          onChange={(hours) =>
            onChange(
              new TimeSpan(value.seconds, value.minutes, hours)
            )
          }
        />
        {" : "}
      </Fragment>
    )}
    <NumberInput
      title="Minutter"
      min={value.hours > 0 ? undefined : 0}
      max={disableHours ? 59 : undefined}
      value={value.minutes}
      onChange={(minutes) =>
        onChange(new TimeSpan(value.seconds, minutes, value.hours))
      }
    />
    {" : "}
    <NumberInput
      title="Sekunder"
      min={value.minutes + value.hours > 0 ? undefined : 0}
      value={value.seconds}
      onChange={(seconds) =>
        onChange(new TimeSpan(seconds, value.minutes, value.hours))
      }
    />
  </div>
);

export default TimeSpanInput;

import React from "react";
import { Switch } from "react-router-dom";
import AuthenticatedRoute from "../AuthenticatedRoute";
import Layouts from "../Layouts/Index";
import Tracking, { TrackingPreselected } from "../Tracking/Tracking";
import VehicleHistory, { VehicleHistoryIndex } from "../Tracking/VehicleHistory";

const MapRoutes: React.FC = () => {
  return (
    <Layouts.Maps>
      <Switch>
        {/* Live */}
        <AuthenticatedRoute exact path="/map/live" isAuthorized={() => true}>
          <Tracking />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path="/map/live/:id"
          isAuthorized={() => true}
          component={TrackingPreselected}
        />
        {/* History */}
        <AuthenticatedRoute exact path="/map/history" isAuthorized={() => true}>
          <VehicleHistory />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path="/map/history/:id/:from/:to"
          isAuthorized={() => true}
          component={VehicleHistoryIndex}
        />
      </Switch>
    </Layouts.Maps>
  );
};

export default MapRoutes;

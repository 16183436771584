import Vehicle, { IVehicleResource } from "../../models/vehicle";
import React, { useState, useEffect } from "react";
import { VehicleDistance } from "../../models/vehicleDistance";
import { Spinner } from "react-bootstrap";
import { NumberWithSeperator } from "../NumberWithSeperator";

interface Props {
  vehicle: IVehicleResource;
  daily: boolean;
  total: boolean;
  totalByDates: boolean;
  from?: Date;
  to: Date;
}

const VehicleKilometerCounter: React.FC<Props> = ({
  vehicle,
  daily,
  total,
  totalByDates,
  from,
  to,
}) => {
  const [loading, setLoading] = useState(true);
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    var abort = new AbortController();
    const loadDistance = async () => {
      try {
        setLoading(true);
        var distance: VehicleDistance | undefined = undefined;
        if (daily) {
          distance = await Vehicle.ReadDistanceDaily(vehicle, abort.signal);
        } else if (total) {
          distance = await Vehicle.ReadDistanceTotal(vehicle, abort.signal);
        } else if (from && to) {
          if (totalByDates) {
            distance = await Vehicle.ReadDistanceTotalByDates(
              vehicle,
              from,
              to,
              abort.signal
            );
          } else {
            distance = await Vehicle.ReadDistance(
              vehicle,
              from,
              to,
              abort.signal
            );
          }
        }

        setDistance(distance ? Math.floor(distance.distance / 1000) : 0);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    loadDistance();
    return () => {
      abort.abort();
    };
  }, [from, to, daily, total, totalByDates, vehicle]);

  return loading ? (
    <Spinner animation="grow" size="sm" />
  ) : (
    <span>
      <NumberWithSeperator number={distance} />
      Km
    </span>
  );
};

export default VehicleKilometerCounter;

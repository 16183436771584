import React, { FormEvent, useState } from "react";
import { Button } from "react-bootstrap";
import usePreferences from "../../hooks/usePreferences";
import PreferenceForm from "./PreferenceForm";

const ChangeSettings: React.FC = () => {
  const [savedPreferences, setSavedPreferences] = usePreferences();

  const [preferences, setPreferences] = useState(savedPreferences);

  const saveChanges = () => {
    setSavedPreferences(preferences);
  };

  const formSave = (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();
    saveChanges();
  };
  return (
    <form onSubmit={formSave}>
      <div className="preferences">
        <PreferenceForm value={preferences} onChange={setPreferences} />

        <Button type="submit" disabled={preferences === savedPreferences} variant="success">Gem</Button>
      </div>
    </form>
  );
};
export default ChangeSettings;

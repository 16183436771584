import React, { useMemo, useState } from "react";
import { Alert, Form, FormControl, InputGroup } from "react-bootstrap";
import Preferences, { IPreferences } from "../../models/preferences";
import Slider from "../Slider";
import DefaultIcon from "../Images/truck.svg";
import { VehicleIcon } from "../Icons/Icon";
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  value: IPreferences;
  onChange: (value: IPreferences) => void;
}
const PreferenceForm: React.FC<Props> = ({ value, onChange }) => {
  const iconPercentage = useMemo(
    () => Math.round(value.map.iconSizeScale * 100),
    [value.map.iconSizeScale]
  );

  const zoomLevel = useMemo(
    () => value.map.zoomLevel || Preferences.Default.map.zoomLevel,
    [value]
  );
  const vehicleZoomLevel = useMemo(
    () => value.map.vehicleZoomLevel || Preferences.Default.map.vehicleZoomLevel,
    [value]
  );
  

  // const [iconHover, setIconHover] = useState(false);

  return (
    <div className="section">
      <b>Kort indstillinger</b>
      <hr />
      <div className="icon-size">
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Køretøjs ikon størrelse</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              value={`${iconPercentage}%`}
              readOnly={true}
            />
            <Slider
              className="form-control-range"
              value={value.map.iconSizeScale * 100}
              min={70}
              max={130}
              step={1}
              onChange={(val) =>
                onChange({
                  ...value,
                  map: { ...value.map, iconSizeScale: val / 100 },
                })
              }
            />
          </InputGroup>
        </Form.Group>
      </div>
      <div className="icon-size">
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Standard zoom niveau</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl type="text" value={`${zoomLevel}`} readOnly={true} />
            <Slider
              className="form-control-range"
              value={zoomLevel}
              min={0}
              max={18}
              step={1}
              onChange={(val) =>
                onChange({
                  ...value,
                  map: { ...value.map, zoomLevel: val },
                })
              }
            />
          </InputGroup>
        </Form.Group>
      </div>
      <div className="icon-size">
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>'Zoom til køretøj' zoom niveau (<FontAwesomeIcon icon={faCrosshairs} />)</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl type="text" value={`${vehicleZoomLevel}`} readOnly={true} />
            <Slider
              className="form-control-range"
              value={vehicleZoomLevel}
              min={10}
              max={18}
              step={1}
              onChange={(val) =>
                onChange({
                  ...value,
                  map: { ...value.map, vehicleZoomLevel: val },
                })
              }
            />
          </InputGroup>
        </Form.Group>
      </div>
      {/* <div
        onMouseEnter={() => setIconHover(true)}
        onMouseLeave={() => setIconHover(false)}
      >
        <VehicleIcon
          name={"truck"}
          online={iconHover}
          online={false}
          still={false}
          zoom={zoomLevel}
          scale={value.map.iconSizeScale}
        />
      </div> */}
      <Form.Group>
        <Form.Check
          label="Vis køretøjs navne når kort vises"
          id="show-map-tooltip"
          custom
          checked={value.map.showNamesByDefault}
          onChange={() =>
            onChange({
              ...value,
              map: {
                ...value.map,
                showNamesByDefault: !value.map.showNamesByDefault,
              },
            })
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          label="Brug køretøjs navn som ikon"
          id="use-vehicle-alias-icon"
          custom
          checked={value.map.useAliasAsIcon}
          onChange={() =>
            onChange({
              ...value,
              map: {
                ...value.map,
                useAliasAsIcon: !value.map.useAliasAsIcon,
              },
            })
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          label="Deaktiver eksperimentel optimering"
          id="disable-experimental-map-optimization"
          custom
          checked={value.map.disableOptimization}
          onChange={() =>
            onChange({
              ...value,
              map: {
                ...value.map,
                disableOptimization: !value.map.disableOptimization,
              },
            })
          }
        />
      </Form.Group>
      {value.map.disableOptimization && <Alert variant="warning">Deaktivering af dette kan gøre live-kortet meget langsomt hvis der er valgt mange køretøjer</Alert>}
    </div>
  );
};
export default PreferenceForm;

import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { Spinner } from "react-bootstrap";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyResource } from "../../models/company";
import Vehicle, { IVehicleResource, VehicleProps, VehicleResource } from "../../models/vehicle";
import UserContext from "../../contexts/userContext";
import User from "../../models/user";
import TableError from "../TableError";
import useSorting from "../../hooks/useSorting";
import ExcludeContext from "../../contexts/excludeContext";
import useLoading from "../../hooks/useLoading";
interface Props {
  vehicles: IVehicleResource[];
  value?: IVehicleResource;
  onChange: (vehicle: IVehicleResource | undefined) => void;
}

const VehicleSelector: React.FC<Props> = ({ vehicles, value, onChange }) => {

  const {excludes} = useContext(ExcludeContext);

  const actual = useMemo(() => {
    return vehicles.filter(v => !excludes.vehicles.filter(exv => exv.id === v.id).length).map(v => new VehicleResource(v));
  }, [vehicles, excludes.vehicles]);

  const [sorted] = useSorting<VehicleResource, VehicleProps>(actual, {type: "alias", fallback: "licensePlate", asc: true});
  return (
    <select
      className="form-control w-auto"
      value={value ? value.id.toString() : "0"}
      onChange={(e) => {
        const val = +e.target.value;
        const vehicle = sorted.find(v => v.id === val);
        onChange(vehicle);
      }}
    >
      <option>Vælg et køretøj</option>
      {sorted.map(vehicle => (
        <option
          key={vehicle.id}
          value={vehicle.id}
        >
          {vehicle.licensePlate}, {vehicle.alias}
        </option>
      ))}
    </select>
  );
};

export default VehicleSelector;

interface CompanyProps {
  company: ICompanyResource;
  value?: IVehicleResource;
  onChange: (vehicle: IVehicleResource | undefined) => void;
}
export const CompanyVehicleSelector: React.FC<CompanyProps> = ({
  company,
  onChange,
  value,
}) => {
  const request = useCallback(
    (signal: AbortSignal) => Company.ReadVehicles(company, signal),
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [vehicles, error] = usePromise(request, [], setLoading);

  if (loading) return <Spinner animation="border" />;
  else if (error) return <TableError />;

  return (
    <VehicleSelector vehicles={vehicles} value={value} onChange={onChange} />
  );
};

interface UserProps{
  value?: IVehicleResource;
  onChange: (vehicle: IVehicleResource | undefined) => void;
}

export const UserVehicleSelector: React.FC<UserProps> = ({
  value,
  onChange,
}) => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState<IVehicleResource[] | undefined>();

  const userId = useMemo(() => (user ? user.identity.id : undefined), [user]);
  const userAdmin = useMemo(() => (user ? user.identity.admin : false), [user]);
  useEffect(() => {
    const abort = new AbortController();
    const fetch = async () => {
      try {
        if (userId) {
          var vehicles: IVehicleResource[] = [];
          if (userAdmin) {
            vehicles = await Vehicle.ReadAll(abort.signal);
          } else if (userId) {
            vehicles = await User.ReadVehicles(userId, abort.signal);
          }
          setVehicles(vehicles);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetch();

    return () => {
      abort.abort();
    };
  }, [userId, userAdmin]);

  if (loading || !vehicles) {
    return <Spinner animation="border" />;
  }

  return (
    <VehicleSelector vehicles={vehicles} value={value} onChange={onChange} />
  );
};

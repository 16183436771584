import { faCross, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/SearchBox";
import React, { useState } from "react";
import { Button, InputGroup } from "react-bootstrap";

interface Props {
  value?: string;
  onChange: (value: string) => void;
}

export const ToggleSearch = ({ value, onChange }: Props) => {
  const [open, setOpen] = useState(false);

  const handleClear = () => {
    setOpen(false);
    onChange("");
  };

  if (!open)
    return (
      <Button
        className="mobile-search-button"
        variant="primary"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faSearch} />
      </Button>
    );

  return (
    <InputGroup>
      <SearchBox
        autoFocus
        className="form-control mobile-search"
        placeholder="Søg"
        onChange={(event) => onChange(event.target.value)}
      />
      <InputGroup.Append>
        <Button variant="secondary" onClick={handleClear}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
};

import { string } from "prop-types";
import { IconNames } from "../components/Icons/Index";
import {
  createFetch,
  createFetch2,
  deleteFetch,
  readFetch,
  updateFetch,
  updateFetch2,
} from "../helpers/fetchers";
import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { ICompanyResource } from "./company";
import { DrivingPeriod } from "./drivingPeriod";
import {
  IGPSCoordinate,
  IMappedVehicleCoordinate,
  ISpeedCoordinate,
} from "./gpsCoordinate";
import { IHistoryPeriod, IHistoryWithDistance } from "./historyPeriod";
import { IRemainingDrive } from "./remainingDrive";
import { ITachographParametersResource } from "./tachoSafe/tachographParameters";
import TimeSpan from "./timespan";
import { ITrackingUnitResource } from "./trackingUnit";
import { VehicleDistance } from "./vehicleDistance";

export interface IVehicleResource {
  id: number;
  companyId?: number;
  alias: string;
  licensePlate: string;
  online: boolean;
  inActive?: boolean;
  company?: ICompanyResource;
  icon?: IconNames;
  color?: string;
  canBeRemote: boolean;
  remote?: string;
  remoteReg?: string;
}
export interface IDetailedVehicleResource extends IVehicleResource {
  canBeRemote: boolean;
}
export interface IVehicleResourceWithUnit extends IVehicleResource {
  unit?: ITrackingUnitResource;
}
type CompanyProps = "company.name" | "company.id";
export type VehicleProps =
  | "id"
  | "companyId"
  | "alias"
  | "licensePlate"
  | "online"
  | "inActive"
  | "remote"
  | "remoteReg"
  | CompanyProps;
export class VehicleResource
  implements
    IVehicleResource,
    ISortable<VehicleProps>,
    ISearchable<VehicleProps>
{
  id: number;
  companyId?: number;
  alias: string;
  licensePlate: string;
  online: boolean;
  inActive?: boolean;
  company?: ICompanyResource;
  icon?: IconNames;
  color?: string;
  canBeRemote: boolean;
  remote?: string;
  remoteReg?: string;

  public static Props: VehicleProps;

  constructor(value: IVehicleResource) {
    this.id = value.id;
    this.companyId = value.companyId;
    this.alias = value.alias;
    this.licensePlate = value.licensePlate;
    this.online = value.online;
    this.inActive = value.inActive;
    this.company = value.company;
    this.icon = value.icon;
    this.color = value.color;
    this.canBeRemote = value.canBeRemote;
    this.remote = value.remote;
    this.remoteReg = value.remoteReg;
  }
  search = <T>(value: T, type: VehicleProps): number | undefined => {
    switch (type) {
      case "company.id":
      case "companyId":
        return basicSearch(this, value, type, true);
      default:
        return basicSearch(this, value, type, false);
    }
  };
  compare = (
    value: ISortable<VehicleProps>,
    primary: VehicleProps,
    secondary?: VehicleProps
  ): number => basicSecSort(this, value, primary, secondary);
}

export type VehicleWithUnitProps = VehicleProps | "unit.id";
export class VehicleResourceWithUnit
  implements
    IVehicleResourceWithUnit,
    ISortable<VehicleWithUnitProps>,
    ISearchable<VehicleWithUnitProps>
{
  id: number;
  alias: string;
  licensePlate: string;
  online: boolean;
  inActive?: boolean;
  company?: ICompanyResource;
  icon?: IconNames;
  color?: string;
  canBeRemote: boolean;
  remote?: string;
  remoteReg?: string;
  unit?: ITrackingUnitResource;

  public static Props: VehicleWithUnitProps;

  constructor(value: IVehicleResourceWithUnit) {
    this.id = value.id;
    this.alias = value.alias;
    this.licensePlate = value.licensePlate;
    this.online = value.online;
    this.inActive = value.inActive;
    this.company = value.company;
    this.icon = value.icon;
    this.color = value.color;
    this.canBeRemote = value.canBeRemote;
    this.remote = value.remote;
    this.remoteReg = value.remoteReg;
    this.unit = value.unit;
  }
  search = <T>(value: T, type: VehicleWithUnitProps): number | undefined => {
    switch (type) {
      case "company.id":
      case "companyId":
        return basicSearch(this, value, type, true);
      default:
        return basicSearch(this, value, type, false);
    }
  };
  compare = (
    value: ISortable<VehicleWithUnitProps>,
    primary: VehicleWithUnitProps,
    secondary?: VehicleWithUnitProps
  ): number => basicSecSort(this, value, primary, secondary);
}
export interface IVehicleErrorBinding {
  alias?: string[];
  licensePlate?: string[];
  icon?: string[];
  color?: string[];
  remote?: string[];
}

export interface IVehicleBinding {
  alias: string;
  licensePlate: string;
  icon?: IconNames;
  color?: string;
  remote?: string;
}
export default class Vehicle {
  static Create = async (
    company: ICompanyResource,
    vehicle: IVehicleBinding,
    abort?: AbortSignal
  ) =>
    await createFetch2<IVehicleResource, IVehicleErrorBinding>(
      `api/Companies/${company.id}/vehicles`,
      vehicle,
      abort
    );

  static Read = async (
    id: number,
    abort?: AbortSignal
  ): Promise<IVehicleResource> =>
    (await readFetch("api/Vehicles/" + id, abort)) as IVehicleResource;

  static ReadDetails = async (
    id: number,
    abort?: AbortSignal
  ): Promise<IDetailedVehicleResource> =>
    (await readFetch(
      `api/Vehicles/${id}/details`,
      abort
    )) as IDetailedVehicleResource;

  static ReadAll = async (
    abort?: AbortSignal
  ): Promise<IVehicleResourceWithUnit[]> =>
    (await readFetch(
      "api/Vehicles/company",
      abort
    )) as IVehicleResourceWithUnit[];

  static Update = async (
    id: number,
    vehicle: IVehicleBinding,
    abort?: AbortSignal
  ) =>
    updateFetch2<IVehicleBinding, IVehicleErrorBinding>(
      "api/Vehicles/" + id,
      vehicle,
      abort
    );

  static Delete = async (
    vehicle: IVehicleResource,
    abort?: AbortSignal
  ): Promise<void> => {
    deleteFetch(`api/Vehicles/${vehicle.id}`, abort);
  };

  static ReadLocation = async (
    vehicle: IVehicleResource,
    abort?: AbortSignal
  ): Promise<IGPSCoordinate | undefined> =>
    (await readFetch(`api/Vehicles/${vehicle.id}/location`, abort)) as
      | IGPSCoordinate
      | undefined;

  static ReadTracking = async (
    vehicle: IVehicleResource,
    abort?: AbortSignal
  ): Promise<IGPSCoordinate[]> =>
    (await readFetch(`api/Tracking/${vehicle.id}`, abort)) as IGPSCoordinate[];

  static ReadManyTracking = async (
    vehicles: IVehicleResource[],
    abort?: AbortSignal
  ): Promise<IMappedVehicleCoordinate[]> =>
    (await createFetch(
      `api/Tracking/many`,
      vehicles.map((v) => v.id),
      abort
    )) as IMappedVehicleCoordinate[];

  static ReadTrackingHistory = async (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ): Promise<IGPSCoordinate[]> =>
    (await readFetch(
      `api/Tracking/${
        vehicle.id
      }/history/${from.toISOString()}/${to.toISOString()}`,
      abort
    )) as IGPSCoordinate[];

  static ReadTrackingHistorySpeeds = async (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ): Promise<ISpeedCoordinate[]> =>
    (await readFetch(
      `api/Tracking/${
        vehicle.id
      }/history/${from.toISOString()}/${to.toISOString()}/speed`,
      abort
    )) as ISpeedCoordinate[];

  static ReadPeriodicHistory = async (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    minDuration?: TimeSpan,
    maxRadius?: number,
    abort?: AbortSignal
  ): Promise<IHistoryWithDistance> =>
    (await readFetch(
      `api/Tracking/${
        vehicle.id
      }/history/${from.toISOString()}/${to.toISOString()}/periods?minDuration=${minDuration?.toString()}&maxRadius=${
        maxRadius ?? ""
      }`,
      abort
    )) as IHistoryWithDistance;

  static ReadRemainingDailyDrive = async (
    vehicle: IVehicleResource,
    abort?: AbortSignal
  ): Promise<IRemainingDrive> =>
    (await readFetch(
      `api/Tracking/${vehicle.id}/dailydriving`,
      abort
    )) as IRemainingDrive;

  static ReadDistance = async (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ): Promise<VehicleDistance> =>
    (await readFetch(
      `api/Vehicles/${
        vehicle.id
      }/distance/${from.toISOString()}/${to.toISOString()}`,
      abort
    )) as VehicleDistance;

  static ReadDistanceSinceRest = async (
    vehicle: IVehicleResource,
    abort?: AbortSignal
  ): Promise<VehicleDistance> =>
    (await readFetch(
      `api/Vehicles/${vehicle.id}/rest/distance`
    )) as VehicleDistance;
  static ReadDistanceDaily = async (
    vehicle: IVehicleResource,
    abort?: AbortSignal
  ): Promise<VehicleDistance> =>
    (await readFetch(
      `api/Vehicles/${vehicle.id}/daily/distance`,
      abort
    )) as VehicleDistance;
  static ReadDistanceTotal = async (
    vehicle: IVehicleResource,
    abort?: AbortSignal
  ): Promise<VehicleDistance> =>
    (await readFetch(
      `api/Vehicles/${vehicle.id}/total/distance`,
      abort
    )) as VehicleDistance;

  static ReadDistanceTotalByDates = async (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ): Promise<VehicleDistance> =>
    (await readFetch(
      `api/Vehicles/${vehicle.id}/total/dates/distance?from=${from.toISOString()}&to=${to.toISOString()}`,
      abort
    )) as VehicleDistance;



  static SetActive = async (
    vehicle: IVehicleResource,
    active: boolean
  ): Promise<void> => {
    await createFetch(`api/Vehicles/${vehicle.id}/active`, { active });
  };

  static ReadTacho = (vehicle: IVehicleResource, signal?: AbortSignal) =>
    readFetch<ITachographParametersResource>(
      `api/Vehicles/${vehicle.id}/Tachograph`,
      signal
    );

  static ReadDrivingPeriod = (vehicleId: number, abort?: AbortSignal) => readFetch<DrivingPeriod>(`api/Vehicles/${vehicleId}/period`, abort);
}

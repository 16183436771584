import React from "react";

const Trailer = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 226.772 188.976"
    id="svg2"
    version="1.1"
  >
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,188.976)" id="g10">
      <g id="g12">
        <g id="g14">
          <g transform="translate(29.9983,84.2612)" id="g20">
            <path
              id="path22"
              style={{
                fill: "currentColor",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "currentColor",
                strokeWidth: 4,
              }}
              d="m 0,0 c 0,26.395 0.473,29.849 4.377,31.937 2.471,1.323 107.822,1.367 110.294,0.046 4.117,-2.203 4.463,-4.647 4.463,-31.516 V -24.848 H 81.946 l -4.288,3.854 c -4.744,4.266 -11.772,6.975 -18.089,6.975 -6.318,0 -13.344,-2.709 -18.089,-6.975 l -4.287,-3.854 H 0.004 v 24.847 z m 87.824,-37.955 c -0.508,2.223 -0.712,4.253 -0.452,4.513 0.258,0.26 7.314,0.472 15.678,0.472 h 15.207 l -2.286,-4.513 -2.287,-4.513 H 88.749 l -0.924,4.041 z m -109.482,-2.7 c 0,1.133 1.594,1.367 9.304,1.367 9.751,0 11.935,0.708 12.518,4.062 0.296,1.712 1.118,1.817 15.92,2.052 l 15.606,0.247 -0.275,-4.309 -0.276,-4.308 -26.399,-0.24 c -23.086,-0.209 -26.398,-0.067 -26.398,1.129 z m 71.094,-20.512 c -4.979,2.89 -8.464,7.273 -9.841,12.379 -1.132,4.202 -0.416,12.181 1.407,15.669 3.154,6.037 11.548,10.925 18.82,10.958 17.9,0.083 27.216,-22.366 14.69,-35.403 -6.354,-6.615 -17.199,-8.173 -25.076,-3.603 z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Trailer;

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import useComponentVisible from "../../hooks/useComponentVisible";
import Icon, { VehicleIcon } from "./Icon";
import Icons, { IconNames } from "./Index";

interface Props {
  value?: IconNames;
  onChange: (icon?: IconNames) => void;
  color?: string;
}

const IconSelector: React.FC<Props> = ({ value, onChange, color }) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  color = (color || "black");

  return (
    <Fragment>
      <div
        className="form-control"
        ref={ref}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        {isComponentVisible ? (
          <div className="icon-selector">
            {Icons.AllIconNames.map((name, index) => (
              <p key={index}>
                <VehicleIcon name={name} color={color} />
                <Button
                  className="selector-button"
                  size="sm"
                  variant="primary"
                  onClick={() => {
                    onChange(name === value ? undefined : name);
                    setIsComponentVisible(false);
                  }}
                >
                  <FontAwesomeIcon icon={name === value ? faTimes : faCheck} />
                </Button>
              </p>
            ))}

            <p>
              <b>Kaldenavn som ikon</b>
              <Button
                className="selector-button"
                size="sm"
                variant="primary"
                onClick={() => {
                  onChange(undefined);
                  setIsComponentVisible(false);
                }}
              >
                <FontAwesomeIcon icon={!value ? faTimes : faCheck} />
              </Button>
            </p>
          </div>
        ) : (
          <div>
            {value ? (
              <p>
                <VehicleIcon name={value} color={color} />
              </p>
            ) : (
              <b>Kaldenavn</b>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default IconSelector;

import {
  createFetch,
  deleteFetch,
  readFetch,
  updateFetch,
} from "../helpers/fetchers";
import { ICompanyResource } from "./company";
import { IMeiTrackIdentification } from "./meiTrack";
import { IVehicleResource } from "./vehicle";
import { ITrackingUnitMount } from "./trackingUnitMount";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { basicSearch, basicSecSort, basicSortCompare } from "../helpers/utils";

export enum UnitType {
  VT600 = 1,
  VT100 = 2,
}

export interface IInterval {
  interval: number;
}

export interface IConnectedUnit {
  id: string;
  connectionId: string;
  exists: boolean;
  lastReceive: string;
}
export type ConnectedUnitProps = "id" | "connectionId" | "exists" | "lastReceive";
export class ConnectedUnit implements IConnectedUnit, ISortable<ConnectedUnitProps>, ISearchable<ConnectedUnitProps>{
  id: string;
  connectionId: string;
  exists: boolean;
  lastReceive: string;

  constructor(value: IConnectedUnit){
    this.id = value.id;
    this.connectionId = value.connectionId;
    this.exists = value.exists;
    this.lastReceive = value.lastReceive;
  }
  search = (value: any, type: ConnectedUnitProps): number | undefined => 
    basicSearch(this, value, type, false);
  compare = (value: ISortable<ConnectedUnitProps>, primary: ConnectedUnitProps, secondary?: "id" | "connectionId" | "exists" | "lastReceive" | undefined): number => 
    basicSecSort(this, value, primary, secondary);

}

export interface ITrackingUnitResource {
  id: string;
  iccId?: string;
  phoneNumber: string;
  unitType?: UnitType;
  batteryLevel: number;
  lastSeen?: Date;
  isConnected: boolean;
  active: boolean;
  company?: ICompanyResource;
  vehicle?: IVehicleResource;
}

export interface ITrackingUnitBinding {
  id: string;
  iccId?: string;
  phoneNumber: string;
  active: boolean;
  unitType?: UnitType;
}
type UnitVehicleProps = "vehicle.alias" | "vehicle.licensePlate" | "vehicle.online";
type UnitCompanyProps = "company.name" 
export type TrackingUnitProps = "id" | "iccId" | "phoneNumber" | "unitType" | "batteryLevel" | "lastSeen" | "isConnected" | "active" | UnitVehicleProps | UnitCompanyProps;
export class TrackingUnitResource implements ITrackingUnitResource, ISortable<TrackingUnitProps>, ISearchable<TrackingUnitProps>{
  id: string;
  iccId?: string;
  phoneNumber: string;
  unitType?: UnitType;
  batteryLevel: number;
  lastSeen?: Date;
  isConnected: boolean;
  active: boolean;
  company?: ICompanyResource;
  vehicle?: IVehicleResource;

  constructor(value: ITrackingUnitResource){
    this.id = value.id;
    this.iccId = value.iccId;
    this.phoneNumber = value.phoneNumber;
    this.batteryLevel = value.batteryLevel;
    this.unitType = value.unitType;
    this.lastSeen = value.lastSeen;
    this.isConnected = value.isConnected;
    this.active = value.active;
    this.company = value.company;
    this.vehicle = value.vehicle;
  }
  search = (value: any, type: TrackingUnitProps): number | undefined => 
    basicSearch(this, value, type, false);
  compare = (value: ISortable<TrackingUnitProps>, primary: TrackingUnitProps, secondary?: TrackingUnitProps): number => 
    basicSecSort(this, value, primary, secondary);

}

export default class TrackingUnit {
  static ToBinding = (
    resource: ITrackingUnitResource
  ): ITrackingUnitBinding => ({
    id: resource.id,
    phoneNumber: resource.phoneNumber,
    active: resource.active,
    unitType: resource.unitType,
  });

  static ToResource = (
    binding: ITrackingUnitBinding,
    initial: ITrackingUnitResource
  ): ITrackingUnitResource => ({
    ...initial,
    id: binding.id,
    iccId: binding.iccId,
    phoneNumber: binding.phoneNumber,
    unitType: binding.unitType,
    active: binding.active
  });

  static Create = async (
    trackingUnit: ITrackingUnitBinding,
    abort?: AbortSignal
  ) =>
    (await createFetch(
      "api/TrackingUnits",
      trackingUnit,
      abort
    )) as ITrackingUnitResource;

  static Read = async (
    id: string,
    abort?: AbortSignal
  ): Promise<ITrackingUnitResource> =>
    (await readFetch(
      "api/TrackingUnits/" + id,
      abort
    )) as ITrackingUnitResource;

  static ReadAll = async (
    abort?: AbortSignal
  ): Promise<ITrackingUnitResource[]> =>
    (await readFetch("api/TrackingUnits", abort)) as ITrackingUnitResource[];

  static ReadAllConnected = async (
    abort?: AbortSignal
  ): Promise<IConnectedUnit[]> =>
    (await readFetch(
      "api/TrackingUnits/connected",
      abort
    )) as IConnectedUnit[];

  static Update = async (
    id: string,
    trackingUnit: ITrackingUnitBinding,
    abort?: AbortSignal
  ) => updateFetch("api/TrackingUnits/" + id, trackingUnit, abort);

  static Delete = async (
    trackingUnit: ITrackingUnitResource,
    abort?: AbortSignal
  ) => deleteFetch("api/TrackingUnits/" + trackingUnit.id, abort);

  static ReadMounts = async (
    id: string,
    abort?: AbortSignal
  ): Promise<ITrackingUnitMount[]> =>
    (await readFetch(`api/TrackingUnits/${id}`, abort)) as ITrackingUnitMount[];

  static AssignCompany = async (
    trackingUnit: ITrackingUnitResource,
    company: ICompanyResource,
    abort?: AbortSignal
  ): Promise<void> => {
    await updateFetch(
      `api/TrackingUnits/${trackingUnit.id}/company`,
      company,
      abort
    );
  };

  static UnassignCompany = async (
    trackingUnit: ITrackingUnitResource,
    abort?: AbortSignal
  ): Promise<void> => {
    await deleteFetch(`api/TrackingUnits/${trackingUnit.id}/company`, abort);
  };

  static AssignVehicle = async (
    trackingUnit: ITrackingUnitResource,
    vehicle: IVehicleResource,
    abort?: AbortSignal
  ): Promise<void> => {
    await updateFetch(
      `api/TrackingUnits/${trackingUnit.id}/vehicle`,
      vehicle,
      abort
    );
  };

  static UnassignVehicle = async (
    trackingUnit: ITrackingUnitResource,
    abort?: AbortSignal
  ): Promise<void> => {
    await deleteFetch(`api/TrackingUnits/${trackingUnit.id}/vehicle`, abort);
  };

  static ReadMeiIdentification = async (
    trackingUnit: ITrackingUnitResource,
    abort?: AbortSignal
  ): Promise<IMeiTrackIdentification | undefined> => {
    return readFetch(`api/UnitConfig/${trackingUnit.id}/identification`, abort);
  };

  static ReadInterval = async (
    trackingUnit: ITrackingUnitResource,
    abort?: AbortSignal
  ): Promise<IInterval | undefined> => {
    return readFetch(`api/UnitConfig/${trackingUnit.id}/interval`, abort);
  };

  static SetInterval = async (
    trackingUnit: ITrackingUnitResource,
    interval: IInterval,
    abort?: AbortSignal
  ): Promise<void> => {
    await updateFetch(
      `api/UnitConfig/${trackingUnit.id}/interval`,
      interval,
      abort
    );
  };

  static RebootDevice = async (
    trackingUnit: ITrackingUnitResource,
    abort?: AbortSignal
  ): Promise<void> => {
    await createFetch(
      `api/UnitConfig/${trackingUnit.id}/reboot`,
      undefined,
      abort
    );
  };

  static RebootGPS = async (
    trackingUnit: ITrackingUnitResource,
    abort?: AbortSignal
  ): Promise<void> => {
    await createFetch(
      `api/UnitConfig/${trackingUnit.id}/reboot/gps`,
      undefined,
      abort
    );
  };
  static RebootGSM = async (
    trackingUnit: ITrackingUnitResource,
    abort?: AbortSignal
  ): Promise<void> => {
    await createFetch(
      `api/UnitConfig/${trackingUnit.id}/reboot/gsm`,
      undefined,
      abort
    );
  };
}

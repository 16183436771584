import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, FormGroup, FormLabel, Modal, Spinner } from "react-bootstrap";
import Company, {
  ICompanyBinding,
  ICompanyResource
} from "../../models/company";
import Vehicle, {
  IVehicleBinding,
  IVehicleErrorBinding,
  IVehicleResource
} from "../../models/vehicle";
import CompanySelectorCreate from "../Companies/SelectorCreate";
import Vehicles from "./Index";
const defaultVehicle: IVehicleBinding = {
  alias: "",
  licensePlate: "",
  icon: "truck",
  color: "black",
};
interface Props {
  onCreated?: (vehicle: IVehicleResource) => void;
  company?: ICompanyResource;
}
const CreateVehicle: React.FC<Props> = ({ onCreated, company: comp }) => {
  const [vehicle, setVehicle] = useState<IVehicleBinding>(defaultVehicle);
  const [company, setCompany] = useState<ICompanyResource | undefined>(comp);
  const [companyCreate, setCompanyCreate] = useState<
    ICompanyBinding | undefined
  >(comp);
  const [error, setError] = useState<IVehicleErrorBinding>();
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);

  const handleSuccess = (result: IVehicleResource) => {
    if (!onCreated) return;
    onCreated(result);
    setShown(false);
  };
  const handleModelError = (error: IVehicleErrorBinding) => {
    setError(error);
  };
  /**
   * Save the changes, creates the vehicle
   */
  const saveChanges = async () => {
    try {
      setLoading(true); //Show loading while the vehicle is being created
      var assignCompany: ICompanyResource;
      if (company) {
        assignCompany = company;
      } else if (companyCreate) {
        assignCompany = await Company.Create(companyCreate);
      } else {
        return;
      }
      const [result, error] = await Vehicle.Create(assignCompany, vehicle);
      if (result) handleSuccess(result);
      else if (error) handleModelError(error);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  /**
   * Save when the form is submitted
   * @param event
   */
  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    await saveChanges();
  };
  return (
    <Fragment>
      <Button title="Opret køretøj" onClick={() => setShown(true)}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>

      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Opret køretøj</Modal.Title>
          <Modal.Body>
            <Vehicles.Form
              error={error}
              value={vehicle}
              onChange={setVehicle}
            />
            {!comp && (
              <FormGroup>
                <FormLabel>Virksomhed</FormLabel>
                <CompanySelectorCreate
                  onChange={setCompany}
                  onCreateChange={setCompanyCreate}
                />
              </FormGroup>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={loading} type="submit">
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setVehicle(defaultVehicle);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default CreateVehicle;

import React, {
  useCallback, useEffect, useMemo, useState
} from "react";
import { Form, FormGroup, InputGroup, Spinner } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { getHTML5DateTimeStringsFromDate } from "../../helpers/utils";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company, {
  ICompanyIdentifiable
} from "../../models/company";
import { IDriven, ISpecificDriven } from "../../models/graphing/driven";

interface Props {
  company: ICompanyIdentifiable;
}

const sumAmounts = (values: IDriven[]) => {
  var prev = 0;
  const result: IDriven[] = [];
  for (const value of values) {
    result.push({ ...value, kilometers: value.kilometers + prev });
    prev += value.kilometers;
  }

  return result;
};
const sumAmounts2 = (values: ISpecificDriven[]) => {
  var prev = 0;
  const result: ISpecificDriven[] = [];
  for (const value of values) {
    result.push({ ...value, kilometers: value.kilometers + prev });
    prev += value.kilometers;
  }

  return result;
};

const CeilDynamic = (value: number) => {
  const factors = [100, 1000, 10000, 100000, 1000000];
  for (var i = 0; i >= factors.length; i++) {
    const factor = factors[i];
    if (value < factor) {
      return Math.ceil(value / factor) * factor;
    }
  }
};
const DaysInMonth = (date: Date): number => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

const fillEmptyX = (
  data: { x: number; y: number }[]
): { x: number; y: number | undefined }[] => {
  if (data.length == 0) return data;
  const tempResult: { x: number; y: number | undefined }[] = [...data];
  const minDataX = Math.min(...data.map((p) => p.x));

  // Fills data from start to first
  for (var x = minDataX; x > 1; x--) {
    tempResult.unshift({ x, y: undefined });
  }

  const result: { x: number; y: number | undefined }[] = [];

  // Fills spaces between points
  for (var i = 0; i < tempResult.length - 1; i++) {
    const current = tempResult[i];
    const next = tempResult[i + 1];

    result.push(current);
    if (current && next) {
      const diff = next.x - current.x;
      if (diff > 1) {
        for (var j = 1; j < diff; j++) {
          const nextX = current.x + j;
          result.push({ x: nextX, y: current.y });
        }
      }
    }
  }
  result.push(tempResult[tempResult.length - 1]);

  return result;
};

const CompanyDrivenChart: React.FC<Props> = ({ company }) => {
  const request = useCallback(
    (signal: AbortSignal) => Company.ReadKilometersDriven(company, signal),
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [drives] = usePromise(
    request,
    {
      thisMonth: [],
      previousMonth: [],
    },
    setLoading
  );

  const thisMonthChartData = useMemo(() => {
    const sums = sumAmounts(drives.thisMonth);
    return sums.map((drive) => ({
      x: drive.dayOfMonth,
      y: drive.kilometers,
    }));
  }, [drives]);
  const prevMonthChartData = useMemo(
    () =>
      sumAmounts(drives.previousMonth).map((drive) => ({
        x: drive.dayOfMonth,
        y: drive.kilometers,
      })),
    [drives]
  );

  const data = {
    labels: drives.thisMonth.map((drive) => drive.dayOfMonth),

    datasets: [
      {
        label: "Kilometer denne måned",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: thisMonthChartData,
      },
      {
        label: "Kilometer forrige måned",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(245, 185, 66,0.4)",
        borderColor: "rgba(245, 185, 66,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(245, 185, 66,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(245, 185, 66,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: prevMonthChartData,
      },
    ],
  };

  const maxY = useMemo(() => {
    const maxThisMonth = Math.max(...thisMonthChartData.map((p) => p.y));
    const maxPrevMonth = Math.max(...prevMonthChartData.map((p) => p.y));

    return Math.max(maxThisMonth, maxPrevMonth, 1000);
  }, [thisMonthChartData, prevMonthChartData]);

  const options = {
    bezierCurve: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Kørte kilometer",
          },
          type: "linear",
          position: "left",
          ticks: {
            min: 0, //minimum tick
            max: CeilDynamic(maxY * 1.3),
            // callback: function (value: any, index: number, values: any) {
            //   return Number(value.toString()); //pass tick values as a string into Number function
            // },
          },
        },
      ],
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Dag i måned",
          },
        },
      ],
    },

    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          var label = "Kørte kilometer";

          if (label) {
            label += ": ";
          }
          label += Math.round(tooltipItem.yLabel * 100) / 100;
          return label;
        },
      },
    },
  };

  if (loading) {
    return (
      <div>
        Dette kan tage lidt tid...
        <br />
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default CompanyDrivenChart;

export const CompanySpecificDriving = ({ company }: Props) => {
  const now = new Date();

  const [start, setStart] = useState<Date>(now);
  const [compareDate, setCompareDate] = useState<Date | undefined>(new Date(now.getFullYear(), now.getMonth() - 1));
  const [canCompare, setCanCompare] = useState(true);

  const [drivings, setDrivings] = useState<ISpecificDriven[]>([]);
  const [compareDrivings, setCompareDrivings] = useState<
    ISpecificDriven[] | undefined
  >();

  const [loading, setLoading] = useLoading();

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const startMonth = new Date(
          Date.UTC(start.getFullYear(), start.getMonth())
        );

        const endMonth = new Date(
          Date.UTC(start.getFullYear(), start.getMonth() + 1, -1)
        );

        const drivings = await Company.ReadSpecificKilometersDriven(
          company,
          startMonth,
          endMonth
        );
        setDrivings(drivings);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [start, company]);

  useEffect(() => {
    const load = async () => {
      try {
        if (!compareDate) return;
        setLoading(true);
        const compareStartMonth = new Date(
          Date.UTC(compareDate.getFullYear(), compareDate.getMonth())
        );
        const compareEndMonth = new Date(
          Date.UTC(compareDate.getFullYear(), compareDate.getMonth() + 1, -1)
        );
        const drivings = await Company.ReadSpecificKilometersDriven(
          company,
          compareStartMonth,
          compareEndMonth
        );
        setCompareDrivings(drivings);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (compareDate) {
      load();
    } else setCompareDrivings(undefined);
  }, [compareDate, company]);

  const chartData = useMemo(
    () =>
      sumAmounts2(drivings).map((drive) => ({
        x: new Date(drive.timestamp).getDate(),
        y: drive.kilometers,
      })),
    [drivings]
  );
  const compareData = useMemo(() => {
    if (compareDrivings) {
      return sumAmounts2(compareDrivings).map((drive) => ({
        x: new Date(drive.timestamp).getDate(),
        y: drive.kilometers,
      }));
    } else {
      return undefined;
    }
  }, [compareDrivings]);

  const dataset = useMemo(
    () => ({
      label: "Kilometer denne måned",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: "rgba(75,192,192,1)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: fillEmptyX(chartData),
    }),
    [chartData]
  );
  const compareDataset = useMemo(() => {
    if (compareData) {
      return {
        label: "Sammenlignet kilometer",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(245, 185, 66,0.4)",
        borderColor: "rgba(245, 185, 66,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(245, 185, 66,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(245, 185, 66,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: fillEmptyX(compareData),
      };
    } else return undefined;
  }, [compareData]);

  const maxY = useMemo(() => {
    const dataMonth = Math.max(...chartData.map((p) => p.y));
    const compareMonth =
      compareData && Math.max(...compareData.map((p) => p.y));

    return Math.max(dataMonth, compareMonth ?? 0, 1000);
  }, [chartData, compareData]);

  const dayLabels = useMemo(() => {
    let maxDays = 0;
    const days1 = DaysInMonth(start);
    if (compareDate) {
      const days2 = DaysInMonth(compareDate);
      maxDays = Math.max(days1, days2);
    } else maxDays = days1;

    const result = [];
    for (let i = 0; i < maxDays; i++) {
      result[i] = i + 1;
    }
    return result;
  }, [start, compareDate]);

  const sets = compareDataset ? [dataset, compareDataset] : [dataset];
  const data = {
    datasets: sets,
    labels: dayLabels,
  };
  const options = {
    bezierCurve: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Kørte kilometer",
          },
          type: "linear",
          position: "left",
          ticks: {
            min: 0, //minimum tick
            max: CeilDynamic(maxY * 1.3),
            // callback: function (value: any, index: number, values: any) {
            //   return Number(value.toString()); //pass tick values as a string into Number function
            // },
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Dag i måned",
          },
        },
      ],
    },

    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          var label = "Kørte kilometer";

          if (label) {
            label += ": ";
          }
          label += Math.round(tooltipItem.yLabel * 100) / 100;
          return label;
        },
      },
    },
  };

  const setCompareMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
    var monthDate = event.target.valueAsDate || undefined;
    if (monthDate === start) monthDate = undefined;
    setCompareDate(monthDate);
  };

  return (
    <div>
      <FormGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>Måned</InputGroup.Text>
          </InputGroup.Prepend>
          <input
            className="form-control w-auto"
            type="month"
            value={getHTML5DateTimeStringsFromDate(start).substr(0, 7)}
            onChange={(e) => setStart(e.target.valueAsDate || now)}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              Sammenlign
              <Form.Check
                checked={canCompare}
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (!checked) {
                    setCompareDate(undefined);
                  }
                  setCanCompare(checked);
                }}
              />
            </InputGroup.Text>
          </InputGroup.Prepend>

          <input
            className="form-control w-auto"
            type="month"
            disabled={!canCompare}
            value={
              compareDate ?
              getHTML5DateTimeStringsFromDate(compareDate).substr(0, 7) : ""
            }
            onChange={setCompareMonth}
          />
        </InputGroup>
      </FormGroup>
      <Line data={data} options={options} />
    </div>
  );
};

import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ContactDetails = () => (
  <div className="contact-details">
    <h4>Kontakt</h4>
    <hr />
    <div>
      <span className="navbar-icon">
        <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
      </span>
      SALG OG SUPPORT:
      <a href="tel:+4528104838"> 2810 4838</a>
    </div>
    <div>
      <span className="navbar-icon">
        <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
      </span>
      Rugårdsvej 380, 5210 Odense NV
    </div>
  </div>
);

export default ContactDetails;

import { useEffect, useMemo } from "react";


/**
 * A hook that aborts an AbortController on unmount and returns the signal
 * @param controller 
 */
const useAbort = (): AbortSignal => {
    const controller = useMemo(() => new AbortController(), []);
    useEffect(() => {
        return () => {
            controller.abort();
        }
    }, [controller]);

    return controller.signal;
}

export default useAbort;
import React, { useMemo } from "react";
import { ISEGESVehicleResource } from "../../../models/segesVehicle";
import Vehicles from "../../Vehicles/Index";
import Delete from "./Delete";

interface Props {
  value: ISEGESVehicleResource;
  onDeleted: (value: ISEGESVehicleResource) => void;
}

const Row = ({ value, onDeleted }: Props) => {
    const typeLabel = useMemo(() => {
        switch(value.type){
            case 1: return "Trækker";
            case 2: return "Trailer";
            case 10: return "Forvogn";
            case 11: return "Hænger";
        }
    }, [value])
  return (
    <tr>
      <td>{value.vehicle && <Vehicles.Label value={value.vehicle} />}</td>
      <td>{typeLabel}</td>
      <td>
        <Delete vehicle={value} onDeleted={onDeleted} />
      </td>
    </tr>
  );
};
export default Row;

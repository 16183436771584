import React from "react";
import { Table as ReactTable } from "react-bootstrap";
import useSortedSearch from "../../../hooks/useSortedSearch";
import SEGESCompany, {
  ISEGESCompanyResource,
  SEGESCompanyProps,
  SEGESCompanyResource,
} from "../../../models/segesCompany";
import PassivePagination from "../../Pagination";
import SearchableHeader from "../../SearchableHeader";
import SortableHeader from "../../SortableHeader";
import Create from "./Create";
import Row from "./Row";

interface Props {
  values: ISEGESCompanyResource[];
  onDeleted: (value: ISEGESCompanyResource) => void;
  onCreated: (value: ISEGESCompanyResource) => void;
}

const Table = ({ values, onDeleted, onCreated }: Props) => {
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    SEGESCompanyResource,
    SEGESCompanyProps
  >(
    values.map((v) => new SEGESCompanyResource(v)),
    undefined,
    {
      type: "companyId",
      asc: true,
    }
  );

  return (
    <ReactTable striped responsive bordered>
      <thead>
        <tr>
          <SearchableHeader
            types={["company.name"]}
            value={search}
            onSearch={setSearch}
          />
          <SearchableHeader
            types={["authorizationNumber"]}
            value={search}
            onSearch={setSearch}
          />
          <th></th>
        </tr>
        <tr>
          <SortableHeader type="company.name" value={sort} onChange={setSort}>
            Virksomhed
          </SortableHeader>
          <SortableHeader
            type="authorizationNumber"
            value={sort}
            onChange={setSort}
          >
            Autorisationsnummer
          </SortableHeader>
          <th>
            <Create onCreated={onCreated} />
          </th>
        </tr>
      </thead>
      <tbody>
        <PassivePagination
          items={sorted}
          sizeSelector={true}
          id="seges-companies"
          renderItem={(item) => <Row value={item} onDeleted={onDeleted} />}
        />
      </tbody>
    </ReactTable>
  );
};

export default Table;

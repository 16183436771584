import React, { Fragment } from "react";
import { useContext } from "react";
import UserContext from "../contexts/userContext";

const AdminContent: React.FC = ({ children }) => {
  const { user } = useContext(UserContext);

  if (user && user.identity.admin) {
    return <Fragment>{children}</Fragment>;
  } else return null;
};

export default AdminContent;

import React, { Fragment, useCallback, useContext, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import UserContext from "../../contexts/userContext";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyResource } from "../../models/company";
import {
  IVehicleResource,
  IVehicleResourceWithUnit,
} from "../../models/vehicle";
import Bordered from "../Bordered";
import TableError from "../TableError";
import Vehicles from "../Vehicles/Index";
import Companies from "./Index";
import SyncRemote from "./SyncRemote";

interface Props {
  company: ICompanyResource;
}
const CompanyVehicles: React.FC<Props> = ({ company }) => {
  const { user } = useContext(UserContext);
  const request = useCallback(
    (signal: AbortSignal) => Company.ReadVehicles(company, signal),
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [vehicles, error, setVehicles] = usePromise(request, [], setLoading);
  const updateSync = (vehicles: IVehicleResource[]) => {
    const withUnits: IVehicleResourceWithUnit[] = vehicles.map((v) => ({
      ...v,
      unit: undefined,
    }));
    setVehicles((prev) => [...prev, ...withUnits]);
  };

  const companiedVehicles = useMemo(() => vehicles.map(v => ({...v, company: company})), [vehicles, company]);

  if (loading) return <Spinner animation="border" />;
  if (error) return <TableError />;

  if (user && user.identity.admin) {
    return (
      <Fragment>
        <Bordered shrinkToFit>
          <SyncRemote company={company} onCreated={updateSync} />
        </Bordered>
        <Vehicles.Table vehicles={companiedVehicles} company={company} />
      </Fragment>
    );
  }

  return <Companies.VehiclesTable vehicles={companiedVehicles} company={company} />;
};

export default CompanyVehicles;

import {
  faChartLine,
  faChartPie,
  faTruck
} from "@fortawesome/free-solid-svg-icons";
import React, { Fragment, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyIdentifiable } from "../../models/company";
import { CompanySpecificDriving } from "../Charts/CompanyDrivenChart";
import {
  CompanyVehiclesOnlineChart,
  Vehicles24OnlineChart
} from "../Charts/VehiclesOnlineChart";
import { DashboardItemWithIcon } from "./Dashboard";
import VehiclesDrivenDashboard from "./VehiclesDriven";
import VehiclesOnlineDashboard from "./VehiclesOnline";

interface CompanyDashProps {
  company: ICompanyIdentifiable;
}
const CompanyDashboard: React.FC<CompanyDashProps> = ({ company }) => {
  const request = useCallback(
    (signal: AbortSignal) => Company.ReadVehicles(company, signal),
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [vehicles] = usePromise(request, [], setLoading);

  if (loading) return <Spinner animation="border" />;

  return (
    <Fragment>
      <div className="dashboard-container">
        <DashboardItemWithIcon
          icon={faChartLine}
          defaultOpen={true}
          dashTitle="Kørte kilometer"
        >
          <div className="drive-chart">
            <CompanySpecificDriving company={company} />
          </div>
        </DashboardItemWithIcon>
        <DashboardItemWithIcon
          icon={faChartPie}
          defaultOpen={true}
          dashTitle="Antal tændte køretøjer seneste 24 timer"
        >
          <div className="online-chart">
            <Vehicles24OnlineChart company={company} />
          </div>
        </DashboardItemWithIcon>
        <DashboardItemWithIcon
          icon={faChartPie}
          defaultOpen={true}
          dashTitle="Tændte køretøjer lige nu"
        >
          <div className="online-chart">
            <CompanyVehiclesOnlineChart company={company} />
          </div>
        </DashboardItemWithIcon>
      </div>
      <hr />
      <div className="dashboard-container">
        <DashboardItemWithIcon icon={faTruck} dashTitle="Kørsel">
          <VehiclesDrivenDashboard vehicles={vehicles} />
        </DashboardItemWithIcon>
        <DashboardItemWithIcon
          icon={faTruck}
          dashTitle="Tændte og slukkede køretøjer"
        >
          <VehiclesOnlineDashboard company={company} />
        </DashboardItemWithIcon>
      </div>
    </Fragment>
  );
};

export default CompanyDashboard;

import React, { useCallback, useMemo, useState } from "react";
import { Badge, Button, Spinner } from "react-bootstrap";
import { getDays, getYesterday } from "../../helpers/utils";
import useDateRange from "../../hooks/useDateRange";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyResource } from "../../models/company";
import DataExport from "../../models/export";
import IExportOption from "../../models/exportOption";
import { IVehicleResource } from "../../models/vehicle";
import DateSelector from "../Coordinates/DateSelector";
import Vehicles from "../Vehicles/Index";
import CustomExportForm from "./CustomExportForm";

interface IndexProps {
  company: ICompanyResource;
}

const VehicleCustomExportIndex: React.FC<IndexProps> = ({ company }) => {
  const request = useCallback(
    (signal: AbortSignal) => Company.ReadVehicles(company, signal),
    [company]
  );

  const [loading, setLoading] = useLoading();
  const [vehicles] = usePromise(request, [], setLoading);

  const [vehicle, setVehicle] = useState<IVehicleResource | undefined>();

  const [from, to, setRange] = useDateRange(getYesterday(), new Date());

  return (
    <div>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Vehicles.Selector
          value={vehicle}
          onChange={setVehicle}
          vehicles={vehicles}
        />
      )}
      <DateSelector
        from={from}
        to={to}
        onChange={setRange}
      />
      {vehicle && <VehicleCustomExport vehicle={vehicle} from={from} to={to} />}
    </div>
  );
};
export default VehicleCustomExportIndex;

interface Props {
  vehicle: IVehicleResource;
  from: Date;
  to: Date;
}

const initialOptions: IExportOption = {
  history: undefined,
  driving: undefined,
  coordinates: undefined,
};

const VehicleCustomExport: React.FC<Props> = ({ vehicle, from, to }) => {
  const maxDays = 5;
  const [options, setOptions] = useState(initialOptions);
  const [loading, setLoading] = useState(false);

  const addressSelected = useMemo(
    () =>
      options.history &&
      (options.history.startAddress || options.history.endAddress),
    [options]
  );
  const days = useMemo(() => getDays(from, to), [from, to]);
  const daysCloseToExceed = useMemo(() => days > maxDays - 1, [days]);

  const downloadExcel = async () => {
    setLoading(true);
    try {
      const downloaded = await DataExport.VehicleCustomExcel(
        vehicle,
        from,
        to,
        options
      );
      if (downloaded) {
        saveFile(downloaded.fileName, downloaded.blob);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Spinner animation="border" />;

  return (
    <div>
      {addressSelected && (
        <Badge variant={daysCloseToExceed ? "danger" : "warning"}>
          Bemærk at valget af adresser under historik, begrænser det maksimale
          tidsrum til 5 dage
        </Badge>
      )}
      <CustomExportForm value={options} onChange={setOptions} />
      <hr />
      {(options.history || options.driving || options.coordinates) && (
        <div className="export-buttons">
          <Button
            variant="primary"
            disabled={addressSelected && days >= maxDays}
            onClick={downloadExcel}
          >
            Download Excel (.xlsx)
          </Button>
        </div>
      )}
    </div>
  );
};

function saveFile(name: string, data: Blob) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(data, name);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = name;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import useTitle from "../../hooks/useTitle";
import { ITrackingUnitResource } from "../../models/trackingUnit";
import TrackingUnitQRCode from "../QRCodes/TrackingUnitQRCode";
import Mounts from "./Mounts";

interface Props {
  trackingUnit: ITrackingUnitResource;
  initialTab?: string;
}
const Details: React.FC<Props> = ({ trackingUnit, initialTab }) => {
  const [tab, setTab] = useState(initialTab || "mounts");
  useEffect(() => {
    if (initialTab) {
      setTab(initialTab);
    }
  }, [initialTab]);
  useTitle("DigiFleet - Enhed: " + trackingUnit.id);
  return (
    <Tabs
      activeKey={tab}
      onSelect={(k: string | null) => k && setTab(k)}
      id="trackingUnit-tabs"
    >
      <Tab eventKey="mounts" title="Monteringer">
        <Mounts trackingUnit={trackingUnit} />
      </Tab>
      <Tab event="qr" title="QR-kode">
        <TrackingUnitQRCode trackingUnit={trackingUnit} />
      </Tab>
    </Tabs>
  );
};

export default Details;

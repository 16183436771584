import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { FormEvent, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";

export type variants =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "dark"
  | "light"
  | "link"
  | "outline-primary"
  | "outline-secondary"
  | "outline-success"
  | "outline-danger"
  | "outline-warning"
  | "outline-info"
  | "outline-dark"
  | "outline-light"
  | "blank";
type size = "sm" | "lg";
export interface ModalProps {
  title: string;
  disabled?: boolean;
  onSubmit: () => Promise<boolean | undefined>;
  onClose?: () => void;
  buttonSize?: size;
}
export interface VariantModalProps extends ModalProps{
  variant?: variants;
}
interface Props extends VariantModalProps {
  buttonText: string;
  icon: IconDefinition;
}

const FormModal: React.FC<Props> = ({
  title,
  disabled,
  variant,
  buttonText,
  icon,
  onSubmit,
  onClose,
  buttonSize,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useLoading(false);

  const formSubmit = async (event: FormEvent) => {
    setLoading(true);
    event.preventDefault();
    event.stopPropagation();
    const shouldClose = await onSubmit();
    if (shouldClose) close();
    setLoading(false);
  };
  const close = () => {
    if (onClose) onClose();
    setOpen(false);
  };
  return (
    <Fragment>
      {variant === "blank" ? (
        <button
          title={title}
          className="blank-button"
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon icon={icon} />
        </button>
      ) : (
        <Button
          size={buttonSize}
          variant={variant}
          title={title}
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon icon={icon} />
        </Button>
      )}

      <Modal show={open} onHide={close}>
        <form onSubmit={formSubmit}>
          <Modal.Title>{title}</Modal.Title>
          <Modal.Body>{children}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              disabled={loading || disabled}
              type="submit"
            >
              {buttonText} {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button variant="secondary" onClick={close}>
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};
export default FormModal;

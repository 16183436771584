import { downloadFileFetch, downloadFilePostFetch } from "../helpers/fetchers";
import { ICompanyResource } from "./company";
import { IVehicleResource } from "./vehicle";
import IExportOption from "./exportOption";

class DataExport {
  static VehicleExcel = (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ) =>
    downloadFileFetch(
      `api/Export/Excel/Vehicle/${
        vehicle.id
      }?from=${from.toISOString()}&to=${to.toISOString()}`,
      abort
    );
  static VehicleCSV = (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ) =>
    downloadFileFetch(
      `api/Export/csv/Vehicle/${
        vehicle.id
      }?from=${from.toISOString()}&to=${to.toISOString()}`,
      abort
    );
  static VehicleHistoryExcel = (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ) =>
    downloadFileFetch(
      `api/Export/Excel/Vehicle/${
        vehicle.id
      }/history?from=${from.toISOString()}&to=${to.toISOString()}`,
      abort
    );
  static VehicleHistoryCSV = (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ) =>
    downloadFileFetch(
      `api/Export/csv/Vehicle/${
        vehicle.id
      }/history?from=${from.toISOString()}&to=${to.toISOString()}`,
      abort
    );
  static VehicleCoordinatesExcel = (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ) =>
    downloadFileFetch(
      `api/Export/Excel/Vehicle/${
        vehicle.id
      }/coordinates?from=${from.toISOString()}&to=${to.toISOString()}`,
      abort
    );
  static VehicleCoordinatesCSV = (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ) =>
    downloadFileFetch(
      `api/Export/csv/Vehicle/${
        vehicle.id
      }/coordinates?from=${from.toISOString()}&to=${to.toISOString()}`,
      abort
    );

  static VehicleCustomExcel = (
    vehicle: IVehicleResource,
    from: Date,
    to: Date,
    options: IExportOption,
    abort?: AbortSignal
  ) =>
    downloadFilePostFetch(
      `api/Export/Excel/Vehicle/${
        vehicle.id
      }/custom?from=${from.toISOString()}&to=${to.toISOString()}`,
      options,
      abort
    );

  static CompanyVehiclesExcel = (
    company: ICompanyResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ) =>
    downloadFileFetch(
      `api/Export/Excel/Company/${
        company.id
      }/Vehicles?from=${from.toISOString()}&to=${to.toISOString()}`,
      abort
    );
  static CompanyVehiclesCSV = (
    company: ICompanyResource,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ) =>
    downloadFileFetch(
      `api/Export/csv/Company/${
        company.id
      }/Vehicles?from=${from.toISOString()}&to=${to.toISOString()}`,
      abort
    );
}

export default DataExport;

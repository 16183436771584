import React, { useContext } from "react";
import { Button, NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserContext from "../../contexts/userContext";
import Accounts from "./Index";

const LoginNavItem: React.FC = () => {
  const { user } = useContext(UserContext);
  if (user) {
    return (
      <NavItem>
        {/* Hej
        <b> {user.identity.name}</b> */}
        <span style={{ marginLeft: "0.5em", marginRight: "0.5em" }}>
          <Accounts.Logout />
        </span>
        <Link  style={{ marginLeft: "0.5em", marginRight: "0.5em" }} to={`/account/profile`}>
          <Button size="sm" variant="primary">Min profil</Button>
        </Link>
      </NavItem>
    );
  } else {
    return (
      <NavItem>
        <Link to={`/login`}>
          <Button size="sm" variant="primary">Log ind</Button>
        </Link>
      </NavItem>
    );
  }
};

export default LoginNavItem;

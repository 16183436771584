import {
  faBuilding, faCog,
  faFileExport,
  faLink, faMapMarked,
  faRandom, faRoute, faRuler,
  faStopwatch, faTable, faTruck,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import UserContext from "../contexts/userContext";
import digitachLogo from "../images/DigiFleet-Logo_Sort grå u baggrund.png";
import { IJWTCompany } from "../models/jwtUser";
import LoginNavItem from "./Account/LoginNavItem";

const NavMenu: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user && user.identity.admin) {
    return (
      <Fragment>
        <BaseNavbar>
          <AdminNavLinks />
        </BaseNavbar>
      </Fragment>
    );
  } else if (user) {
    return (
      <Fragment>
        <BaseNavbar>
          <UserCompanyNavLinks companies={user.identity.companies} />
        </BaseNavbar>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <BaseNavbar />
    </Fragment>
  );
};

export default NavMenu;

const BaseNavbar: React.FC = ({ children }) => (
  <Navbar expand="lg" variant="light" className="navbar-digifleet">
    <Container>
      <Navbar.Brand href="/">
        <img src={digitachLogo} alt="DigiFleet logo" height="50" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <LinkContainer exact to="/">
            <Nav.Link>Hjem</Nav.Link>
          </LinkContainer>
          {children}
        </Nav>
      </Navbar.Collapse>
    </Container>
    <LoginNavItem />
  </Navbar>
);
interface CompProps {
  companies: IJWTCompany[];
}

const UserCompanyNavLinks: React.FC<CompProps> = ({ companies }) => {
  if (companies.length === 1) {
    return (
      <Fragment>
        <LinkContainer to="/map/live">
          <Nav.Link>Live</Nav.Link>
        </LinkContainer>
        <NavDropdown title="Historik" id="Vehicles history nav">
          <LinkContainer to="/map/history">
            <NavDropdown.Item>
              <div className="navmenu-icon">
                <FontAwesomeIcon icon={faMapMarked} />
              </div>
              Grafisk
            </NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to="/history-table">
            <NavDropdown.Item>
              <div className="navmenu-icon">
                <FontAwesomeIcon icon={faTable} />
              </div>
              Tabel
            </NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to={`/company/${companies[0].id}/proximity`}>
            <NavDropdown.Item>
              <div className="navmenu-icon">
                <FontAwesomeIcon icon={faRuler} />
              </div>
              Nærhedssøgning
            </NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to={`/company/${companies[0].id}/exports`}>
            <NavDropdown.Item>
              <div className="navmenu-icon">
                <FontAwesomeIcon icon={faFileExport} />
              </div>
              Data eksport
            </NavDropdown.Item>
          </LinkContainer>
        </NavDropdown>
        <NavDropdown title={companies[0].name} id="Company nav">
          <LinkContainer to={`/company/${companies[0].id}/vehicles`}>
            <NavDropdown.Item>
              <div className="navmenu-icon">
                <FontAwesomeIcon icon={faTruck} />
              </div>
              Køretøjer
            </NavDropdown.Item>
          </LinkContainer>
          {companies[0].isRemote && (
            <LinkContainer to={`/company/${companies[0].id}/remote`}>
              <NavDropdown.Item>
                <div className="navmenu-icon">
                  <FontAwesomeIcon icon={faStopwatch} />
                </div>
                Køre hviletid
              </NavDropdown.Item>
            </LinkContainer>
          )}

          <LinkContainer to={`/company/${companies[0].id}/units`}>
            <NavDropdown.Item>
              <div className="navmenu-icon">
                <FontAwesomeIcon icon={faCog} />
              </div>
              Tracking enheder
            </NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to={`/company/${companies[0].id}/users`}>
            <NavDropdown.Item>
              <div className="navmenu-icon">
                <FontAwesomeIcon icon={faUsers} />
              </div>
              Brugere
            </NavDropdown.Item>
          </LinkContainer>
        </NavDropdown>
      </Fragment>
    );
  } else if (companies.length > 1) {
    return (
      <Fragment>
        <LinkContainer to="/map/live">
          <Nav.Link>Live</Nav.Link>
        </LinkContainer>
        <NavDropdown title="Historik" id="Vehicles history nav">
          <LinkContainer to="/map/history">
            <NavDropdown.Item>
              <div className="navmenu-icon">
                <FontAwesomeIcon icon={faMapMarked} />
              </div>
              Grafisk
            </NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to="/history-table">
            <NavDropdown.Item>
              <div className="navmenu-icon">
                <FontAwesomeIcon icon={faTable} />
              </div>
              Tabel
            </NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to={`/proximity`}>
            <NavDropdown.Item>
              <div className="navmenu-icon">
                <FontAwesomeIcon icon={faRuler} />
              </div>
              Nærhedssøgning
            </NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to={`/exports`}>
            <NavDropdown.Item>
              <div className="navmenu-icon">
                <FontAwesomeIcon icon={faFileExport} />
              </div>
              Data eksport
            </NavDropdown.Item>
          </LinkContainer>
        </NavDropdown>
        <NavDropdown title="Virksomheder" id="Companies dropdown nav">
          {companies.map((item, index) => (
            <LinkContainer to={`/company/${item.id}`} key={item.id}>
              <NavDropdown.Item>{item.name}</NavDropdown.Item>
            </LinkContainer>
          ))}
        </NavDropdown>
      </Fragment>
    );
  }

  return null;
};

const AdminNavLinks: React.FC = () => (
  <Fragment>
    <LinkContainer to="/map/live">
      <Nav.Link>Live</Nav.Link>
    </LinkContainer>
    <NavDropdown title="Historik" id="Vehicles history nav">
      <LinkContainer to="/map/history">
        <NavDropdown.Item>
          <div className="navmenu-icon">
            <FontAwesomeIcon icon={faMapMarked} />
          </div>
          Grafisk
        </NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/history-table">
        <NavDropdown.Item>
          <div className="navmenu-icon">
            <FontAwesomeIcon icon={faTable} />
          </div>
          Tabel
        </NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/proximity`}>
        <NavDropdown.Item>
          <div className="navmenu-icon">
            <FontAwesomeIcon icon={faRuler} />
          </div>
          Nærhedssøgning
        </NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/exports`}>
        <NavDropdown.Item>
          <div className="navmenu-icon">
            <FontAwesomeIcon icon={faFileExport} />
          </div>
          Data eksport
        </NavDropdown.Item>
      </LinkContainer>
    </NavDropdown>
    <LinkContainer to="/company">
      <Nav.Link>Virksomheder</Nav.Link>
    </LinkContainer>

    <NavDropdown title="Køretøjer" id="Vehicles nav menu">
      <LinkContainer exact to="/vehicle">
        <NavDropdown.Item>
          <div className="navmenu-icon">
            <FontAwesomeIcon icon={faTruck} />
          </div>
          Køretøjer
        </NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/vehicle/flex">
        <NavDropdown.Item>
          <div className="navmenu-icon">
            <FontAwesomeIcon icon={faLink} />
          </div>
          Flex-Køretøjer
        </NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/reflectors">
        <NavDropdown.Item>
          <div className="navmenu-icon">
            <FontAwesomeIcon icon={faRandom} />
          </div>
          Reflektor
        </NavDropdown.Item>
      </LinkContainer>
    </NavDropdown>
    <NavDropdown title="Tracking enheder" id="Tracking units nav menu">
      <LinkContainer exact to="/unit">
        <NavDropdown.Item>
          <div className="navmenu-icon">
            <FontAwesomeIcon icon={faRoute} />
          </div>
          Alle
        </NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/unit/active">
        <NavDropdown.Item>
          <div className="navmenu-icon">
            <FontAwesomeIcon icon={faRoute} />
          </div>
          Aktive
        </NavDropdown.Item>
      </LinkContainer>
    </NavDropdown>
    <LinkContainer to="/user">
      <Nav.Link>Brugere</Nav.Link>
    </LinkContainer>
    <LinkContainer to="/email">
      <Nav.Link>Email skabeloner</Nav.Link>
    </LinkContainer>
    <LinkContainer to="/emulators">
      <Nav.Link>Emulatore</Nav.Link>
    </LinkContainer>
    <LinkContainer to="/quota">
      <Nav.Link>Kvoter</Nav.Link>
    </LinkContainer>

    <NavDropdown title="SEGES" id="SEGES nav menu">
      <LinkContainer to="/seges/vehicles">
        <NavDropdown.Item>
          <div className="navmenu-icon">
            <FontAwesomeIcon icon={faTruck} />
          </div>
          Køretøjer
        </NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/seges/companies">
        <NavDropdown.Item>
          <div className="navmenu-icon">
            <FontAwesomeIcon icon={faBuilding} />
          </div>
          Virksomheder
        </NavDropdown.Item>
      </LinkContainer>
    </NavDropdown>
  </Fragment>
);

import { readFetch, createFetch, updateFetch, deleteFetch } from "../helpers/fetchers";
import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";

export enum EmailTemplateUsedBy {
  None,
  ForgotPassword,
  PasswordWasReset,
  UserCreated,
}
export interface IEmailTemplate {
  id: number;
  title: string;
  subject: string;
  body: string;
  usedBy: EmailTemplateUsedBy;
}
export type EmailTemplateProps = "id" | "title" | "subject" | "body" | "usedBy";
class EmailTemplate implements IEmailTemplate, ISortable<EmailTemplateProps>, ISearchable<EmailTemplateProps>{
  id: number;
  title: string;
  subject: string;
  body: string;
  usedBy: EmailTemplateUsedBy;

  constructor(value: IEmailTemplate){
    this.id = value.id;
    this.title = value.title;
    this.subject = value.subject;
    this.body = value.body;
    this.usedBy = value.usedBy;
  }
  search = (value: any, type: EmailTemplateProps): number | undefined => 
    basicSearch(this, value, type, false);
  compare = (value: ISortable<EmailTemplateProps>, primary: EmailTemplateProps, secondary?: "id" | "title" | "subject" | "body" | "usedBy" | undefined): number => 
    basicSecSort(this, value, primary, secondary);

  static Read = async (id: number, abort?: AbortSignal): Promise<IEmailTemplate> =>
    (await readFetch("api/EmailTemplates/" + id, abort)) as IEmailTemplate;

  static ReadAll = async (abort?: AbortSignal): Promise<IEmailTemplate[]> =>
    (await readFetch("api/EmailTemplates", abort)) as IEmailTemplate[];

  static Create = async (template: IEmailTemplate, abort?: AbortSignal): Promise<IEmailTemplate> =>
    (await createFetch("api/EmailTemplates", template, abort)) as IEmailTemplate;

  static Update = async (template: IEmailTemplate, abort?: AbortSignal): Promise<void> => {
    await updateFetch("api/EmailTemplates/" + template.id, template, abort);
  };
  static Delete = async (template: IEmailTemplate, abort?: AbortSignal): Promise<void> => {
    await deleteFetch("api/EmailTemplates/" + template.id, abort);
  };
}

export default EmailTemplate;

import { useState, useEffect, useCallback } from "react";
import TrackingUnit, { ITrackingUnitResource, TrackingUnitProps, TrackingUnitResource } from "../../models/trackingUnit";
import { Row, Col, Table, Button, Spinner } from "react-bootstrap";
import React from "react";
import useSortedSearch from "../../hooks/useSortedSearch";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import PassivePagination from "../Pagination";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TrackingUnitQR from "../../models/trackingUnitQR";
import { convertBlobToBase64 } from "../../helpers/fetchers";
import { printManyImages } from "./QRViewer";
import useTitle from "../../hooks/useTitle";

const PrintManyUnitCodes: React.FC = () => {

  useTitle("DigiFleet - Print QR-koder");
  const [loading, setLoading] = useState(false);

  const [trackingUnits, setTrackingUnits] = useState<ITrackingUnitResource[]>([]);
  const [selected, setSelected] = useState<ITrackingUnitResource[]>([]);

  useEffect(() => {
    const readAll = async () => {
      const units = await TrackingUnit.ReadAll();
      setTrackingUnits(units);
    };
    readAll();
  }, []);

  const printSelected = useCallback(async () => {
    const result: string[][] = [];
    setLoading(true);
    for (var unit of selected) {
      var blob: Blob;
      try {
        blob = await TrackingUnitQR.ReadImage(unit.id);
      } catch (error) {
        await TrackingUnitQR.Create(unit);
        blob = await TrackingUnitQR.ReadImage(unit.id);
      }

      const base64 = await convertBlobToBase64(blob);
      if (base64) {
        result.push([`${base64}`, unit.id]);
      }
    }
    setLoading(false);
    printManyImages(result);
  }, [selected]);

  const [
    sortedUnits,
    unitSearch,
    unitSort,
    setUnitSort,
    setUnitSearch,
  ] = useSortedSearch<TrackingUnitResource, TrackingUnitProps>(trackingUnits.map(v => new TrackingUnitResource(v)), undefined, {
    type: "id",
    asc: true,
  });

  const [
    sortedSelected,
    selectedSearch,
    selectedSort,
    setSelectedSort,
    setSelectedSearch,
  ] = useSortedSearch<TrackingUnitResource, TrackingUnitProps>(selected.map(v => new TrackingUnitResource(v)), undefined, {
    type: "id",
    asc: true,
  });

  const selectUnit = (unit: ITrackingUnitResource) => {
    setTrackingUnits(trackingUnits.filter((u) => u.id !== unit.id));
    setSelected([...selected, unit]);
  };
  const deselectUnit = (unit: ITrackingUnitResource) => {
    setSelected(selected.filter((u) => u.id !== unit.id));
    setTrackingUnits([...trackingUnits, unit]);
  };

  return (
    <div>
      <Row>
        <Col>
          <h4>Enheder</h4>
          <Table hover>
            <thead>
              <tr>
                <SearchableHeader
                  value={unitSearch}
                  types={["id"]}
                  onSearch={setUnitSearch}
                />
                <SearchableHeader
                  value={unitSearch}
                  types={["company.name"]}
                  onSearch={setUnitSearch}
                />
                <SearchableHeader
                  value={unitSearch}
                  types={["vehicle.alias", "vehicle.licensePlate"]}
                  onSearch={setUnitSearch}
                />
                <th></th>
              </tr>
              <tr>
                <SortableHeader
                  type="id"
                  value={unitSort}
                  onChange={setUnitSort}
                >
                  Id
                </SortableHeader>
                <SortableHeader
                  type="company.name"
                  value={unitSort}
                  onChange={setUnitSort}
                >
                  Virksomhed
                </SortableHeader>
                <SortableHeader
                  type="vehicle.alias"
                  fallback="vehicle.licensePlate"
                  value={unitSort}
                  onChange={setUnitSort}
                >
                  Køretøj
                </SortableHeader>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <PassivePagination<ITrackingUnitResource>
                items={sortedUnits}
                renderItem={(unit) => (
                  <tr key={unit.id}>
                    <td>{unit.id}</td>
                    <td>{unit.company ? unit.company.name : ""}</td>
                    <td>
                      {unit.vehicle
                        ? `${unit.vehicle.licensePlate}, ${
                            unit.vehicle.alias || ""
                          }`
                        : ""}
                    </td>
                    <td>
                      <Button
                        title="Vælg"
                        variant="primary"
                        onClick={() => selectUnit(unit)}
                      >
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </td>
                  </tr>
                )}
              />
            </tbody>
          </Table>
        </Col>
        <Col>
          <h4>Valgte enheder</h4>
          <Table hover>
            <thead>
              <tr>
                <th></th>
                <SearchableHeader
                  value={selectedSearch}
                  types={["id"]}
                  onSearch={setSelectedSearch}
                />
                <SearchableHeader
                  value={selectedSearch}
                  types={["company.name"]}
                  onSearch={setSelectedSearch}
                />
                <SearchableHeader
                  value={selectedSearch}
                  types={["vehicle.alias", "vehicle.licensePlate"]}
                  onSearch={setSelectedSearch}
                />
              </tr>
              <tr>
                <th></th>
                <SortableHeader
                  type="id"
                  value={selectedSort}
                  onChange={setSelectedSort}
                >
                  Id
                </SortableHeader>
                <SortableHeader
                  type="company.name"
                  value={selectedSort}
                  onChange={setSelectedSort}
                >
                  Virksomhed
                </SortableHeader>
                <SortableHeader
                  type="vehicle.alias"
                  fallback="vehicle.licensePlate"
                  value={selectedSort}
                  onChange={setSelectedSort}
                >
                  Køretøj
                </SortableHeader>
              </tr>
            </thead>
            <tbody>
              <PassivePagination<ITrackingUnitResource>
                items={sortedSelected}
                renderItem={(unit) => (
                  <tr key={unit.id}>
                    <td>
                      <Button
                        title="Fravælg"
                        variant="primary"
                        onClick={() => deselectUnit(unit)}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </Button>
                    </td>
                    <td>{unit.id}</td>
                    <td>{unit.company ? unit.company.name : ""}</td>
                    <td>
                      {unit.vehicle
                        ? `${unit.vehicle.licensePlate}, ${
                            unit.vehicle.alias || ""
                          }`
                        : ""}
                    </td>
                  </tr>
                )}
              />
            </tbody>
          </Table>
          <Button
            variant="success"
            disabled={selected.length === 0}
            onClick={() => printSelected()}
          >
            Print valgte
            {loading && <Spinner size="sm" animation="border" />}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PrintManyUnitCodes;

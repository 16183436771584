import React from "react";
import { Form } from "react-bootstrap";
import { ICoordinateOptions } from "../../models/exportOption";

interface Props {
  value: ICoordinateOptions;
  onChange: (value: ICoordinateOptions) => void;
}

const CustomCoordinateExport: React.FC<Props> = ({ value, onChange }) => {
  return (
    <Form.Group>
      <Form.Check
        label="Navn"
        id="coordinate-alias"
        custom
        checked={value.alias}
        onChange={() =>
          onChange({ ...value, alias: !value.alias })
        }
      />
      <Form.Check
        label="Reg. nr."
        id="coordinate-licensePlate"
        custom
        checked={value.licensePlate}
        onChange={() =>
          onChange({ ...value, licensePlate: !value.licensePlate })
        }
      />
      <Form.Check
        label="Længdegrad"
        id="coordinate-longitude"
        custom
        checked={value.longitude}
        onChange={() =>
          onChange({ ...value, longitude: !value.longitude })
        }
      />
      <Form.Check
        label="Breddegrad"
        id="coordinate-latitude"
        custom
        checked={value.latitude}
        onChange={() =>
          onChange({ ...value, latitude: !value.latitude })
        }
      />
      <Form.Check
        label="Hastighed"
        id="coordinate-speed"
        custom
        checked={value.speed}
        onChange={() => onChange({ ...value, speed: !value.speed })}
      />
      <Form.Check
        label="Tænding"
        id="coordinate-acc"
        custom
        checked={value.acc}
        onChange={() =>
          onChange({ ...value, acc: !value.acc })
        }
      />
      <Form.Check
        label="Dato"
        id="driving-timestamp"
        custom
        checked={value.timestamp}
        onChange={() => onChange({ ...value, timestamp: !value.timestamp })}
      />
    </Form.Group>
  );
};

export default CustomCoordinateExport;

import React from "react";
import { ICompanyResource } from "../models/company";
import { ITrackingUnitResource } from "../models/trackingUnit";
import { IUserResource } from "../models/user";
import { IVehicleResource } from "../models/vehicle";

/**
 * Definition of types of items to exclude
 */
export interface ExcludeContent {
  vehicles: IVehicleResource[];
  trackingUnits: ITrackingUnitResource[];
  users: IUserResource[];
  companies: ICompanyResource[];
}

/**
 * Default values for exclusion
 */
export const DefaultExcludeContent: ExcludeContent = {
  vehicles: [],
  trackingUnits: [],
  users: [],
  companies: [],
};

/**
 * Exclude context value
 */
export interface ExcludeContextValue {
  excludes: ExcludeContent;
  setExcludes: (value: ExcludeContent) => void;
}

/**
 * Context for excluding items from certain lists
 */
const ExcludeContext = React.createContext<ExcludeContextValue>({
  excludes: DefaultExcludeContent,
  setExcludes: () => {},
});

export default ExcludeContext;

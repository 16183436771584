import React from "react";
import { Table, Badge, Button } from "react-bootstrap";
import { NumberWithSeperator } from "../NumberWithSeperator";
import { IServiceStatus, IServiceTotals } from "../../models/serviceStatus";
import { createFetch, updateFetch } from "../../helpers/fetchers";
import useLoading from "../../hooks/useLoading";

/**
 * Displays a table of status totals
 * @param {*} param0
 */
interface TotalsProps {
  totals: IServiceTotals;
}

export const StatusTotals: React.FC<TotalsProps> = ({ totals }) => {
  const [loading, setLoading] = useLoading(false);
  const syncTachoSafe = async () => {
    try {
      setLoading(true);
      await createFetch("api/Companies/Tachosafe/Sync", undefined);
    } catch (error) {
      console.error(error);
    }
    finally{
      setLoading(false);
    }
  }
  return (
    <div className="status-totals">
      <Table striped responsive>
        <thead>
          <tr>
            <th></th>
            <th>Antal</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Virksomheder</td>
            <td>
              <NumberWithSeperator number={totals.companies} />
            </td>
          </tr>
          <tr>
            <td>Køretøjer</td>
            <td>
              <NumberWithSeperator number={totals.vehicles} />
            </td>
          </tr>
          <tr>
            <td>Brugere</td>
            <td>
              <NumberWithSeperator number={totals.users} />
            </td>
          </tr>
          <tr>
            <td>Tracking enheder</td>
            <td>
              <NumberWithSeperator number={totals.trackingUnits} />
            </td>
          </tr>
          <tr>
            <td>Enheder forbundet til server</td>
            <td>
              <NumberWithSeperator number={totals.connectedTrackers} />
            </td>
          </tr>
          <tr>
            <td>GPS koordinater</td>
            <td title="Tælling deaktiveret fordi det taget meget lang tid">
              {/* <NumberWithSeperator number={totals.gpsCoordinates} /> */}
              Mange!
            </td>
          </tr>
        </tbody>
      </Table>
      <Button variant="warning" onClick={syncTachoSafe}>
        Synkroniser TachoSafe relationer
      </Button>
    </div>
  );
};

interface LabelProps {
  status?: IServiceStatus;
  invertColor?: boolean
}
export const ServiceStatusLabel: React.FC<LabelProps> = ({ status, invertColor }) => {
  return (
    <Badge variant={(status && (status.online == !invertColor)) ? "success" : "danger"}>
      {status && status.online ? "Online" : "Offline"}
    </Badge>
  );
};

interface ServiceControlProps {
  status?: IServiceStatus;
  url: string;
  warning?: string;
  onStatusChange: (status: IServiceStatus) => void;
  [x: string]: any
}

export const ServiceControlButton: React.FC<ServiceControlProps> = ({
  status,
  url,
  warning,
  onStatusChange,
  ...rest
}) => {
  const toggleStatus = async () => {
    try {
      if (!status) return;
      //Show warning when service is set to go offline
      if (warning && status.online) {
        if (!window.confirm(warning)) return;
      }
      const newStatus = await updateFetch<IServiceStatus>(url, {
        online: status && !status.online,
      });
      if (newStatus) {
        onStatusChange(newStatus);
      }
    } catch (error) {
      window.alert("Fejl");
      console.error(error);
    }
  };

  return (
    <Button
      size="sm"
      variant={status && status.online ? "danger" : "success"}
      onClick={toggleStatus}
      {...rest}
    >
      {status && status.online ? "Stop" : "Start"}
    </Button>
  );
};

import { faMapMarkedAlt, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { renderToString } from "react-dom/server";
import { LayersControl, Map, Marker, Popup, TileLayer } from "react-leaflet";
import { ICoordinate } from "../../models/gpsCoordinate";

interface Props {
  position: ICoordinate;
}

const PositionModal: React.FC<Props> = ({ position }) => {
  const [shown, setShown] = useState(false);
  const [zoom, setZoom] = useState(13);
  const mapElement = useRef<Map>(null);

  const show = () => {
    setShown(true);
    setTimeout(() => {
      if(mapElement && mapElement.current){
        mapElement.current.leafletElement.invalidateSize();
      }
    }, 100);
  }

  if (!shown) {
    return (
      <button
        title="Vis på kort"
        onClick={() => show()}
        className="blank-button"
      >
        <FontAwesomeIcon icon={faMapMarkedAlt} />
      </button>
    );
  }

  const iconHTML = renderToString(<FontAwesomeIcon size="lg" icon={faMapPin} />)
  const divIcon = L.divIcon({
    html: `<div>${iconHTML}</div>`,
    iconSize: [10, 10],
    // iconAnchor: [Math.sqrt(iconSize), (iconSize / 2)],
    className: "",
  });

  return (
    <Modal show={shown} onHide={() => setShown(false)}>
      <Modal.Title>Position</Modal.Title>
      <Modal.Body>
        <div className="modal-map">
          <Map
            ref={mapElement}
            center={[position.latitude, position.longitude]}
            zoom={zoom}
            onViewportChanged={(viewport) => {
              if (viewport.zoom && viewport.zoom !== zoom) {
                setZoom(viewport.zoom);
              }
            }}
          >
            <LayersControl position="bottomright">
              <LayersControl.BaseLayer checked name="Standard">
                <TileLayer url="https://api.mapbox.com/styles/v1/brianmose/ck9vbbwk20nhr1il5wkc9fjoz/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYnJpYW5tb3NlIiwiYSI6ImNrMnU5dGV1ZTA2bzAzY3FtY2U4ZTFoMXYifQ.fKvvV85REkfdJ6vqtWF66Q" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellit">
                <TileLayer url="https://api.mapbox.com/styles/v1/brianmose/ckanv0ym564xz1ilksgqxiaic/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYnJpYW5tb3NlIiwiYSI6ImNrMnU5dGV1ZTA2bzAzY3FtY2U4ZTFoMXYifQ.fKvvV85REkfdJ6vqtWF66Q" />
              </LayersControl.BaseLayer>
            </LayersControl>

            <Marker position={[position.latitude, position.longitude]} icon={divIcon}>
              <Popup autoPan={false}>
                <p>
                  Bredde: <b>{position.latitude}</b>
                </p>
                <p>
                  Længdegrad: <b>{position.longitude}</b>
                </p>
              </Popup>
            </Marker>
          </Map>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShown(false);
          }}
        >
          Luk
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PositionModal;

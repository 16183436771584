import { GeoFeature } from "../models/geoAddress";
import { ICoordinate } from "../models/gpsCoordinate";
import levenshtein from "fast-levenshtein";

export const getDays = (from: Date, to: Date): number => {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.abs((from.getTime() - to.getTime()) / oneDay);
};

export function spaceship(val1: any, val2: any) {
  if (val1 === null || val1 === undefined) {
    return 1;
  } else if (val2 === null || val2 === undefined) {
    return -1;
  } else if (typeof val1 !== typeof val2) {
    return 1;
  }

  // if(typeof val1 === 'boolean'){
  //   return (val1 === val2)? 0 : val1? -1 : 1;
  // }
  if (typeof val1 === "string") {
    return val1.localeCompare(val2, undefined, { numeric: true });
  } else {
    if (val1 > val2) {
      return 1;
    } else if (val1 < val2) {
      return -1;
    }
    return 0;
  }
}

export function getObjectProperty(item: any, name: string): any {
  if (item === null || item === undefined) return undefined;
  let names = name.split(".");
  let value = item[names[0]];
  if (Array.isArray(value)) {
    if (names.length > 1) {
      return getArrayProperties(value, names[1]);
    }
    return value;
  }
  for (let i = 1; i < names.length; i++) {
    if (value === null) return undefined;
    value = value[names[i]];
    if (Array.isArray(value)) {
      if (i < names.length - 1) {
        return getArrayProperties(value, names[i + 1]);
      }
      return value;
    }
  }
  return value;
}

export const basicSortCompare = (
  item1: any,
  item2: any,
  name: string
): number | undefined => {
  const val1 = getObjectProperty(item1, name);
  const val2 = getObjectProperty(item2, name);
  if (val1 === undefined || val2 === undefined) return undefined;

  return spaceship(val1, val2);
};

/**
 * Performs a basic sorting compare with an optional fallback. Returns 0 if all cases fail
 * @param item1
 * @param item2
 * @param name
 * @param fallback
 */
export const basicSecSort = (
  item1: any,
  item2: any,
  name: string,
  fallback?: string
): number => {
  try {
    const comp1 = basicSortCompare(item1, item2, name);
    if (fallback !== undefined && comp1 === undefined) {
      const comp2 = basicSortCompare(item1, item2, fallback);
      return comp2 || 0;
    }
    return comp1 || 0;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const basicSearch = (
  item1: any,
  search: any,
  name: string,
  ignoreLength: boolean
): number | undefined => {
  try {
    const val1 = getObjectProperty(item1, name);
    return checkSearch(search, val1, ignoreLength);
  } catch (error) {
    console.error(error);
    return 0;
  }
};

/**
 * Factor for how much the levenshtein edit distance influences the result
 * Higher: Loose comparison.
 * Lower: Stricter comparison.
 */
 const levenshteinCompensationFactor = 1;
 /**
  * Factor for how much the substring position of the search-text in the text, if present, influences result
  * Higher: Matters less.
  * Lower: Matters more.
  */
 const positionCompensationFactor = 1.5;
 /**
  * Factor for how much the length difference influences the result
  * Higher: Matters more.
  * Lower: Matters less.
  */
 const lengthCompensationFactor = 0.3;
 /**
  * Checks that a value matches an expected value
  * If the value is an array, it checks recursively against every item until a match is found
  */
 const checkSearch = (
   expected: any,
   actual: any,
   ignoreLength: boolean
 ): number | undefined => {
   try {
     //Check if the value is an array
     if (Array.isArray(actual)) {
       //Search through arrays
       for (var aIndex in actual) {
         const aValue = actual[aIndex];
         //Check if the array value matches the expected value
         const aResult = checkSearch(expected, aValue, ignoreLength);
         if (aResult !== undefined) {
           return aResult;
         }
       }
       return undefined;
     }
 
     if (typeof actual === "number") actual = actual.toString();
     if (typeof expected === "number") expected = expected.toString();
     if (typeof actual !== "undefined" && actual !== null) {
       const actualText: string = actual.toLowerCase().replace(/\s+/g, "");
       const expectedText: string = expected.toLowerCase().replace(/\s+/g, "");
 
       if (!ignoreLength) {
         const lDistance = levenshtein.get(actualText, expectedText);
         let length = Math.abs(actualText.length - expectedText.length);
         let pos = actualText.indexOf(expectedText);
         if(pos === -1){
           pos = expectedText.indexOf(actualText);
           if(pos === -1) pos = 10;
         }
         
         return ((lDistance / levenshteinCompensationFactor) - (length * lengthCompensationFactor)) + ((pos + 1) * positionCompensationFactor);
       }
       return levenshtein.get(actualText, expectedText);
     }
     return undefined;
   } catch (error) {
     console.error(error);
     return 0;
   }
 };

function getArrayProperties(items: any[], name: string) {
  return items.map((item) => getObjectProperty(item, name));
}

export function colourNameToHex(colour: string | undefined): string {
  if (!colour) return "";
  var colours = {
    aliceblue: "#f0f8ff",
    antiquewhite: "#faebd7",
    aqua: "#00ffff",
    aquamarine: "#7fffd4",
    azure: "#f0ffff",
    beige: "#f5f5dc",
    bisque: "#ffe4c4",
    black: "#000000",
    blanchedalmond: "#ffebcd",
    blue: "#0000ff",
    blueviolet: "#8a2be2",
    brown: "#a52a2a",
    burlywood: "#deb887",
    cadetblue: "#5f9ea0",
    chartreuse: "#7fff00",
    chocolate: "#d2691e",
    coral: "#ff7f50",
    cornflowerblue: "#6495ed",
    cornsilk: "#fff8dc",
    crimson: "#dc143c",
    cyan: "#00ffff",
    darkblue: "#00008b",
    darkcyan: "#008b8b",
    darkgoldenrod: "#b8860b",
    darkgray: "#a9a9a9",
    darkgreen: "#006400",
    darkkhaki: "#bdb76b",
    darkmagenta: "#8b008b",
    darkolivegreen: "#556b2f",
    darkorange: "#ff8c00",
    darkorchid: "#9932cc",
    darkred: "#8b0000",
    darksalmon: "#e9967a",
    darkseagreen: "#8fbc8f",
    darkslateblue: "#483d8b",
    darkslategray: "#2f4f4f",
    darkturquoise: "#00ced1",
    darkviolet: "#9400d3",
    deeppink: "#ff1493",
    deepskyblue: "#00bfff",
    dimgray: "#696969",
    dodgerblue: "#1e90ff",
    firebrick: "#b22222",
    floralwhite: "#fffaf0",
    forestgreen: "#228b22",
    fuchsia: "#ff00ff",
    gainsboro: "#dcdcdc",
    ghostwhite: "#f8f8ff",
    gold: "#ffd700",
    goldenrod: "#daa520",
    gray: "#808080",
    green: "#008000",
    greenyellow: "#adff2f",
    honeydew: "#f0fff0",
    hotpink: "#ff69b4",
    "indianred ": "#cd5c5c",
    indigo: "#4b0082",
    ivory: "#fffff0",
    khaki: "#f0e68c",
    lavender: "#e6e6fa",
    lavenderblush: "#fff0f5",
    lawngreen: "#7cfc00",
    lemonchiffon: "#fffacd",
    lightblue: "#add8e6",
    lightcoral: "#f08080",
    lightcyan: "#e0ffff",
    lightgoldenrodyellow: "#fafad2",
    lightgrey: "#d3d3d3",
    lightgreen: "#90ee90",
    lightpink: "#ffb6c1",
    lightsalmon: "#ffa07a",
    lightseagreen: "#20b2aa",
    lightskyblue: "#87cefa",
    lightslategray: "#778899",
    lightsteelblue: "#b0c4de",
    lightyellow: "#ffffe0",
    lime: "#00ff00",
    limegreen: "#32cd32",
    linen: "#faf0e6",
    magenta: "#ff00ff",
    maroon: "#800000",
    mediumaquamarine: "#66cdaa",
    mediumblue: "#0000cd",
    mediumorchid: "#ba55d3",
    mediumpurple: "#9370d8",
    mediumseagreen: "#3cb371",
    mediumslateblue: "#7b68ee",
    mediumspringgreen: "#00fa9a",
    mediumturquoise: "#48d1cc",
    mediumvioletred: "#c71585",
    midnightblue: "#191970",
    mintcream: "#f5fffa",
    mistyrose: "#ffe4e1",
    moccasin: "#ffe4b5",
    navajowhite: "#ffdead",
    navy: "#000080",
    oldlace: "#fdf5e6",
    olive: "#808000",
    olivedrab: "#6b8e23",
    orange: "#ffa500",
    orangered: "#ff4500",
    orchid: "#da70d6",
    palegoldenrod: "#eee8aa",
    palegreen: "#98fb98",
    paleturquoise: "#afeeee",
    palevioletred: "#d87093",
    papayawhip: "#ffefd5",
    peachpuff: "#ffdab9",
    peru: "#cd853f",
    pink: "#ffc0cb",
    plum: "#dda0dd",
    powderblue: "#b0e0e6",
    purple: "#800080",
    rebeccapurple: "#663399",
    red: "#ff0000",
    rosybrown: "#bc8f8f",
    royalblue: "#4169e1",
    saddlebrown: "#8b4513",
    salmon: "#fa8072",
    sandybrown: "#f4a460",
    seagreen: "#2e8b57",
    seashell: "#fff5ee",
    sienna: "#a0522d",
    silver: "#c0c0c0",
    skyblue: "#87ceeb",
    slateblue: "#6a5acd",
    slategray: "#708090",
    snow: "#fffafa",
    springgreen: "#00ff7f",
    steelblue: "#4682b4",
    tan: "#d2b48c",
    teal: "#008080",
    thistle: "#d8bfd8",
    tomato: "#ff6347",
    turquoise: "#40e0d0",
    violet: "#ee82ee",
    wheat: "#f5deb3",
    white: "#ffffff",
    whitesmoke: "#f5f5f5",
    yellow: "#ffff00",
    yellowgreen: "#9acd32",
  };
  const objValue = getObjectProperty(colours, colour);
  if (objValue) return objValue;

  return "";
}

/**
 * Updates a URL query parameter
 * @param {*} uri
 * @param {*} key
 * @param {*} value
 */
export function updateUrlParameter(
  uri: string,
  key: string,
  value: string
): string {
  // remove the hash part before operating on the uri
  var i = uri.indexOf("#");
  var hash = i === -1 ? "" : uri.substr(i);
  uri = i === -1 ? uri : uri.substr(0, i);

  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    uri = uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    uri = uri + separator + key + "=" + value;
  }
  return uri + hash; // finally append the hash as well
}

/**
 * Updates OR adds a URL hash fragment
 * @param {String} hash The hash value, usually gotten by window.location.hash
 * @param {String} key The fragment key to update or insert
 * @param {String|Number} value The value to update or add
 * @returns {String} Always returns the hash, whether it has changes or not
 */
export function updateUrlFragment(
  hash: string,
  key: string,
  value: string
): string {
  var keyIndex = hash.indexOf(key + "=");
  if (keyIndex === -1) {
    if (hash === "#" || !hash) {
      if (!hash) {
        hash += "#" + key + "=" + value;
      } else {
        hash += key + "=" + value;
      }
    } else {
      hash += "&" + key + "=" + value;
    }
  } else {
    let hashPart = hash.substr(keyIndex);
    let indexOfNext = hashPart.indexOf("&");

    if (indexOfNext === -1) {
      hash = hash.substr(0, keyIndex + key.length + 1) + value;
    } else {
      hash =
        hash.substr(0, keyIndex + key.length + 1) +
        value +
        hash.substr(keyIndex + indexOfNext);
    }
  }

  return hash;
}

/**
 * Removes a fragment key from a URL hash fragment
 * @param {String} hash The hash value
 * @param {String} key The key to remove
 */
export function removeUrlFragment(hash: string, key: string): string {
  var keyIndex = hash.indexOf(key + "=");
  if (keyIndex === -1) return hash;

  let hashPart = hash.substr(keyIndex + key.length + 1);
  let indexOfNext = hashPart.indexOf("&");
  if (indexOfNext === -1) {
    return hash.substr(0, keyIndex);
  } else {
    return hash.substr(0, keyIndex) + hash.substr(keyIndex + indexOfNext);
  }
}

/**
 * Gets the value of a URL hash fragment
 * @param {String} hash The hash value
 * @param {*} key The fragment key to get the value of
 * @returns {String|undefined} The value of the fragment or undefined if not found
 */
export function getUrlFragmentValue(hash: string, key: string): string {
  var keyIndex = hash.indexOf(key + "=");
  if (keyIndex === -1) return "";

  let hashPart = hash.substr(keyIndex + key.length + 1);
  let indexOfNext = hashPart.indexOf("&");
  if (indexOfNext === -1) {
    //If there are no other values, the value is the remaining hash
    return hashPart;
  } else {
    return hashPart.substr(0, indexOfNext);
  }
}

export function toRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

// Converts from radians to degrees.
export function toDegrees(radians: number): number {
  return (radians * 180) / Math.PI;
}

export function bearing(
  startLat: number,
  startLng: number,
  destLat: number,
  destLng: number
): number {
  startLat = toRadians(startLat);
  startLng = toRadians(startLng);
  destLat = toRadians(destLat);
  destLng = toRadians(destLng);

  let y = Math.sin(destLng - startLng) * Math.cos(destLat);
  let x =
    Math.cos(startLat) * Math.sin(destLat) -
    Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  let brng = Math.atan2(y, x);
  brng = toDegrees(brng);
  return (brng + 360) % 360;
}

export function getHTML5DateTimeStringsFromDate(d: Date): string {
  // Date string
  let ds =
    d.getFullYear().toString() +
    "-" +
    (d.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    d.getDate().toString().padStart(2, "0");

  // Time string
  let ts =
    d.getHours().toString().padStart(2, "0") +
    ":" +
    d.getMinutes().toString().padStart(2, "0");

  // Return them in array
  return ds + "T" + ts;
}

export function CalculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    dist = dist * 1000;
    return dist;
  }
}

export function fancyTimeFormat(time: number): string {
  time = time / 1000;
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  return `${hrs}:${mins < 10 ? "0" : ""}${mins}:${secs < 10 ? "0" : ""}${secs}`;
}

export const geoFeatureToCoordinate = (feature: GeoFeature): ICoordinate => {
  return { latitude: feature.center[1], longitude: feature.center[0] };
};

export const getYesterday = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
};

import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Button, Table } from "react-bootstrap";
import Vehicle, { IVehicleResource, VehicleProps, VehicleResource } from "../../models/vehicle";
import PassivePagination from "../Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSearch from "../../hooks/useSearch";
import SearchableHeader from "../SearchableHeader";

interface Props {
  vehicles: IVehicleResource[];
  value?: IVehicleResource;
  onChange: (vehicle: IVehicleResource) => void;
}

const HistoryVehicleSelector: React.FC<Props> = ({
  vehicles,
  value,
  onChange,
}) => {
  const [searched, search, setSearch] = useSearch<VehicleResource, VehicleProps>(vehicles.map(v => new VehicleResource(v)));
  return (
    <Table size="sm" hover responsive>
      <thead>
        <tr>
          <SearchableHeader value={search} types={["alias", "licensePlate"]} onSearch={setSearch} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        <PassivePagination
          items={searched}
          showPages={2}
          renderItem={(vehicle) => (
            <tr key={vehicle.id}>
              <td>{vehicle.alias || vehicle.licensePlate}</td>
              <td>
                <Button
                  size="sm"
                  variant={
                    value && vehicle.id === value.id ? "primary" : "secondary"
                  }
                  onClick={() => onChange(vehicle)}
                >
                  <FontAwesomeIcon
                    icon={
                      value && vehicle.id === value.id
                        ? faCheckSquare
                        : faSquare
                    }
                  />
                </Button>
              </td>
            </tr>
          )}
        />
      </tbody>
    </Table>
  );
};

export default HistoryVehicleSelector;

import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentLoading from "components/ContentLoading";
import SearchBox from "components/SearchBox";
import useLoading from "hooks/useLoading";
import usePromise from "hooks/usePromise";
import useSortedSearch from "hooks/useSortedSearch";
import Company, { ICompanyIdentifiable } from "models/company";
import { VehicleTachoProps, VehicleTachoResource } from "models/vehicleTacho";
import React from "react";
import { useCallback } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Row } from "./Row";

interface Props {
  company: ICompanyIdentifiable;
}

export const Tachographs = ({ company }: Props) => {
  const request = useCallback(
    (abort: AbortSignal) => Company.ReadVehicleTachographs(company, abort),
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [tachographs] = usePromise(request, [], setLoading);

  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    VehicleTachoResource,
    VehicleTachoProps
  >(
    tachographs.map((item) => new VehicleTachoResource(item)),
    undefined,
    {
      type: "vehicle.alias",
      fallback: "vehicle.licensePlate",
      asc: true,
    }
  );

  return (
    <ContentLoading loading={loading}>
      <Link to="/nav">
        <Button variant="secondary">
          <FontAwesomeIcon icon={faArrowCircleLeft} />
        </Button>
      </Link>
      <SearchBox
        placeholder="Søg"
        className="form-control"
        onChange={(event) => {
          setSearch({
            types: ["vehicle.alias", "vehicle.licensePlate"],
            value: event.target.value,
          });
        }}
      />
      <div className="mobile-tacho">
        {sorted.map((tacho) => (
          <Row key={tacho.vehicle.id} value={tacho} />
        ))}
      </div>
    </ContentLoading>
  );
};

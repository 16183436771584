export function Version(s) {
  this.arr = s.split(".").map(Number);
}
Version.prototype.compareTo = function (v) {
  for (var i = 0; ; i++) {
    if (i >= v.arr.length) return i >= this.arr.length ? 0 : 1;
    if (i >= this.arr.length) return -1;
    var diff = this.arr[i] - v.arr[i];
    if (diff) return diff > 0 ? 1 : -1;
  }
};

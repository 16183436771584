import { Badge } from "react-bootstrap";
import React from "react";
interface Props {
  batteryLevel: number;
  dangerLevel: number;
  warningLevel: number;
}
export const BatteryLevelIndicator: React.FC<Props> = ({
  batteryLevel,
  dangerLevel,
  warningLevel,
}) => {
  if (batteryLevel < 0) {
    batteryLevel = 0;
  }
  if (batteryLevel > warningLevel) {
    return <div>{batteryLevel}%</div>;
  }

  return (
    <Badge variant={batteryLevel > dangerLevel ? "warning" : "danger"}>
      {batteryLevel}%
    </Badge>
  );
};

import React, { useEffect, useReducer } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import useSortedSearch from "../../hooks/useSortedSearch";
import { CompanyProps, CompanyResource, ICompanyResource } from "../../models/company";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import Companies from "./Index";

interface Props {
  companies: ICompanyResource[];
}

type Add = {
  readonly type: "ADD";
  company: ICompanyResource;
};
type Remove = {
  readonly type: "REMOVE";
  company: ICompanyResource;
};
type Update = {
  readonly type: "UPDATE";
  company: ICompanyResource;
};
type Replace = {
  readonly type: "REPLACE";
  companies: ICompanyResource[];
};

type Actions = Add | Remove | Update | Replace;

const reducer = (companies: ICompanyResource[], action: Actions): ICompanyResource[] => {
  switch (action.type) {
    case "ADD":
      return [...companies, action.company];
    case "REMOVE":
      return companies.filter((v) => v.id !== action.company.id);
    case "UPDATE":
      const index = companies.findIndex(
        (v: ICompanyResource) => v.id === action.company.id
      );
      return [
        ...companies.slice(0, index),
        action.company,
        ...companies.slice(index + 1),
      ];
    case "REPLACE":
      return action.companies;
  }
};
const CompanyTable: React.FC<Props> = ({ companies: comp }) => {
  const [companies, dispatch] = useReducer(reducer, comp);
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<CompanyResource, CompanyProps>(
    companies.map(v => new CompanyResource(v)),
    undefined,
    {
      type: "name",
      asc: true,
    }
  );

  useEffect(() => dispatch({ type: "REPLACE", companies: comp }), [comp]);

  return (
    <div className="vehicles">
      <Table striped responsive bordered>
        <thead>
          <tr>
            <SearchableHeader value={search} types={["name"]} onSearch={setSearch} />
            <th colSpan={3}></th>
          </tr>
          <tr>
            <SortableHeader type="name" value={sort} onChange={setSort}>
              Navn
            </SortableHeader>
            <SortableHeader type="demo" value={sort} onChange={setSort}>
              Demo
            </SortableHeader>
            <th></th>
            <th>
              <Companies.Create
                onCreated={(company: ICompanyResource) =>
                  dispatch({ type: "ADD", company })
                }
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<ICompanyResource>
            items={sorted}
            id="companies"
            sizeSelector={true}
            renderItem={(item) => (
              <tr key={item.id}>
                <td>
                  <Link to={`/company/${item.id}`}>{item.name}</Link>
                </td>
                <td>{item.demo ? "Ja" : "Nej"}</td>
                <td>
                  <Companies.Edit
                    company={item}
                    onUpdated={(company: ICompanyResource) =>
                      dispatch({ type: "UPDATE", company })
                    }
                  />
                </td>
                <td>
                  <Companies.Delete
                    company={item}
                    onDeleted={(company: ICompanyResource) =>
                      dispatch({ type: "REMOVE", company })
                    }
                  />
                </td>
              </tr>
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default CompanyTable;

import { useMemo, useState } from "react";
import { SortValue } from "../components/SortableHeader";
import ISortable from "../ISortable";

export const sortItems = <T extends ISortable<TSortType>, TSortType>(items: T[], sort?: SortValue<TSortType>): T[] => {
  if(!sort) return items;

  return [...items].sort((a, b) => {
    const compare = a.compare(b, sort.type, sort.fallback);
    return sort.asc ? compare : compare * -1;
  })
}

export const useSorting= <T extends ISortable<TSortType>, TSortType>(initialState: T[], initialSort?: SortValue<TSortType>): [
  T[],
  SortValue<TSortType> | undefined,
  (sort?:  SortValue<TSortType> | undefined) => void,
] => {
  const [sort, setSort] = useState<SortValue<TSortType> | undefined>(initialSort);
  
  const resultItems = useMemo(() => sortItems(initialState, sort), [initialState, sort]);
  return [resultItems, sort, setSort];
};

export default useSorting;

export function isEmptyOrSpaces(str: any) {
  if (typeof str === "undefined" || str === null) return true;
  if (!str.match) {
    str = str.toString();
  }
  return str === null || str.match(/^ *$/) !== null;
}
import React, { FormEvent, useState } from "react";
import {
  Badge, Button,
  Form,
  FormControl,
  InputGroup,
  Spinner
} from "react-bootstrap";
import useSuccess from "../../hooks/useSuccess";
import User, { IUserResource } from "../../models/user";
import FormControlFeedback from "../FormControlFeedback";
interface Props {
  user: IUserResource;
}
const ChangePassword: React.FC<Props> = ({ user }) => {
  const [newPassword, setNewPassword] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const [success, succeed] = useSuccess(1000);

  const saveChanges = async () => {
    try {
      if (newPassword !== confirmPassword || !newPassword || !confirmPassword)
        return;
      setLoading(true);
      await User.ChangePassword(user.id, password || "", newPassword);
      succeed();
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };
  const valid = newPassword === confirmPassword;

  return (
    <div className="login-form">
      <form onSubmit={formSave}>
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Nuværende adgangskode</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControlFeedback
              autoComplete="current-password"
              type="password"
              errors={error}
              field="password"
              onChange={(value: string) => setPassword(value)}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Ny adgangskode</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              autoComplete="new-password"
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              isInvalid={!valid}
            ></FormControl>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Bekræft ny</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              autoComplete="new-password"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              isInvalid={!valid}
            ></FormControl>
            <FormControl.Feedback type="invalid">
              Adgangskoderne stemmer ikke overens
            </FormControl.Feedback>
          </InputGroup>
        </Form.Group>
        <hr />
        <Button type="submit" disabled={!valid}>
          Gem
          {loading && <Spinner animation="border" size="sm" />}
        </Button>
        {success && (
          <Badge variant="success">
            Adgangskoden er blevet ændret
          </Badge>
        )}
      </form>
    </div>
  );
};

export default ChangePassword;

import { ITrackingUnitMount, TrackingUnitMount, TrackingUnitMountProps } from "../../models/trackingUnitMount";
import React from "react";
import useSorting from "../../hooks/useSorting";
import { Table } from "react-bootstrap";
import SortableHeader from "../SortableHeader";
import PassivePagination from "../Pagination";
import Moment from "react-moment";
import PositionModal from "../Tracking/PositionModal";

interface Props {
  mounts: ITrackingUnitMount[];
}

const MountsTable: React.FC<Props> = ({ mounts }) => {
  const [sorted, sortValue, setSort] = useSorting<TrackingUnitMount, TrackingUnitMountProps>(mounts.map(v => new TrackingUnitMount(v)), {
    type: "timestamp",
    asc: false,
  });

  return (
    <Table striped>
      <thead>
        <SortableHeader type="unitId" value={sortValue} onChange={setSort}>
          Enhed
        </SortableHeader>
        <SortableHeader type="timestamp" value={sortValue} onChange={setSort}>
          Monterings tidspunkt
        </SortableHeader>
        <SortableHeader type="initial" value={sortValue} onChange={setSort}>
          Første reele
        </SortableHeader>
        <SortableHeader type="mountInfo" value={sortValue} onChange={setSort}>
          Monterings info
        </SortableHeader>
        <th>Position</th>
      </thead>
      <tbody>
        <PassivePagination
          items={sorted}
          renderItem={(mount) => (
            <tr key={mount.id}>
              <td>{mount.unitId}</td>
              <td>
                <Moment local utc format="DD-MM-YYYY H:mm:ss">
                  {mount.timestamp}
                </Moment>
              </td>
              <td>{mount.initial ? "Ja" : "Nej"}</td>
              <td>{mount.mountInfo}</td>
              <td>
                {mount.position && <PositionModal position={mount.position} />}
              </td>
            </tr>
          )}
        />
      </tbody>
    </Table>
  );
};

export default MountsTable;



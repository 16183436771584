import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { Fragment } from "react";
import { FormGroup, InputGroup } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import { ICoordinateReflectorBinding } from "../../models/coordinateReflector";
import Vehicle from "../../models/vehicle";
import Vehicles from "../Vehicles/Index";

interface Props {
  value: ICoordinateReflectorBinding;
  onChange: (value: ICoordinateReflectorBinding) => void;
}

const Form = ({ value, onChange }: Props): React.ReactElement => {
  const [loading, setLoading] = useLoading(true);
  const request = useCallback(
    (signal: AbortSignal) => Vehicle.ReadAll(signal),
    []
  );

  const [vehicles] = usePromise(request, [], setLoading);

  return (
    <Fragment>
      <FormGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>Fra køretøj</InputGroup.Text>
          </InputGroup.Prepend>
          <Vehicles.Selector
            vehicles={vehicles}
            value={value.from}
            onChange={(from) => onChange({ ...value, from })}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>Til køretøj</InputGroup.Text>
          </InputGroup.Prepend>
          <Vehicles.Selector
            vehicles={vehicles}
            value={value.to}
            onChange={(to) => onChange({ ...value, to })}
          />
        </InputGroup>
      </FormGroup>
    </Fragment>
  );
};

export default Form;

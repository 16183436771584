import { IDrivingExportOption } from "../../models/exportOption";
import { Form } from "react-bootstrap";
import React from "react";

interface Props {
  value: IDrivingExportOption;
  onChange: (value: IDrivingExportOption) => void;
}

const CustomDrivingExport: React.FC<Props> = ({ value, onChange }) => {
  return (
    <Form.Group>
      <Form.Check
        label="Dato"
        id="driving-timestamp"
        custom
        checked={value.timestamp}
        onChange={() => onChange({ ...value, timestamp: !value.timestamp })}
      />
      <Form.Check
        label="Total kørte KM"
        id="driving-totalKM"
        custom
        checked={value.totalKilometers}
        onChange={() =>
          onChange({ ...value, totalKilometers: !value.totalKilometers })
        }
      />
      <Form.Check
        label="Total kørte KM diagram"
        id="driving-totalKMChart"
        custom
        checked={value.totalKilometersChart}
        onChange={() =>
          onChange({
            ...value,
            totalKilometersChart: !value.totalKilometersChart,
          })
        }
      />
      <Form.Check
        label="Maks hastighed"
        id="driving-maxspeed"
        custom
        checked={value.maxSpeed}
        onChange={() => onChange({ ...value, maxSpeed: !value.maxSpeed })}
      />
      <Form.Check
        label="Gennemsnits hastighed"
        id="driving-avgspeed"
        custom
        checked={value.averageSpeed}
        onChange={() =>
          onChange({ ...value, averageSpeed: !value.averageSpeed })
        }
      />
      <Form.Check
        label="Tid i bevægelse (hastighed > 5 km/t)"
        id="driving-movement"
        custom
        checked={value.moving}
        onChange={() =>
          onChange({ ...value, moving: !value.moving })
        }
      />
    </Form.Group>
  );
};

export default CustomDrivingExport;

import { faArrowCircleLeft, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentLoading from "components/ContentLoading";
import DateSelector from "components/Coordinates/DateSelector";
import FormModal from "components/Modals/FormModal";
import NumberInput from "components/NumberInput";
import TimeSpanInput from "components/TimeSpanInput";
import { SavedOptions } from "components/Tracking/HistoryDateSelector";
import useLoading from "hooks/useLoading";
import useLocalStorage from "hooks/useLocalStorage";
import usePromise from "hooks/usePromise";
import TimeSpan from "models/timespan";
import Vehicle from "models/vehicle";
import React, { useCallback, useState } from "react";
import { Button, Col, FormCheck, Row } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import { HistoryTable } from "./HistoryTable";

const fiveDays = () => {
  const date = new Date();
  date.setDate(date.getDate() - 5);
  return date;
};
interface Params {
  id: string;
}

interface Props extends RouteComponentProps<Params> {}

export const Periodic = ({ match }: Props) => {
  const [from, setFrom] = useState<Date>(fiveDays());
  const [to, setTo] = useState<Date>(new Date());

  const [saved, setSaved] = useLocalStorage<SavedOptions | undefined>("History-Options", undefined);

  const [minDuration, setMinDuration] = useState(saved ? new TimeSpan(saved.minDuration) : new TimeSpan(0, 1));
  const [maxDistance, setMaxDistance] = useState(saved ? saved.maxDistance : 50);

  const request = useCallback(
    (abort?: AbortSignal) => Vehicle.Read(+match.params.id),
    [match.params.id]
  );
  const [loading, setLoading] = useLoading();

  const [vehicle] = usePromise(request, undefined, setLoading);

  const handleChange = (min: TimeSpan, maxDist: number, save: boolean) => {
    if(save){
      const seconds = min.toSeconds();
      setSaved({minDuration: seconds, maxDistance: maxDist});
    }
    
    setMinDuration(min);
    setMaxDistance(maxDist);
  }

  if (!vehicle) return null;

  return (
    <ContentLoading loading={loading}>
      <Link to="/history">
        <Button variant="secondary">
          <FontAwesomeIcon icon={faArrowCircleLeft} />
        </Button>
      </Link>
      <DateSelector
        from={from}
        to={to}
        onChange={(from, to) => {
          setFrom(from);
          setTo(to);
        }}
      />
      <AdvancedOptionsModal
        minDuration={minDuration}
        maxDistance={maxDistance}
        shouldSave={saved !== undefined}
        onChange={handleChange}
      />
      <div className="mobile-periodic-history">
        <HistoryTable vehicle={vehicle} from={from} to={to} maxDistance={maxDistance} minDuration={minDuration} />
      </div>
    </ContentLoading>
  );
};

type OptionProps = {
  minDuration: TimeSpan;
  maxDistance: number;
  shouldSave: boolean;
  onChange: (minDur: TimeSpan, maxMin: number, shouldSave: boolean) => void;
};
const AdvancedOptionsModal = ({
  minDuration,
  maxDistance,
  shouldSave,
  onChange,
}: OptionProps) => {
  const [minDurValue, setMinDurValue] = useState(minDuration);
  const [maxDistValue, setMaxDistValue] = useState(maxDistance);
  const [doSave, setDoSave] = useState(shouldSave);
  const save = (): Promise<boolean> => {
    onChange(minDurValue, maxDistValue, doSave);
    return Promise.resolve(true);
  };

  const toggleShouldSave = () => {
    setDoSave(prev => !prev);
  }

  return (
    <FormModal
      buttonText="Gem"
      onSubmit={save}
      title="Periode justeringer"
      icon={faCog}
      variant={"success"}
    >
      <Row>
        <Col>
          <label>
            Minimum pause varighed (min:sek)
            <TimeSpanInput
              disableHours
              value={minDurValue}
              onChange={setMinDurValue}
            />
          </label>
        </Col>
        <Col>
          <label>
            Maximum pause radius (meter)
            <NumberInput value={maxDistValue} onChange={setMaxDistValue} />
          </label>
        </Col>
        <FormCheck checked={doSave} onChange={toggleShouldSave} label="Husk mine indstillinger" />
      </Row>
    </FormModal>
  );
};

import MobileOverrideButton from "components/Mobile/MobileOverrideButton";
import React from "react";

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <div className="container-fluid main-container">
      {children}
      <MobileOverrideButton />
    </div>
  );
};

export default DefaultLayout;

import { jwtResponse } from "../models/jwtResponse";

export const authHeader = (
  method?: string,
  body?: any,
  abort?: AbortSignal
): RequestInit => {
  
  // return authorization header with jwt token
  const userJson =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  if (userJson) {
    const tokens: jwtResponse = JSON.parse(userJson);

    return {
      headers: { Authorization: `Bearer ${tokens.access_token}` },
      method: method,
      body: body,
      signal: abort,
    };
  } else {
    console.warn(
      "Tried using authentication header but access token is not set"
    );
    return { method: method, body: body, signal: abort };
  }
};

export const contentHeader = (
  method: string,
  body: any,
  contentType: string,
  abort: AbortSignal
): RequestInit => {
  const userJson =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  if (userJson) {
    const tokens: jwtResponse = JSON.parse(userJson);
    return {
      headers: {
        Authorization: `Bearer ${tokens.access_token}`,
        "Content-type": contentType,
      },
      method: method,
      body: body,
      signal: abort,
    };
  } else {
    return { method: method, body: body, signal: abort };
  }
};

export const jsonHeader = (
  method: string,
  body: any,
  abort?: AbortSignal
): RequestInit => {
  const userJson =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  let json = JSON.stringify(body);
  if (userJson) {
    const tokens: jwtResponse = JSON.parse(userJson);
    return {
      headers: {
        Authorization: `Bearer ${tokens.access_token}`,
        "Content-Type": "application/json",
      },
      method: method,
      body: json,
      signal: abort,
    };
  } else {
    return {
      headers: { "Content-Type": "application/json" },
      method: method,
      body: json,
      signal: abort,
    };
  }
};

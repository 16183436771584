import { ICompanyResource } from "models/company";
import { readFetch } from "../../helpers/fetchers";
import { basicSearch, basicSecSort } from "../../helpers/utils";
import ISearchable from "../../ISearchable";
import ISortable from "../../ISortable";
import { ITachoSafeTerminalResource } from "./terminal";

export interface ITachoSafeCompanyResource {
  id: number;
  name: string;
}

export type TachoSafeCompanyProps = "id" | "name";

export class TachoSafeCompanyResource
  implements
    ITachoSafeCompanyResource,
    ISortable<TachoSafeCompanyProps>,
    ISearchable<TachoSafeCompanyProps> {
  id: number;
  name: string;
  constructor(value: ITachoSafeCompanyResource) {
    this.id = value.id;
    this.name = value.name;
  }
  search = (value: any, type: TachoSafeCompanyProps): number | undefined =>
    basicSearch(this, value, type, false);
  compare = (
    value: ISortable<TachoSafeCompanyProps>,
    primary: TachoSafeCompanyProps,
    secondary?: TachoSafeCompanyProps
  ) => basicSecSort(this, value, primary, secondary);
}

export default class TachoSafeCompany {
  static ReadAll = async (
    abort?: AbortSignal
  ): Promise<ITachoSafeCompanyResource[]> =>
    (await readFetch(
      "api/TachoSafe/Companies",
      abort
    )) as ITachoSafeCompanyResource[];

  static ReadTerminals = (company: ICompanyResource, signal?: AbortSignal) => 
    readFetch<ITachoSafeTerminalResource[]>(`api/TachoSafe/Terminals/company/${company.id}`, signal) as Promise<ITachoSafeTerminalResource[]>
}

import { IVehicleResource } from "models/vehicle";
import React from "react";

export interface SelectedVehicleContextValue {
  vehicle?: IVehicleResource;
  setVehicle: (company?: IVehicleResource) => void;
}


const SelectedVehicleContext = React.createContext<SelectedVehicleContextValue>({
    vehicle: undefined,
    setVehicle: () => {},
  });
  
  export default SelectedVehicleContext;
import { IHistoryExportOption } from "../../models/exportOption";
import { Form } from "react-bootstrap";
import React from "react";

interface Props {
  value: IHistoryExportOption;
  onChange: (value: IHistoryExportOption) => void;
}

const CustomHistoryExport: React.FC<Props> = ({value, onChange}) => {
  return (
    <Form.Group>
      <Form.Check
        label="Køretøjs navn"
        id="history-alias"
        custom
        checked={value.vehicleAlias}
        onChange={() => onChange({ ...value, vehicleAlias: !value.vehicleAlias })}
      />
      <Form.Check
        label="Reg. nr."
        id="history-maxspeed"
        custom
        checked={value.vehicleLicensePlate}
        onChange={() => onChange({ ...value, vehicleLicensePlate: !value.vehicleLicensePlate })}
      />
      <Form.Check
        label="Aktivitet"
        id="history-activity"
        custom
        checked={value.activity}
        onChange={() => onChange({ ...value, activity: !value.activity })}
      />
      <Form.Check
        label="Start længde- og breddegrad"
        id="history-start-latlng"
        custom
        checked={value.startLatLng}
        onChange={() => onChange({ ...value, startLatLng: !value.startLatLng })}
      />
      <Form.Check
        label="Start dato"
        id="history-start-timestamp"
        custom
        checked={value.startTimestamp}
        onChange={() => onChange({ ...value, startTimestamp: !value.startTimestamp })}
      />
      <Form.Check
        label="Start adresse"
        id="history-start-address"
        custom
        checked={value.startAddress}
        onChange={() => onChange({ ...value, startAddress: !value.startAddress })}
      />
      <Form.Check
        label="Slut længde- og breddegrad"
        id="history-end-latlng"
        custom
        checked={value.endLatLng}
        onChange={() => onChange({ ...value, endLatLng: !value.endLatLng })}
      />
      <Form.Check
        label="Slut dato"
        id="history-end-timestamp"
        custom
        checked={value.endTimestamp}
        onChange={() => onChange({ ...value, endTimestamp: !value.endTimestamp })}
      />
      <Form.Check
        label="Slut adresse"
        id="history-end-address"
        custom
        checked={value.endAddress}
        onChange={() => onChange({ ...value, endAddress: !value.endAddress })}
      />
      <Form.Check
        label="Tid"
        id="history-duration"
        custom
        checked={value.duration}
        onChange={() => onChange({ ...value, duration: !value.duration })}
      />
      <Form.Check
        label="Kørt afstand i KM"
        id="history-distance"
        custom
        checked={value.distance}
        onChange={() => onChange({ ...value, distance: !value.distance })}
      />
      <Form.Check
        label="Maks hastighed"
        id="history-maxspeed"
        custom
        checked={value.maxSpeed}
        onChange={() => onChange({ ...value, maxSpeed: !value.maxSpeed })}
      />
    </Form.Group>
  );
};

export default CustomHistoryExport;
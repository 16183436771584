import React, { useCallback, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import useAbort from "../../hooks/useAbort";
import User, { IUserResource } from "../../models/user";

interface Props {
  user: IUserResource;
}

const UserSendPasswordReset: React.FC<Props> = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const abortSignal = useAbort();

  const resend = useCallback(async () => {
    try {
      setLoading(true);
      await User.ForgotPassword(user.email, abortSignal);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [user, abortSignal]);

  if (loading) return <Spinner size="sm" animation="border" />;

  return (
    <Button
      variant="warning"
      size="sm"
      title="Send password nulstilling"
      onClick={resend}
    >
      Send password nulstilling
    </Button>
  );
};

export default UserSendPasswordReset;

import React, { useCallback } from "react";
import { useLiveCoordinatesConnection } from "../../hooks/useLiveCoordinates";
import usePromise from "../../hooks/usePromise";
import useTitle from "../../hooks/useTitle";
import Vehicle from "../../models/vehicle";
import Vehicles from "./Index";
import TableError from "../TableError";
import useLoading from "../../hooks/useLoading";
import Bordered from "../Bordered";

const AllVehicles: React.FC = () => {
  const request = useCallback(
    (signal: AbortSignal) => Vehicle.ReadAll(signal),
    []
  );
  const [loading, setLoading] = useLoading();
  const [vehicles, error] = usePromise(request, [], setLoading);
  // const connection = useLiveCoordinatesConnection();
  useTitle("DigiFleet - Køretøjer");
  if (loading) {
    return <em>Loading...</em>;
  } else if (error) {
    return <TableError />;
  }
  return (
    <Bordered>
      <Vehicles.CompanyTable vehicles={vehicles} />
    </Bordered>
  );
};

export default AllVehicles;

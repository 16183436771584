import {
  faCheckSquare,
  faCrosshairs,
  faRoute,
  faSquare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Table } from "react-bootstrap";
import GeoRoute from "../../models/route";
import { IVehicleResource, VehicleResource } from "../../models/vehicle";
import PassivePagination from "../Pagination";
import { VehicleRouteSelector } from "./RouteSelector";

export interface ISelectedVehicle extends IVehicleResource {
  shown: boolean;
  pathShown: boolean;
}

export class SelectedVehicle
  extends VehicleResource
  implements ISelectedVehicle
{
  shown: boolean;
  pathShown: boolean;
  constructor(value: ISelectedVehicle) {
    super(value);
    this.shown = value.shown;
    this.pathShown = value.pathShown;
  }
}

interface Props {
  values: ISelectedVehicle[];
  onChange: (vehicle: ISelectedVehicle) => void;
  onZoom?: (vehicle: ISelectedVehicle) => void;
  onRouteOpen?: () => void;
  onRouteSelected?: (route: GeoRoute) => void;
  hidePath?: boolean;
}

const MapVehicleSelector: React.FC<Props> = ({
  values,
  onChange,
  onZoom,
  onRouteOpen,
  onRouteSelected,
  hidePath,
}) => (
  <Table size="sm">
    <thead>
      <tr>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <PassivePagination<ISelectedVehicle>
        items={values}
        defaultPageSize={5}
        showPages={2}
        renderItem={(item) => (
          <tr key={item.id} className={item.inActive ? "vehicle-select inactive" : "vehicle-select active"}>
            <td>
              <Button
                size="sm"
                variant={item.shown ? "success" : "secondary"}
                onClick={() => {
                  if (item.shown) {
                    onChange({ ...item, shown: false, pathShown: false });
                  } else {
                    onChange({ ...item, shown: true });
                  }
                }}
              >
                <FontAwesomeIcon icon={item.shown ? faCheckSquare : faSquare} />
              </Button>
              {item.alias || item.licensePlate}

              {!hidePath && (
                <Button
                  size="sm"
                  className="vehicle-control-button-right"
                  variant={item.pathShown ? "success" : "secondary"}
                  onClick={() =>
                    onChange({
                      ...item,
                      pathShown: !item.pathShown,
                      shown: !item.pathShown ? true : item.shown,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faRoute} />
                </Button>
              )}
              {onRouteSelected && (
                <VehicleRouteSelector
                  vehicle={item}
                  onOpen={onRouteOpen}
                  onSelect={onRouteSelected}
                />
              )}
              {onZoom && (
                <Button
                  size="sm"
                  title="Zoom til køretøj"
                  className="vehicle-control-button-right"
                  variant="secondary"
                  onClick={() => onZoom(item)}
                >
                  <FontAwesomeIcon icon={faCrosshairs} />
                </Button>
              )}
            </td>
          </tr>
        )}
      />
    </tbody>
  </Table>
);

export default MapVehicleSelector;

import React, { useCallback, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import { Link, RouteComponentProps } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import useSortedSearch from "../../hooks/useSortedSearch";
import LoginAttempt, { ILoginAttempt, LoginAttemptProps } from "../../models/loginAttempt";
import User, { IUserResource } from "../../models/user";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import Accounts from "./Index";

interface Props {
  user: IUserResource;
}

const LoginAttempts: React.FC<Props> = ({ user }) => {
  const request = useCallback(
    (signal: AbortSignal) => User.ReadLogins(user.id, signal),
    [user.id]
  );
  const [loading, setLoading] = useState(true);
  const [attempts] = usePromise(request, [], setLoading);

  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<LoginAttempt, LoginAttemptProps>(
    attempts.map(v => new LoginAttempt(v)),
    undefined,
    {
      type: "timeOfLogin",
      asc: false,
    }
  );

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Table striped responsive>
      <thead>
        <tr>
          <SearchableHeader
            value={search}
            types={["ip"]}
            onSearch={setSearch}
          />
          <th></th>
          <th></th>
          <SearchableHeader
            value={search}
            types={["userAgent"]}
            onSearch={setSearch}
          />
        </tr>
        <tr>
          <SortableHeader value={sort} type="ip" onChange={setSort}>
            IP-adresse
          </SortableHeader>
          <SortableHeader value={sort} type="success" onChange={setSort}>
            Success
          </SortableHeader>
          <SortableHeader value={sort} type="timeOfLogin" onChange={setSort}>
            Dato
          </SortableHeader>
          <SortableHeader value={sort} type="userAgent" onChange={setSort}>
            Browser
          </SortableHeader>
        </tr>
      </thead>
      <tbody>
        <PassivePagination<ILoginAttempt>
          id="attempts"
          sizeSelector={true}
          items={sorted}
          renderItem={(attempt) => (
            <tr key={attempt.id}>
              <td>{attempt.ip}</td>
              <td>{attempt.success ? "Ja" : "Nej"}</td>
              <td>
                <Moment local utc format="DD-MM-YYYY H:mm">
                  {attempt.timeOfLogin}
                </Moment>
              </td>
              <td>{attempt.userAgent}</td>
            </tr>
          )}
        />
      </tbody>
    </Table>
  );
};

export default LoginAttempts;

export const LoginAttemptsSummary: React.FC<Props> = ({ user }) => {
  const request = useCallback(
    (signal: AbortSignal) => User.ReadLoginSummary(user.id, signal),
    [user.id]
  );
  const [loading, setLoading] = useLoading();
  const [summary] = usePromise(request, undefined, setLoading);

  if (loading || !summary) {
    return <Spinner size="sm" animation="grow" />;
  }

  return <Link to={`user/logins/${user.id}`}>{summary.passwordLogins}</Link>;
};

interface MatchParams {
  id: string;
}
interface RouteProps extends RouteComponentProps<MatchParams> {}

export const LoginAttemptsIndex: React.FC<RouteProps> = ({ match }) => {
  const request = useCallback(
    (signal: AbortSignal) => User.Read(+match.params.id, signal),
    [match.params.id]
  );
  const [loading, setLoading] = useLoading();
  const [user] = usePromise(request, undefined, setLoading);

  if (loading || !user) {
    return <Spinner animation="border" />;
  }

  return <Accounts.LoginAttempts user={user} />;
};

import React from "react";
import { ITachoSafeCompanyResource } from "../../../models/tachoSafe/company";

interface Props {
  value?: ITachoSafeCompanyResource;
  values: ITachoSafeCompanyResource[];
  onChange: (value?: ITachoSafeCompanyResource) => void;
}

const Selector = ({ value, values, onChange }: Props) => (
  <select className="form-control w-auto" value={value?.id} onChange={event => {
      const id = +event.target.value;
      const company = values.find(v => v.id === id);
      onChange(company);
  }}>
      <option>Vælg TachoSafe virksomhed</option>
      {values.map(company => (
          <option key={company.id} value={company.id}>{company.name}</option>
      ))}
  </select>
);

export default Selector;

import React, { useCallback } from "react";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import TrackingUnit, { ITrackingUnitResource } from "../../models/trackingUnit";

interface Props {
  value?: ITrackingUnitResource;
  onChange: (trackignUnit: ITrackingUnitResource) => void;
}
const TrackingUnitSelector: React.FC<Props> = ({ value, onChange }) => {
  const request = useCallback(
    (signal: AbortSignal) => TrackingUnit.ReadAll(signal),
    []
  );
  const [loading, setLoading] = useLoading();
  const [trackingUnits] = usePromise(request, [], setLoading);

  return (
    <select
      className="form-control w-auto"
      onChange={(e) => {
        const val = +e.target.value;
        onChange(trackingUnits[val]);
      }}
    >
      {loading ? (
        <option>Loader enheder...</option>
      ) : (
        <option>Vælg en enhed</option>
      )}
      {trackingUnits.map((trackingUnit, index) => (
        <option
          key={trackingUnit.id}
          value={index}
          selected={value && value.id === trackingUnit.id}
        >
          {trackingUnit.id}
        </option>
      ))}
    </select>
  );
};

export default TrackingUnitSelector;

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Badge, Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import User, { IUserBinding, IUserResource } from "../../models/user";
import FormControlFeedback from "../FormControlFeedback";
import Users from "./Index";

interface Props {
  user: IUserResource;
  onUpdated: (user: IUserResource) => void;
}
const EditUser: React.FC<Props> = ({ user: usr, onUpdated }) => {
  const [user, setUser] = useState<IUserBinding>(usr);

  const [shown, setShown] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const saveChanges = async () => {
    try {
      setLoading(true);
      await User.Update(usr.id, user);
      setLoading(false);
      setShown(false);
      if (onUpdated) {
        onUpdated(User.ToResource(user, usr));
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  };

  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };

  return (
    <Fragment>
      <button
        className="blank-button sm"
        title="Rediger bruger"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Rediger bruger</Modal.Title>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Email</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  errors={error}
                  defaultValue={usr.email}
                  field="email"
                  onChange={(value: string) =>
                    setUser({ ...user, email: value })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Fornavn</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  errors={error}
                  defaultValue={usr.firstName}
                  field="firstName"
                  onChange={(value: string) =>
                    setUser({ ...user, firstName: value })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Efternavn</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  errors={error}
                  defaultValue={usr.lastName}
                  field="lastName"
                  onChange={(value: string) =>
                    setUser({ ...user, lastName: value })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Check
                label="Master-Administrator"
                id="company-demo"
                custom
                checked={user.isAdmin}
                onChange={() => setUser({ ...user, isAdmin: !user.isAdmin })}
              />
            </Form.Group>
            {user.isAdmin && (
              <Badge variant="warning">
                Bemærk: Denne bruger er sat til at være administrator for hele DigiFleet
              </Badge>
            )}
            {usr.active && (
              <div>
                <hr />
                <Users.SendPasswordReset user={usr} />
                <hr />
              </div>
            )}
            <Users.EditCompanies
              user={usr}
              onUpdated={(user) => {
                onUpdated(user);
                setUser(user);
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={loading} type="submit">
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setUser(usr);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default EditUser;

import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { ITachographParametersResource } from "./tachoSafe/tachographParameters";
import { IVehicleResource } from "./vehicle";

export interface IVehicleTachoResource {
  vehicle: IVehicleResource;
  tachograph?: ITachographParametersResource;
}
type TachoProps =
  | "tachograph.driver1Name"
  | "tachograph.driver1ContinuousDrivingTime"
  | "tachograph.driver1CumulativeBreakTime"
  | "tachograph.driver1BeginBreak"
  | "tachograph.driver1CurrentDailyDrivingTime"
  | "tachograph.driver1CurrentWeeklyDrivingTime"
  | "tachograph.driver2Name"
  | "tachograph.driver2ContinuousDrivingTime"
  | "tachograph.driver2CumulativeBreakTime"
  | "tachograph.driver2BeginBreak"
  | "tachograph.UpdatedAt";

type VehicleProps = "vehicle.alias" | "vehicle.licensePlate";
export type VehicleTachoProps = TachoProps | VehicleProps;
export class VehicleTachoResource
  implements
    IVehicleTachoResource,
    ISearchable<VehicleTachoProps>,
    ISortable<VehicleTachoProps> {
  vehicle: IVehicleResource;
  tachograph?: ITachographParametersResource;

  constructor(value: IVehicleTachoResource) {
    this.vehicle = value.vehicle;
    this.tachograph = value.tachograph;
  }

  search = <T>(value: T, type: VehicleTachoProps): number | undefined => {
    return basicSearch(this, value, type, false);
  };
  compare = (
    value: ISortable<VehicleTachoProps>,
    primary: VehicleTachoProps,
    secondary?: VehicleTachoProps
  ): number => basicSecSort(this, value, primary, secondary);
}

import { readFetch } from "../helpers/fetchers";
import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { IGPSCoordinate } from "./gpsCoordinate";
import { IVehicleResource } from "./vehicle";

export interface IVehicleCoordinateStatus {
  vehicle: IVehicleResource;
  coordinate?: IGPSCoordinate;
}

export type VehicleCoordinateStatusProps =
  | "vehicle.alias"
  | "vehicle.licensePlate"
  | "coordinate.timestamp";
export default class VehicleCoordinateStatus
  implements
    IVehicleCoordinateStatus,
    ISortable<VehicleCoordinateStatusProps>,
    ISearchable<VehicleCoordinateStatusProps>
{
  vehicle: IVehicleResource;
  coordinate?: IGPSCoordinate;
  constructor(value: IVehicleCoordinateStatus) {
    this.vehicle = value.vehicle;
    this.coordinate = value.coordinate;
  }
  static listAll = (abort?: AbortSignal) =>
    readFetch<IVehicleCoordinateStatus[]>("api/Vehicles/Status", abort);

  search = (
    value: VehicleCoordinateStatus,
    type: VehicleCoordinateStatusProps
  ): number | undefined => basicSearch(this, value, type, false);
  compare = (
    value: ISortable<VehicleCoordinateStatusProps>,
    primary: VehicleCoordinateStatusProps,
    secondary?: VehicleCoordinateStatusProps
  ): number => basicSecSort(this, value, primary, secondary);
}

import React, { useState, useCallback } from "react";
import { getYesterday } from "../../helpers/utils";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyResource } from "../../models/company";
import { ICoordinate } from "../../models/gpsCoordinate";
import TableError from "../TableError";
import ProximityForm, { ProximityFormContent } from "./ProximityForm";
import EncounterTable from "./Table";

interface Props {
  company: ICompanyResource;
}

const CompanyProximity: React.FC<Props> = ({ company }) => {
  const [search, setSearch] = useState<ProximityFormContent>({
    from: getYesterday(),
    to: new Date(),
  });

  return (
    <div>
      <p>
        <b>
          Vælg et tidsrum og en adresse for at se om et køretøj har været på
          adressen
        </b>
      </p>
      <ProximityForm value={search} onChange={setSearch} />
      {search && search.coordinate && (
        <ProximityView
          company={company}
          coordinate={search.coordinate}
          from={search.from}
          to={search.to}
        />
      )}
    </div>
  );
};

export default CompanyProximity;

interface ViewProps {
  company: ICompanyResource;
  coordinate: ICoordinate;
  from: Date;
  to: Date;
}

const ProximityView: React.FC<ViewProps> = ({
  company,
  coordinate,
  from,
  to,
}) => {
  const request = useCallback(
    (signal: AbortSignal) =>
      Company.ReadProximity(company, coordinate, from, to, signal),
    [company, coordinate, from, to]
  );
  const [loading, setLoading] = useLoading();
  const [encounters, error] = usePromise(request, [], setLoading);

  if (loading) {
    return <em>Loading...</em>;
  } else if (error) {
    return <TableError />;
  }

  if (encounters.length === 0) {
    return (
      <span>
        Ingen køretøjer har været i nærheden af denne adresse i det valgte
        tidsrum
      </span>
    );
  }

  return <EncounterTable encounters={encounters} />;
};

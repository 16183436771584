import React from "react";

const availableSizes = [10, 25, 50, 100];
interface Props {
  value?: number;
  onChange: (size: number) => void;
  rowCount?: number;
}
const PageSizeSelector: React.FC<Props> = ({ value, onChange, rowCount }) => {
  return (
    <div>
      <select
        className="form-control w-auto d-inline"
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
      >
        {availableSizes.map((size, index) => (
          <option key={index} value={size}>
            {size}
          </option>
        ))}
      </select>{" "}
      {rowCount
        ? `rækker pr. side, ${rowCount} rækker i alt`
        : "rækker pr. side"}
    </div>
  );
};

export default PageSizeSelector;

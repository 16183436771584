import { faCogs, faCrosshairs, faDatabase } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment, useState, useCallback } from "react";
import { Spinner, Table } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import useTitle from "../../hooks/useTitle";
import { ICompanyResource } from "../../models/company";
import { IServiceStatus, ServiceStatus } from "../../models/serviceStatus";
import Companies from "../Companies/Index";
import CoordinateStatus from "../Vehicles/CoordinateStatus/Index";
import AuthServiceModal from "./AuthServiceModal";
import CompanyDashboard from "./CompanyDashboard";
import { DashboardItemWithIcon } from "./Dashboard";
import {
  ServiceControlButton,
  ServiceStatusLabel,
  StatusTotals,
} from "./ServiceStatus";

const AdminDashboard: React.FC = () => {
  useTitle("DigiFleet - Dashboard");

  const totalReq = useCallback(
    (signal: AbortSignal) => ServiceStatus.ReadTotals(signal),
    []
  );

  const [loadingTotals, setLoadingTotals] = useLoading();
  const [totals] = usePromise(totalReq, undefined, setLoadingTotals);

  const ipcReq = useCallback(
    (signal: AbortSignal) => ServiceStatus.ReadIPCStatus(signal),
    []
  );

  const [loadingIpc, setLoadingIpc] = useLoading();
  const [ipcStatus, , setIpcStatus] = usePromise(
    ipcReq,
    undefined,
    setLoadingIpc
  );

  const emuReq = useCallback(
    (signal: AbortSignal) => ServiceStatus.ReadEmulatorStatus(signal),
    []
  );
  const [loadingEmulator, setLoadingEmulator] = useLoading();
  const [emulatorStatus, , setEmulatorStatus] = usePromise(
    emuReq,
    undefined,
    setLoadingEmulator
  );

  const authReq = useCallback(
    (signal: AbortSignal) => ServiceStatus.ReadAuthenticationStatus(signal),
    []
  );

  const [loadingAuthenticate, setLoadingAuthenticate] = useLoading();
  const [authenticationStatus, , setAuthenticationStatus] = usePromise(
    authReq,
    undefined,
    setLoadingAuthenticate
  );

  const [company, setCompany] = useState<ICompanyResource | undefined>();

  const changeIPCStatus = (status: IServiceStatus) => {
    const defaultIPCStatus = {
      restarter: { online: status.online },
      client: { online: status.online },
      queue: { online: status.online },
    };
    setIpcStatus({ ipc: status, ...defaultIPCStatus });
  };

  return (
    <div>
      <div className="dashboard-container">
        <DashboardItemWithIcon icon={faDatabase} dashTitle="Database tal">
          <div>
            {loadingTotals ? (
              <Spinner animation="border" />
            ) : (
              <Fragment>{totals && <StatusTotals totals={totals} />}</Fragment>
            )}
          </div>
        </DashboardItemWithIcon>
        <DashboardItemWithIcon icon={faCrosshairs} dashTitle="Koordinat status">
          <CoordinateStatus.All />
        </DashboardItemWithIcon>

        <DashboardItemWithIcon
          icon={faCogs}
          dashTitle="Service status"
          defaultOpen={true}
        >
          <Table size="sm" hover responsive>
            <tbody>
              <tr>
                <td>IPC:</td>
                <td>
                  {loadingIpc ? (
                    <Spinner animation="border" />
                  ) : (
                    <div>
                      <ServiceStatusLabel status={ipcStatus?.ipc} />
                      <br />
                      <Table size="sm" hover responsive>
                        <tbody>
                          <tr>
                            <td>Client:</td>
                            <td>
                              <ServiceStatusLabel status={ipcStatus?.client} />
                            </td>
                          </tr>
                          <tr>
                            <td>Send kø:</td>
                            <td>
                              <ServiceStatusLabel status={ipcStatus?.queue} />
                            </td>
                          </tr>
                          <tr>
                            <td>Genstarter:</td>
                            <td>
                              <ServiceStatusLabel
                                status={ipcStatus?.restarter}
                                invertColor
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  )}
                </td>
                <td>
                  <ServiceControlButton
                    className="service-button"
                    url="api/Status/services/ipc"
                    status={ipcStatus?.ipc}
                    onStatusChange={changeIPCStatus}
                    warning="Hvis du stopper denne service, vil DigiFleet ikke have forbindelse med IPC hubben og vil ikke have adgangs til information fra den (GPS, FlexDanmark, ...)"
                  />
                </td>
              </tr>
              <tr>
                <td>Enheds emulator:</td>
                <td>
                  {loadingEmulator ? (
                    <Spinner animation="border" />
                  ) : (
                    <ServiceStatusLabel status={emulatorStatus} />
                  )}
                </td>
                <td>
                  <ServiceControlButton
                    className="service-button"
                    url="api/Status/services/emulators"
                    status={emulatorStatus}
                    onStatusChange={setEmulatorStatus}
                  />
                </td>
              </tr>
              <tr>
                <td>Autentificering:</td>
                <td>
                  {loadingAuthenticate ? (
                    <Spinner animation="border" />
                  ) : (
                    <ServiceStatusLabel status={authenticationStatus} />
                  )}
                </td>
                <td>
                  <AuthServiceModal
                    status={authenticationStatus}
                    setStatus={setAuthenticationStatus}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </DashboardItemWithIcon>
      </div>
      <hr />
      <Companies.Selector value={company} onChange={setCompany} />
      {company && <CompanyDashboard key={company.id} company={company} />}
    </div>
  );
};
export default AdminDashboard;

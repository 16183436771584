import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { uploadFetch } from "../../helpers/fetchers";
import { IEmulatedUnit } from "../../models/emulatedUnit";
import TrackingUnitSelector from "../Tracking/TrackingUnitSelector";

interface Props {
  onCreated: (emulator: IEmulatedUnit) => void;
}
const CreateEmulator: React.FC<Props> = ({ onCreated }) => {
  const [shown, setShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [trackId, setTrackId] = useState("");
  const [file, setFile] = useState<File | undefined>();

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    } else {
      setFile(undefined);
    }
  };

  const saveChanges = async () => {
    try {
      if (!file || !trackId) return;
      setLoading(true);
      var fData = new FormData();
      fData.append("file", file as Blob);
      const unit = await uploadFetch<IEmulatedUnit>(
        "api/Emulator/" + trackId,
        fData
      );
      setShown(false);
      if (unit) {
        onCreated(unit);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };
  return (
    <Fragment>
      <Button variant="success" onClick={() => setShown(true)}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Opret emulator</Modal.Title>
          <Modal.Body>
            <div className="form-group">
              <TrackingUnitSelector
                onChange={(trackingUnit) =>
                  setTrackId(trackingUnit && trackingUnit.id)
                }
              />
            </div>

            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Spor fil</InputGroup.Text>
                </InputGroup.Prepend>
                <div className="custom-file">
                  <label className="custom-file-label">
                    {file && file.name}
                    <input
                      type="file"
                      className="custom-file-input"
                      id="emulator-file"
                      onChange={selectFile}
                    />
                  </label>
                </div>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              type="submit"
              disabled={!file || !trackId}
            >
              Opret
              {loading && <Spinner size="sm" animation="border" />}
            </Button>
            <Button variant="secondary" onClick={() => setShown(false)}>
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default CreateEmulator;

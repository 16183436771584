import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControlFeedback from "components/FormControlFeedback";
import React, { FormEvent, Fragment, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { ICompanyResource } from "../../models/company";
import TrackingUnit, { ITrackingUnitResource } from "../../models/trackingUnit";
import FormPhoneFeedback from "../FormPhoneFeedback";
import { UnitTypeSelector } from "../UnitType";
import TrackingUnits from "./Index";

interface Props {
  trackingUnit: ITrackingUnitResource;
  company?: ICompanyResource;
  onUpdated: (trackingUnit: ITrackingUnitResource) => void;
}

const Edit: React.FC<Props> = ({ trackingUnit: unit, onUpdated }) => {
  const [trackingUnit, setTrackingUnit] = useState(unit);
  const [error, setError] = useState<Error | undefined>();
  const [shown, setShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveChanges = async () => {
    try {
      setLoading(true);
      await TrackingUnit.Update(unit.id, trackingUnit);
      setShown(false);
      if (onUpdated) {
        onUpdated(TrackingUnit.ToResource(trackingUnit, unit));
      }
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };

  return (
    <Fragment>
      <button
        className="blank-button"
        title="Rediger enhed"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Rediger Tracking enhed</Modal.Title>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Track ID</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl defaultValue={trackingUnit.id} readOnly={true} />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Telefon nr.</InputGroup.Text>
                </InputGroup.Prepend>
                <FormPhoneFeedback
                  country="dk"
                  field="phoneNumber"
                  errors={error}
                  value={trackingUnit.phoneNumber}
                  onChange={(phone) =>
                    setTrackingUnit({ ...trackingUnit, phoneNumber: phone })
                  }
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>SIM-kort nummer</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  value={trackingUnit.iccId}
                  errors={error}
                  field="iccId"
                  onChange={(value: string) =>
                    setTrackingUnit({ ...trackingUnit, iccId: value })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Enheds type</InputGroup.Text>
                </InputGroup.Prepend>
                <UnitTypeSelector
                  value={trackingUnit.unitType}
                  onChange={(unitType) =>
                    setTrackingUnit({ ...trackingUnit, unitType })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Check
                label="Aktiv"
                id="unit-active"
                custom
                checked={trackingUnit.active}
                onChange={() =>
                  setTrackingUnit({
                    ...trackingUnit,
                    active: !trackingUnit.active,
                  })
                }
              />
            </Form.Group>
            <TrackingUnits.Config trackingUnit={unit} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={loading} type="submit">
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setTrackingUnit(unit);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default Edit;

import { abort } from "process";
import React from "react";
import { useCallback } from "react";
import useLoading from "../../hooks/useLoading"
import usePromise from "../../hooks/usePromise";
import CoordinateReflector from "../../models/coordinateReflector";
import ContentLoading from "../ContentLoading";
import TableError from "../TableError";
import Table from "./Table";


const All = (): React.ReactElement => {
    const [loading, setLoading] = useLoading(true);
    const request = useCallback((signal: AbortSignal) => CoordinateReflector.ReadAll(signal), []);

    const [reflectors, error] = usePromise(request, [], setLoading);

    if(error) return <TableError />
    return(
        <ContentLoading loading={loading}>
            <Table values={reflectors} />
        </ContentLoading>
    )
}

export default All;
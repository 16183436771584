import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControlFeedback from "components/FormControlFeedback";
import React, { FormEvent, Fragment, useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import Company, {
  ICompanyBinding,
  ICompanyResource,
} from "../../models/company";
import TrackingUnit, {
  ITrackingUnitResource,
  UnitType,
} from "../../models/trackingUnit";
import Vehicle, {
  IVehicleBinding,
  IVehicleResource,
} from "../../models/vehicle";
import Companies from "../Companies/Index";
import FormPhoneFeedback from "../FormPhoneFeedback";
import NotFoundMessage from "../NotFoundMessage";
import { UnitTypeSelector } from "../UnitType";

interface Props {
  unitId: string;
  onCreated?: (trackingUnit: ITrackingUnitResource) => void;
}

const defaultUnit: ITrackingUnitResource = {
  id: "",
  phoneNumber: "",
  unitType: UnitType.VT600,
  batteryLevel: 0,
  isConnected: false,
  active: true,
};
const CreateConnectedUnit: React.FC<Props> = ({ unitId, onCreated }) => {
  const [trackingUnit, setTrackingUnit] = useState<ITrackingUnitResource>({
    ...defaultUnit,
    id: unitId,
  });

  const [company, setCompany] = useState<ICompanyResource>();
  const [vehicle, setVehicle] = useState<IVehicleResource>();

  const [shown, setShown] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const [vehicleCreate, setVehicleCreate] = useState<
    IVehicleBinding | undefined
  >();
  const [companyCreate, setCompanyCreate] = useState<
    ICompanyBinding | undefined
  >();

  const saveChanges = async () => {
    try {
      //Create the tracking unit
      setLoading(true);
      const created = await TrackingUnit.Create(trackingUnit);
      //Assign the company if company is selected or a new is formulated
      if (company || companyCreate) {
        var assignCompany = company;
        if (companyCreate) {
          //Create the new company
          assignCompany = await Company.Create(companyCreate);
        }
        if (assignCompany) {
          //Assign the company
          await TrackingUnit.AssignCompany(created, assignCompany);
          created.company = assignCompany;
          //Assign the vehicle if a vehicle is selected or a new is formulated
          if (vehicle || vehicleCreate) {
            var assignVehicle = vehicle;
            if (vehicleCreate) {
              //Create the new vehicle
              const [veh, vehError] = await Vehicle.Create(
                assignCompany,
                vehicleCreate
              );
              if (veh) assignVehicle = veh;
              else return;
            }

            if (assignVehicle) {
              //Assign the vehicle
              await TrackingUnit.AssignVehicle(created, assignVehicle);
              created.vehicle = assignVehicle;
            }
          }
        }
      }
      setShown(false);
      if (onCreated) {
        onCreated(created);
      }
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };

  return (
    <Fragment>
      <Button title="Opret enhed" onClick={() => setShown(true)}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Opret Tracking enhed</Modal.Title>
          <Modal.Body>
            <NotFoundMessage error={error}>
              <Alert variant="warning">Fejl: Ikke fundet</Alert>
            </NotFoundMessage>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Track ID</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl defaultValue={trackingUnit.id} readOnly={true} />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Telefon nr.</InputGroup.Text>
                </InputGroup.Prepend>
                <FormPhoneFeedback
                  country="dk"
                  errors={error}
                  field="phoneNumber"
                  onChange={(phoneNumber) =>
                    setTrackingUnit({ ...trackingUnit, phoneNumber })
                  }
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>SIM-kort nummer</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  errors={error}
                  field="iccId"
                  onChange={(value: string) =>
                    setTrackingUnit({ ...trackingUnit, iccId: value })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Enhedstype</InputGroup.Text>
                </InputGroup.Prepend>
                <UnitTypeSelector
                  value={trackingUnit.unitType}
                  onChange={(unitType) =>
                    setTrackingUnit({ ...trackingUnit, unitType })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Fragment>
              <hr></hr>
              Eventuelt
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Virksomhed</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Companies.SelectorCreate
                    value={company}
                    error={error}
                    createValue={companyCreate}
                    onChange={setCompany}
                    onCreateChange={setCompanyCreate}
                  />
                </InputGroup>
              </Form.Group>
              {(company || companyCreate) && (
                <Form.Group>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Køretøj</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Companies.VehicleSelectorCreate
                      value={vehicle}
                      company={company}
                      error={error}
                      createValue={vehicleCreate}
                      onChange={setVehicle}
                      onCreateChange={setVehicleCreate}
                    />
                  </InputGroup>
                </Form.Group>
              )}
            </Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={loading} type="submit">
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setTrackingUnit(defaultUnit);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default CreateConnectedUnit;

import React, { Fragment, useReducer } from "react";
import { Table } from "react-bootstrap";
import useSortedSearch from "../../hooks/useSortedSearch";
import { ICompanyResource } from "../../models/company";
import { IUserResource, UserProps, UserResource } from "../../models/user";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import Users from "../Users/Index";
import Companies from "./Index";

interface Props {
  users: IUserResource[];
  company: ICompanyResource;
}

type Add = {
  readonly type: "ADD";
  user: IUserResource;
};
type Remove = {
  readonly type: "REMOVE";
  user: IUserResource;
};
type Update = {
  readonly type: "UPDATE";
  user: IUserResource;
};
type Replace = {
  readonly type: "REPLACE";
  users: IUserResource[];
};

type Actions = Add | Remove | Update | Replace;

const reducer = (users: IUserResource[], action: Actions): IUserResource[] => {
  switch (action.type) {
    case "ADD":
      return [...users, action.user];
    case "REMOVE":
      return users.filter((v) => v.id !== action.user.id);
    case "UPDATE":
      const index = users.findIndex(
        (u: IUserResource) => u.id === action.user.id
      );
      return [...users.slice(0, index), action.user, ...users.slice(index + 1)];
    case "REPLACE":
      return action.users;
  }
};

const CompanyUserTable: React.FC<Props> = ({ users: usrs, company }) => {

  const [users, dispatch] = useReducer(reducer, usrs);
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    UserResource, UserProps
  >(users.map(v => new UserResource(v)), undefined, {
    type: "firstName",
    fallback: "active",
    asc: true,
  });

  return (
    <Fragment>
      <Table striped responsive bordered>
        <thead>
          <tr>
            <SearchableHeader
              value={search}
              types={["firstName"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["lastName"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["email"]}
              onSearch={setSearch}
            />
            <th></th>
          </tr>
          <tr>
            <SortableHeader type="firstName" value={sort} onChange={setSort}>
              Fornavn
            </SortableHeader>
            <SortableHeader type="lastName" value={sort} onChange={setSort}>
              Efternavn
            </SortableHeader>
            <SortableHeader type="email" value={sort} onChange={setSort}>
              Email
            </SortableHeader>
            <th>
              <Companies.CreateUser
                company={company}
                onCreated={(user) => dispatch({ type: "ADD", user })}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<IUserResource>
            items={sorted}
            id="users"
            sizeSelector={true}
            renderItem={(item) => (
              <tr key={item.id}>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.email}</td>
                <td>
                  <Users.Unassign
                    user={item}
                    company={company}
                    onUpdated={(user) => dispatch({ type: "REMOVE", user })}
                  />
                </td>
              </tr>
            )}
          />
        </tbody>
      </Table>
    </Fragment>
  );
};

export default CompanyUserTable;

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { isVehicle } from "../../helpers/instanceOf";
import { GeoFeature } from "../../models/geoAddress";
import { IVehicleResource } from "../../models/vehicle";
import AddressSelector from "../AddressSelector";
import Users from "../Users/Index";

interface Props {
  onChange: (value: IVehicleResource | GeoFeature | undefined) => void;
  value: IVehicleResource | GeoFeature | undefined;
}

const RoutePositionSelector: React.FC<Props> = ({ onChange, value }) => {
  if (value) {
    return (
      <div>
        <div>
          {isVehicle(value) ? (
            <div>
                {value.alias ? `${value.alias}, ${value.licensePlate}` : value.licensePlate}
            </div>
          ) : (
            <div>{value.place_name}</div>
          )}
        </div>
        <Button variant="secondary" onClick={() => onChange(undefined)}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Users.VehicleSelector onChange={(vehicle) => onChange(vehicle)} />
      <em>eller</em>
      <AddressSelector onChange={(addr) => onChange(addr)} />
    </div>
  );
};

export default RoutePositionSelector;

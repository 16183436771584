import { HubConnection } from "@microsoft/signalr";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { useLiveCoordinatesFromConnection } from "../../hooks/useLiveCoordinates";
import useLiveTrackerStatus, { IStatus, useLiveTrackerStatusFromConnection } from "../../hooks/useLiveTrackerStatus";
import { IGPSCoordinate } from "../../models/gpsCoordinate";
import { ITrackingUnitResource } from "../../models/trackingUnit";
import { BatteryLevelIndicator } from "../BatteryLevelIndicator";
import QRModal from "../QRCodes/QRModal";
import { UnitTypeLabel } from "../UnitType";
import Vehicles from "../Vehicles/Index";
import OnlineStatus from "../Vehicles/OnlineStatus";
import ConnectionStatus from "./ConnectionStatus";
import TrackingUnits from "./Index";

interface Props {
  value: ITrackingUnitResource;
  trackerConnection?: HubConnection;
  coordinateConnection?: HubConnection;
  onUpdate: (updated: ITrackingUnitResource) => void;
  onDelete: (deleted: ITrackingUnitResource) => void;
}

const TrackingUnitRow = ({ value, onUpdate, onDelete, trackerConnection, coordinateConnection }: Props) => {
  const className = useMemo(() => (value.active ? "" : "inactive"), [value]);

  return (
    <tr className={className}>
      <td>{value.id}</td>
      {value.company ? (
        <td>
          <TrackingUnits.UnassignCompany
            trackingUnit={value}
            onUpdated={onUpdate}
          />
          <Link to={`/company/${value.company.id}`}>{value.company.name}</Link>
        </td>
      ) : (
        <td>
          <TrackingUnits.AssignCompany
            trackingUnit={value}
            onUpdated={onUpdate}
          />
        </td>
      )}
      {value.vehicle ? (
        <td>
          <TrackingUnits.UnassignVehicle
            trackingUnit={value}
            onUpdated={onUpdate}
          />
          {value.vehicle.licensePlate}, <b>{value.vehicle.alias}</b>
        </td>
      ) : (
        <Fragment>
          {value.company ? (
            <td>
              <TrackingUnits.AssignVehicle
                trackingUnit={value}
                onUpdated={onUpdate}
              />
            </td>
          ) : (
            <td></td>
          )}
        </Fragment>
      )}
      <td>
        {(value.phoneNumber && value.phoneNumber.trim()) ? value.phoneNumber : "-"}
        <br />
        {value.iccId}
        </td>
      <td>
        {value.lastSeen && (
          <Moment local utc format="DD-MM-YYYY HH:mm:ss">
            {value.lastSeen}
          </Moment>
        )}
      </td>
      <td>
        <UnitTypeLabel unitType={value.unitType} />
      </td>
      <td>
        <BatteryLevelIndicator
          batteryLevel={value.batteryLevel}
          warningLevel={40}
          dangerLevel={20}
        />
      </td>
      <td>{value.active ? "Ja" : "Nej"}</td>
      <td><ConnectionStatus connection={trackerConnection} unit={value} /></td>
      <td>
        {value.vehicle && <OnlineStatus connection={coordinateConnection} vehicle={value.vehicle} />}
      </td>
      <td>
        <QRModal trackingUnit={value} />
      </td>
      <td>
        <TrackingUnits.Edit trackingUnit={value} onUpdated={onUpdate} />
      </td>
      <td>
        <TrackingUnits.Delete trackingUnit={value} onDeleted={onDelete} />
      </td>
    </tr>
  );
};

export default TrackingUnitRow;

import React from "react";
import { IVehicleResource } from "../../models/vehicle";

interface Props{
    value: IVehicleResource
}

const Label = ({value}: Props): React.ReactElement => (
    <span>
        {value.alias ? `${value.alias}, ${value.licensePlate}` : value.licensePlate}
    </span>
)

export default Label;
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { ICompanyResource } from "../../models/company";
import { IVehicleBinding, IVehicleResource } from "../../models/vehicle";
import Companies from "../Companies/Index";
import Vehicles from "./Index";

interface Props {
  value?: IVehicleResource;
  vehicles: IVehicleResource[];
  defaultCreate?: boolean;
  error?: Error;
  onChange: (vehicle?: IVehicleResource) => void;
  createValue?: IVehicleBinding;
  onCreateChange: (vehicle?: IVehicleBinding) => void;
}

const defaultVehicle: IVehicleBinding = {
  alias: "",
  licensePlate: "",
  icon: "truck",
  color: "black"
};

const VehicleSelectorCreate: React.FC<Props> = ({
  value,
  vehicles,
  defaultCreate,
  error,
  onChange,
  createValue,
  onCreateChange,
}) => {
  const [create, setCreate] = useState(defaultCreate);

  const toggleCreate = useCallback(() => {
    const flip = !create;
    if (flip) {
      onCreateChange(undefined);
    } else {
      onChange(undefined);
    }
    setCreate(flip);
  }, [onChange, onCreateChange, create]);

  if (create) {
    return (
      <Fragment>
        <Button title="Luk oprettelse" onClick={() => toggleCreate()}>
          <FontAwesomeIcon icon={faMinus} />
        </Button>
        <div className="vehicles">
          <b>Opret køretøj</b>
          <Vehicles.Form
            value={createValue || defaultVehicle}
            onChange={onCreateChange}
          />
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Vehicles.Selector
        vehicles={vehicles}
        value={value}
        onChange={onChange}
      />
      <Button title="Opret køretøj" onClick={() => toggleCreate()}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </Fragment>
  );
};

export default VehicleSelectorCreate;

interface CompanyProps {
  company?: ICompanyResource;
  value?: IVehicleResource;
  defaultCreate?: boolean;
  error?: Error;
  onChange: (vehicle?: IVehicleResource) => void;
  createValue?: IVehicleBinding;
  onCreateChange: (vehicle?: IVehicleBinding) => void;
  exclude?: IVehicleResource[];
}

export const CompanyVehicleSelectorCreate: React.FC<CompanyProps> = ({
  value,
  company,
  defaultCreate,
  error,
  onChange,
  createValue,
  onCreateChange,
  exclude,
}) => {
  const [create, setCreate] = useState(defaultCreate);

  const toggleCreate = useCallback(() => {
    const flip = !create;
    if (flip) {
      onCreateChange(undefined);
    } else {
      onChange(undefined);
    }
    setCreate(flip);
  }, [onChange, onCreateChange, create]);

  if (create) {
    return (
      <Fragment>
        <Button title="Luk oprettelse" onClick={() => toggleCreate()}>
          <FontAwesomeIcon icon={faMinus} />
        </Button>
        <div className="vehicles">
          <b>Opret køretøj</b>
          <Vehicles.Form
            value={createValue || defaultVehicle}
            onChange={onCreateChange}
          />
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {company && company.id !== 0 && (
        <Companies.VehicleSelector
          company={company}
          value={value}
          onChange={onChange}
        />
      )}
      <Button title="Opret køretøj" onClick={() => toggleCreate()}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </Fragment>
  );
};

import React from "react";
import { useCallback } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import useLoading from "../../../hooks/useLoading";
import usePromise from "../../../hooks/usePromise";
import SEGESCompany, {
  ISEGESCompanyResource,
} from "../../../models/segesCompany";
import ContentLoading from "../../ContentLoading";
import Table from "./Table";

const All = () => {
  const [loading, setLoading] = useLoading();

  const request = useCallback(
    (signal: AbortSignal) => SEGESCompany.ReadAll(signal),
    []
  );
  const [values, , setValues] = usePromise(request, [], setLoading);

  const created = (value: ISEGESCompanyResource) => {
    setValues((prev) => [...prev, value]);
  };
  const deleted = (value: ISEGESCompanyResource) => {
    setValues((prev) => prev.filter((v) => v.companyId !== value.companyId));
  };

  return (
    <ContentLoading loading={loading}>
      <h3>Virksomheder der er registeret hos Landbrug og Fødevarer SEGES</h3>
      <Alert variant="warning">
        Bemærk: Virksomhedens køretøjer skal være oprettet som <Link to="/seges/vehicles">SEGES køretøjer</Link> for at sende koordinater
      </Alert>
      <div className="companies">
        <Table values={values} onCreated={created} onDeleted={deleted} />
      </div>
    </ContentLoading>
  );
};
export default All;

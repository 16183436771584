import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import ISortable from "../ISortable";

export interface SortValue<T>{
  type: T;
  fallback?: T;
  asc: boolean;
}
interface Props<T> {
  type: T;
  fallback?: T;
  onChange: (value: SortValue<T>) => void;
  value?: SortValue<T>;
  invert?: boolean;
  children?: React.ReactNode;
}

const SortableHeader = <T extends any>({
  type,
  value,
  fallback,
  onChange,
  invert,
  children,
}: Props<T>) => {
  const selected = useMemo(() => value && value.type === type, [value, type]);
  const icon = useMemo(() => {
    var icon = faSort;
    if(selected && value){
      if(value.asc === !(invert || false)){
        icon = faSortDown;
      }
      else{
        icon = faSortUp;
      }
    }
    return icon;
  }, [value, selected, invert]);

  const buttonClass = useMemo(() => selected ? "sort-link active" : "sort-link", [selected]);
  return (
    <th>
      <button
        title="Sorter"
        className={buttonClass}
        onClick={() => {
          if (value) {
            const flip = type === value.type ? !value.asc : !invert;
            onChange({ type: type, asc: flip, fallback });
          }
        }}
      >
        {children}
        <FontAwesomeIcon
            icon={icon}
          />
      </button>
    </th>
  );
};
export default SortableHeader;

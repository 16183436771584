import React, { useEffect, useReducer } from "react";
import { Table } from "react-bootstrap";
import useSortedSearch from "../../hooks/useSortedSearch";
import Quota, { IQuota, QuotaProps } from "../../models/quota";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import EditQuota from "./EditQuota";
import QuotaUsage from "./QuotaUsage";

interface Props {
  quotas: IQuota[];
}

type Add = {
  readonly type: "ADD";
  quota: IQuota;
};
type Remove = {
  readonly type: "REMOVE";
  quota: IQuota;
};
type Update = {
  readonly type: "UPDATE";
  quota: IQuota;
};

type Replace = {
  readonly type: "REPLACE";
  quotas: IQuota[];
};

type Actions = Add | Remove | Update | Replace;

const reducer = (quotas: IQuota[], action: Actions): IQuota[] => {
  switch (action.type) {
    case "ADD":
      return [...quotas, action.quota];
    case "REMOVE":
      return quotas.filter((v) => v.id !== action.quota.id);
    case "UPDATE":
      const index = quotas.findIndex((v: IQuota) => v.id === action.quota.id);
      return [
        ...quotas.slice(0, index),
        action.quota,
        ...quotas.slice(index + 1),
      ];

    case "REPLACE":
      return action.quotas;
  }
};

const QuotaTable: React.FC<Props> = ({ quotas: qu }) => {
  useEffect(() => dispatch({ type: "REPLACE", quotas: qu }), [qu]);
  const [quotas, dispatch] = useReducer(reducer, qu);

  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<Quota, QuotaProps>(
    quotas.map(v => new Quota(v)),
    undefined,
    {
      type: "company.name",
      asc: true,
    }
  );

  return (
    <div className="quotas">
      <Table striped responsive bordered>
        <thead>
          <tr>
            <SearchableHeader
              value={search}
              types={["company.name"]}
              onSearch={setSearch}
            />
            <th></th>
          </tr>
          <tr>
            <SortableHeader value={sort} type="company.name" onChange={setSort}>
              Virksomhed
            </SortableHeader>
            <th>Brugere</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination
            items={sorted}
            renderItem={(item) => (
              <tr key={item.id}>
                <td>{item.company.name}</td>
                <td>
                  <QuotaUsage
                    available={item.users}
                    used={item.usersUsed}
                    assigned={item.assigned}
                  />
                </td>
                <td>
                  <EditQuota
                    quota={item}
                    onUpdated={(quota) => dispatch({ type: "UPDATE", quota })}
                  />
                </td>
              </tr>
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default QuotaTable;

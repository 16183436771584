import { HubConnection } from "@microsoft/signalr";
import { IVehicleResource } from "models/vehicle";
import React from "react";

export interface ConnectionContextValue {
  connection?: HubConnection
}


const ConnectionContext = React.createContext<ConnectionContextValue>({
    connection: undefined
  });
  
  export default ConnectionContext;
/**
 * Represents a point in time or a duration
 */
export default class TimeSpan {
  private _hours: number;
  private _minutes: number;
  private _seconds: number;

  constructor();
  constructor(seconds: number);
  constructor(seconds: number, minutes: number);
  constructor(seconds: number, minutes: number, hours: number);
  constructor(seconds?: number, minutes?: number, hours?: number) {
    this._hours = 0;
    this._minutes = 0;
    this._seconds = 0;

    this.hours = hours ?? 0;
    this.minutes = minutes ?? 0;
    this.seconds = seconds ?? 0;
  }

  public get hours() {
    return this._hours;
  }
  public set hours(hours: number) {
    this._hours = hours;
  }
  public get minutes() {
    return this._minutes;
  }
  public set minutes(minutes: number) {
    this._minutes = TimeSpan.mod(minutes, 60);
    this.hours += (minutes - this._minutes) / 60;
  }
  public get seconds() {
    return this._seconds;
  }
  public set seconds(seconds: number) {
    this._seconds = TimeSpan.mod(seconds, 60);
    this.minutes += (seconds - this._seconds) / 60;
  }

  public isZero = () =>
    this._hours === 0 && this._minutes === 0 && this._seconds === 0;

  public toString = () => {
    const minuteFormatted = TimeSpan.TwoDigitLeadZeroFormat(this.minutes);
    const secondFormatted = TimeSpan.TwoDigitLeadZeroFormat(this.seconds);
    return `${this.hours}:${minuteFormatted}:${secondFormatted}`;
  };
  public toSeconds = () => {
    const hourMinutes = this.hours * 60;
    const minuteHourSeconds = (this.minutes + hourMinutes) * 60;
    return this.seconds + minuteHourSeconds;
  };

  public equals = (other: TimeSpan) =>
    this.hours === other.hours &&
    this.minutes === other.minutes &&
    this.seconds === other.seconds;

  private static mod = (n: number, m: number): number => {
    return ((n % m) + m) % m;
  };
  public static TwoDigitLeadZeroFormat = (value: number) => {
    const isOneDigit = value < 10 && value > -10;
    if (value >= 0) {
      return isOneDigit ? `0${value}` : value;
    } else {
      return isOneDigit ? `-0${value}` : value;
    }
  };
}

import { createFetch, deleteFetch, readFetch } from "../helpers/fetchers";
import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { ICompanyResource } from "./company";

export interface ISEGESCompanyResource {
  companyId: number;
  company?: ICompanyResource;
  authorizationNumber: string;
}
export interface ISEGESCompanyBinding {
  company?: ICompanyResource;
  authorizationNumber: string;
}
type CompanyProps = "company.name" | "company.id";
export type SEGESCompanyProps =
  | "companyId"
  | "authorizationNumber"
  | CompanyProps;
export class SEGESCompanyResource
  implements
    ISEGESCompanyResource,
    ISortable<SEGESCompanyProps>,
    ISearchable<SEGESCompanyProps> {
  companyId: number;
  company?: ICompanyResource | undefined;
  authorizationNumber: string;
  constructor(value: ISEGESCompanyResource) {
    this.companyId = value.companyId;
    this.authorizationNumber = value.authorizationNumber;
    this.company = value.company;
  }

  search = <T>(value: T, type: SEGESCompanyProps): number | undefined => {
    switch (type) {
      case "company.id":
      case "companyId":
        return basicSearch(this, value, type, true);
      default:
        return basicSearch(this, value, type, false);
    }
  };

  compare = (
    value: ISortable<SEGESCompanyProps>,
    primary: SEGESCompanyProps,
    secondary?: SEGESCompanyProps
  ): number => basicSecSort(this, value, primary, secondary);
}


export default class SEGESCompany{
    static Create = async (
        binding: ISEGESCompanyBinding,
        abort?: AbortSignal
      ): Promise<ISEGESCompanyResource> =>
        (await createFetch(
          "api/SEGES/companies",
          binding,
            abort
        )) as ISEGESCompanyResource;
    
      static ReadAll = async (abort?: AbortSignal): Promise<ISEGESCompanyResource[]> =>
        (await readFetch("api/SEGES/companies", abort)) as ISEGESCompanyResource[];
    
      static Delete = async (
        company: ISEGESCompanyResource,
        abort?: AbortSignal
      ): Promise<void> => {
        deleteFetch(`api/SEGES/companies/${company.companyId}`, abort);
      };
}

import { createFetch, deleteFetch, readFetch } from "../helpers/fetchers";
import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { IVehicleResource } from "./vehicle";

export interface ICoordinateReflectorResource{
    fromId: number,
    toId: number,
    from?: IVehicleResource,
    to?: IVehicleResource
}

export interface ICoordinateReflectorBinding{
    from?: IVehicleResource,
    to?: IVehicleResource
}

export type CoordinateReflectorProps = "fromId" | "toId" | "from.alias" | "from.licensePlate" | "to.alias" | "to.licensePlate";
export default class CoordinateReflector implements ICoordinateReflectorResource, ISortable<CoordinateReflectorProps>, ISearchable<CoordinateReflectorProps>{
    fromId: number;
    toId: number;
    from?: IVehicleResource;
    to?: IVehicleResource;

    constructor(value: ICoordinateReflectorResource){
        this.fromId = value.fromId;
        this.toId = value.toId;
        this.from = value.from;
        this.to = value.to;
    }
    search = (value: any, type: CoordinateReflectorProps): number | undefined => 
        basicSearch(this, value, type, false);
    compare = (value: ISortable<CoordinateReflectorProps>, primary: CoordinateReflectorProps, secondary?: CoordinateReflectorProps): number => 
        basicSecSort(this, value, primary, secondary);


    static Create = async (
        reflector: ICoordinateReflectorBinding,
        abort?: AbortSignal
        ): Promise<ICoordinateReflectorResource> =>
        (await createFetch("api/Reflectors/", reflector, abort)) as ICoordinateReflectorResource;
    static Read = async (
        id: number,
        abort?: AbortSignal
        ): Promise<ICoordinateReflectorResource> =>
        (await readFetch("api/Reflectors/" + id, abort)) as ICoordinateReflectorResource;
    
    static ReadAll = async (abort?: AbortSignal): Promise<ICoordinateReflectorResource[]> =>
    (await readFetch("api/Reflectors", abort)) as ICoordinateReflectorResource[];

        
    static Delete = async (
        reflector: ICoordinateReflectorResource,
        abort?: AbortSignal
        ): Promise<void> => {
        await deleteFetch(`api/Reflectors/${reflector.toId}`, abort);
        };

}
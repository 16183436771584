import { SearchValue } from "../components/SearchableHeader";
import { useState, useMemo } from "react";
import ISearchable from "../ISearchable";

const useSearch = <T extends ISearchable<TType>, TType>(
  initialItems: T[],
  initialSearch?: SearchValue<TType>
): [
  T[],
  SearchValue<TType> | undefined,
  (search?: SearchValue<TType> | undefined) => void
] => {
  const [search, setSearch] = useState<SearchValue<TType> | undefined>(
    initialSearch
  );

  const resultItems = useMemo(() => searchItems(initialItems, search), [
    initialItems,
    search,
  ]);

  return [resultItems, search, setSearch];
};

export default useSearch;

interface searchMapValue<T> {
  value: T;
  compare: number;
}

export const searchItems = <T extends ISearchable<TType>, TType>(
  items: T[],
  search?: SearchValue<TType>
) => {
  if (!search || !search.value) return items;

  const expected =
    typeof search.value === "string"
      ? search.value.toLowerCase().replace(/\s+/g, "")
      : search.value;

  const result: searchMapValue<T>[] = [];

  for (let item of items) {
    // Find the best possible comparison in given types
    let best: searchMapValue<T> | undefined = undefined;
    for (var sType of search.types) {
      const compare = item.search(expected, sType);
      if (compare !== undefined) {
        const sMap: searchMapValue<T> = {
          value: item,
          compare: compare,
        };
        if (best === undefined || sMap.compare < best.compare) {
          best = sMap;
        }
        // Break the loop if the compare value is 0, and as such cant be any better
        if(sMap.compare === 0) break;
      }
    }
    if(best){
      if(search.exact && best.compare !== 0){
        continue;
      }
      result.push(best);
    }
  }

  // Sort the results by their comparison distance
  // result.sort((a, b) => {
  //   return a.compare - b.compare;
  // });

  const bestCompare = Math.min(...result.map(r => r.compare));

  return result.filter(r => r.compare === bestCompare).map((r) => r.value);
};

/**
 * Checks that a value matches an expected value
 * If the value is an array, it checks recursively against every item until a match is found
 */
const checkSearch = (expected: any, actual: any, exact: boolean) => {
  //Check if the value is an array
  if (Array.isArray(actual)) {
    //Search through arrays
    for (var aIndex in actual) {
      const aValue = actual[aIndex];
      //Check if the array value matches the expected value
      if (checkSearch(expected, aValue, exact)) {
        return true;
      }
    }
    return false;
  }
  if (exact) return expected === actual;

  if (typeof actual === "number") actual = actual.toString();
  if (typeof actual !== "undefined" && actual !== null) {
    return actual.toLowerCase().replace(/\s+/g, "").indexOf(expected) !== -1;
  }
  return false;
};

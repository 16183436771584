import { useState } from "react";

interface state {
    from: Date,
    to: Date
}

const useDateRange = (
  initialFrom: Date,
  initialTo: Date
): [Date, Date, (from: Date, to: Date) => void] => {
    const [range, setRange] = useState<state>({from: initialFrom, to: initialTo});
    
    const changeRange = (from: Date, to: Date) => {
        if(from > to){
            throw Error("'From' date can't occur after 'to' date")
        }
        setRange({from, to});
    }


    return [range.from, range.to, changeRange];
};
export default useDateRange;

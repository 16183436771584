import useLocalStorage from "hooks/useLocalStorage";
import React, { useState } from "react";
import { Alert, Button, Col, FormCheck, Row } from "react-bootstrap";
import { getHTML5DateTimeStringsFromDate } from "../../helpers/utils";
import TimeSpan from "../../models/timespan";
import Coordinates from "../Coordinates/Index";
import Descriptor from "../Descriptor";
import NumberInput from "../NumberInput";
import TimeSpanInput from "../TimeSpanInput";
import ToggleSection from "../ToggleSection";

interface Props {
  from: Date;
  to: Date;
  minDuration: TimeSpan;
  maxDistance: number;
  onChange: (
    from: Date,
    to: Date,
    minDuration: TimeSpan,
    maxDistance: number
  ) => void;
}

export type SavedOptions = {
  minDuration: number,
  maxDistance: number,
}

const HistoryDateSelector: React.FC<Props> = ({
  from: fromDate,
  to: toDate,
  minDuration: minDur,
  maxDistance: maxDist,
  onChange,
}) => {
  const [from, setFrom] = useState(fromDate);
  const [to, setTo] = useState(toDate);

  const [saved, setSaved] = useLocalStorage<SavedOptions | undefined>("History-Options", undefined);
  const [shouldSave, setShouldSave] = useState<boolean>(saved !== undefined);

  const [minDuration, setMinDuration] = useState(saved ? new TimeSpan(saved.minDuration) : minDur);
  const [maxDistance, setMaxDistance] = useState(saved ? saved.maxDistance : maxDist);

 

  const search = () => {
    onChange(from, to, minDuration, maxDistance);
  };

  const handleSaveChanged = () => {
    
    if(!shouldSave){
      const seconds = minDuration.toSeconds();
      // Save changes if it would flip.
      setSaved({minDuration: seconds, maxDistance});
    }
    else{
      setSaved(undefined);
    }
    setShouldSave(prev => !prev);
  }

  const handleTimeChange = (value: TimeSpan) => {
    if(saved){
      setSaved({...saved, minDuration: value.toSeconds()});
    }

    setMinDuration(value);
  }

  const handleDistanceChange = (value: number) => {
    if(saved){
      setSaved({...saved, maxDistance: value});
    }

    setMaxDistance(value);
  }

  const changed =
    from !== fromDate ||
    to !== toDate ||
    !minDuration.equals(minDur) ||
    maxDistance !== maxDist;
  return (
    <div className="history-date-selector">
      <b>Vælg et tidsrum for at se hvor det valgte køretøj har</b>
      <Row>
        <Col>
          <label>
            Fra dato
            <input
              type="datetime-local"
              max={getHTML5DateTimeStringsFromDate(to)}
              value={getHTML5DateTimeStringsFromDate(from)}
              className="form-control"
              onChange={(e) => setFrom(new Date(e.target.value))}
            />
          </label>
        </Col>
        <Col>
          <label>
            Til dato
            <input
              type="datetime-local"
              min={getHTML5DateTimeStringsFromDate(from)}
              value={getHTML5DateTimeStringsFromDate(to)}
              className="form-control"
              onChange={(e) => setTo(new Date(e.target.value))}
            />
          </label>
        </Col>
      </Row>
      <Coordinates.DateSelectorButtons
        onChange={(from, to) => {
          setFrom(from);
          setTo(to);
        }}
      />

      <div style={{ marginTop: "0.5em" }}>
        <ToggleSection
          title="Avancerede søge indstillinger"
          variant="primary"
          value="Avanceret"
        >
          <Row>
            <Col>
              <label>
                Minimum pause varighed (min:sek)
                <Descriptor title="Bestemmer den minimale varighed af en periode der kan vurderes som en pause" />
                <TimeSpanInput
                  disableHours
                  value={minDuration}
                  onChange={handleTimeChange}
                />
              </label>
            </Col>
            <Col>
              <label>
                Maximum pause radius (meter)
                <Descriptor title="Bestemmer den maximale radius, hvor koordinater inden for vil blive beregnet som en del af en pause" />
                <NumberInput value={maxDistance} onChange={handleDistanceChange} />
              </label>
            </Col>
          </Row>
          <FormCheck checked={shouldSave} onChange={handleSaveChanged} label="Husk mine indstillinger" />
          <Alert variant="warning">
            BEMÆRK: Disse funktioner fungere i øjeblikket ikke altid helt
            rigtigt, rettelse er på vej
          </Alert>
        </ToggleSection>
      </div>

      <hr />
      <Button variant="success" onClick={() => search()} disabled={!changed}>
        Søg
      </Button>
    </div>
  );
};

export default HistoryDateSelector;

import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, useState } from "react";
import { Badge, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import User from "../../models/user";
import FormControlFeedback from "../FormControlFeedback";
import Centered from "./Centered";

interface MatchParams {
  token: string;
}
interface Props extends RouteComponentProps<MatchParams> {}

const FinishRegistration: React.FC<Props> = ({ match }) => {
  const token = match.params.token;

  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const [finished, setFinished] = useState(false);

  const saveChanges = async () => {
    try {
      if (password !== confirmPassword || !password || !confirmPassword) return;
      setLoading(true);
      await User.FinishRegistration(token, { password });
      setFinished(true);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };
  const valid = password === confirmPassword;

  if (finished) {
    return <Redirect to="/login" />;
  }
  return (
    <Centered>
      <Link to="/login">
        <Button variant="secondary">
          <FontAwesomeIcon icon={faArrowCircleLeft} />
        </Button>
      </Link>
      <div className="mobile-login-form">
        <form onSubmit={formSave}>
          <h4>Indtast den adgangskode du vil have</h4>
          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Adgangskode</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControlFeedback
                autoComplete="new-password"
                type="password"
                errors={error}
                field="password"
                onChange={(value: string) => setPassword(value)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Bekræft</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControlFeedback
                autoComplete="new-password"
                type="password"
                errors={error}
                field="token"
                onChange={(value: string) => setConfirmPassword(value)}
              />
            </InputGroup>
          </Form.Group>
          {!valid && (
            <Badge variant="warning">Adgangskoderne stemmer ikke overens</Badge>
          )}
          <hr />
          <Button type="submit" disabled={!valid}>
            Gem
            {loading && <Spinner animation="border" size="sm" />}
          </Button>
        </form>
      </div>
    </Centered>
  );
};

export default FinishRegistration;

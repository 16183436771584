import TrackingMap from "components/Tracking/TrackingMap";
import ConnectionContext, {
  ConnectionContextValue,
} from "contexts/connectionContext";
import SelectedCompanyContext from "contexts/selectedCompanyContext";
import SelectedVehicleContext, {
  SelectedVehicleContextValue,
} from "contexts/selectedVehicleContext";
import {
  useLiveCoordinatesConnection,
  useLiveCoordinatesFromConnection,
} from "hooks/useLiveCoordinates";
import useSessionStorage from "hooks/useSessionStorage";
import useTitle from "hooks/useTitle";
import { LatLngBounds } from "leaflet";
import { ICoordinate, IGPSCoordinate } from "models/gpsCoordinate";
import Preferences from "models/preferences";
import Vehicle, { IVehicleResource } from "models/vehicle";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CompanyContent } from "./CompanyContent";

export const Map = () => {
  useTitle("DigiFleet - Live");
  const [follow, setFollow] = useSessionStorage<IVehicleResource | undefined>(
    "vehicle-follow",
    undefined
  );
  const vehicleValue: SelectedVehicleContextValue = {
    vehicle: follow,
    setVehicle: setFollow,
  };
  const [location, setLocation] = useSessionStorage<ICoordinate>("location", {
    latitude: 55.403756,
    longitude: 10.40237,
  });
  const [bounds, setBounds] = useState<LatLngBounds>();
  const [zoom, setZoom] = useState(Preferences.Default.map.zoomLevel);
  const { company } = useContext(SelectedCompanyContext);

  const connection = useLiveCoordinatesConnection();
  const connectionValue: ConnectionContextValue = { connection };

  /**
   * Handles zooming to vehicle when followed
   */
  useEffect(() => {
    const abort = new AbortController();
    if (follow) {
      const request = async () => {
        try {
          const coordinate = await Vehicle.ReadLocation(follow, abort.signal);
          if (coordinate && !abort.signal.aborted) {
            setLocation(coordinate);
            setZoom(Preferences.Default.map.vehicleZoomLevel);
          } else console.warn("Location not gotten");
        } catch (error) {
          console.error(error);
        }
      };
      request();
    }
    return () => abort.abort();
  }, [follow]);

  /**
   * Handles following incoming coordinates of the followed vehicle
   */
  const handleFollowCoordinate = useCallback(
    (coordinate: IGPSCoordinate) => {
      if (!follow) return;
      if (coordinate.vehicleId === follow.id) {
        setLocation(coordinate);
      }
    },
    [follow]
  );
  /**
   * Handles map panning
   * @param location
   */
  const handlePan = (panLocation: ICoordinate) => {
    if (
      location.latitude === panLocation.latitude &&
      location.longitude === panLocation.longitude
    )
      return;
    // Clear followed vehicle, otherwise panning will be annoying due to jumping back to vehicle constantly
    if (follow) setFollow(undefined);
    // setLocation(panLocation);
  };

  useLiveCoordinatesFromConnection(connection, handleFollowCoordinate);

  if (!company || !connection) return null;

  return (
    <SelectedVehicleContext.Provider value={vehicleValue}>
      <ConnectionContext.Provider value={connectionValue}>
        <TrackingMap
          center={location}
          onPan={handlePan}
          onZoom={setZoom}
          zoom={zoom}
          tap={false}
          onBoundsChanged={setBounds}
          zoomControl={false}
        >
          <CompanyContent company={company} zoom={zoom} bounds={bounds} />
        </TrackingMap>
      </ConnectionContext.Provider>
    </SelectedVehicleContext.Provider>
  );
};

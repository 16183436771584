import { faArrowUp, faPause } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import L from "leaflet";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Spinner } from "react-bootstrap";
import { renderToString } from "react-dom/server";
import { Marker, Polyline, Popup } from "react-leaflet";
import Moment from "react-moment";
import { bearing, CalculateDistance } from "../../helpers/utils";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import GPSCoordinate, {
  IGPSCoordinate,
  ITimedCoordinate,
} from "../../models/gpsCoordinate";
import Vehicle, { IVehicleResource } from "../../models/vehicle";
import CoordinateAddress from "../Coordinates/Address";
import { ISelectedVehicle } from "./MapVehicleSelector";

interface Props {
  vehicle: ISelectedVehicle;
  zoom: number;
  from: Date;
  to: Date;
}
const scaleModifer = 1.083;
const VehicleHistoricTrack: React.FC<Props> = ({ vehicle, zoom, from, to }) => {
  const request = useCallback(
    (signal: AbortSignal) =>
      Vehicle.ReadTrackingHistory(vehicle, from, to, signal),
    [vehicle, from, to]
  );

  const periodRequest = useCallback(
    (signal: AbortSignal) =>
      Vehicle.ReadPeriodicHistory(
        vehicle,
        from,
        to,
        undefined,
        undefined,
        signal
      ),
    [vehicle, from, to]
  );

  const [loading, setLoading] = useLoading();
  const [coordinates] = usePromise(request, [], setLoading);

  const [periodLoading, setPeriodLoading] = useLoading();
  const [periods] = usePromise(periodRequest, undefined, setPeriodLoading);

  const filtered = useMemo(
    () => filterNth(filterNoise(coordinates), 25),
    [coordinates]
  );

  const stops = useMemo(
    () => periods !== undefined ? periods.periods.filter((p) => p.isMoving === false) : [],
    [periods]
  );
  const mapCoords = useMemo(
    () =>
      coordinates.map((coordinate) => ({
        lat: coordinate.latitude,
        lng: coordinate.longitude,
      })),
    [coordinates]
  );

  const iconSize = useMemo(() => Math.pow(zoom, scaleModifer), [zoom]);

  return (
    <Fragment>
      <Polyline positions={mapCoords} />
      {filtered.map((coordinate, index) => (
        <VehicleHistoricTrackPoint
          key={index}
          position={coordinate}
          nextPosition={filtered[index - 1]}
          iconSize={iconSize}
          vehicle={vehicle}
        />
      ))}

      {stops.map((stop, index) => (
        <VehicleHistoricTrackStop
          key={index}
          start={stop.start}
          end={stop.stop}
          iconSize={iconSize}
          vehicle={vehicle}
        />
      ))}
    </Fragment>
  );
};

export default VehicleHistoricTrack;

interface PointProps {
  iconSize: number;
  position: IGPSCoordinate;
  nextPosition?: IGPSCoordinate;
  vehicle: IVehicleResource;
}
const VehicleHistoricTrackPoint: React.FC<PointProps> = ({
  iconSize,
  position,
  nextPosition,
  vehicle,
}) => {
  const angle = useMemo(() => {
    return nextPosition
      ? bearing(
          position.latitude,
          position.longitude,
          nextPosition.latitude,
          nextPosition.longitude
        )
      : 0;
  }, [position, nextPosition]);

  const iconHTML = renderToString(<FontAwesomeIcon icon={faArrowUp} />);
  const divIcon = L.divIcon({
    html: `<div class="historic-icon" style="transform: rotate(${angle}deg)">${iconHTML}</div>`,
    iconSize: [iconSize, iconSize],
    // iconAnchor: [Math.sqrt(iconSize), (iconSize / 2)],
    className: "",
  });

  return (
    <Marker position={[position.latitude, position.longitude]} icon={divIcon}>
      <Popup>
        <HistoricPopup position={position} vehicle={vehicle} />
      </Popup>
    </Marker>
  );
};

interface StopProps {
  iconSize: number;
  start: ITimedCoordinate;
  end: ITimedCoordinate;
  vehicle: IVehicleResource;
}
const VehicleHistoricTrackStop: React.FC<StopProps> = ({
  iconSize,
  start,
  end,
  vehicle,
}) => {
  const iconHTML = renderToString(<FontAwesomeIcon icon={faPause} />);
  const divIcon = L.divIcon({
    html: `<div class="historic-stop">${iconHTML}</div>`,
    iconSize: [iconSize, iconSize],
    // iconAnchor: [Math.sqrt(iconSize), (iconSize / 2)],
    className: "",
  });

  return (
    <Marker position={[start.latitude, start.longitude]} icon={divIcon}>
      <Popup>
        <h5>Stop på rute</h5>
        <p>
          <b>
            Køretøj: {vehicle.alias} - {vehicle.licensePlate}
          </b>
        </p>
        <p>
          <b>Adresse: </b>
          <CoordinateAddress coordinate={start} />
        </p>
        <p>
          <b>Start: </b>
          <Moment local utc format="DD-MM-YYYY H:mm:ss">
            {start.timestamp}
          </Moment>
           <br />
          <b>Slut: </b>
          <Moment local utc format="DD-MM-YYYY H:mm:ss">
            {end.timestamp}
          </Moment>
        </p>
        <p>
          
        </p>
        <p>
          <b>Varighed: </b>
          <Moment duration={start.timestamp} date={end.timestamp} />
        </p>
      </Popup>
    </Marker>
  );
};

interface PopupProps {
  vehicle: IVehicleResource;
  position: IGPSCoordinate;
}
const HistoricPopup: React.FC<PopupProps> = ({ position, vehicle }) => {
  const [address, setAddress] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadAddress = async () => {
      try {
        const geo = await GPSCoordinate.GetAddress(position);
        if (geo) {
          const feature =
            geo.features &&
            geo.features.find((feat) => feat.place_type[0] === "address");
          if (feature) {
            setAddress(feature.place_name);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    loadAddress();
  }, [position]);

  return (
    <Fragment>
      {loading && <Spinner animation="border" />}
      <span>
        <b>
          Køretøj: {vehicle.alias} - {vehicle.licensePlate}
        </b>
        <br />
      </span>
      <span>
        <b>Dato: </b>
        <Moment local utc format="DD-MM-YYYY H:mm:ss">
          {position.timestamp}
        </Moment>
        <br />
      </span>
      <span>
        <b>Tændt: </b>
        {position.accOn ? "Ja" : "Nej"}
        <br />
      </span>

      <span>
        <b>Adresse: </b>
        {address}
        <br />
      </span>
    </Fragment>
  );
};

const filterNoise = (coordinates: IGPSCoordinate[]) => {
  return coordinates.filter((c, index) => {
    let coord1 = coordinates[index];
    let coord2 = coordinates[index + 1];
    if (coord1 && coord2) {
      let distance = CalculateDistance(
        coord1.latitude,
        coord1.longitude,
        coord2.latitude,
        coord2.longitude
      );
      return distance >= 40;
    }
    return true;
  });
};

const filterNth = (coordinates: IGPSCoordinate[], n: number) => {
  if (coordinates.length <= n) {
    return coordinates;
  }
  let take = Math.floor(coordinates.length / n);
  return coordinates.filter((item, index) => index % take === 0);
};

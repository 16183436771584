import {
  faChevronDown,
  faChevronUp,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Countdown from "components/Countdown";
import TimeLabel from "components/TimeLabel";
import useLoading from "hooks/useLoading";
import TimeSpan from "models/timespan";
import Vehicle from "models/vehicle";
import { IVehicleTachoResource } from "models/vehicleTacho";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Moment from "react-moment";

interface Props {
  value: IVehicleTachoResource;
}

export const Row = ({ value }: Props) => {
  const request = useCallback(
    () => Vehicle.ReadTacho(value.vehicle),
    [value.vehicle]
  );
  const [open, setOpen] = useState(false);
  const [tacho, setTacho] = useState(value.tachograph);
  const [loading, setLoading] = useLoading(false);

  const [duration, setDuration] = useState(0);

  const [lockRefresh, setLockRefresh] = useState(false);
  const [refreshFailed, setRefreshFailed] = useState(false);

  useEffect(() => {
    if (duration) {
      setLockRefresh(duration > 0);
    }
  }, [duration]);

  const refresh = async () => {
    try {
      setLoading(true);
      setLockRefresh(true);
      const tachograph = await request();
      if (tachograph) {
        setTacho(tachograph);
        setDuration(tachograph.secondsTillRefresh);
        setRefreshFailed(false);
      }
    } catch (error) {
      console.log(error);
      setDuration(5 * 60);
      setRefreshFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const spinnerClass = useMemo(
    () => (loading ? "loading-spinner active" : "loading-spinner"),
    [loading]
  );

  const unlockRefresh = () => {
    setLockRefresh(false);
    setRefreshFailed(false);
  };

  const buttonVariant = useMemo(() => {
    if (lockRefresh) {
      return refreshFailed ? "warning" : "secondary";
    } else return "primary";
  }, [lockRefresh, refreshFailed]);

  const clampExceed = Math.max(
    0,
    tacho?.driver1NumberOfTimes9hDailyDrivingTimesExceeded || 0
  );

  return (
    <div className="mobile-tacho-row">
      <div className="mobile-tacho-rowheader">
        <div className="mobile-tacho-section">
          <Button
            disabled={lockRefresh}
            size="sm"
            variant={buttonVariant}
            onClick={refresh}
          >
            <div className={spinnerClass}>
              <FontAwesomeIcon icon={faSync} />
            </div>
          </Button>
          <Countdown
            value={duration}
            onTick={setDuration}
            onComplete={unlockRefresh}
          />
        </div>
        <div className="mobile-tacho-section">
          {value.vehicle.alias || value.vehicle.licensePlate}
        </div>

        {tacho && (
          <>
            <div className="mobile-tacho-section tacho-name">
              <b>{tacho.driver1Name}</b>
            </div>
            <div className="mobile-tacho-section">
              <Button
                size="sm"
                variant="primary"
                onClick={() => setOpen((prev) => !prev)}
              >
                <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
              </Button>
            </div>
          </>
        )}
      </div>
      {tacho && open && (
        <div className="mobile-tacho-rowcontent">
          <Table size="sm" striped bordered>
            <tbody>
              <tr>
                <td>Chauffør:</td>
                <td>{tacho.driver1Name}</td>
              </tr>
              <tr>
                <td>
                  Køretid: <br />
                  Resterende:
                </td>
                <td>
                  <TimeLabel
                    format="hours-minutes"
                    value={
                      new TimeSpan(tacho.driver1ContinuousDrivingTime * 60)
                    }
                  />
                  <br />
                  <TimeLabel
                    format="hours-minutes"
                    value={
                      new TimeSpan(
                        270 * 60 - tacho.driver1ContinuousDrivingTime * 60
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Afholdt pause: <br />
                  Begynd pause:
                </td>
                <td>
                  <TimeLabel
                    format="hours-minutes"
                    value={new TimeSpan(tacho.driver1CumulativeBreakTime * 60)}
                  />
                  <br />
                  <Moment local utc format="HH:mm DD-MM-YYYY">
                    {tacho.driver1BeginBreak}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>
                  Køredøgn start: <br />
                  Daglig køretid:
                </td>
                <td>
                  <Moment local utc format="HH:mm DD-MM-YYYY">
                    {tacho.driver1EndOfLastDailyRestPeriod}
                  </Moment>
                  <br />
                  <TimeLabel
                    format="hours-minutes"
                    value={
                      new TimeSpan(tacho.driver1CurrentDailyDrivingTime * 60)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Køreuge start: <br />
                  Ugentlig køretid:
                </td>
                <td>
                  <Moment local utc format="HH:mm DD-MM-YYYY">
                    {tacho.driver1EndOfLastWeeklyRestPeriod}
                  </Moment>
                  <br />
                  <TimeLabel
                    format="hours-minutes"
                    value={
                      new TimeSpan(tacho.driver1CurrentWeeklyDrivingTime * 60)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Indtil dagligt hvil:
                  <br />
                  Påbegynd senest:
                </td>
                <td>
                  <TimeLabel
                    format="hours-minutes"
                    value={
                      new TimeSpan(
                        tacho.driver1TimeLeftUntilNewDailyRestPeriod * 60
                      )
                    }
                  />
                  <br />
                  <Moment local utc format="HH:mm DD-MM-YYYY">
                    {tacho.driver1BeginDailyRest}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>Begynd uge hvil:</td>
                <td>
                  <Moment local utc format="HH:mm DD-MM-YYYY">
                    {tacho.driver1BeginWeeklyRest}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>
                  Brugte 10 timers
                  <br /> perioder:
                </td>
                <td>
                  <br />
                  {clampExceed}
                </td>
              </tr>
              <tr>
                <td>Opdateret:</td>
                <td>
                  <Moment local utc format="HH:mm DD-MM-YYYY">
                    {tacho.updatedAt}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { authHeader } from "../../helpers/auth-header";
import { IUserResource } from "../../models/user";

interface Props {
  user: IUserResource;
}

const ForceLogout: React.FC<Props> = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const logout = async () => {
    if (
      window.confirm(
        "Hvis brugeren er logget ind, bliver de logget ud efter maks. 15 minutter."
      )
    ) {
      setLoading(true);
      await fetch(`api/Users/${user.id}/logout`, authHeader("POST"));
      setLoading(false);
    }
  };

  return (
    <Button title="Tving log ud" size="sm" variant="warning" onClick={logout}>
      <FontAwesomeIcon icon={faSignOutAlt} />
      {loading && <Spinner animation="border" />}
    </Button>
  );
};

export default ForceLogout;

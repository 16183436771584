export interface IPreferences {
  map: IMapPreferences;
}

interface IMapPreferences {
  iconSizeScale: number;
  showNamesByDefault: boolean;
  useAliasAsIcon: boolean;
  zoomLevel: number;
  disableOptimization: boolean,
  vehicleZoomLevel: number;
}

export interface IPageSizePreferences {
  [index: string]: number;
}

interface ITablePreferences {
  pageSizes: IPageSizePreferences;
}

const defaultPreferences: IPreferences = {
  map: {
    iconSizeScale: 1,
    showNamesByDefault: false,
    useAliasAsIcon: false,
    zoomLevel: 8,
    disableOptimization: false,
    vehicleZoomLevel: 12
  },
};
export default class Preferences {
  static Default = defaultPreferences;
}

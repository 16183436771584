import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import Company, { ICompanyBinding, ICompanyResource } from "../../models/company";
import TrackingUnit, { ITrackingUnitResource } from "../../models/trackingUnit";
import Companies from "../Companies/Index";

interface Props {
  trackingUnit: ITrackingUnitResource;
  onUpdated: (trackingUnit: ITrackingUnitResource) => void;
}

const AssignCompany: React.FC<Props> = ({ trackingUnit, onUpdated }) => {
  const [company, setCompany] = useState<ICompanyResource | undefined>();
  const [companyCreate, setCompanyCreate] = useState<
    ICompanyBinding | undefined
  >();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<Error | undefined>();
  const [shown, setShown] = useState(false);

  const saveChanges = async () => {
    try {
      setLoading(true);
      var assignCompany: ICompanyResource;
      if (companyCreate) {
        assignCompany = await Company.Create(companyCreate);
      } else if (company) {
        assignCompany = company;
      } else {
        return;
      }
      await TrackingUnit.AssignCompany(trackingUnit, assignCompany);

      setShown(false);
      if (onUpdated) {
        onUpdated({ ...trackingUnit, company: assignCompany });
      }
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const formSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };

  return (
    <Fragment>
      <button
        title="Tilføj til virksomhed"
        className="blank-button"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSubmit}>
          <Modal.Header>Tilføj enhed til virksomhed</Modal.Header>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Virksomhed</InputGroup.Text>
                </InputGroup.Prepend>
                <Companies.SelectorCreate
                  value={company}
                  error={error}
                  onChange={setCompany}
                  createValue={companyCreate}
                  onCreateChange={setCompanyCreate}
                />
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={loading} type="submit">
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default AssignCompany;

import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/SearchBox";
import SelectedCompanyContext from "contexts/selectedCompanyContext";
import useLoading from "hooks/useLoading";
import useSortedSearch from "hooks/useSortedSearch";
import useTitle from "hooks/useTitle";
import Company from "models/company";
import {
  IVehicleResource,
  VehicleProps,
  VehicleResource
} from "models/vehicle";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Centered from "../Centered";

export const HistorySelect = () => {
  useTitle("DigiFleet - Historik")
  const { company } = useContext(SelectedCompanyContext);
  const [loading, setLoading] = useLoading();
  const [vehicles, setVehicles] = useState<IVehicleResource[]>([]);

  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    VehicleResource,
    VehicleProps
  >(
    vehicles.map((v) => new VehicleResource(v)),
    undefined,
    {
      type: "alias",
      fallback: "licensePlate",
      asc: true,
    }
  );

  useEffect(() => {
    const abort = new AbortController();
    if (company) {
      const request = async () => {
        try {
          setLoading(true);
          const vehicles = await Company.ReadVehicles(company, abort.signal);
          setVehicles(vehicles);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
      request();
    }
  }, [company]);

  return (
    <Centered>
      <Link to="/nav">
        <Button variant="secondary">
          <FontAwesomeIcon icon={faArrowCircleLeft} />
        </Button>
      </Link>
      <h4>Vælg køretøj</h4>
      <SearchBox
        placeholder="Søg"
        className="form-control"
        onChange={(event) =>
          setSearch({
            types: ["alias", "licensePlate"],
            value: event.target.value,
          })
        }
      />
      <div className="mobile-history-select">
        {sorted.map((item) => (
          <div key={item.id} className="mobile-history-selectoption">
            <Link to={`/history/periodic/${item.id}`}>
              <Button>
                {item.alias || item.licensePlate}
              </Button>
            </Link>
          </div>
        ))}
      </div>
    </Centered>
  );
};

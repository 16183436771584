import React, { useState, useMemo, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import FullScreen from "react-full-screen";
import { RouteComponentProps } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import useTitle from "../../hooks/useTitle";
import Vehicle, { IVehicleResource } from "../../models/vehicle";
import HistoricMap from "./HistoricMap";
interface Props {
  from?: string;
  to?: string;
  vehicle?: IVehicleResource;
}
/**
 * Shows a map with vehicle's driving history
 * @param param0
 */
const VehicleHistory: React.FC<Props> = ({ from, to, vehicle }) => {
  const [fullscreen, setFullscreen] = useState(false);
  useTitle("DigiFleet - Historik");
  return (
    <FullScreen
      enabled={fullscreen}
      onChange={(isFull) => setFullscreen(isFull)}
    >
      <HistoricMap
        isFullscreen={fullscreen}
        initialVehicle={vehicle}
        initialFrom={from}
        initialTo={to}
        fullscreenChange={setFullscreen}
      />
    </FullScreen>
  );
};

export default VehicleHistory;

interface MatchParams {
  id: string;
  from: string;
  to: string;
}
interface ParamProps extends RouteComponentProps<MatchParams> {}

/**
 * Shows a map with a specific vehicle's driving history
 * @param param0
 */
export const VehicleHistoryIndex: React.FC<ParamProps> = ({ match }) => {
  const vehicleId = useMemo(() => +match.params.id, [match.params.id]);
  const request = useCallback(
    (signal: AbortSignal) => Vehicle.Read(vehicleId, signal),
    [vehicleId]
  );
  const [loading, setLoading] = useLoading();
  const [vehicle] = usePromise(request, undefined, setLoading);
  
  const from = match.params.from;
  const to = match.params.to;
  if (loading || !vehicle) return <Spinner animation="border" />;

  return <VehicleHistory from={from} to={to} vehicle={vehicle} />;
};

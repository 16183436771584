import { faSleigh, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useCallback, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import Moment from "react-moment";
import useLoading from "../../hooks/useLoading";
import TimeSpan from "../../models/timespan";
import Vehicle from "../../models/vehicle";
import { IVehicleTachoResource } from "../../models/vehicleTacho";
import Countdown from "../Countdown";
import TimeLabel from "../TimeLabel";
import Vehicles from "../Vehicles/Index";

interface Props {
  value: IVehicleTachoResource;
}

const Row = ({ value }: Props) => {
  const request = useCallback(
    () => Vehicle.ReadTacho(value.vehicle),
    [value.vehicle]
  );

  const [state, setState] = useState(value);
  const [loading, setLoading] = useLoading(false);

  const vehicle = useMemo(() => state.vehicle, [state.vehicle]);
  const tacho = useMemo(() => state.tachograph, [state.tachograph]);

  const [duration, setDuration] = useState(0);

  const [lockRefresh, setLockRefresh] = useState(false);
  const [refreshFailed, setRefreshFailed] = useState(false);

  useEffect(() => {
    if (duration) {
      setLockRefresh(duration > 0);
    }
  }, [duration]);

  const refresh = async () => {
    try {
      setLoading(true);
      setLockRefresh(true);
      const tachograph = await request();
      if (tachograph) {
        setState((cur) => ({ ...cur, tachograph }));
        setDuration(tachograph.secondsTillRefresh);
        setRefreshFailed(false);
      }
    } catch (error) {
      console.log(error);
      setDuration(5 * 60);
      setRefreshFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const spinnerClass = useMemo(
    () => (loading ? "loading-spinner active" : "loading-spinner"),
    [loading]
  );

  const unlockRefresh = () => {
    setLockRefresh(false);
    setRefreshFailed(false);
  };

  const buttonVariant = useMemo(() => {
    if (lockRefresh) {
      return refreshFailed ? "warning" : "secondary";
    } else return "primary";
  }, [lockRefresh, refreshFailed]);

  return (
    <tr>
      <td>
        <Vehicles.Label value={vehicle} />
        <br />
      </td>
      <td>
        <Button
          disabled={lockRefresh}
          size="sm"
          variant={buttonVariant}
          onClick={refresh}
        >
          <div className={spinnerClass}>
            <FontAwesomeIcon icon={faSync} />
          </div>
        </Button>
        <Countdown
          value={duration}
          onTick={setDuration}
          onComplete={unlockRefresh}
        />
      </td>
      <td>
        {tacho && (
          <Moment local utc format="DD-MM-YYYY HH:mm:ss">
            {tacho.updatedAt}
          </Moment>
        )}
      </td>
      <td>{tacho?.driver1Name}</td>
      <td>
        {tacho && (
          <TimeLabel
            format="hours-minutes"
            value={new TimeSpan(tacho.driver1ContinuousDrivingTime * 60)}
          />
        )}
      </td>
      <td>
        {tacho && (
          <TimeLabel
            format="hours-minutes"
            value={new TimeSpan(tacho.driver1CumulativeBreakTime * 60)}
          />
        )}
      </td>
      <td>
        {tacho && (
          <TimeLabel
            format="hours-minutes"
            value={new TimeSpan(tacho.driver1CurrentDailyDrivingTime * 60)}
          />
        )}
      </td>
      <td>
        {tacho && (
          <TimeLabel
            format="hours-minutes"
            value={new TimeSpan(tacho.driver1CurrentWeeklyDrivingTime * 60)}
          />
        )}
      </td>
    </tr>
  );
};

export default Row;

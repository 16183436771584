import React from "react";
import { useCallback } from "react";
import { Spinner } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import FlexVehicle from "../../models/flexVehicle";
import FlexVehicleTable from "./Table";

const AllFlexVehicles: React.FC = () => {
  const request = useCallback(
    (abort: AbortSignal) => FlexVehicle.ReadAll(abort),
    []
  );
  const [loading, setLoading] = useLoading();
  const [flexVehicles] = usePromise(request, [], setLoading);

  if (loading) return <Spinner animation="border" />;

  return <FlexVehicleTable flexVehicles={flexVehicles} />
};

export default AllFlexVehicles;

import { ICompanyIdentifiable } from "./company";

export interface IJWTUser {
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
  companies: IJWTCompany[];
  name: string;
  admin: boolean;
  id: number;
  sub: number;
}

export interface IJWTCompany extends ICompanyIdentifiable {
  isRemote: boolean;
}

export class JWTUser {
  static GetTimeUntilExpiry = (user: IJWTUser): number => {
    const unixTime = Math.floor(Date.now() / 1000);

    return user.exp - unixTime;
  };
}

export interface Lane {
  indications: string[];
  valid: boolean;
}

export interface Intersection {
  bearings: number[];
  location: number[];
  entry: boolean[];
  geometry_index: number;
  out: number;
  in?: number;
  duration?: number;
  lanes: Lane[];
  classes: string[];
}

export interface Maneuver {
  bearing_after: number;
  type: string;
  bearing_before: number;
  location: number[];
  instruction: string;
  modifier: string;
  exit?: number;
}

export interface Geometry {
  coordinates: number[][];
  type: string;
}

export interface Step {
  intersections: Intersection[];
  name: string;
  distance: number;
  maneuver: Maneuver;
  weight: number;
  geometry: Geometry;
  duration: number;
  mode: string;
  driving_side: string;
  destinations: string;
  ref: string;
  exits: string;
  rotary_name: string;
}

export interface Leg {
  summary: string;
  steps: Step[];
  distance: number;
  duration: number;
  weight: number;
}

export interface Geometry2 {
  coordinates: number[][];
  type: string;
}

export interface Route {
  weight_name: string;
  legs: Leg[];
  geometry: Geometry2;
  distance: number;
  duration: number;
  weight: number;
}

export interface Waypoint {
  distance: number;
  name: string;
  location: number[];
}

export default interface GeoRoute {
  routes: Route[];
  waypoints: Waypoint[];
  code: string;
  uuid: string;
}

export class RouteFinder {
  static GetRoute = async (
    start: [number, number],
    end: [number, number],
    abort?: AbortSignal
  ): Promise<GeoRoute | undefined> => {
    const pointString = `${start[1]},${start[0]};${end[1]},${end[0]}`;
    const response = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${pointString}?geometries=geojson&steps=true&language=da&access_token=pk.eyJ1IjoiYnJpYW5tb3NlIiwiYSI6ImNrMnU5dGV1ZTA2bzAzY3FtY2U4ZTFoMXYifQ.fKvvV85REkfdJ6vqtWF66Q`,
      { signal: abort }
    );
    if (response.ok) {
      return (await response.json()) as GeoRoute;
    }
  };
}

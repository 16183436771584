import {
  faArrowCircleLeft, faCheck,
  faCog,
  faMapMarkedAlt,
  faSquareFull
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonControl from "components/Tracking/MapControls/ButtonControl";
import ControlContent from "components/Tracking/MapControls/ControlContent";
import ConnectionContext from "contexts/connectionContext";
import useLoading from "hooks/useLoading";
import useLocalStorage from "hooks/useLocalStorage";
import usePromise from "hooks/usePromise";
import { LatLngBounds } from "leaflet";
import Company from "models/company";
import { IJWTCompany } from "models/jwtUser";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import Control from "react-leaflet-control";
import { Link } from "react-router-dom";
import TrackRenderer from "./TrackRenderer";
import { VehicleSelector } from "./VehicleSelector";

interface Props {
  company: IJWTCompany;
  zoom: number;
  bounds?: LatLngBounds;
}

const zoomNameLimit = 10;

export const CompanyContent = ({ company, zoom, bounds }: Props) => {
  const { connection } = useContext(ConnectionContext);
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [showTracks, setShowTracks] = useLocalStorage("mobile-tracks", true);
  const [showNames, setShowNames] = useLocalStorage("mobile-names", false);
  const request = useCallback(
    (abort?: AbortSignal) => Company.ReadVehicles(company, abort),
    [company]
  );
  const [trackLoading, setTrackLoading] = useLoading(false);
  const [loading, setLoading] = useLoading();
  const [vehicles] = usePromise(request, [], setLoading);

  const shownVehicles = useMemo(
    () => (showTracks ? vehicles : []),
    [showTracks, vehicles]
  );

  if (!bounds || !connection) return null;

  return (
    <>
      <Control position={"topleft"}>
        <Link to="/nav">
          <Button variant="secondary">
            <FontAwesomeIcon icon={faArrowCircleLeft} />
          </Button>
        </Link>
      </Control>
      <ButtonControl
        icon={faMapMarkedAlt}
        show={selectorOpen}
        variant={selectorOpen ? "secondary" : "success"}
        onClick={() => setSelectorOpen(!selectorOpen)}
        position="topright"
        dontUnmount={true}
      >
        <ControlContent corner="top-right">
          <VehicleSelector vehicles={vehicles} />
        </ControlContent>
      </ButtonControl>

      <ButtonControl
        icon={faCog}
        show={optionsOpen}
        variant={optionsOpen ? "secondary" : "success"}
        onClick={() => setOptionsOpen(!optionsOpen)}
        position="topright"
        dontUnmount={true}
      >
        <ControlContent corner="top-right">
          <div className="mobile-map-options">
            <div className="section">
              Vis spor
              <Button
                variant="primary"
                size="sm"
                onClick={() => setShowTracks(!showTracks)}
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={showTracks ? faCheck : faSquareFull}
                />
              </Button>
            </div>
            <div className="section">
              Vis navne
              <Button
                variant="primary"
                size="sm"
                onClick={() => setShowNames(!showNames)}
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={showNames ? faCheck : faSquareFull}
                />
              </Button>
            </div>
            {/* <div className="sub-section">
                Ved zoom
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => setShowZoomNames(!showZoomNames)}
                >
                  <FontAwesomeIcon
                    size="sm"
                    icon={showZoomNames ? faCheck : faSquareFull}
                  />
                </Button>
              </div> */}
          </div>
        </ControlContent>
      </ButtonControl>

      <TrackRenderer
        bounds={bounds}
        connection={connection}
        pathsToShow={shownVehicles}
        vehicles={vehicles}
        zoom={zoom}
        onLoadTracks={setTrackLoading}
        showTooltip={showNames}
      />
    </>
  );
};

import React, { useCallback, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import TrackingUnitQR from "../../models/trackingUnitQR";
import QRVehicleAssign from "./QRVehicleAssign";

interface MatchParams {
  token: string;
}
interface Props extends RouteComponentProps<MatchParams> {}

const QRAssignIndex: React.FC<Props> = ({ match }) => {
  const token = useMemo(() => match.params.token, [match.params.token]);

  const request = useCallback(
    (signal: AbortSignal) => TrackingUnitQR.Valid(token, signal),
    [token]
  );
  const [loading, setLoading] = useLoading();
  const [qrValidity] = usePromise(request, undefined, setLoading);

  if (loading || !qrValidity) {
    return <Spinner animation="border" />;
  }

  if (qrValidity.valid === false) {
    return <h3>QR koden er ugyldig</h3>;
  }

  return <QRVehicleAssign token={token} />;
};

export default QRAssignIndex;

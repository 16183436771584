import React, { useMemo } from "react";
import { useCallback } from "react";
import useLoading from "../../../hooks/useLoading";
import usePromise from "../../../hooks/usePromise";
import TachoSafeCompany, {
  ITachoSafeCompanyResource,
} from "../../../models/tachoSafe/company";
import ContentLoading from "../../ContentLoading";
import Selector from "./Selector";

interface Props {
  value?: ITachoSafeCompanyResource;
  onChange: (value?: ITachoSafeCompanyResource) => void;
}

const AllSelector = ({ ...props }: Props) => {
  const [loading, setLoading] = useLoading();
  const request = useCallback(
    (signal: AbortSignal) => TachoSafeCompany.ReadAll(signal),
    []
  );

  const [values] = usePromise(request, [], setLoading);

  return (
    <ContentLoading loading={loading}>
      <Selector values={values} {...props} />
    </ContentLoading>
  );
};

export default AllSelector;

interface IDProps {
  id?: number;
  onChange: (value?: ITachoSafeCompanyResource) => void;
}
export const AllSelectorIDBased = ({ id, onChange }: IDProps) => {
    const [loading, setLoading] = useLoading();
  const request = useCallback(
    (signal: AbortSignal) => TachoSafeCompany.ReadAll(signal),
    []
  );

  const [values] = usePromise(request, [], setLoading);

  const value = useMemo(() => {
      return values.find(v => v.id === id);
  }, [values]);

  return (
    <ContentLoading loading={loading}>
      <Selector values={values} value={value} onChange={onChange} />
    </ContentLoading>
  );
};

import React from "react";

interface Props{
    kilometers: number;
}

const KilometerLabel:React.FC<Props> = ({kilometers}) => {
    const rounded = Math.round(kilometers * 10) / 10;

    return(
        <span>{rounded}</span>
    );
}
export default KilometerLabel;
import React from "react";
import { useCallback } from "react";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyResource } from "../../models/company";
import ContentLoading from "../ContentLoading";
import Table from "../VehicleTacho/Table";

interface Props{
    company: ICompanyResource
}

const VehicleTachographs = ({company}: Props) => {
    const request = useCallback((signal: AbortSignal) => Company.ReadVehicleTachographs(company, signal), [company]);

    const [loading, setLoading] = useLoading();
    const [values] = usePromise(request, [], setLoading);


    return(
        <ContentLoading loading={loading}>
            <Table values={values} />
        </ContentLoading>
    )
}

export default VehicleTachographs;
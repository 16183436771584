import { useContext, useEffect, useMemo } from "react";
import UserContext from "../contexts/userContext";
import { IGPSCoordinate } from "../models/gpsCoordinate";
import useSignalRConnection from "./useSignalRConnection";
import { HubConnection } from "@microsoft/signalr";

const useLiveCoordinates = (
  coordinateArrived: (coordinate: IGPSCoordinate) => void
) => {
  const connection = useLiveCoordinatesConnection();

  useLiveCoordinatesFromConnection(connection, coordinateArrived);
};

export default useLiveCoordinates;

export const useLiveCoordinatesFromConnection = (
  connection: HubConnection | undefined,
  coordinateArrived: (coordinate: IGPSCoordinate) => void
) => {
  useEffect(() => {
    if (connection) {
      connection.on("CoordinateArrived", coordinateArrived);
      return () => {
        connection.off("CoordinateArrived", coordinateArrived);
      };
    }
  }, [connection, coordinateArrived]);
};

export const useLiveCoordinatesConnection = (): HubConnection | undefined => {
  const { user } = useContext(UserContext);
  const [connection, started] = useSignalRConnection("/hubs/coordinate");
  const userAdmin = useMemo(() => (user ? user.identity.admin : false), [user]);
  useEffect(() => {
    if (connection && started) {
      const subscribe = async () => {
        try {
          if (userAdmin) {
            await connection.invoke("SubscribeAdmin");
          } else {
            await connection.invoke("SubscribeUser");
          }
        } catch (error) {
          console.error(error);
        }
      };
      subscribe();
    }
  }, [connection, userAdmin, started]);

  return connection;
};

import { createFetch, deleteFetch, readFetch } from "../helpers/fetchers";
import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { IVehicleResource } from "./vehicle";

export interface ISEGESVehicleResource {
  vehicleId: number;
  vehicle?: IVehicleResource;
  type: number;
}
export interface ISEGESVehicleBinding {
  vehicle?: IVehicleResource;
  type: number;
}
type VehicleProps = "vehicle.licensePlate" | "vehicle.alias" | "vehicle.id";
export type SEGESVehicleProps =
  | "vehicleId"
  | "type"
  | VehicleProps;
export class SEGESVehicleResource
  implements
    ISEGESVehicleResource,
    ISortable<SEGESVehicleProps>,
    ISearchable<SEGESVehicleProps> {
  vehicleId: number;
  vehicle?: IVehicleResource | undefined;
  type: number;
  constructor(value: ISEGESVehicleResource) {
    this.vehicleId = value.vehicleId;
    this.type = value.type;
    this.vehicle = value.vehicle;
  }

  search = <T>(value: T, type: SEGESVehicleProps): number | undefined => {
    switch (type) {
      case "vehicle.id":
      case "vehicleId":
        return basicSearch(this, value, type, true);
      default:
        return basicSearch(this, value, type, false);
    }
  };

  compare = (
    value: ISortable<SEGESVehicleProps>,
    primary: SEGESVehicleProps,
    secondary?: SEGESVehicleProps
  ): number => basicSecSort(this, value, primary, secondary);
}


export default class SEGESVehicle{
    static Create = async (
        binding: ISEGESVehicleBinding,
        abort?: AbortSignal
      ): Promise<ISEGESVehicleResource> =>
        (await createFetch(
          "api/SEGES/vehicles",
          binding,
          abort
        )) as ISEGESVehicleResource;
    
      static ReadAll = async (abort?: AbortSignal): Promise<ISEGESVehicleResource[]> =>
        (await readFetch("api/SEGES/vehicles", abort)) as ISEGESVehicleResource[];
    
      static Delete = async (
        vehicle: ISEGESVehicleResource,
        abort?: AbortSignal
      ): Promise<void> => {
        deleteFetch(`api/SEGES/vehicles/${vehicle.vehicleId}`, abort);
      };
}

import React from "react";
import { ISEGESCompanyResource } from "../../../models/segesCompany";
import Delete from "./Delete";

interface Props{
    value: ISEGESCompanyResource,
    onDeleted: (value: ISEGESCompanyResource) => void
}

const Row = ({value, onDeleted}: Props) => (
    <tr>
        <td>
            {value.company && value.company.name}
        </td>
        <td>
            {value.authorizationNumber}
        </td>
        <td>
            <Delete company={value} onDeleted={onDeleted} />
        </td>
    </tr>
)
export default Row;
import React from "react";
import { useMemo } from "react";

interface Props {
  value: number;
  onChange?: (value: number) => void;
  disabled?: boolean
}
const TypeSelector = ({ value, onChange, disabled }: Props) => (
    <select className="form-control w-auto" disabled={disabled} value={value} onChange={event => {
        const type = +event.target.value;
        onChange && onChange(type);
    }}>
        <option value="1">Trækker</option>
        <option value="2">Trailer</option>
        <option value="10">Forvogn</option>
        <option value="11">Hænger</option>
    </select>
)

export default TypeSelector;

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from "react-bootstrap";
import Company, { ICompanyResource } from "../../models/company";
import { IUserResource } from "../../models/user";

interface Props {
  user: IUserResource;
  company: ICompanyResource;
  onUpdated: (user: IUserResource) => void;
}

const UnassignUser: React.FC<Props> = ({ company, user, onUpdated }) => {
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);

  const saveChanges = async () => {
    try {
      setLoading(true);
      await Company.UnassignUser(company, user);
      setShown(false);
      if (onUpdated) {
        onUpdated(user);
      }
    } catch (error) {
      
      console.error(error);
    }
    finally{
      setLoading(false);
    }
  };
  const formSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };

  return (
    <Fragment>
      <Button
        variant="warning"
        title="Fjern fra virksomhed"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSubmit}>
          <Modal.Header>Fjern bruger fra virksomhed</Modal.Header>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Fornavn</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl readOnly={true} defaultValue={user.firstName} />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Efternavn</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl readOnly={true} defaultValue={user.lastName} />
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" disabled={loading} type="submit">
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default UnassignUser;

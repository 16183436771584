import React, { useContext, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import UserContext from "../../contexts/userContext";
import useAbort from "../../hooks/useAbort";
import Account from "../../models/account";

const Logout: React.FC = () => {
  const { setUser } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const abortSignal = useAbort();

  const logout = async () => {
    try {
      setLoading(true);
      await Account.Logout(abortSignal);
      setLoading(false);
      sessionStorage.removeItem("user");
      localStorage.removeItem("user");
      setUser(undefined);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Button onClick={() => logout()}>
      Log ud {loading && <Spinner size="sm" animation="border" />}
    </Button>
  );
};

export default Logout;

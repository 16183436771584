import { ITrackingUnitResource } from "./trackingUnit";
import { readFetch, createFetch, downloadFetch } from "../helpers/fetchers";
import { IVehicleResource, IVehicleBinding } from "./vehicle";

export interface IQRValid {
  valid: boolean;
}
export interface ITrackingUnitQR {
  id: string;
  token: string;
  trackingUnit?: ITrackingUnitResource;
}

export default class TrackingUnitQR {
  static ReadAll = async (abort?: AbortSignal): Promise<ITrackingUnitQR[]> =>
    (await readFetch("api/QR", abort)) as ITrackingUnitQR[];

  static Read = async (
    id: string,
    abort?: AbortSignal
  ): Promise<ITrackingUnitQR> =>
    (await readFetch("api/QR/" + id, abort)) as ITrackingUnitQR;

  static ReadImage = async (id: string, abort?: AbortSignal): Promise<Blob> =>
    (await downloadFetch(`api/QR/${id}/image/svg`, abort)) as Blob;

  static TryReadImage = async (id: string, abort?: AbortSignal): Promise<Blob | undefined> => {
    try {
      const blob = await TrackingUnitQR.ReadImage(id, abort);
      return blob;
    } catch (error) {
      return undefined;
    }
  }

  static Create = async (
    unit: ITrackingUnitResource,
    abort?: AbortSignal
  ): Promise<ITrackingUnitQR> =>
    (await createFetch("api/QR", unit, abort)) as ITrackingUnitQR;

  static ReadVehicles = async (
    token: string,
    abort?: AbortSignal
  ): Promise<IVehicleResource[]> =>
    (await readFetch(`api/QR/register/${token}/vehicles`, abort)) as IVehicleResource[];

  static CreateVehicle = async (
    token: string,
    vehicle: IVehicleBinding,
    abort?: AbortSignal
  ): Promise<IVehicleResource> =>
    (await createFetch(
      `api/QR/register/${token}/vehicles`,
      vehicle,
      abort
    )) as IVehicleResource;
  static AssignVehicle = async (
    token: string,
    vehicle: IVehicleResource,
    mountInfo: string,
    abort?: AbortSignal
  ): Promise<IVehicleResource> =>
    (await createFetch(
      `api/QR/register/${token}`,
      {vehicle, mountInfo},
      abort
    )) as IVehicleResource;

  static Valid = async (
    token: string,
    abort?: AbortSignal
  ): Promise<IQRValid> =>
    (await readFetch(`api/QR/token/${token}/valid`, abort)) as IQRValid;
}

import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import useSorting from "../../hooks/useSorting";
import { CloseEncounter, CloseEncounterProps, ICloseEncounter } from "../../models/closeEncounter";
import PassivePagination from "../Pagination";
import SortableHeader from "../SortableHeader";
import PositionModal from "../Tracking/PositionModal";

interface Props {
  encounters: ICloseEncounter[];
}

const EncounterTable: React.FC<Props> = ({ encounters }) => {
  const [sorted, sort, setSort] = useSorting<CloseEncounter, CloseEncounterProps>(
    encounters.map(v => new CloseEncounter(v)),
    { type: "distance", asc: true }
  );

  return (
    <Table striped bordered>
      <thead>
        <tr>
          <SortableHeader
            type={"vehicle.alias"}
            fallback={"vehicle.licensePlate"}
            value={sort}
            onChange={setSort}
          >
            Køretøj
          </SortableHeader>
          <SortableHeader
            type={"coordinate.timestamp"}
            value={sort}
            onChange={setSort}
          >
            Tidspunkt
          </SortableHeader>
          <SortableHeader type={"distance"} value={sort} onChange={setSort}>
            Afstand
          </SortableHeader>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <PassivePagination
          items={sorted}
          id="encounters"
          sizeSelector={true}
          renderItem={(item, index) => (
            <tr key={index}>
              <td>
                {item.vehicle.alias ? (
                  <div>
                    {item.vehicle.alias}, {item.vehicle.licensePlate}
                  </div>
                ) : (
                  <div>{item.vehicle.licensePlate}</div>
                )}
              </td>
              <td>
                <div title="Dato og tid da køretøjet var tættest på målet">
                  <Moment local utc format="DD-MM-YYYY H:mm:ss">
                    {item.coordinate.timestamp}
                  </Moment>
                </div>
              </td>
              <td>
                <div title="Køretøjet var så langt fra målet da det var tættest">
                  {item.distance.toFixed(0)} meter
                </div>
              </td>
              <td>
                <PositionModal position={item.coordinate} />
              </td>
            </tr>
          )}
        />
      </tbody>
    </Table>
  );
};

export default EncounterTable;

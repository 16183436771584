import { IVehicleResource } from "../../models/vehicle";
import { useState, useCallback } from "react";
import DateSelector from "../Coordinates/DateSelector";
import React from "react";
import DataExport from "../../models/export";
import { Button, Spinner } from "react-bootstrap";
import Company, { ICompanyResource } from "../../models/company";
import usePromise from "../../hooks/usePromise";
import Vehicles from "../Vehicles/Index";
import useLoading from "../../hooks/useLoading";

interface IndexProps {
  company: ICompanyResource;
}

const VehicleHistoryExportIndex: React.FC<IndexProps> = ({ company }) => {
  const request = useCallback(
    (signal: AbortSignal) => Company.ReadVehicles(company, signal),
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [vehicles] = usePromise(request, [], setLoading);

  const [vehicle, setVehicle] = useState<IVehicleResource | undefined>();

  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  return (
    <div>
      <Vehicles.Selector
        value={vehicle}
        onChange={setVehicle}
        vehicles={vehicles}
      />
      <DateSelector
        from={from}
        to={to}
        onChange={(from, to) => {
          setFrom(from);
          setTo(to);
        }}
      />
      {vehicle && (
        <VehicleHistoryExport vehicle={vehicle} from={from} to={to} />
      )}
    </div>
  );
};
export default VehicleHistoryExportIndex;

interface Props {
  vehicle: IVehicleResource;
  from: Date;
  to: Date;
}
export const VehicleHistoryExport: React.FC<Props> = ({ vehicle, from, to }) => {
  const [loading, setLoading] = useState(false);
  const downloadExcel = async () => {
    setLoading(true);
    try {
      const downloaded = await DataExport.VehicleHistoryExcel(
        vehicle,
        from,
        to
      );
      if (downloaded) {
        saveFile(downloaded.fileName, downloaded.blob);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const downloadCSV = async () => {
    setLoading(true);
    try {
      const downloaded = await DataExport.VehicleHistoryCSV(vehicle, from, to);
      if (downloaded) {
        saveFile(downloaded.fileName, downloaded.blob);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Spinner animation="border" />;

  return (
    <div className="export-buttons">
      <Button variant="primary" onClick={downloadExcel}>
        Download Excel (.xlsx)
      </Button>
      <Button variant="primary" onClick={downloadCSV}>
        Download CSV (.csv)
      </Button>
    </div>
  );
};

function saveFile(name: string, data: Blob) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(data, name);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = name;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

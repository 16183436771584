import React, { Fragment, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { ICompanyBinding } from "../../models/company";
import FormControlFeedback from "../FormControlFeedback";
import AllSelector, {
  AllSelectorIDBased,
} from "../TachoSafe/Companies/AllSelector";

interface Props {
  value: ICompanyBinding;
  onChange: (company: ICompanyBinding) => void;
  error?: Error;
}

const CompanyForm: React.FC<Props> = ({ value: company, error, onChange }) => {
  const [remoteEnabled, setRemoteEnabled] = useState(company.remote ? true : false);
  return (
    <Fragment>
      <Form.Group>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>Virksomheds navn</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControlFeedback
            errors={error}
            field="name"
            value={company.name}
            onChange={(name: string) => onChange({ ...company, name })}
          />
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.Check
          label="Demo virksomhed"
          id="company-demo"
          custom
          checked={company.demo}
          onChange={() => onChange({ ...company, demo: !company.demo })}
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          label="Remote"
          id="company-remote"
          custom
          checked={remoteEnabled}
          onChange={() => {
            const enable = !remoteEnabled;
            if(!enable) onChange({...company, remote: undefined});
            setRemoteEnabled(enable)
          }}
        />
        {remoteEnabled && (
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>TachoSafe</InputGroup.Text>
            </InputGroup.Prepend>
            <AllSelector
              value={company.remote}
              onChange={(tacho) =>
                onChange({ ...company, remote: tacho })
              }
            />
          </InputGroup>
        )}
      </Form.Group>
    </Fragment>
  );
};

export default CompanyForm;

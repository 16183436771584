import Preferences, { IPreferences } from "../models/preferences";
import useLocalStorage from "./useLocalStorage";

const usePreferences = (): [IPreferences, (value: IPreferences) => void] => {
  const [savedPreferences, setPreferences] = useLocalStorage<IPreferences>(
    "new-preferences",
    Preferences.Default
  );

  return [savedPreferences, setPreferences];
};

export default usePreferences;

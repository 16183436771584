import { faDesktop, faMobile, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileContext } from "contexts/mobileContext";
import React from "react";
import { useContext } from "react";
import { Button } from "react-bootstrap";

export const MobileOverrideButton = () => {
  const { isMobile, overrideMobile, setOverrideMobile } =
    useContext(MobileContext);

  const toggle = () => {
    setOverrideMobile(!overrideMobile);
  };

  if (!isMobile) return null;
  else {
    return (
      <div className="mobile-override">
        <Button variant="primary" onClick={toggle}>
          <FontAwesomeIcon icon={overrideMobile ? faMobileAlt : faDesktop} />
        </Button>
      </div>
    );
  }
};

export default MobileOverrideButton;

import React, { Fragment, useState } from "react";
import { Button, Badge } from "react-bootstrap";
import Moment from "react-moment";
import { getHTML5DateTimeStringsFromDate } from "../../helpers/utils";
import moment from "moment";
import Coordinates from "../Coordinates/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import ControlContent from "./MapControls/ControlContent";

interface Props {
  from: Date;
  to: Date;
  onChange: (from: Date, to: Date) => void;
}

const MapDateFilter: React.FC<Props> = ({
  from: fromDate,
  to: toDate,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState(fromDate);
  const [to, setTo] = useState(toDate);

  const search = () => {
    onChange(from, to);
    setOpen(false);
  };
  const getDays = () => {
    const mF = moment(from);
    const mT = moment(to);

    return Math.abs(mF.diff(mT, "days"));
  };
  const days = getDays();
  if (open) {
    
    return (
      <ControlContent>
        <div>
          <label style={{width: "100%"}}>
            Fra dato
            <input
              type="datetime-local"
              value={getHTML5DateTimeStringsFromDate(from)}
              max={getHTML5DateTimeStringsFromDate(to)}
              className="form-control"
              onChange={(e) => setFrom(new Date(e.target.value))}
            />
          </label>
          <br />
          <label style={{width: "100%"}}>
            Til dato
            <input
              type="datetime-local"
              value={getHTML5DateTimeStringsFromDate(to)}
              min={getHTML5DateTimeStringsFromDate(from)}
              className="form-control"
              onChange={(e) => setTo(new Date(e.target.value))}
            />
          </label>
          <Coordinates.DateSelectorButtons
            onChange={(from, to) => {
              setFrom(from);
              setTo(to);
            }}
          />
        </div>
        <hr />
        <div>
          <Button size="sm" variant="success" onClick={() => search()}>
            Søg
          </Button>
          <div className="divider" />
          <Button size="sm" variant="secondary" onClick={() => setOpen(false)}>
            Luk
          </Button>
        </div>
        <br />
        {days > 5 && (
          <Badge variant="warning">
            <p>
              Bemærk: Lange perioder er længere tid om at loade og kan gøre
              kortet langsomt.
            </p>
            Hvis du er på mobil bruger det også en del mere data.
          </Badge>
        )}
      </ControlContent>
    );
  } else {
    return (
        <Button title={`Filtrer. Viser ${days} ${days > 1 ? "Dage" : "Dag"}`} variant="primary" onClick={() => setOpen(true)}>
          <FontAwesomeIcon icon={faFilter} />
        </Button>
    );
  }
};

export default MapDateFilter;

import CoordinateAddress from "./Address";
import DateSelector from "./DateSelector";
import DateSelectorButtons, { ButtonThisWeek, ButtonToday } from "./DateSelectorButtons";
import CoordinateHistory from "./History";
import CoordinateTable from "./Table";


class Coordinates{
    static Table = CoordinateTable;
    static Address = CoordinateAddress;
    static History = CoordinateHistory;
    static DateSelector = DateSelector;

    static DateToday = ButtonToday;
    static DateThisWeek = ButtonThisWeek;
    static DateSelectorButtons = DateSelectorButtons;
}

export default Coordinates;
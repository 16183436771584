import { useState } from "react";
import { IPageSizePreferences } from "../models/preferences";
import useLocalStorage from "./useLocalStorage";

/**
 * Hook for size of pages
 * @param defaultSize The default size of the page
 * @param key The key the page size will be saved under
 */
const usePageSize = (
  defaultSize: number,
  key?: string
): [number, (value: number) => void] => {
  const [savedSizes, setSizes] = useLocalStorage<IPageSizePreferences | undefined>(
    "pageSizes", undefined
  );

  const [size, setSize] = useState<number>(
    key ? savedSizes ? savedSizes[key] || defaultSize : defaultSize : defaultSize
  );

  const changeSize = (value: number) => {
    setSize(value);
    if (key) {
      if (savedSizes) {
        savedSizes[key] = value;
        setSizes(savedSizes);
      } else {
        setSizes({ [key]: value });
      }
    }
  };

  return [size, changeSize];
};

export default usePageSize;

import React, { useEffect, useCallback } from "react";
import usePromise from "../../hooks/usePromise";
import useTitle from "../../hooks/useTitle";
import Company from "../../models/company";
import Companies from "./Index";
import TableError from "../TableError";
import useLoading from "../../hooks/useLoading";

const AllCompanies: React.FC = () => {
  const request = useCallback(
    (signal: AbortSignal) => Company.ReadAll(signal),
    []
  );
  const [loading, setLoading] = useLoading()
  const [companies, error] = usePromise(request, [], setLoading);
  useTitle("DigiFleet - Virksomheder");
  useEffect(() => {
    document.title = "DigiFleet - Virksomheder";
  }, []);
  if (loading) {
    return <em>Loading...</em>;
  } else if (error) {
    return <TableError />;
  }
  return <Companies.Table companies={companies} />;
};

export default AllCompanies;

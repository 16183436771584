import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Badge, Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import Company, { ICompanyBinding, ICompanyResource } from "../../models/company";
import User, { IUserBinding, IUserResource } from "../../models/user";
import Companies from "../Companies/Index";
import FormControlFeedback from "../FormControlFeedback";

interface Props {
  company?: ICompanyResource;
  onCreated: (user: IUserResource) => void;
}
const defaultUser: IUserBinding = {
  firstName: "",
  lastName: "",
  email: "",
  isAdmin: false,
  active: false,
};
const CreateUser: React.FC<Props> = ({ company: comp, onCreated }) => {
  const [user, setUser] = useState(defaultUser);
  const [company, setCompany] = useState<ICompanyResource | undefined>(comp);
  const [companyCreate, setCompanyCreate] = useState<
    ICompanyBinding | undefined
  >();

  const [shown, setShown] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const saveChanges = async () => {
    try {
      setLoading(true);
      const created = await User.Create(user);

      var assignCompany: ICompanyResource | undefined;
      if (companyCreate) {
        assignCompany = await Company.Create(companyCreate);
      } else if (company) {
        assignCompany = company;
      }

      if (assignCompany) {
        await Company.AssignUser(assignCompany, created);
        created.companies = [assignCompany];
      }
      setLoading(false);
      setShown(false);
      if (onCreated) {
        onCreated(created);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log(error);
    }
  };

  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };

  return (
    <Fragment>
      <Button title="Opret bruger" onClick={() => setShown(true)}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Opret bruger</Modal.Title>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Email</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  errors={error}
                  field="email"
                  onChange={(value: string) =>
                    setUser({ ...user, email: value })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Fornavn</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  errors={error}
                  field="firstName"
                  onChange={(value: string) =>
                    setUser({ ...user, firstName: value })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Efternavn</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  errors={error}
                  field="lastName"
                  onChange={(value: string) =>
                    setUser({ ...user, lastName: value })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Check
                label="Administrator"
                id="company-demo"
                custom
                checked={user.isAdmin}
                onChange={() => setUser({ ...user, isAdmin: !user.isAdmin })}
              />
            </Form.Group>
            {user.isAdmin && (
              <Badge variant="warning">
                Bemærk: Denne bruger er sat til at være administrator for hele DigiFleet
              </Badge>
            )}
            {!comp && (
              <Fragment>
                <hr></hr>
                Eventuelt
                <Form.Group>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Virksomhed</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Companies.SelectorCreate
                      value={company}
                      onChange={setCompany}
                      createValue={companyCreate}
                      onCreateChange={setCompanyCreate}
                    />
                  </InputGroup>
                </Form.Group>
              </Fragment>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={loading} type="submit">
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setUser(defaultUser);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default CreateUser;

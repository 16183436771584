import React, { useCallback } from "react";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyResource } from "../../models/company";
import Companies from "./Index";
import TableError from "../TableError";
import Quota from "../../models/quota";
import QuotaUsage from "../Quotas/QuotaUsage";
import useLoading from "../../hooks/useLoading";

interface Props {
  company: ICompanyResource;
}
const CompanyUsers: React.FC<Props> = ({ company }) => {
  const request = useCallback(
    (signal: AbortSignal) => Company.ReadUsers(company, signal),
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [users, error] = usePromise(request, [], setLoading);

  const qoutaReq = useCallback(
    (signal: AbortSignal) => Quota.Read(company.id, signal),
    [company.id]
  );

  const [loadingQuota, setLoadingQuota] = useLoading();
  const [quota] = usePromise(qoutaReq, undefined, setLoadingQuota);

  if (loading || loadingQuota) return <em>Loading...</em>;
  if (error) return <TableError />;

  return (
    <div>
      {quota && (
        <div>
          <QuotaUsage
            available={quota.users}
            assigned={quota.assigned}
            used={users.length}
          />
          Brugere
        </div>
      )}
      <Companies.UsersTable company={company} users={users} />
    </div>
  );
};

export default CompanyUsers;

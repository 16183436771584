import React, { useMemo } from "react";
import { ITachoSafeTerminalResource } from "../../../models/tachoSafe/terminal";

interface Props{
    value?: ITachoSafeTerminalResource,
    values: ITachoSafeTerminalResource[],
    onChange: (value?: ITachoSafeTerminalResource) => void
}


const Selector = ({value, values, onChange}: Props) => (
    <select className="form-control w-auto" value={value?.id} onChange={event => {
        const id = +event.target.value;
        const terminal = values.find(v => v.id === id);
        onChange(terminal);
    }}>
        <option>Vælg TachoSafe terminal/enhed</option>
        {values.map(terminal => (
            <option key={terminal.id} value={terminal.id}>{terminal.rduId}, {terminal.rduPhoneNumber}, {terminal.vehicleRegistrationNumber}</option>
        ))}
    </select>
)

export default Selector;


interface IdProps{
    value?: string,
    values: ITachoSafeTerminalResource[],
    onChange: (value?: ITachoSafeTerminalResource) => void
}

export const IdBasedSelector = ({value, values, onChange}: IdProps) => {
    const terminal = useMemo(() => values.find(t => t.rduId === value), [values, value]);

    return <Selector value={terminal} values={values} onChange={onChange} />
}
import { useState, useEffect, useRef } from "react";

const useSuccess = (delay: number): [boolean, () => void] => {
  const timeoutRef = useRef<number | undefined>();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, [timeoutRef]);

  const succeed = () => {
    setSuccess(true);
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => setSuccess(false), delay);
  };

  return [success, succeed];
};

export default useSuccess;
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import EmailTemplate, { IEmailTemplate } from "../../models/emailTemplate";
import FormControlFeedback from "../FormControlFeedback";
import EmailTemplates from "./Index";

interface Props {
  onDeleted: (template: IEmailTemplate) => void;
  template: IEmailTemplate;
}
const DeleteEmailTemplate: React.FC<Props> = ({ onDeleted, template }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [shown, setShown] = useState(false);

  const saveChanges = async () => {
    try {
      setLoading(true);
      await EmailTemplate.Delete(template);
      setShown(false);
      onDeleted(template);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };

  return (
    <Fragment>
      <button className="btn btn-default" onClick={() => setShown(true)}>
        <FontAwesomeIcon icon={faTrash} />
      </button>

      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Slet email skabelon</Modal.Title>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Title</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback errors={error} field="title" readOnly />
              </InputGroup>
              <sub>Ikke en del af sendte emails</sub>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Emne</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  errors={error}
                  field="subject"
                  defaultValue={template.subject}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Indhold</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControlFeedback
                  as="textarea"
                  errors={error}
                  field="body"
                  defaultValue={template.body}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Bruges af</InputGroup.Text>
                </InputGroup.Prepend>
                <EmailTemplates.TypeLabel usedBy={template.usedBy} />
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" disabled={loading} type="submit">
              Slet {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};
export default DeleteEmailTemplate;

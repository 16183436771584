import React from "react";
import { Alert } from "react-bootstrap";

const TableError: React.FC = () => {
  return (
    <Alert variant="warning">
      Der skete en fejl under hentning af tabellen
    </Alert>
  );
};

export default TableError;
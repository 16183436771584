import { faPrint, faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HubConnection } from "@microsoft/signalr";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { Button, Table } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import ReactSwitch from "react-switch";
import ExcludeContext, {
  DefaultExcludeContent,
} from "../../contexts/excludeContext";
import useLiveCoordinates, {
  useLiveCoordinatesFromConnection,
} from "../../hooks/useLiveCoordinates";
import useLiveTrackerStatus, {
  IStatus,
  useLiveTrackerStatusFromConnection,
} from "../../hooks/useLiveTrackerStatus";
import useSortedSearch from "../../hooks/useSortedSearch";
import { ICompanyResource } from "../../models/company";
import { IGPSCoordinate } from "../../models/gpsCoordinate";
import { ITrackingUnitResource, TrackingUnitProps, TrackingUnitResource } from "../../models/trackingUnit";
import { IVehicleResource } from "../../models/vehicle";
import { BatteryLevelIndicator } from "../BatteryLevelIndicator";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import Vehicles from "../Vehicles/Index";
import TrackingUnits from "./Index";
import TrackingUnitRow from "./Row";

interface Props {
  trackingUnits: ITrackingUnitResource[];
  company?: ICompanyResource;
  coordinateConnection?: HubConnection;
  coordinateStarted: boolean;
  trackerConnection?: HubConnection;
  trackerStarted: boolean;
}

type Add = {
  readonly type: "ADD";
  trackingUnit: ITrackingUnitResource;
};
type Remove = {
  readonly type: "REMOVE";
  trackingUnit: ITrackingUnitResource;
};
type Update = {
  readonly type: "UPDATE";
  trackingUnit: ITrackingUnitResource;
};
type Replace = {
  readonly type: "REPLACE";
  trackingUnits: ITrackingUnitResource[];
};

type SetOnline = {
  readonly type: "ONLINE";
  vehicleId: number;
  online: boolean;
};

type Actions = Add | Remove | Update | Replace | SetOnline;

const reducer = (
  trackingUnits: ITrackingUnitResource[],
  action: Actions
): ITrackingUnitResource[] => {
  switch (action.type) {
    case "ADD":
      return [...trackingUnits, action.trackingUnit];
    case "REMOVE":
      return trackingUnits.filter((v) => v.id !== action.trackingUnit.id);
    case "UPDATE":
      const index = trackingUnits.findIndex(
        (u: ITrackingUnitResource) => u.id === action.trackingUnit.id
      );
      return [
        ...trackingUnits.slice(0, index),
        action.trackingUnit,
        ...trackingUnits.slice(index + 1),
      ];
    case "REPLACE":
      return action.trackingUnits;
    case "ONLINE":
      const units = trackingUnits.filter(
        (u) => u.vehicle && u.vehicle.id === action.vehicleId
      );
      const update = units.map((u) => ({
        ...u,
        vehicle: u.vehicle
          ? { ...u.vehicle, online: action.online }
          : undefined,
      }));
      for (const unit of update) {
        trackingUnits = reducer(trackingUnits, {
          type: "UPDATE",
          trackingUnit: unit,
        });
      }
      return trackingUnits;
  }
};

const UnitTable: React.FC<Props> = ({
  trackingUnits: units,
  company,
  coordinateConnection,
  coordinateStarted,
  trackerConnection,
  trackerStarted,
}) => {
  const [trackingUnits, dispatch] = useReducer(reducer, units);
  const [
    sorted,
    search,
    sort,
    setSort,
    setSearch,
  ] = useSortedSearch<TrackingUnitResource, TrackingUnitProps>(trackingUnits.map(v => new TrackingUnitResource(v)), undefined, {
    type: "lastSeen",
    asc: true,
  });

  const { excludes, setExcludes } = useContext(ExcludeContext);

  const unitVehicles = useMemo(
    () =>
      trackingUnits
        .map((u) => u.vehicle)
        .filter((v): v is IVehicleResource => !!v),
    [trackingUnits]
  );

  useEffect(() => {
    if (excludes.vehicles !== unitVehicles) {
      setExcludes({ ...excludes, vehicles: unitVehicles });
    }
  }, [unitVehicles, excludes, setExcludes]);

  useEffect(() => {
    return () => {
      setExcludes(DefaultExcludeContent);
    };
  }, []);

  const updateStatus = useCallback(
    (status: IStatus) => {
      const unit = trackingUnits.find((u) => u.id === status.id);
      if (unit) {
        dispatch({
          type: "UPDATE",
          trackingUnit: { ...unit, isConnected: status.isConnected },
        });
      }
    },
    [trackingUnits]
  );
  useLiveTrackerStatusFromConnection(
    trackerConnection,
    trackerStarted,
    updateStatus
  );

  const updateOnline = useCallback((coordinate: IGPSCoordinate) => {
    dispatch({
      type: "ONLINE",
      vehicleId: coordinate.vehicleId,
      online: coordinate.accOn,
    });
  }, []);
  useLiveCoordinatesFromConnection(coordinateConnection, updateOnline);

  return (
    <div className="tracking-units">
      <Table striped responsive bordered>
        <thead>
          <tr>
            <SearchableHeader
              value={search}
              types={["id"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["company.name"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["vehicle.licensePlate", "vehicle.alias"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["phoneNumber", "iccId"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["lastSeen"]}
              onSearch={setSearch}
            />
          </tr>
          <tr>
            <SortableHeader type="id" value={sort} onChange={setSort}>
              Id
            </SortableHeader>
            <SortableHeader type="company.name" value={sort} onChange={setSort}>
              Virksomhed
            </SortableHeader>
            <SortableHeader
              type="vehicle.alias"
              fallback="vehicle.licensePlate"
              value={sort}
              onChange={setSort}
            >
              Køretøj
            </SortableHeader>
            <SortableHeader type="phoneNumber" value={sort} onChange={setSort}>
              Telefon/SIM-kort nr.
            </SortableHeader>
            <SortableHeader type="lastSeen" value={sort} onChange={setSort}>
              Sidst online
            </SortableHeader>
            <SortableHeader type="unitType" value={sort} onChange={setSort}>
              Enhedstype
            </SortableHeader>
            <SortableHeader type="batteryLevel" value={sort} onChange={setSort}>
              Batteri
            </SortableHeader>
            <SortableHeader type="active" value={sort} onChange={setSort}>
              Aktiv
            </SortableHeader>
            <SortableHeader type="isConnected" value={sort} onChange={setSort}>
              Forbundet
            </SortableHeader>
            <SortableHeader
              type="vehicle.online"
              value={sort}
              onChange={setSort}
            >
              Tændt
            </SortableHeader>
            <th>
              {/* QR */}
              <Link to="/unit/print/qr">
                <Button title="Print mange QR-koder">
                  <FontAwesomeIcon icon={faPrint} />
                </Button>
              </Link>
            </th>
            <th>
              <Link to="/unit/connected">
                <Button title="Vis forbindelser">
                  <FontAwesomeIcon icon={faWifi} />
                </Button>
              </Link>
            </th>
            <th>
              {/*delete */}
              <TrackingUnits.Create
                company={company}
                onCreated={(trackingUnit) =>
                  dispatch({ type: "ADD", trackingUnit })
                }
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<ITrackingUnitResource>
            items={sorted}
            id="trackingUnits"
            sizeSelector={true}
            renderItem={(item) => (
              <TrackingUnitRow
              
                key={item.id}
                value={item}
                coordinateConnection={coordinateConnection}
                trackerConnection={trackerConnection}
                onUpdate={(trackingUnit) =>
                  dispatch({ type: "UPDATE", trackingUnit })
                }
                onDelete={(trackingUnit) =>
                  dispatch({ type: "REMOVE", trackingUnit })
                }
              />
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default UnitTable;

export const UnitActiveTable = ({
  trackingUnits: units,
  company,
  coordinateConnection,
  coordinateStarted,
  trackerConnection,
  trackerStarted,
}: Props): React.ReactElement => {
  const [trackingUnits, dispatch] = useReducer(reducer, units);
  const [
    sorted,
    search,
    sort,
    setSort,
    setSearch,
  ] = useSortedSearch<TrackingUnitResource, TrackingUnitProps>(trackingUnits.map(v => new TrackingUnitResource(v)), undefined, {
    type: "lastSeen",
    asc: true,
  });
  const [onlyNoVehicle, setOnlyNoVehicle] = useState(false);
  const { excludes, setExcludes } = useContext(ExcludeContext);

  const unitVehicles = useMemo(
    () =>
      trackingUnits
        .map((u) => u.vehicle)
        .filter((v): v is IVehicleResource => !!v),
    [trackingUnits]
  );

  useEffect(() => {
    if (excludes.vehicles !== unitVehicles) {
      setExcludes({ ...excludes, vehicles: unitVehicles });
    }
  }, [unitVehicles, excludes, setExcludes]);

  useEffect(() => {
    return () => {
      setExcludes(DefaultExcludeContent);
    };
  }, []);

  const actual = useMemo(() => {
    if (onlyNoVehicle) {
      return sorted.filter((u) => u.active && u.lastSeen && !u.vehicle);
    }
    return sorted.filter((u) => u.active && u.lastSeen);
  }, [sorted, onlyNoVehicle]);

  return (
    <div className="tracking-units">
      <span>
        Vis kun enheder der ikke har køretøj <br />
        <ReactSwitch onChange={setOnlyNoVehicle} checked={onlyNoVehicle} />
      </span>
      <Table striped responsive bordered>
        <thead>
          <tr>
            <SearchableHeader
              value={search}
              types={["id"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["company.name"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["vehicle.licensePlate", "vehicle.alias"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["phoneNumber"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["lastSeen"]}
              onSearch={setSearch}
            />
          </tr>
          <tr>
            <SortableHeader type="id" value={sort} onChange={setSort}>
              Id
            </SortableHeader>
            <SortableHeader type="company.name" value={sort} onChange={setSort}>
              Virksomhed
            </SortableHeader>
            <SortableHeader
              type="vehicle.alias"
              fallback="vehicle.licensePlate"
              value={sort}
              onChange={setSort}
            >
              Køretøj
            </SortableHeader>
            <SortableHeader type="phoneNumber" value={sort} onChange={setSort}>
              Telefon nr.
            </SortableHeader>
            <SortableHeader type="lastSeen" value={sort} onChange={setSort}>
              Sidst online
            </SortableHeader>
            <SortableHeader type="unitType" value={sort} onChange={setSort}>
              Type
            </SortableHeader>
            <SortableHeader type="batteryLevel" value={sort} onChange={setSort}>
              Batteri
            </SortableHeader>
            <SortableHeader type="active" value={sort} onChange={setSort}>
              Aktiv
            </SortableHeader>
            <SortableHeader type="isConnected" value={sort} onChange={setSort}>
              Forbundet
            </SortableHeader>
            <SortableHeader
              type="vehicle.online"
              value={sort}
              onChange={setSort}
            >
              Tændt
            </SortableHeader>
            <th>
              {/* QR */}
              <Link to="/unit/print/qr">
                <Button title="Print mange QR-koder">
                  <FontAwesomeIcon icon={faPrint} />
                </Button>
              </Link>
            </th>
            <th>
              <Link to="/unit/connected">
                <Button title="Vis forbindelser">
                  <FontAwesomeIcon icon={faWifi} />
                </Button>
              </Link>
            </th>
            <th>
              {/*delete */}
              <TrackingUnits.Create
                company={company}
                onCreated={(trackingUnit) =>
                  dispatch({ type: "ADD", trackingUnit })
                }
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<ITrackingUnitResource>
            items={actual}
            id="trackingUnits"
            sizeSelector={true}
            renderItem={(item) => (
              <TrackingUnitRow
                key={item.id}
                value={item}
                coordinateConnection={coordinateConnection}
                trackerConnection={trackerConnection}
                onUpdate={(trackingUnit) =>
                  dispatch({ type: "UPDATE", trackingUnit })
                }
                onDelete={(trackingUnit) =>
                  dispatch({ type: "REMOVE", trackingUnit })
                }
              />
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

export const UnitCompanyTable: React.FC<Props> = ({ trackingUnits: units }) => {
  const [trackingUnits, dispatch] = useReducer(reducer, units);
  const [
    sorted,
    search,
    sort,
    setSort,
    setSearch,
  ] = useSortedSearch<TrackingUnitResource, TrackingUnitProps>(trackingUnits.map(v => new TrackingUnitResource(v)), undefined, {
    type: "id",
    asc: true,
  });

  // const updateStatus = useCallback(
  //   (status: IStatus) => {
  //     const unit = trackingUnits.find((u) => u.id === status.id);
  //     if (unit) {
  //       dispatch({
  //         type: "UPDATE",
  //         trackingUnit: { ...unit, isConnected: status.isConnected },
  //       });
  //     }
  //   },
  //   [trackingUnits]
  // );
  // useLiveTrackerStatus(updateStatus);

  // const updateOnline = useCallback((coordinate: IGPSCoordinate) => {
  //   dispatch({
  //     type: "ONLINE",
  //     vehicleId: coordinate.vehicleId,
  //     online: coordinate.accOn,
  //   });
  // }, []);
  // useLiveCoordinates(updateOnline);

  useEffect(() => dispatch({ type: "REPLACE", trackingUnits: units }), [
    units,
    sort,
  ]);

  return (
    <div className="tracking-units">
      <Table striped responsive bordered>
        <thead>
          <tr>
            <SearchableHeader
              value={search}
              types={["id"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["vehicle.licensePlate", "vehicle.alias"]}
              onSearch={setSearch}
            />
          </tr>
          <tr>
            <SortableHeader type="id" value={sort} onChange={setSort}>
              Id
            </SortableHeader>
            <SortableHeader
              type="vehicle.alias"
              fallback="vehicle.licensePlate"
              value={sort}
              onChange={setSort}
            >
              Køretøj
            </SortableHeader>
            <SortableHeader type="lastSeen" value={sort} onChange={setSort}>
              Sidst online
            </SortableHeader>
            <SortableHeader type="batteryLevel" value={sort} onChange={setSort}>
              Batteri
            </SortableHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<ITrackingUnitResource>
            items={sorted}
            id="trackingUnits"
            sizeSelector={true}
            renderItem={(item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                {item.vehicle ? (
                  <td>
                    <TrackingUnits.UnassignVehicle
                      trackingUnit={item}
                      onUpdated={(trackingUnit) =>
                        dispatch({ type: "UPDATE", trackingUnit })
                      }
                    />
                    {item.vehicle.licensePlate}, <b>{item.vehicle.alias}</b>
                  </td>
                ) : (
                  <Fragment>
                    {item.company ? (
                      <td>
                        <TrackingUnits.AssignVehicle
                          trackingUnit={item}
                          onUpdated={(trackingUnit) =>
                            dispatch({ type: "UPDATE", trackingUnit })
                          }
                        />
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </Fragment>
                )}
                <td>
                  <Moment format="DD-MM-YYYY HH:mm:ss">{item.lastSeen}</Moment>
                </td>
                <td>
                  <BatteryLevelIndicator
                    batteryLevel={item.batteryLevel}
                    warningLevel={40}
                    dangerLevel={20}
                  />
                </td>
                <td>
                  {item.vehicle && (
                    <Vehicles.OnlineIcon vehicle={item.vehicle} />
                  )}
                </td>
              </tr>
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

import QRViewer from "./QRViewer";
import PrintManyUnitCodes from "./PrintManyUnitCodes";
import QRVehicleAssign from "./QRVehicleAssign";
import QRAssignIndex from "./QRAssignIndex";
import TrackingUnitQRCode from "./TrackingUnitQRCode";
import TrackingUnitQRIndex from "./TrackingUnitQRIndex";

class QR {
  static Viewer = QRViewer;
  static PrintMany = PrintManyUnitCodes;
  static Assign = QRVehicleAssign;
  static AssignIndex = QRAssignIndex;
  static UnitQR = TrackingUnitQRCode;
  static UnitQRIndex = TrackingUnitQRIndex;
}

export default QR;

import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PassivePagination from "components/Pagination";
import Label from "components/Vehicles/Label";
import SelectedVehicleContext from "contexts/selectedVehicleContext";
import useSortedSearch from "hooks/useSortedSearch";
import {
  IVehicleResource,
  VehicleProps,
  VehicleResource,
} from "models/vehicle";
import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ToggleSearch } from "../ToggleSearch";

interface Props {
  vehicles: IVehicleResource[];
}

export const VehicleSelector = ({ vehicles }: Props) => {
  const { vehicle, setVehicle } = useContext(SelectedVehicleContext);
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    VehicleResource,
    VehicleProps
  >(
    vehicles.map((v) => new VehicleResource(v)),
    undefined,
    {
      type: "alias",
      fallback: "licensePlate",
      asc: true,
    }
  );

  const handleSelect = (value: IVehicleResource) => {
    if (!vehicle || value.id !== vehicle.id) setVehicle(value);
    else setVehicle(undefined);
  };
  return (
    <table className="mobile-map-vehicles">
      <thead>
        <tr>
          <th>
            <ToggleSearch
              onChange={(value) =>
                setSearch({ value, types: ["alias", "licensePlate"] })
              }
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <PassivePagination
          items={sorted}
          showPages={1}
          defaultPageSize={5}
          renderItem={(item) => (
            <tr key={item.id}>
              <td>
                <Button
                  variant={vehicle?.id === item.id ? "success" : "primary"}
                  onClick={() => handleSelect(item)}
                >
                  <FontAwesomeIcon icon={faCrosshairs} />
                </Button>
                <Label value={item} />
              </td>
            </tr>
          )}
        />
      </tbody>
    </table>
  );
};

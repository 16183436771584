import React, { useCallback } from "react";
import { Spinner } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import TrackingUnit from "../../models/trackingUnit";
import ConnectedUnitTable from "./ConnectedUnitTable";

const ConnectedUnitIndex: React.FC = () => {
  const request = useCallback(
    (signal: AbortSignal) => TrackingUnit.ReadAllConnected(signal),
    []
  );
  const [loading, setLoading] = useLoading();
  const [connected] = usePromise(request, [], setLoading);

  if (loading) return <Spinner animation="border" />;

  return <ConnectedUnitTable units={connected} />;
};

export default ConnectedUnitIndex;

import React from "react";
import { useCallback } from "react"
import useLoading from "../../../hooks/useLoading";
import usePromise from "../../../hooks/usePromise";
import VehicleCoordinateStatus from "../../../models/vehicleCoordinateStatus"
import ContentLoading from "../../ContentLoading";
import Table from "./Table";



const All = () => {
    const request = useCallback((abort: AbortSignal) => VehicleCoordinateStatus.listAll(abort), []);
    const [loading, setLoading] = useLoading();
    const [result] = usePromise(request, [], setLoading);


    return(
        <ContentLoading loading={loading}>
            <Table values={result} />
        </ContentLoading>
    )
}
export default All;
import React, { useContext, useEffect, useMemo, useReducer } from "react";
import { Table } from "react-bootstrap";
import ExcludeContext, {
  DefaultExcludeContent,
} from "../../contexts/excludeContext";
import useSortedSearch from "../../hooks/useSortedSearch";
import { FlexVehicleProps, FlexVehicleResource, IFlexVehicleResource } from "../../models/flexVehicle";
import { IVehicleResource } from "../../models/vehicle";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import CreateFlexVehicle from "./Create";
import DeleteFlexVehicle from "./Delete";

interface Props {
  flexVehicles: IFlexVehicleResource[];
}

type Add = {
  readonly type: "ADD";
  flexVehicle: IFlexVehicleResource;
};
type Remove = {
  readonly type: "REMOVE";
  flexVehicle: IFlexVehicleResource;
};
type Update = {
  readonly type: "UPDATE";
  flexVehicle: IFlexVehicleResource;
};
type Replace = {
  readonly type: "REPLACE";
  flexVehicles: IFlexVehicleResource[];
};

type Actions = Add | Remove | Update | Replace;

const reducer = (
  flexVehicles: IFlexVehicleResource[],
  action: Actions
): IFlexVehicleResource[] => {
  switch (action.type) {
    case "ADD":
      return [...flexVehicles, action.flexVehicle];
    case "REMOVE":
      return flexVehicles.filter(
        (v) => v.vehicleId !== action.flexVehicle.vehicleId
      );
    case "UPDATE":
      const index = flexVehicles.findIndex(
        (v: IFlexVehicleResource) =>
          v.vehicleId === action.flexVehicle.vehicleId
      );
      return [
        ...flexVehicles.slice(0, index),
        action.flexVehicle,
        ...flexVehicles.slice(index + 1),
      ];
    case "REPLACE":
      return action.flexVehicles;
  }
};

const FlexVehicleTable: React.FC<Props> = ({ flexVehicles }) => {
  const [vehicles, dispatch] = useReducer(reducer, flexVehicles);

  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    FlexVehicleResource, FlexVehicleProps
  >(vehicles.map(v => new FlexVehicleResource(v)), undefined, {
    type: "vehicleId",
    asc: true,
  });

  const { excludes, setExcludes } = useContext(ExcludeContext);

  const vehResources = useMemo(
    () =>
      vehicles.map((u) => u.vehicle).filter((v): v is IVehicleResource => !!v),
    [vehicles]
  );

  useEffect(() => {
    if (excludes.vehicles !== vehResources) {
      setExcludes({ ...excludes, vehicles: vehResources });
    }
  }, [vehResources, excludes, setExcludes]);

  useEffect(() => {
    return () => {
      setExcludes(DefaultExcludeContent);
    };
  }, []);

  return (
    <div className="flex-vehicles">
      <b>
        Køretøjer der har en FlexDanmark ID, får deres koordinater sendt videre
        til FlexDanmark
      </b>
      <Table striped responsive bordered>
        <thead>
          <tr>
            <SearchableHeader
              value={search}
              types={["vehicle.alias", "vehicle.licensePlate"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["flexId"]}
              onSearch={setSearch}
            />
            <th>
              <CreateFlexVehicle
                exceptVehicles={vehResources}
                onCreated={(flexVehicle) =>
                  dispatch({ type: "ADD", flexVehicle })
                }
              />
            </th>
          </tr>
          <tr>
            <SortableHeader
              type="vehicle.alias"
              fallback="vehicle.licensePlate"
              value={sort}
              onChange={setSort}
            >
              Køretøj
            </SortableHeader>
            <SortableHeader type="flexId" value={sort} onChange={setSort}>
              FlexDanmark ID
            </SortableHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination
            id={"flex-vehicles"}
            items={sorted}
            sizeSelector={true}
            renderItem={(vehicle) => (
              <tr key={vehicle.vehicleId}>
                {vehicle.vehicle ? (
                  <td>
                    {vehicle.vehicle.alias || vehicle.vehicle.licensePlate}
                  </td>
                ) : (
                  <td></td>
                )}

                <td>{vehicle.flexId}</td>
                <td>
                  <DeleteFlexVehicle
                    flexVehicle={vehicle}
                    onDeleted={(flexVehicle) =>
                      dispatch({ type: "REMOVE", flexVehicle })
                    }
                  />
                </td>
              </tr>
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default FlexVehicleTable;

import AllVehicles from "./All";
import CreateVehicle from "./Create";
import DeleteVehicle from "./Delete";
import EditVehicle from "./Edit";
import VehicleForm from "./Form";
import Label from "./Label";
import VehicleOnlineIcon from "./OnlineIcon";
import VehicleSelector from "./Selector";
import VehicleSelectorCreate from "./SelectorCreate";
import SetVehicleActive from "./SetActive";
import VehicleTable, { VehicleCompanyTable } from "./Table";


class Vehicles{
    static All = AllVehicles;
    static Create = CreateVehicle;
    static Edit = EditVehicle;
    static Delete = DeleteVehicle;
    static Form = VehicleForm;
    static SetActive = SetVehicleActive;
    static OnlineIcon = VehicleOnlineIcon;
    static Table = VehicleTable;
    static CompanyTable = VehicleCompanyTable;
    static Selector = VehicleSelector;
    static SelectorCreate = VehicleSelectorCreate;
    static Label = Label;
    
}

export default Vehicles;
import ContentLoading from "components/ContentLoading";
import useLoading from "hooks/useLoading";
import usePromise from "hooks/usePromise";
import TimeSpan from "models/timespan";
import Vehicle, { IVehicleResource } from "models/vehicle";
import React, { Fragment, useCallback } from "react";
import { PeriodTab } from "./PeriodTab";

interface Props {
  vehicle: IVehicleResource;
  from: Date;
  to: Date;
  minDuration: TimeSpan,
  maxDistance: number
}

export const HistoryTable = ({ vehicle, from, to, minDuration, maxDistance }: Props) => {
  const request = useCallback(
    (signal?: AbortSignal) =>
      Vehicle.ReadPeriodicHistory(
        vehicle,
        from,
        to,
        minDuration,
        maxDistance,
        signal
      ),
    [vehicle, from, to, minDuration, maxDistance]
  );

  const [loading, setLoading] = useLoading();
  const [periods] = usePromise(request, undefined, setLoading);
  return (
    <ContentLoading loading={loading}>
      {periods && (
        <Fragment>
          <div className="mobile-history">
            {periods.periods.length === 0 && (
              <p>
                <strong>Ingen historik for perioden</strong>
              </p>
            )}
            {periods.periods.map((p, index) => (
              <PeriodTab key={index} value={p} />
            ))}
          </div>
        </Fragment>
      )}
    </ContentLoading>
  );
};

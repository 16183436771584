import {
  faForward,
  faPause,
  faRedo,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLoading from "hooks/useLoading";
import usePromise from "hooks/usePromise";
import { DrivingPeriod } from "models/drivingPeriod";
import Vehicle, { IVehicleResource } from "models/vehicle";
import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { Button } from "react-bootstrap";
import Moment from "react-moment";

type Props = {
  vehicle: IVehicleResource;
};

export const DrivingPeriodDisplay = ({ vehicle }: Props) => {
  const [loading, setLoading] = useLoading();

  const request = useCallback(
    () => Vehicle.ReadDrivingPeriod(vehicle.id),
    [vehicle.id]
  );

  const [period, setPeriod] = useState<DrivingPeriod | undefined>(undefined);

  const fetchPeriod = useCallback(async () => {
    try {
      setLoading(true);
      const period = await request();
      setPeriod(period);
    } catch (ex) {
      console.error("Failed to fetch period: ", ex);
    } finally {
      setLoading(false);
    }
  }, [request]);

  useEffect(() => {
    fetchPeriod();
  }, [fetchPeriod]);

  const spinnerClass = useMemo(
    () => (loading ? "loading-spinner active" : "loading-spinner"),
    [loading]
  );

  if (!period) return null;

  return (
    <div className="driving-period">
      <div className="section">
        <span>Aktuel aktivitet begyndt:</span>
        <div className="sub-section">
          {period.driving ? (
            <div className="speed-label-icon active">
              <FontAwesomeIcon icon={faForward} />
            </div>
          ) : (
            <div className="speed-label-icon inactive">
              <FontAwesomeIcon icon={faPause} />
            </div>
          )}

          <div>
            <Moment format="DD-MM-YYYY HH:mm:ss">{period.start}</Moment>
          </div>
        </div>
      </div>
      <Button
        className="btn-xs"
        title="Opdater aktuel aktivitet"
        variant="secondary"
        onClick={fetchPeriod}
      >
        <div className={spinnerClass}>
          <FontAwesomeIcon icon={faSync} />
        </div>
      </Button>
    </div>
  );
};

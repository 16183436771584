import {
  faChevronDown,
  faChevronUp,
  faForward,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Address from "components/Coordinates/Address";
import KilometerLabel from "components/KilometerLabel";
import { fancyTimeFormat } from "helpers/utils";
import { IHistoryPeriod } from "models/historyPeriod";
import React, { useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import Moment from "react-moment";

interface Props {
  value: IHistoryPeriod;
}

const duration = (from: string, to: string) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const diff = Math.abs(toDate.getTime() - fromDate.getTime());
  return fancyTimeFormat(diff);
};

export const PeriodTab = ({ value }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="mobile-history-row">
      <div className="mobile-history-rowheader">
        <div className="mobile-history-section">
          <FontAwesomeIcon icon={value.isMoving ? faForward : faPause} />
        </div>
        <div className="mobile-history-section">
          <Moment local utc format="DD-MM-YYYY HH:mm">
            {value.start.timestamp}
          </Moment>
        </div>
        <div className="mobile-history-section history-address">
          <Address coordinate={value.start} />
        </div>
        <div className="mobile-history-section">
          <Button size="sm" onClick={() => setOpen((prev) => !prev)}>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
          </Button>
        </div>
      </div>
      {open && (
        <div className="mobile-history-content">
          <Table striped bordered size="sm">
            <tbody>
              <tr>
                <td>Fra:</td>
                <td>
                  <Address coordinate={value.start} />
                  <br />
                  <Moment local utc format="DD-MM-YYYY HH:mm">
                    {value.start.timestamp}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>Til:</td>
                <td>
                  <Address coordinate={value.stop} />
                  <br />
                  <Moment local utc format="DD-MM-YYYY HH:mm">
                    {value.stop.timestamp}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>Afstand i KM:</td>
                <td>
                  <KilometerLabel kilometers={value.distance / 1000} />
                </td>
              </tr>
              <tr>
                <td>
                  Tid:
                </td>
                <td>
                  {duration(value.start.timestamp, value.stop.timestamp)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

import { faDirections } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { isVehicle } from "../../helpers/instanceOf";
import { GeoFeature } from "../../models/geoAddress";
import GeoRoute, { RouteFinder } from "../../models/route";
import Vehicle, { IVehicleResource } from "../../models/vehicle";
import RoutePositionSelector from "./RoutePositionSelector";

interface Props {
  onSelect: (geo: GeoRoute) => void;
  onOpen?: () => void;
}

const RouteSelector: React.FC<Props> = ({ onSelect, onOpen }) => {
  const [shown, setShown] = useState(false);
  const [start, setStart] = useState<
    IVehicleResource | GeoFeature | undefined
  >();
  const [end, setEnd] = useState<IVehicleResource | GeoFeature | undefined>();
  const [loading, setLoading] = useState(false);

  const findRoute = async () => {
    try {
      if (start && end) {
        setLoading(true);
        var posStart: [number, number] = [0, 0];
        var posEnd: [number, number] = [0, 0];
        //Get start position
        if (isVehicle(start)) {
          const vehCoord = await Vehicle.ReadLocation(start);
          if(!vehCoord) return;
          posStart = [vehCoord.latitude, vehCoord.longitude];
        } else {
          posStart = [start.center[1], start.center[0]];
        }
        //Get destination position
        if (isVehicle(end)) {
          const vehCoord = await Vehicle.ReadLocation(end);
          if(!vehCoord) return;
          posEnd = [vehCoord.latitude, vehCoord.longitude];
        } else {
          posEnd = [end.center[1], end.center[0]];
        }

        const route = await RouteFinder.GetRoute(posStart, posEnd);
        if (route) {
          onSelect(route);
          setShown(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button
        variant="secondary"
        title="Rute planlægger"
        onClick={() => {
          setShown(true);
          if (onOpen) {
            onOpen();
          }
        }}
      >
        <FontAwesomeIcon icon={faDirections} />
      </Button>
      <Modal show={shown} onHide={() => setShown(false)}>
        <Modal.Header>
          <h3>Route planlægger</h3>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <b>Vælg start</b>
              <RoutePositionSelector value={start} onChange={setStart} />
              <hr />
            </div>
            <div>
              <b>Vælg destination</b>
              <RoutePositionSelector value={end} onChange={setEnd} />
              <hr />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={!start || !end}
            onClick={() => findRoute()}
          >
            Find rute
            {loading && <Spinner animation="border" size="sm" />}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShown(false);
              setStart(undefined);
              setEnd(undefined);
            }}
          >
            Luk
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

interface VehicleProps extends Props {
  vehicle: IVehicleResource;
}
export const VehicleRouteSelector: React.FC<VehicleProps> = ({
  onSelect,
  onOpen,
  vehicle,
}) => {
  const [shown, setShown] = useState(false);
  const [start, setStart] = useState<
    IVehicleResource | GeoFeature | undefined
  >(vehicle);
  const [end, setEnd] = useState<IVehicleResource | GeoFeature | undefined>();
  const [loading, setLoading] = useState(false);

  const findRoute = async () => {
    try {
      if (start && end) {
        setLoading(true);
        var posStart: [number, number] = [0, 0];
        var posEnd: [number, number] = [0, 0];
        //Get start position
        if (isVehicle(start)) {
          const vehCoord = await Vehicle.ReadLocation(start);
          if(!vehCoord) return;
          posStart = [vehCoord.latitude, vehCoord.longitude];
        } else {
          posStart = [start.center[1], start.center[0]];
        }
        //Get destination position
        if (isVehicle(end)) {
          const vehCoord = await Vehicle.ReadLocation(end);
          if(!vehCoord) return;
          posEnd = [vehCoord.latitude, vehCoord.longitude];
        } else {
          posEnd = [end.center[1], end.center[0]];
        }

        const route = await RouteFinder.GetRoute(posStart, posEnd);
        if (route) {
          onSelect(route);
          setShown(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button
        size="sm"
        title="Route planlægger"
        className="vehicle-control-button-right"
        variant="secondary"
        onClick={() => {
          setShown(true);
          if (onOpen) {
            onOpen();
          }
        }}
      >
        <FontAwesomeIcon icon={faDirections} />
      </Button>
      <Modal show={shown} onHide={() => setShown(false)}>
        <Modal.Header>
          <h3>Route planlægger</h3>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <b>Vælg start</b>
              <RoutePositionSelector value={start} onChange={setStart} />
              <hr />
            </div>
            <div>
              <b>Vælg destination</b>
              <RoutePositionSelector value={end} onChange={setEnd} />
              <hr />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={!start || !end}
            onClick={() => findRoute()}
          >
            Find rute
            {loading && <Spinner animation="border" size="sm" />}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShown(false);
              setStart(undefined);
              setEnd(undefined);
            }}
          >
            Luk
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default RouteSelector;

import React, { useReducer } from "react";
import * as ReactBootstrap from "react-bootstrap";
import useSortedSearch from "../../hooks/useSortedSearch";
import CoordinateReflector, {
  CoordinateReflectorProps,
  ICoordinateReflectorResource,
} from "../../models/coordinateReflector";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import CoordinateReflectors from "./Index";
import Row from "./Row";

interface Props {
  values: ICoordinateReflectorResource[];
}

/**
 * Adds a coordinate reflector
 */
type Add = {
  readonly type: "ADD";
  reflector: ICoordinateReflectorResource;
};
/**
 * Removes a coordinate reflector
 */
type Remove = {
  readonly type: "REMOVE";
  reflector: ICoordinateReflectorResource;
};


type Actions = Add | Remove

const reducer = (
  reflectors: ICoordinateReflectorResource[],
  action: Actions
): ICoordinateReflectorResource[] => {
  switch (action.type) {
    case "ADD":
      return [...reflectors, action.reflector];
    case "REMOVE":
      return reflectors.filter((r) => r.toId !== action.reflector.toId);
  }
};

const Table = ({ values }: Props): React.ReactElement => {
  const [reflectors, dispatch] = useReducer(reducer, values);

  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    CoordinateReflector,
    CoordinateReflectorProps
  >(
    reflectors.map((r) => new CoordinateReflector(r)),
    undefined,
    {
      type: "from.alias",
      fallback: "from.licensePlate",
      asc: true,
    }
  );

  return (
    <div className="reflectors">
      <ReactBootstrap.Table striped responsive bordered>
        <thead>
          <tr>
            <SearchableHeader
              value={search}
              types={["from.alias", "from.licensePlate"]}
              onSearch={setSearch}
            />
            <SearchableHeader
              value={search}
              types={["to.alias", "to.licensePlate"]}
              onSearch={setSearch}
            />
            <th>
              <CoordinateReflectors.Create
                onCreated={(reflector) => dispatch({ type: "ADD", reflector })}
              />
            </th>
          </tr>
          <tr>
            <SortableHeader
              type="from.alias"
              fallback="from.licensePlate"
              value={sort}
              onChange={setSort}
            >
              Fra køretøj
            </SortableHeader>
            <SortableHeader
              type="to.alias"
              fallback="to.licensePlate"
              value={sort}
              onChange={setSort}
            >
              Til køretøj
            </SortableHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination
            items={sorted}
            id="reflectors"
            sizeSelector={true}
            renderItem={(item) => (
              <Row
                value={item}
                onDelete={(reflector) =>
                  dispatch({ type: "REMOVE", reflector })
                }
              />
            )}
          />
        </tbody>
      </ReactBootstrap.Table>
    </div>
  );
};

export default Table;

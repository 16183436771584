import React, { useCallback } from "react";
import usePromise from "../../hooks/usePromise";
import useTitle from "../../hooks/useTitle";
import Quota from "../../models/quota";
import QuotaTable from "./QuotaTable";
import TableError from "../TableError";
import useLoading from "../../hooks/useLoading";

const Quotas: React.FC = () => {
  const request = useCallback(
    (signal: AbortSignal) => Quota.ReadAll(signal),
    []
  );
  const [loading, setLoading] = useLoading();
  const [quotas, error] = usePromise(request, [], setLoading);

  useTitle("DigiFleet - Kvoter");
  if (loading) return <em>Loading...</em>;
  else if (error) return <TableError />;
  return <QuotaTable quotas={quotas} />;
};

export default Quotas;

import React, { useCallback } from "react";
import usePromise from "../../hooks/usePromise";
import useTitle from "../../hooks/useTitle";
import EmulatedUnit from "../../models/emulatedUnit";
import { EmulatorTable } from "./EmulatorTable";
import TableError from "../TableError";
import useLoading from "../../hooks/useLoading";

const Emulators: React.FC = () => {
  const request = useCallback(
    (signal: AbortSignal) => EmulatedUnit.ReadAll(signal),
    []
  );
  const [loading, setLoading] = useLoading();
  const [emulators, error] = usePromise(request, [], setLoading);

  useTitle("DigiFleet - Emulatorer");
  if (loading) return <em>Loading...</em>;
  if (error) return <TableError />;
  return <EmulatorTable emulators={emulators} />;
};

export default Emulators;

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Company, { ICompanyBinding, ICompanyResource } from "../../models/company";
import Companies from "./Index";

interface Props {
  onCreated?: (company: ICompanyResource) => void;
}
const defaultCompany: ICompanyBinding = {
  name: "",
  demo: false,
};
const CreateCompany: React.FC<Props> = ({ onCreated }) => {
  const [company, setCompany] = useState<ICompanyBinding>(defaultCompany);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);

  /**
   * Save the changes, creates the vehicle
   */
  const saveChanges = async () => {
    try {
      setLoading(true); //Show loading while the vehicle is being created
      const created = await Company.Create(company);
      setLoading(false);
      setShown(false);
      if (onCreated) {
        onCreated(created);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  };
  /**
   * Save when the form is submitted
   * @param event
   */
  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    await saveChanges();
  };
  return (
    <Fragment>
      <Button title="Opret virksomhed" onClick={() => setShown(true)}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>

      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSave}>
          <Modal.Title>Opret virksomhed</Modal.Title>
          <Modal.Body>
            <Companies.Form error={error} value={company} onChange={setCompany} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={loading} type="submit">
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setCompany(defaultCompany);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default CreateCompany;

import { ICompanyResource } from "../../models/company";
import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import CompanyExportIndex from "./CompanyExport";
import VehicleExportIndex from "./VehicleExport";
import VehicleHistoryExportIndex from "./VehicleHistoryExport";
import VehicleCoordinateExportIndex from "./VehicleCoordinateExport";
import VehicleCustomExportIndex from "./CustomVehicleExport";

interface Props {
  company: ICompanyResource;
  initialTab?: string;
}

const ExportTabs: React.FC<Props> = ({ company, initialTab }) => {
  const [tab, setTab] = useState(initialTab || "company");
  useEffect(() => {
    if (initialTab) {
      setTab(initialTab);
    }
  }, [initialTab]);

  return (
    <Tabs
      activeKey={tab}
      unmountOnExit={true}
      onSelect={(k: string | null) => k && setTab(k)}
      id="company-tabs"
    >
      <Tab eventKey="company" title="Alle køretøjer">
        <div className="export-option">
          <CompanyExportIndex company={company} />
        </div>
      </Tab>
      <Tab eventKey="vehicle" title="Enkelt køretøjs daglige kørsel">
        <div className="export-option">
          <VehicleExportIndex company={company} />
        </div>
      </Tab>
      <Tab eventKey="history" title="Enkelt køretøjs historik">
        <div className="export-option">
          <VehicleHistoryExportIndex company={company} />
        </div>
      </Tab>
      <Tab eventKey="coordinates" title="Enkelt køretøjs koordinater">
        <div className="export-option">
          <VehicleCoordinateExportIndex company={company} />
        </div>
      </Tab>
      <Tab eventKey="custom" title="Brugerdefineret">
        <div className="export-option">
          <VehicleCustomExportIndex company={company} />
        </div>
      </Tab>
    </Tabs>
  );
};
export default ExportTabs;

import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Centered from "components/Centered";
import { CompanySpecificDriving } from "components/Charts/CompanyDrivenChart";
import ContentLoading from "components/ContentLoading";
import VehiclesDrivenDashboard from "components/Dashboard/VehiclesDriven";
import SelectedCompanyContext from "contexts/selectedCompanyContext";
import useLoading from "hooks/useLoading";
import usePromise from "hooks/usePromise";
import Company from "models/company";
import { IVehicleResource } from "models/vehicle";
import React, { Fragment, useCallback } from "react";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Driving = () => {
  const { company } = useContext(SelectedCompanyContext);
  const request = useCallback(
    (abort: AbortSignal) => {
      if (!company) return new Promise<IVehicleResource[]>(() => []);

      return Company.ReadVehicles(company, abort);
    },
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [vehicles] = usePromise(request, [], setLoading);
  return (
    <Fragment>
      <Link to="/nav">
        <Button variant="secondary">
          <FontAwesomeIcon icon={faArrowCircleLeft} />
        </Button>
      </Link>
      <ContentLoading loading={loading}>
        <VehiclesDrivenDashboard vehicles={vehicles} />
      </ContentLoading>
    </Fragment>
  );
};
export default Driving;

import {
  createFetch,
  deleteFetch,
  readFetch,
  updateFetch,
} from "../helpers/fetchers";
import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { ICloseEncounter } from "./closeEncounter";
import { IFlexVehicleResource } from "./flexVehicle";
import { ICoordinate } from "./gpsCoordinate";
import { ICompanyDriven } from "./graphing/companyDriven";
import { ISpecificDriven } from "./graphing/driven";
import { IVehiclesOnline } from "./graphing/vehiclesOnline";
import { ITachoSafeCompanyResource } from "./tachoSafe/company";
import { ITrackingUnitResource } from "./trackingUnit";
import { IUserResource } from "./user";
import { IVehicleResource, IVehicleResourceWithUnit } from "./vehicle";
import { IVehicleTachoResource } from "./vehicleTacho";

export interface ICompanyIdentifiable {
  id: number;
  name: string;
}
export interface ICompanyResource extends ICompanyIdentifiable {
  demo: boolean;
  remote?: ITachoSafeCompanyResource;
}
export type CompanyProps = "id" | "name" | "demo";
export class CompanyResource
  implements
    ICompanyResource,
    ISortable<CompanyProps>,
    ISearchable<CompanyProps> {
  id: number;
  name: string;
  demo: boolean;
  remote?: ITachoSafeCompanyResource;
  constructor(value: ICompanyResource) {
    this.id = value.id;
    this.name = value.name;
    this.demo = value.demo;
    this.remote = value.remote;
  }
  search = (value: any, type: CompanyProps): number | undefined =>
    basicSearch(this, value, type, false);
  compare = (
    value: ISortable<CompanyProps>,
    primary: CompanyProps,
    secondary?: "id" | "name" | "demo" | undefined
  ) => basicSecSort(this, value, primary, secondary);
}

export interface ICompanyBinding {
  name: string;
  demo: boolean;
  remote?: ITachoSafeCompanyResource;
}

export default class Company {
  static ToBinding = (resource: ICompanyResource): ICompanyBinding => {
    return {
      name: resource.name,
      demo: resource.demo,
    };
  };

  static ToResource = (
    binding: ICompanyBinding,
    initial: ICompanyResource
  ): ICompanyResource => {
    return { ...initial, name: binding.name, demo: binding.demo };
  };
  static Create = async (
    company: ICompanyBinding,
    abort?: AbortSignal
  ): Promise<ICompanyResource> =>
    (await createFetch("api/Companies/", company, abort)) as ICompanyResource;

  static Read = async (
    id: number,
    abort?: AbortSignal
  ): Promise<ICompanyResource> =>
    (await readFetch("api/Companies/" + id, abort)) as ICompanyResource;

  static ReadAll = async (abort?: AbortSignal): Promise<ICompanyResource[]> =>
    (await readFetch("api/Companies", abort)) as ICompanyResource[];

  static Update = async (
    id: number,
    company: ICompanyBinding,
    abort?: AbortSignal
  ): Promise<void> => {
    await updateFetch(`api/Companies/${id}`, company, abort);
  };
  static Delete = async (
    company: ICompanyResource,
    abort?: AbortSignal
  ): Promise<void> => {
    await deleteFetch(`api/Companies/${company.id}`, abort);
  };
  static ReadVehicles = async (
    company: ICompanyIdentifiable,
    abort?: AbortSignal
  ): Promise<IVehicleResourceWithUnit[]> =>
    (await readFetch(
      `api/Companies/${company.id}/vehicles`,
      abort
    )) as IVehicleResourceWithUnit[];
  static ReadVehicleTachographs = async (
    company: ICompanyIdentifiable,
    abort?: AbortSignal
  ): Promise<IVehicleTachoResource[]> =>
    (await readFetch(
      `api/Companies/${company.id}/vehicles/tachographs`,
      abort
    )) as IVehicleTachoResource[];

  static ReadUsers = async (
    company: ICompanyResource,
    abort?: AbortSignal
  ): Promise<IUserResource[]> =>
    (await readFetch(
      `api/Companies/${company.id}/users`,
      abort
    )) as IUserResource[];

  static SyncRemote = async (
    company: ICompanyIdentifiable,
    create: boolean,
    abort?: AbortSignal
  ): Promise<IVehicleResource[]> =>
    (await createFetch(
      `api/Companies/${company.id}/Tachosafe/Sync?create=${create}`,
      undefined,
      abort
    )) as IVehicleResourceWithUnit[];

  static AssignUser = async (
    company: ICompanyResource,
    user: IUserResource,
    abort?: AbortSignal
  ): Promise<void> => {
    await createFetch(`api/Companies/${company.id}/users`, user, abort);
  };

  static ReadTrackingUnits = async (
    company: ICompanyResource,
    abort?: AbortSignal
  ): Promise<ITrackingUnitResource[]> =>
    (await readFetch(
      `api/Companies/${company.id}/units`,
      abort
    )) as ITrackingUnitResource[];

  static UnassignUser = async (
    company: ICompanyResource,
    user: IUserResource,
    abort?: AbortSignal
  ): Promise<void> => {
    await deleteFetch(`api/Companies/${company.id}/users/${user.id}`, abort);
  };

  static ReadOnlineVehicles = async (
    company: ICompanyIdentifiable,
    abort?: AbortSignal
  ): Promise<IVehiclesOnline> =>
    (await readFetch(
      `api/Graphing/company/${company.id}/vehicles/online`,
      abort
    )) as IVehiclesOnline;

  static ReadKilometersDriven = async (
    company: ICompanyIdentifiable,
    abort?: AbortSignal
  ): Promise<ICompanyDriven> =>
    (await readFetch(
      `api/Graphing/company/${company.id}/vehicles/driven/monthly`,
      abort
    )) as ICompanyDriven;
  static ReadSpecificKilometersDriven = async (
    company: ICompanyIdentifiable,
    start: Date,
    end: Date,
    abort?: AbortSignal
  ): Promise<ISpecificDriven[]> =>
    (await readFetch(
      `api/Graphing/company/${company.id}/vehicles/driven/specific?start=${start.toISOString().substr(0, 10)}&end=${end.toISOString().substr(0, 10)}`,
      abort
    )) as ISpecificDriven[];

  static ReadProximity = async (
    company: ICompanyResource,
    coordinate: ICoordinate,
    from: Date,
    to: Date,
    abort?: AbortSignal
  ): Promise<ICloseEncounter[]> =>
    (await readFetch(
      `api/Tracking/${company.id}/proximity?lat=${coordinate.latitude}&lon=${
        coordinate.longitude
      }&from=${from.toISOString()}&to=${to.toISOString()}`,
      abort
    )) as ICloseEncounter[];

  static ReadFlexVehicles = async (
    company: ICompanyResource,
    abort?: AbortSignal
  ): Promise<IFlexVehicleResource[]> =>
    (await readFetch(
      `api/Companies/${company.id}/vehicles/flex`,
      abort
    )) as IFlexVehicleResource[];
}

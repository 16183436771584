import React, { useCallback } from "react";
import { Spinner } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Vehicle, { IVehicleResource } from "../../models/vehicle";
import { CoordinateTableNoAddress } from "./Table";

interface Props {
  vehicle: IVehicleResource;
  from: Date;
  to: Date;
}

const HistoryRowCoordinates = ({
  vehicle,
  from,
  to,
}: Props): React.ReactElement => {
  const request = useCallback(
    (signal: AbortSignal) =>
      Vehicle.ReadTrackingHistorySpeeds(vehicle, from, to, signal),
    [vehicle, from, to]
  );
    const [loading, setLoading] = useLoading();
  const [coordinates] = usePromise(request, [], setLoading);


  if (loading) return <Spinner animation="border" />;

  return <CoordinateTableNoAddress coordinates={coordinates} />;
};

export default HistoryRowCoordinates;

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, FormControl, InputGroup, Modal, Spinner } from "react-bootstrap";
import FlexVehicle, { IFlexVehicleResource } from "../../models/flexVehicle";
interface Props {
  flexVehicle: IFlexVehicleResource;
  onDeleted?: (flexVehicle: IFlexVehicleResource) => void;
}
const DeleteFlexVehicle: React.FC<Props> = ({ flexVehicle, onDeleted }) => {
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);
  const deleteFlex = async () => {
    try {
      setLoading(true);
      await FlexVehicle.Delete(flexVehicle);
      setLoading(false);
      setShown(false);
      if (onDeleted) {
        onDeleted(flexVehicle);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const formDelete = async (event: FormEvent) => {
    event.preventDefault();
    await deleteFlex();
  };
  return (
    <Fragment>
      <button title="Slet Flex" className="blank-button" onClick={() => setShown(true)}>
        <FontAwesomeIcon icon={faTrash} />
      </button>

      <Modal show={shown} onHide={() => setShown(true)}>
        <form onSubmit={formDelete}>
          <Modal.Title>Slet FlexDanmark forbindelse</Modal.Title>
          <Modal.Body>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>DigiFleet ID</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly={true}
                defaultValue={flexVehicle.vehicleId}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>FlexDanmark ID</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly={true}
                defaultValue={flexVehicle.flexId}
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" disabled={loading} type="submit">
              Slet {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShown(false)}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default DeleteFlexVehicle;

import { Route } from "../../models/route"
import { Polyline } from "react-leaflet"
import React, { Fragment } from "react";
import { faPlay, faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import MapFAIcon from "./MapFAIcon";

interface Props{
    route: Route
    [x: string]: any
}

const RouteTrack:React.FC<Props> = ({route, ...rest}) =>{
    const points = route.geometry.coordinates.map(c => ({lat: c[1], lng: c[0]}));

    return(
        <Fragment>
            <MapFAIcon position={points[0]} icon={faPlay} />
            <Polyline positions={points} color="red" />
            <MapFAIcon position={points[points.length - 1]} icon={faFlagCheckered} />
        </Fragment>
    )
}

export default RouteTrack;
import React, { Fragment, useMemo, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import GPSCoordinate, { ITimedCoordinate } from "../../models/gpsCoordinate";

interface Props {
  coordinate: ITimedCoordinate;
}

const CoordinateAddress: React.FC<Props> = ({ coordinate }) => {
  const request = useCallback(
    (signal: AbortSignal) => GPSCoordinate.GetAddress(coordinate, signal),
    [coordinate]
  );
  const [loading, setLoading] = useLoading();
  const [feature] = usePromise(request, undefined, setLoading);

  const address = useMemo(
    () =>
      feature
        ? feature.features.find(
            (f) => f.place_type[0] === "address" || f.place_type[0] === "poi"
          )?.place_name
        : "",
    [feature]
  );

  if (loading) {
    return <Spinner animation="border" size="sm" />;
  }

  return <span title={address}>{address || "Ukendt"}</span>;
};

export default CoordinateAddress;

import { ICompanyResource } from "models/company";
import React from "react";
import { useCallback } from "react";
import useLoading from "../../../hooks/useLoading";
import usePromise from "../../../hooks/usePromise";
import TachoSafeCompany, { ITachoSafeCompanyResource } from "../../../models/tachoSafe/company";
import { ITachoSafeTerminalResource } from "../../../models/tachoSafe/terminal";
import Selector, { IdBasedSelector } from "../Terminals/Selector";

interface Props{
    value?: ITachoSafeTerminalResource,
    company: ICompanyResource,
    onChange: (value?: ITachoSafeTerminalResource) => void
}

const TerminalSelector = ({company, ...props}: Props) => {
    const request = useCallback((signal: AbortSignal) => TachoSafeCompany.ReadTerminals(company, signal), [company]);
    const [loading, setLoading] = useLoading();

    const [terminals] = usePromise(request, [], setLoading);

    return <Selector {...props} values={terminals} />
}
export default TerminalSelector;
interface IdProps{
    value?: string,
    company: ICompanyResource,
    onChange: (value?: ITachoSafeTerminalResource) => void
}

export const TerminalIDSelector = ({company, ...props}: IdProps) => {
    const request = useCallback((signal: AbortSignal) => TachoSafeCompany.ReadTerminals(company, signal), [company]);
    const [loading, setLoading] = useLoading();

    const [terminals] = usePromise(request, [], setLoading);

    return <IdBasedSelector {...props} values={terminals} />
}
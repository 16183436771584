import React, { useRef, useEffect } from "react";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  delay?: number;
  [x: string]: any;
}

/**
 * A search box that has a delay before firing its onChange event
 * @param param0
 */
const SearchBox: React.FC<Props> = ({ onChange, delay, ...rest }) => {
  const timeoutRef = useRef<number | undefined>();

  useEffect(() => {
    return () => {
        //Clear the timeout when this component unmounts
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //Clear the timeout if a previous one is already running
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    //Persist the event such that it can be used in an asyncronous way
    event.persist(); 
    //Start a new timeout that will fire the onchange event once it finishes
    timeoutRef.current = window.setTimeout(() => onChange(event), delay || 500);
  };

  return <input {...rest} onChange={handleChange} />;
};

export default SearchBox;

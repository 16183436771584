import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import IExportOption, {
  ICoordinateOptions,
  IDrivingExportOption,
  IHistoryExportOption,
} from "../../models/exportOption";
import CustomCoordinateExport from "./CustomCoordinateExport";
import CustomDrivingExport from "./CustomDrivingExport";
import CustomHistoryExport from "./CustomHistoryExport";

interface Props {
  value: IExportOption;
  onChange: (value: IExportOption) => void;
}

const initialHistory: IHistoryExportOption = {
  activity: true,
  startLatLng: false,
  startTimestamp: true,
  startAddress: true,
  endLatLng: false,
  endTimestamp: true,
  endAddress: true,
  duration: true,
  distance: true,
  maxSpeed: false,
  vehicleAlias: false,
  vehicleLicensePlate: true
};
const initialDriving: IDrivingExportOption = {
  timestamp: true,
  totalKilometers: true,
  totalKilometersChart: true,
  maxSpeed: false,
  averageSpeed: false,
  moving: false,
};
const initialCoordinates: ICoordinateOptions = {
  latitude: true,
  longitude: true,
  speed: true,
  acc: false,
  timestamp: true,
  alias: false,
  licensePlate: false
};

const CustomExportForm: React.FC<Props> = ({ value, onChange }) => {
  const addHistory = () => {
    onChange({ ...value, history: initialHistory });
  };
  const removeHistory = () => {
    onChange({ ...value, history: undefined });
  };
  const addDriving = () => {
    onChange({ ...value, driving: initialDriving });
  };
  const removeDriving = () => {
    onChange({ ...value, driving: undefined });
  };
  const addCoordinates = () => {
    onChange({ ...value, coordinates: initialCoordinates });
  };
  const removeCoordinates = () => {
    onChange({ ...value, coordinates: undefined });
  };
  return (
    <div>
      <Row>
        <Col>
          <b>Kørsel</b>
          {value.driving ? (
            <div>
              <Button
                variant="danger"
                title="Fjern kørsel"
                onClick={removeDriving}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
              <hr />
              <CustomDrivingExport
                value={value.driving}
                onChange={(driving) => onChange({ ...value, driving })}
              />
            </div>
          ) : (
            <div>
              <Button
                variant="success"
                title="Tilføj kørsel"
                onClick={addDriving}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          )}
        </Col>
        <Col>
          <b>Historik</b>
          {value.history ? (
            <div>
              <Button
                variant="danger"
                title="Fjern historik"
                onClick={removeHistory}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
              <hr />
              <CustomHistoryExport
                value={value.history}
                onChange={(history) => onChange({ ...value, history })}
              />
            </div>
          ) : (
            <div>
              <Button
                variant="success"
                title="Tilføj historik"
                onClick={addHistory}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          )}
        </Col>
        <Col>
          <b>Koordinater</b>
          {value.coordinates ? (
            <div>
              <Button
                variant="danger"
                title="Fjern koordinater"
                onClick={removeCoordinates}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
              <hr />
              <CustomCoordinateExport
                value={value.coordinates}
                onChange={(coordinates) => onChange({ ...value, coordinates })}
              />
            </div>
          ) : (
            <div>
              <Button
                variant="success"
                title="Tilføj koordinater"
                onClick={addCoordinates}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CustomExportForm;

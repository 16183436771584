import { faForward, faPause } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";

interface Props {
  speed: number;
  acc?: boolean
}

const SpeedLabel: React.FC<Props> = ({ speed, acc }) => {
  const spd = Math.round(speed * 3.6);

  const still = useMemo(() => spd === 0, [spd]);

  const clsName = useMemo(() => acc ? still ? "speed-label-icon still" : "speed-label-icon active" : "speed-label-icon inactive", [acc, still]);

  if (still) {
    return (
      <div className="speed-label">
        <div className={clsName}>
          <FontAwesomeIcon icon={faPause} />
        </div>
        {spd} km/t
      </div>
    );
  }

  return (
    <div className="speed-label">
      <div className={clsName}>
          <FontAwesomeIcon icon={faForward} />
        </div>
      {spd} km/t
    </div>
  );
};

export default SpeedLabel;

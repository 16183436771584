import React from "react";

const Bike = () => (
  <svg
    viewBox="0 0 226.772 188.976"
    id="svg2"
    version="1.1"
  >
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,188.976)" id="g10">
      <g id="g12">
        <g id="g14">
          <g transform="translate(72.4163,111.8466)" id="g20">
            <path
              id="path22"
              style={{
                fill: "currentColor",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "currentColor",
                strokeWidth: 4,
              }}
              d="m 0,0 c -1.008,2.627 0.168,3.874 4.686,4.962 4.223,1.018 4.456,1.262 4.759,4.969 0.378,4.612 -0.969,5.816 -5.569,4.974 -3.646,-0.667 -4.009,0.136 -1.478,3.261 2.488,3.071 5.518,4.367 10.212,4.367 8.328,0 13.405,-5.269 12.821,-13.306 L 25.126,5.033 H 18.607 C 12.958,5.033 11.411,4.599 7.019,1.783 1.265,-1.907 0.779,-2.03 0,0 m 8.026,-83.447 c -11.235,1.185 -15.468,4.221 -17.951,12.878 -2.284,7.963 -5.796,13.511 -11.129,17.584 -4.691,3.582 -12.503,6.518 -17.342,6.518 -3.255,0 -4.979,3.237 -4.979,9.351 0,5.446 3.756,9.308 13.229,13.6 7.583,3.436 9.308,3.7 10.134,1.549 0.316,-0.825 0.963,-1.5 1.436,-1.5 0.474,0 7.764,5.175 16.202,11.5 20.653,15.482 22.267,15.473 34.043,-0.194 3.875,-5.156 7.278,-9.976 7.56,-10.711 0.481,-1.256 -11.348,-10.653 -13.281,-10.551 -0.451,0.024 -4.073,4.524 -8.047,10 -4.48,6.173 -7.9,9.955 -9,9.951 -2.059,-0.007 -25.137,-17.471 -25.975,-19.657 -0.648,-1.69 4.818,-7.783 8.902,-9.923 3.843,-2.013 6.329,-1.755 8.047,0.836 1.403,2.116 2.288,2.269 14.873,2.577 l 13.379,0.328 7.218,5.422 c 3.971,2.983 7.592,5.423 8.048,5.423 0.456,0 2.383,-2.453 4.281,-5.452 l 3.453,-5.452 12.128,-0.048 c 12.897,-0.051 15.203,-0.859 13.926,-4.879 -0.527,-1.661 -1.817,-1.988 -9.332,-2.366 -10.497,-0.527 -16.316,-2.838 -22.066,-8.763 -6.373,-6.566 -8.644,-12.155 -8.65,-21.289 l -0.006,-7.749 -11,0.135 c -6.049,0.074 -14.194,0.472 -18.1,0.884 z m 50.967,-16.594 c -8.565,2.68 -15.295,10.785 -16.843,20.286 -1.578,9.677 5.73,22.309 14.941,25.827 7.008,2.676 17.269,1.728 22.714,-2.098 2.346,-1.649 5.613,-4.917 7.263,-7.263 3.816,-5.431 4.773,-15.706 2.112,-22.675 -4.505,-11.797 -17.744,-17.971 -30.187,-14.077 m -105.58,-0.233 c -4.861,1.293 -12.597,8.176 -14.91,13.268 -3.702,8.15 -1.644,20.862 4.434,27.388 8.653,9.291 26.118,9.568 35.037,0.556 9.646,-9.745 9.773,-25.335 0.282,-34.826 -6.633,-6.633 -15.42,-8.892 -24.844,-6.386 z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Bike;

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Company, { ICompanyBinding, ICompanyResource } from "../../models/company";
import Companies from "./Index";
interface Props {
  company: ICompanyResource;
  onUpdated?: (company: ICompanyResource) => void;
}
const EditCompany: React.FC<Props> = ({ company: comp, onUpdated }) => {
  const [company, setCompany] = useState<ICompanyBinding>(comp);
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);

  const saveChanges = async () => {
    try {
      setLoading(true);
      await Company.Update(comp.id, company);
      setLoading(false);
      setShown(false);
      if (onUpdated) {
        onUpdated(Company.ToResource(company, comp));
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  };

  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };
  return (
    <Fragment>
      <button
        title="Rediger virksomhed"
        className="blank-button"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>

      <Modal
        show={shown}
        onHide={() => {
          setShown(false);
        }}
      >
        <form onSubmit={formSave}>
          <Modal.Title>Rediger virksomhed</Modal.Title>
          <Modal.Body>
            <Companies.Form
              error={error}
              value={company}
              onChange={(edit) =>
                setCompany((val) => ({
                  ...val,
                  name: edit.name,
                  demo: edit.demo,
                  remote: edit.remote
                }))
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={loading} type="submit">
              Gem {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setCompany(comp);
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default EditCompany;

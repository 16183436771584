import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import useTitle from "../../hooks/useTitle";
import { ICompanyResource } from "../../models/company";
import ExportsPage from "../DataExports/ExportsPage";
import Proximity from "../Proximity";
import Companies from "./Index";
import ExportTabs from "../DataExports/ExportTabs";
import UserContext from "../../contexts/userContext";
import CompanyFlexVehicles from "./FlexVehicles";
import VehicleTachographs from "./VehicleTachographs";

interface Props {
  company: ICompanyResource;
  initialTab?: string;
}
const CompanyDetails: React.FC<Props> = ({ company, initialTab }) => {
  useTitle("DigiFleet - " + company.name);
  const { user } = useContext(UserContext);
  const [tab, setTab] = useState(initialTab || "vehicles");
  useEffect(() => {
    if (initialTab) {
      setTab(initialTab);
    }
  }, [initialTab]);
  return (
    <Tabs
      activeKey={tab}
      unmountOnExit={true}
      onSelect={(k: string | null) => k && setTab(k)}
      id="company-tabs"
    >
      <Tab eventKey="vehicles" title="Køretøjer">
        <Companies.Vehicles company={company} />
      </Tab>
      {company.remote && (
        <Tab eventKey="remote" title="Køre hviletid">
          <VehicleTachographs company={company} />
        </Tab>
      )}
      <Tab eventKey="units" title="Tracking enheder">
        <Companies.TrackingUnits company={company} />
      </Tab>
      <Tab eventKey="users" title="Brugere">
        <Companies.Users company={company} />
      </Tab>
      <Tab eventKey="proximity" title="Nærhedssøgning">
        <Proximity.Company company={company} />
      </Tab>
      <Tab eventKey="exports" title="Data eksport">
        <ExportsPage>
          <ExportTabs company={company} />
        </ExportsPage>
      </Tab>
      {user && user.identity.admin && (
        <Tab eventKey="flex-vehicles" title="Flex køretøjer">
          <CompanyFlexVehicles company={company} />
        </Tab>
      )}
      {/* <Tab eventKey="divisions" title="Afdelinger">
        <CompanyDivisions.All company={company} />
      </Tab> */}
    </Tabs>
  );
};

export default CompanyDetails;

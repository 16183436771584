import React from "react";
import { ICoordinateReflectorResource } from "../../models/coordinateReflector";
import Vehicles from "../Vehicles/Index";
import Delete from "./Delete";

interface Props {
    value: ICoordinateReflectorResource
    onDelete: (value: ICoordinateReflectorResource) => void;
}

const Row = ({value, onDelete}: Props): React.ReactElement => (
    <tr>
        <td>
            {value.from && (<Vehicles.Label value={value.from} />)}
        </td>
        <td>
            {value.to && (<Vehicles.Label value={value.to} />)}
        </td>
        <td>
            <Delete value={value} onDelete={() => onDelete(value)} />
        </td>
    </tr>
)

export default Row;
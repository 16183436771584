import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from "react-bootstrap";
import CoordinateReflector, { ICoordinateReflectorResource } from "../../models/coordinateReflector";

interface Props{
    value: ICoordinateReflectorResource,
    onDelete: () => void;
}

const Delete = ({value, onDelete}: Props): React.ReactElement => {
    const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);
  const deleteReflector = async () => {
    try {
      setLoading(true);
      await CoordinateReflector.Delete(value)
      setLoading(false);
      setShown(false);
      onDelete();
    } catch (error) {
      console.error(error);
    }
  };
  const formDelete = async (event: FormEvent) => {
    event.preventDefault();
    await deleteReflector();
  };
  return (
    <Fragment>
      <button
        title="Slet køretøjs reflektor"
        className="blank-button"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>

      <Modal show={shown} onHide={() => setShown(true)}>
        <form onSubmit={formDelete}>
          <Modal.Title>Slet køretøjs reflektor</Modal.Title>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Fra køretøj</InputGroup.Text>
                </InputGroup.Prepend>
                {value.from && (
                    <FormControl readOnly={true} defaultValue={value.from.licensePlate} />
                )}
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Til køretøj</InputGroup.Text>
                </InputGroup.Prepend>
                {value.to && (
                    <FormControl readOnly={true} defaultValue={value.to.licensePlate} />
                )}
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" disabled={loading} type="submit">
              Slet {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
}

export default Delete;
import { ICompanyResource } from "./company";
import { readFetch, updateFetch } from "../helpers/fetchers";
import ISortable from "../ISortable";
import ISearchable from "../ISearchable";
import { basicSearch, basicSecSort } from "../helpers/utils";

export interface IQuota {
  id: number;
  company: ICompanyResource;
  assigned: number;
  users: number;
  usersUsed: number;
}
export type QuotaProps = "company.name";
export default class Quota implements IQuota, ISortable<QuotaProps>, ISearchable<QuotaProps>{
    id: number;
    company: ICompanyResource;
    assigned: number;
    users: number;
    usersUsed: number;

  constructor(quota: IQuota) {
    this.id = quota.id;
    this.company = quota.company;
    this.assigned = quota.assigned;
    this.users = quota.users;
    this.usersUsed = quota.usersUsed;
  }
  search = (value: any, type: "company.name"): number | undefined => 
    basicSearch(this, value, type, false);
  compare = (value: ISortable<"company.name">, primary: "company.name", secondary?: "company.name" | undefined): number => 
    basicSecSort(this, value, primary, secondary);
    
    Update = async (abort?: AbortSignal) => updateFetch("api/Quota/" + this.id, this, abort);
    static Read = async (id: number, abort?: AbortSignal): Promise<IQuota> =>
    (await readFetch("api/Quota/" + id, abort)) as IQuota;
  static ReadAll = async (abort?: AbortSignal): Promise<IQuota[]> =>
    (await readFetch("api/Quota", abort)) as IQuota[];
}



import React from "react";
import MobileOverrideButton from "./MobileOverrideButton";
export const Layout: React.FC = ({ children }) => (
  <div className="mobile-layout">
    {children}
    <MobileOverrideButton />
  </div>
);

export default Layout;

export const MapLayout: React.FC = ({ children }) => (
  <div className="mobile-layout map-layout">
    {children}
    <MobileOverrideButton />
  </div>
);

import { MapVehicleIcon, TextVehicleIcon } from "components/Icons/Icon";
import { bearing, CalculateDistance } from "helpers/utils";
import usePreferences from "hooks/usePreferences";
import { IGPSCoordinate } from "models/gpsCoordinate";
import TrackLoader from "models/trackLoader";
import React, {
  Fragment, useEffect,
  useMemo,
  useState
} from "react";
import { Polyline, Popup, Tooltip } from "react-leaflet";
import VehiclePopup from "./VehiclePopup";



interface LoaderProps {
  loader: TrackLoader;
  zoom: number;
  showTooltip: boolean;
  path: boolean;
}
export const VehicleTrack2 = ({
  loader,
  zoom,
  showTooltip,
  path,
}: LoaderProps) => {
  const [preferences] = usePreferences();
  const [coordinates, setCoordinates] = useState(loader.coordinates);

  useEffect(() => {
    loader.onCoordinateArrived = (coordinate) => {
      setCoordinates((cur) => [coordinate, ...cur]);
    };

    setCoordinates(loader.coordinates);

    return () => {
      loader.onCoordinateArrived = undefined;
    };
  }, [loader]);

  const scale = preferences.map.iconSizeScale;
  const speed = useMemo(() => {
    if (coordinates.length >= 2) {
      const coord1 = coordinates[0];
      const coord2 = coordinates[1];
      const distance = Math.abs(
        CalculateDistance(
          coord1.latitude,
          coord1.longitude,
          coord2.latitude,
          coord2.longitude
        )
      );
      let coord1Time = new Date(coord1.timestamp).getTime() / 1000;
      let coord2Time = new Date(coord2.timestamp).getTime() / 1000;
      const duration = Math.abs(coord1Time - coord2Time);

      return distance / duration;
    }
    return 0;
  }, [coordinates]);
  const still = speed <= 0.27;

  const online = useMemo(() => {
    const coordOnline = coordinates[0] && coordinates[0].accOn;
    const assumeOnline = speed > 15;
    return coordOnline || assumeOnline;
  }, [coordinates, speed]);

  const angle = useMemo(() => findAngle(coordinates) || 90, [coordinates]);

  const toolTip = useMemo(() => showTooltip ? (
    <Tooltip key="0" className="vehicle-tooltip mobile" permanent={true} opacity={0.7}>
      {loader.vehicle.alias ? loader.vehicle.alias : loader.vehicle.alias}
    </Tooltip>
  ) : (
    <Tooltip
    key="1"
      className="vehicle-tooltip mobile"
      permanent={false}
      opacity={0.8}
    >
      {loader.vehicle.alias
        ? loader.vehicle.alias
        : loader.vehicle.licensePlate}
    </Tooltip>
  ), [showTooltip]);

  const popup = (
    <Popup autoPan={false}>
      <VehiclePopup
        vehicle={loader.vehicle}
        speed={speed}
        online={online}
        acc={loader.vehicle.online}
      />
    </Popup>
  );

  const leafletCoordinates: [number, number][] = useMemo(
    () => coordinates.map((c) => [c.latitude, c.longitude]),
    [coordinates]
  );

  return (
    <Fragment>
      {path && <Polyline positions={leafletCoordinates} />}
      {preferences.map.useAliasAsIcon || !loader.vehicle.icon ? (
        <TextVehicleIcon
          zoom={zoom}
          position={coordinates[0]}
          online={online}
          still={still}
          scale={scale}
          value={loader.vehicle.alias}
          angle={angle}
        >
          {popup}
        </TextVehicleIcon>
      ) : (
        <MapVehicleIcon
          zoom={zoom}
          position={coordinates[0]}
          name={loader.vehicle.icon}
          color={loader.vehicle.color}
          online={online}
          angle={angle}
          still={still}
          scale={scale}
        >
          {popup}
          {toolTip}
        </MapVehicleIcon>
      )}
    </Fragment>
  );
};

const findAngle = (coordinates: IGPSCoordinate[]) => {
  const minDistance = 10;
  if (coordinates.length > 1) {
    const first = coordinates[0];
    for (let i in coordinates) {
      let current = coordinates[i];

      let distance = CalculateDistance(
        first.latitude,
        first.longitude,
        current.latitude,
        current.longitude
      );
      if (distance > minDistance) {
        return (
          bearing(
            current.latitude,
            current.longitude,
            first.latitude,
            first.longitude
          )
        );
      }
    }
  }
};

import useSortedSearch from "../../../hooks/useSortedSearch";
import VehicleCoordinateStatus, {
  IVehicleCoordinateStatus,
  VehicleCoordinateStatusProps,
} from "../../../models/vehicleCoordinateStatus";
import { Table as ReactTable } from "react-bootstrap";
import React from "react";
import SearchableHeader from "../../SearchableHeader";
import SortableHeader from "../../SortableHeader";
import PassivePagination from "../../Pagination";
import Vehicles from "../Index";
import Moment from "react-moment";
import PositionModal from "../../Tracking/PositionModal";

interface Props {
  values: IVehicleCoordinateStatus[];
}

const Table = ({ values }: Props) => {
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    VehicleCoordinateStatus,
    VehicleCoordinateStatusProps
  >(
    values.map((v) => new VehicleCoordinateStatus(v)),
    undefined,
    {
      type: "coordinate.timestamp",
      asc: true,
    }
  );
  return (
    <ReactTable striped responsive bordered>
      <thead>
        <tr>
          <SearchableHeader
            types={["vehicle.alias", "vehicle.licensePlate"]}
            value={search}
            onSearch={setSearch}
          />
          <th></th>
          <th></th>
        </tr>
        <tr>
          <SortableHeader
            type="vehicle.alias"
            fallback="vehicle.licensePlate"
            value={sort}
            onChange={setSort}
          >
            Køretøj
          </SortableHeader>
          <SortableHeader type="coordinate.timestamp" onChange={setSort} value={sort}>
            Tidspunkt
          </SortableHeader>
          <th>Position</th>
        </tr>
      </thead>
      <tbody>
        <PassivePagination
          items={sorted}
          id="vehicle-coordinate-status"
          renderItem={(item) => (
            <tr key={item.vehicle.id}>
              <td>
                <Vehicles.Label value={item.vehicle} />
              </td>
              <td>
                {item.coordinate && (
                  <Moment local utc format="DD-MM-YYYY HH:mm:ss">
                    {item.coordinate.timestamp}
                  </Moment>
                )}
              </td>
              <td>
                {item.coordinate && (
                  <div>
                    {item.coordinate.latitude}, {item.coordinate.longitude}
                    <PositionModal position={item.coordinate} />
                  </div>
                )}
              </td>
            </tr>
          )}
        />
      </tbody>
    </ReactTable>
  );
};

export default Table;

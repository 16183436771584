import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import UserContext from "../../contexts/userContext";
import Login from "./Login";

export const Home = () => {
  const { user } = useContext(UserContext);
  if (user) return <Redirect to="/nav" />;
  else return <Login />;
};

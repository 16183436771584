import React, { useState } from "react";
import Companies from "../Companies/Index";
import CompanyProximity from "./CompanyProximity";
import { ICompanyResource } from "../../models/company";

const UserProximity: React.FC = () => {
  const [company, setCompany] = useState<ICompanyResource | undefined>();

  return (
    <div className="proximity-index navigation-selector">
      <b>Vælg virksomhed</b>
      <Companies.Selector value={company} onChange={setCompany} />

      {company && (
        <div className="proximity navigation-result">
          <CompanyProximity company={company} />
        </div>
      )}
    </div>
  );
};

export default UserProximity;

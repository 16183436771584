import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from "react-bootstrap";
import TrackingUnit, { ITrackingUnitResource, UnitType } from "../../models/trackingUnit";
interface Props {
  trackingUnit: ITrackingUnitResource;
  onDeleted?: (vehicle: ITrackingUnitResource) => void;
}
const Delete: React.FC<Props> = ({ trackingUnit, onDeleted }) => {
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);
  const deleteTrackingUnit = async () => {
    try {
      setLoading(true);
      await TrackingUnit.Delete(trackingUnit);
      setLoading(false);
      setShown(false);
      if (onDeleted) {
        onDeleted(trackingUnit);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const formDelete = async (event: FormEvent) => {
    event.preventDefault();
    await deleteTrackingUnit();
  };
  return (
    <Fragment>
      <button
        title="Slet enhed"
        className="blank-button"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>

      <Modal show={shown} onHide={() => setShown(true)}>
        <form onSubmit={formDelete}>
          <Modal.Title>Slet tracking enhed</Modal.Title>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Track ID</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl readOnly={true} defaultValue={trackingUnit.id} />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Telefon nr.</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly={true}
                  defaultValue={trackingUnit.phoneNumber}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Enhedstype</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly={true}
                  defaultValue={trackingUnit.unitType ? UnitType[trackingUnit.unitType] : "Ukendt"}
                />
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" disabled={loading} type="submit">
              Slet {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default Delete;

import { HubConnection } from "@microsoft/signalr";
import React from "react";
import { useCallback, useState } from "react";
import { IStatus, useLiveTrackerStatusFromConnection } from "../../hooks/useLiveTrackerStatus";
import { ITrackingUnitResource } from "../../models/trackingUnit";

interface Props{
    unit: ITrackingUnitResource,
    connection?: HubConnection,
    onUpdate?: (connected: boolean) => void;
}


const ConnectionStatus = ({unit, connection, onUpdate}: Props): React.ReactElement => {
    const [connected, setConnected] = useState(unit.isConnected);

    const changeCallback = useCallback((status: IStatus) => {
        if(status.id === unit.id){
            setConnected(status.isConnected);
            if(onUpdate){
                onUpdate(status.isConnected);
            }
        }
    }, [onUpdate, unit])
    useLiveTrackerStatusFromConnection(connection, true, changeCallback);

    return(
        <span>
            {connected ? "Ja" : "Nej"}
        </span>
    )
}

export default ConnectionStatus;
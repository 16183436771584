import { HubConnection } from "@microsoft/signalr";
import React, { useCallback, useReducer } from "react";
import { Table } from "react-bootstrap";
import { useLiveCoordinatesFromConnection } from "../../hooks/useLiveCoordinates";
import useSortedSearch from "../../hooks/useSortedSearch";
import { ICompanyResource } from "../../models/company";
import { IGPSCoordinate } from "../../models/gpsCoordinate";
import {
  IVehicleResourceWithUnit,
  VehicleResource,
  VehicleWithUnitProps,
  VehicleResourceWithUnit,
  IVehicleResource,
} from "../../models/vehicle";
import AdminContent from "../AdminContent";
import Bordered from "../Bordered";
import SyncRemote from "../Companies/SyncRemote";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import Vehicles from "./Index";
import VehicleRow, { VehicleCompanyRow } from "./Row";

interface Props {
  vehicles: IVehicleResourceWithUnit[];
  company?: ICompanyResource;
  connection?: HubConnection;
}

type Add = {
  readonly type: "ADD";
  vehicle: IVehicleResourceWithUnit;
};
type AddMany = {
  readonly type: "ADD-MANY";
  vehicles: IVehicleResourceWithUnit[];
};
type Remove = {
  readonly type: "REMOVE";
  vehicle: IVehicleResourceWithUnit;
};
type Update = {
  readonly type: "UPDATE";
  vehicle: IVehicleResourceWithUnit;
};

type Replace = {
  readonly type: "REPLACE";
  vehicles: IVehicleResourceWithUnit[];
};
type SetOnline = {
  readonly type: "ONLINE";
  vehicleId: number;
  online: boolean;
};

type Actions = Add | AddMany | Remove | Update | Replace | SetOnline;

const reducer = (
  vehicles: IVehicleResourceWithUnit[],
  action: Actions
): IVehicleResourceWithUnit[] => {
  switch (action.type) {
    case "ADD":
      return [...vehicles, action.vehicle];
    case "ADD-MANY":
      return [...vehicles, ...action.vehicles];
    case "REMOVE":
      return vehicles.filter((v) => v.id !== action.vehicle.id);
    case "UPDATE":
      const index = vehicles.findIndex(
        (v: IVehicleResourceWithUnit) => v.id === action.vehicle.id
      );
      return [
        ...vehicles.slice(0, index),
        action.vehicle,
        ...vehicles.slice(index + 1),
      ];
    case "ONLINE":
      const vehicle = vehicles.find((v) => v.id === action.vehicleId);
      if (vehicle) {
        vehicle.online = action.online;
        return reducer(vehicles, { type: "UPDATE", vehicle });
      }
      return vehicles;
    case "REPLACE":
      return action.vehicles;
  }
};

const VehicleTable: React.FC<Props> = ({
  vehicles: veh,
  connection,
  company,
}) => {
  const [vehicles, dispatch] = useReducer(reducer, veh);
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    VehicleResourceWithUnit,
    VehicleWithUnitProps
  >(
    vehicles.map((v) => new VehicleResourceWithUnit(v)),
    undefined,
    {
      type: "alias",
      fallback: "licensePlate",
      asc: true,
    }
  );

  const handleCoordinateUpdate = useCallback((coordinate: IGPSCoordinate) => {
    const online = coordinate.accOn;
    dispatch({ type: "ONLINE", vehicleId: coordinate.vehicleId, online });
  }, []);

  useLiveCoordinatesFromConnection(connection, handleCoordinateUpdate);

  return (
    <div className="vehicles">
      <Table striped responsive bordered>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <SearchableHeader<VehicleWithUnitProps>
              value={search}
              types={["alias"]}
              onSearch={setSearch}
            />
            <SearchableHeader<VehicleWithUnitProps>
              value={search}
              types={["licensePlate"]}
              onSearch={setSearch}
            />
            <SearchableHeader<VehicleWithUnitProps>
              value={search}
              types={["unit.id"]}
              onSearch={setSearch}
            />
            <th></th>
            <th></th>
            <th>
              <Vehicles.Create
                company={company}
                onCreated={(vehicle) => dispatch({ type: "ADD", vehicle })}
              />
            </th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <SortableHeader type="alias" value={sort} onChange={setSort}>
              Kaldenavn
            </SortableHeader>
            <SortableHeader type="licensePlate" value={sort} onChange={setSort}>
              Indregisterings nr.
            </SortableHeader>
            <SortableHeader type="unit.id" value={sort} onChange={setSort}>
              DigiFleet tracker tildelt
            </SortableHeader>
            <SortableHeader type="online" value={sort} onChange={setSort}>
              Tændt
            </SortableHeader>
            <SortableHeader
              type="inActive"
              invert={true}
              value={sort}
              onChange={setSort}
            >
              Aktiv
            </SortableHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<IVehicleResourceWithUnit>
            items={sorted}
            sizeSelector={true}
            id="vehicles"
            renderItem={(item) => (
              <VehicleRow
                key={item.id}
                value={item}
                onUpdate={(vehicle) => dispatch({ type: "UPDATE", vehicle })}
                onDelete={(vehicle) => dispatch({ type: "REMOVE", vehicle })}
              />
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

export default VehicleTable;

export const VehicleCompanyTable: React.FC<Props> = ({
  vehicles: veh,
  connection,
  company,
}) => {
  const [vehicles, dispatch] = useReducer(reducer, veh);
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    VehicleResourceWithUnit,
    VehicleWithUnitProps
  >(
    vehicles.map((v) => new VehicleResourceWithUnit(v)),
    undefined,
    {
      type: "alias",
      fallback: "licensePlate",
      asc: true,
    }
  );

  const handleCoordinateUpdate = useCallback((coordinate: IGPSCoordinate) => {
    const online = coordinate.accOn;
    dispatch({ type: "ONLINE", vehicleId: coordinate.vehicleId, online });
  }, []);

  useLiveCoordinatesFromConnection(connection, handleCoordinateUpdate);

  return (
    <div className="vehicles">
      <Table responsive striped bordered>
        <thead>
          <tr>
            <th colSpan={2}></th>
            <SearchableHeader<VehicleWithUnitProps>
              value={search}
              types={["company.name"]}
              onSearch={setSearch}
            />
            <SearchableHeader<VehicleWithUnitProps>
              value={search}
              types={["alias"]}
              onSearch={setSearch}
            />
            <SearchableHeader<VehicleWithUnitProps>
              value={search}
              types={["licensePlate"]}
              onSearch={setSearch}
            />
            <SearchableHeader<VehicleWithUnitProps>
              value={search}
              types={["unit.id"]}
              onSearch={setSearch}
            />
            <SearchableHeader<VehicleWithUnitProps>
              value={search}
              types={["remote", "remoteReg"]}
              onSearch={setSearch}
            />
            <th colSpan={2}></th>
            <th>
              <Vehicles.Create
                company={company}
                onCreated={(vehicle) => dispatch({ type: "ADD", vehicle })}
              />
            </th>
          </tr>
          <tr>
            <th colSpan={2}></th>
            <SortableHeader type="company.name" value={sort} onChange={setSort}>
              Virksomhed
            </SortableHeader>
            <SortableHeader type="alias" value={sort} onChange={setSort}>
              Kaldenavn
            </SortableHeader>
            <SortableHeader type="licensePlate" value={sort} onChange={setSort}>
              Indregisterings nr.
            </SortableHeader>
            <SortableHeader type="unit.id" value={sort} onChange={setSort}>
              DigiFleet tracker tildelt
            </SortableHeader>
            <SortableHeader type="remote" fallback="remoteReg" value={sort} onChange={setSort}>
              Remote
            </SortableHeader>
            <SortableHeader type="online" value={sort} onChange={setSort}>
              Tændt
            </SortableHeader>
            <SortableHeader
              type="inActive"
              invert={true}
              value={sort}
              onChange={setSort}
            >
              Aktiv
            </SortableHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <PassivePagination<IVehicleResourceWithUnit>
            items={sorted}
            id="vehicles"
            sizeSelector={true}
            renderItem={(item) => (
              <VehicleCompanyRow
                key={item.id}
                value={item}
                onUpdate={(vehicle) => dispatch({ type: "UPDATE", vehicle })}
                onDelete={(vehicle) => dispatch({ type: "REMOVE", vehicle })}
              />
            )}
          />
        </tbody>
      </Table>
    </div>
  );
};

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from "react-bootstrap";
import EmulatedUnit, { IEmulatedUnit } from "../../models/emulatedUnit";
interface Props {
  emulator: IEmulatedUnit;
  onDeleted?: (emulator: IEmulatedUnit) => void;
}
const DeleteEmulator: React.FC<Props> = ({ emulator, onDeleted }) => {
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);
  const deleteEmulator = async () => {
    try {
      setLoading(true);
      await EmulatedUnit.Delete(emulator);
      setShown(false);
      if (onDeleted) {
        onDeleted(emulator);
      }
    } catch (error) {
      console.error(error);
    }
    finally{
        setLoading(false);
    }
  };
  const formDelete = async (event: FormEvent) => {
    event.preventDefault();
    await deleteEmulator();
  };
  return (
    <Fragment>
      <button
        title="Slet emulator"
        className="blank-button"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>

      <Modal show={shown} onHide={() => setShown(true)}>
        <form onSubmit={formDelete}>
          <Modal.Title>Slet enheds emulator</Modal.Title>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Emuleret ID</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl readOnly={true} defaultValue={emulator.trackerId} />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Filnavn</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly={true}
                  defaultValue={emulator.fileName}
                />
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" disabled={loading} type="submit">
              Slet {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default DeleteEmulator;
import { useCallback, useEffect, useRef, useState } from "react";

/**
 * A hook that resolves a promise upon mounting
 * @param invoke A function that returns a promise, the AbortSignal is aborted upon unmounting. This function is invoke again if it changes and it is therefore recommended that the function is wrapped in useCallback.
 * @param initialValue An initial value that is returned before the promise is resolved
 */
const usePromise = <T>(
  invoke: (abort: AbortSignal) => Promise<T>,
  initialValue: T,
  setLoad: (loading: boolean) => void,
  defaultLoading: boolean = true
): [T, Error | undefined, React.Dispatch<React.SetStateAction<T>>] => {
  const loadingRef = useRef(defaultLoading);
  const [result, setResult] = useState<T>(initialValue);
  const [error, setError] = useState<Error | undefined>();

  /**
   * Resolves the promise
   */
  const resolve = useCallback(
    async (abort: AbortSignal) => {
      try {
        setError(undefined);
        if(!loadingRef.current){
          setLoad(true);
        }
        //Get the promise from the function
        const promise = invoke(abort);
        //Async resolve it
        const resolved = await promise;
        //Set the result
        setResult(resolved);
        loadingRef.current = true;
      } catch (error) {
        if (!abort.aborted) {
          setError(error);
        }
      } finally {
        if (!abort.aborted) {
          // setLoading(false);
          loadingRef.current = false;
          setLoad(false);
        }
      }
    },
    [invoke]
  );

  useEffect(() => {
    const abort = new AbortController();
    //Resolve the promise on mount and when the resolve callback changes, which it does when passed in dependencies changes
    resolve(abort.signal);
    return () => {
      abort.abort();
    };
  }, [resolve]);

  return [result, error, setResult];
};

export default usePromise;
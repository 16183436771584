import React from "react";
const ExportsPage: React.FC = ({ children }) => {
    return (
        <div>
            <h4>Eksport af data</h4>
            <div className="exports">
                {children}
            </div>
        </div>
    )
};

export default ExportsPage;

import React from "react";
import { Spinner } from "react-bootstrap";

interface Props {
  loading: boolean;
}

const ContentLoading: React.FC<Props> = ({ loading, children }) => {
  if (loading) return <Spinner animation="border" />;

  return <>{children}</>;
};


export default ContentLoading;

import React from "react";

interface Props {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  [x: string]: any;
}

const Slider: React.FC<Props> = ({
  value,
  onChange,
  min,
  max,
  step,
  ...rest
}) => {
  return (
    <input
      type="range"
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={(e) => onChange(+e.target.value)}
      {...rest}
    />
  );
};

export default Slider;

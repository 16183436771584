import SelectedCompanyContext from "contexts/selectedCompanyContext";
import UserContext from "contexts/userContext";
import { basicSecSort, basicSortCompare } from "helpers/utils";
import Company from "models/company";
import { IJWTCompany } from "models/jwtUser";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Centered from "./Centered";

export const ChooseCompany: React.FC = ({ children }) => {
  const { user } = useContext(UserContext);
  const { company, setCompany } = useContext(SelectedCompanyContext);

  const [companies, setCompanies] = useState<IJWTCompany[]>([]);

  useEffect(() => {
    const abort = new AbortController();
    if (user) {
      if (user.identity.admin) {
        const request = async () => {
          const companies = await Company.ReadAll(abort.signal);
          if (!abort.signal.aborted)

          companies.sort((a,b) => basicSortCompare(a, b, "name") ?? 0);
            setCompanies(
              companies.map((c) => ({ ...c, isRemote: !!c.remote }))
            );
        };

        request();
      } else {
        setCompanies(user.identity.companies);
      }
    }

    return () => abort.abort();
  }, [user]);
  if (!user) return <Redirect to="/login" />;
  if (company) return <>{children}</>;
  return (
    <Centered>
      <h4>Vælg virksomhed</h4>
      <div className="mobile-company-selector">
        {companies.map((company) => (
          <div key={company.id} className="mobile-company-choice">
            <Button variant="primary" onClick={() => setCompany(company)}>
              {company.name}
            </Button>
          </div>
        ))}
      </div>
    </Centered>
  );
};

import React, { Fragment, useEffect, useState, useMemo } from "react";
import { FormControl } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { BadRequestError } from "../HttpErrors";

interface Props {
  errors?: Error;
  field: string;
  onChange?: (value: string) => void;
  [x: string]: any;
}

const FormPhoneFeedback: React.FC<Props> = ({
  errors,
  field,
  onChange,
  ...rest
}) => {
  const [messages, setMessages] = useState<string[]>([]);
  const fields = useMemo(() => field.split(/[\s,]+/), [field]);
  useEffect(() => {
    if (errors && errors instanceof BadRequestError) {
      const badRequest = errors as BadRequestError;
      const msgs: string[] = [];
      for (const fld of fields) {
        //Read the messages from the error body, try with both field (licensePlate) and capitalized field (LicensePlate)
        const val = badRequest.body[fld] || badRequest.body[capitalize(fld)];
        if (val) msgs.push(val);
      }
      setMessages(msgs.flat());
    }
  }, [errors, fields]);

  const handleChange = (value: string, data: {}, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    if (messages.length > 0) {
      setMessages([]);
    }
    if (onChange) {
      onChange(formattedValue);
    }
  };
  return (
    <Fragment>
      <PhoneInput masks={{
        dk: '.... ....'
      }} specialLabel={""} {...rest} onChange={handleChange} />
      <FormControl type="hidden" isInvalid={messages.length > 0} />
      <FormControl.Feedback type="invalid">
        <ul>
          {messages.map((msg: string, index: number) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      </FormControl.Feedback>
    </Fragment>
  );
};
export default FormPhoneFeedback;

function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

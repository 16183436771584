import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import TimeSpan from "../models/timespan";
import TimeLabel from "./TimeLabel";

interface Props {
  /**
   * Duration to count down
   */
  value: number;
  /**
   * Called when count down completes.
   */
  onComplete?: () => void;
  onTick: (newValue: number) => void;
}

const Countdown = ({ value, onComplete, onTick }: Props) => {
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (value) {
      const interval = setTimeout(() => {
        value--;
        onTick(value);
      }, 1000);
      intervalRef.current = interval;

      return () => clearInterval(interval);
    }
    else if(onComplete){
      onComplete();
    }
  }, [value])

  return (
    <Fragment>
      <TimeLabel format="auto" value={new TimeSpan(value)} />
    </Fragment>
  );
};

export default Countdown;

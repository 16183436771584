import AllUsers from "./All";
import CreateUser from "./Create";
import EditUser from "./Edit";
import DeleteUser from "./Delete";
import UnassignUser from "./Unassign";
import ForceLogout from "./ForceLogout";
import UsersTable from "./UsersTable";
import EditUserCompanies from "./EditCompanies";
import { UserVehicleSelector } from "../Vehicles/Selector";
import UserResendActivation from "./ResendActivation";
import UserSendPasswordReset from "./SendForgot";



class Users{
    static All = AllUsers;
    static Create = CreateUser;
    static Edit = EditUser;
    static Delete = DeleteUser;
    static Unassign = UnassignUser
    static ForceLogout = ForceLogout;
    static Table = UsersTable;
    static EditCompanies = EditUserCompanies;
    static VehicleSelector = UserVehicleSelector;
    static ResendActivation = UserResendActivation;
    static SendPasswordReset = UserSendPasswordReset;
}

export default Users;
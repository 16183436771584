import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { GeoJson } from "./geoAddress";

export interface ICoordinate{
  latitude: number;
  longitude: number;
}

type CoordinateProps = "latitude" | "longitude";
export class Coordinate implements ICoordinate{
  latitude: number;
  longitude: number;
  constructor(value: ICoordinate){
    this.latitude = value.latitude;
    this.longitude = value.longitude;
  }
}

type TimedCoordinateProps = "timestamp" | CoordinateProps;
export interface ITimedCoordinate extends ICoordinate {
  timestamp: string;
}

type GPSCoordinateProps = "id" | "accOn" | TimedCoordinateProps;
export interface IGPSCoordinate extends ITimedCoordinate {
  id: number;
  vehicleId: number;
  accOn: boolean;
}


export default class GPSCoordinate extends Coordinate implements IGPSCoordinate {

  id: number;
  vehicleId: number;
  accOn: boolean;
  timestamp: string;

  constructor(value: IGPSCoordinate){
    super(value);
    this.id = value.id;
    this.vehicleId = value.vehicleId;
    this.accOn = value.accOn;
    this.timestamp = value.timestamp;
  }

  static GetAddress = async (
    coordinate: ITimedCoordinate,
    abort?: AbortSignal
  ): Promise<GeoJson | undefined> => {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinate.longitude},${coordinate.latitude}.json?access_token=pk.eyJ1IjoiYnJpYW5tb3NlIiwiYSI6ImNrMnU5dGV1ZTA2bzAzY3FtY2U4ZTFoMXYifQ.fKvvV85REkfdJ6vqtWF66Q`,
      { signal: abort }
    );
    if (response.ok) {
      return (await response.json()) as GeoJson;
    }
  };

  static GetAddresses = async (
    proximity: [number, number],
    search: string,
    abort?: AbortSignal
  ): Promise<GeoJson | undefined> => {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        search
      )}.json?proximity=${`${proximity[1]},${proximity[0]}`}&language=da&access_token=pk.eyJ1IjoiYnJpYW5tb3NlIiwiYSI6ImNrMnU5dGV1ZTA2bzAzY3FtY2U4ZTFoMXYifQ.fKvvV85REkfdJ6vqtWF66Q`,
      { signal: abort }
    );
    if (response.ok) {
      return (await response.json()) as GeoJson;
    }
  };
}

export interface IMappedVehicleCoordinate{
  id: number,
  coordinates: IGPSCoordinate[]
}

export interface ISpeedCoordinate extends IGPSCoordinate{
  speed?: number;
}
export type SpeedCoordinateProps = "speed" | GPSCoordinateProps;
export class SpeedCoordinate extends GPSCoordinate implements ISpeedCoordinate, ISortable<SpeedCoordinateProps>, ISearchable<SpeedCoordinateProps>{
  speed?: number;

  constructor(value: ISpeedCoordinate){
    super(value);
    this.speed = value.speed;
  }
  search = (value: any, type: SpeedCoordinateProps): number | undefined => 
    basicSearch(this, value, type, false);
  compare = (value: ISortable<SpeedCoordinateProps>, primary: SpeedCoordinateProps, secondary?: SpeedCoordinateProps): number => 
    basicSecSort(this, value, primary, secondary);
  

}

import { useState } from "react";

const useSessionStorage = <T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from session storage by key
      const item = sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return (item ? JSON.parse(item) : initialValue) as T;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      if (valueToStore === undefined) {
        // Remove from session storage
        sessionStorage.removeItem(key);
      } else {
        // Save to session storage
        sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export default useSessionStorage;

import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import {
  faHistory,
  faMapMarkedAlt,
  faStopwatch,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectedCompanyContext from "contexts/selectedCompanyContext";
import UserContext from "contexts/userContext";
import useTitle from "hooks/useTitle";
import React, { useContext } from "react";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Centered from "./Centered";
import Logout from "./Logout";
import Logo from "components/Images/DigiFleet-logo - Kopi.png";

export const Navboard = () => {
  useTitle("DigiFleet - Menu");
  const { company, setCompany } = useContext(SelectedCompanyContext);
  const { user } = useContext(UserContext);

  return (
    <>
      <Centered>
        <Alert variant="info">
          <div className="mobile-icon">
            <FontAwesomeIcon icon={faBuilding} />
          </div>
          <b>{company?.name}</b>
        </Alert>
        <div className="mobile-navbuttons">
          <div className="mobile-navbutton">
            <Link to="/map/live">
              <Button variant="primary">
                <div className="mobile-icon">
                  <FontAwesomeIcon icon={faMapMarkedAlt} />
                </div>
                Live
              </Button>
            </Link>
          </div>
          <div className="mobile-navbuttons">
            <div className="mobile-navbutton">
              <Link to="/history">
                <Button variant="primary">
                  <div className="mobile-icon">
                    <FontAwesomeIcon icon={faHistory} />
                  </div>
                  Historik
                </Button>
              </Link>
            </div>
          </div>
          {company && (
            <div className="mobile-navbutton">
              <Link to="/driving">
                <Button variant="primary">
                  <div className="mobile-icon">
                    <FontAwesomeIcon icon={faTruck} />
                  </div>
                  Kørsel
                </Button>
              </Link>
            </div>
          )}
          {company && company.isRemote && (
            <div className="mobile-navbutton">
              <Link to="/driverest">
                <Button variant="primary">
                  <div className="mobile-icon">
                    <FontAwesomeIcon icon={faStopwatch} />
                  </div>
                  Kørehviletid
                </Button>
              </Link>
            </div>
          )}

          <hr />
          <div className="mobile-navbutton">
            <Logout />
          </div>
          {user && (user.identity.companies.length > 1 || user.identity.admin) && (
            <div className="mobile-navbutton">
              <Button variant="primary" onClick={() => setCompany(undefined)}>
                Skift virksomhed
              </Button>
            </div>
          )}

          <img src={Logo} />
        </div>
      </Centered>
    </>
  );
};

export default Navboard;

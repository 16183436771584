import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import useSortedSearch from "../../hooks/useSortedSearch";
import { ConnectedUnit, ConnectedUnitProps, IConnectedUnit } from "../../models/trackingUnit";
import PassivePagination from "../Pagination";
import SearchableHeader from "../SearchableHeader";
import SortableHeader from "../SortableHeader";
import CreateConnectedUnit from "./CreateConnectedUnit";

interface Props {
  units: IConnectedUnit[];
}

const ConnectedUnitTable: React.FC<Props> = ({ units }) => {
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<ConnectedUnit, ConnectedUnitProps>(
    units.map(v => new ConnectedUnit(v)), undefined, 
    {
    type: "id",
    asc: true
  });
  return (
    <Table striped>
      <thead>
        <tr>
          <SearchableHeader
            types={["connectionId"]}
            value={search}
            onSearch={setSearch}
          />
          <SearchableHeader
            types={["id"]}
            value={search}
            onSearch={setSearch}
          />
          <th></th>
          <th></th>
        </tr>
        <tr>
          <SortableHeader type="connectionId" value={sort} onChange={setSort}>
            Forbindelse ID
          </SortableHeader>
          <SortableHeader type="id" value={sort} onChange={setSort}>
            Enheds ID
          </SortableHeader>
          <SortableHeader type="exists" value={sort} onChange={setSort}>
            Enhed findes i DB
          </SortableHeader>
          <SortableHeader type="lastReceive" value={sort} onChange={setSort}>
            Sidste modtagelse
          </SortableHeader>
        </tr>
      </thead>
      <tbody>
        <PassivePagination
          items={sorted}
          id="connectedUnits"
          renderItem={(item, index) => (
            <tr key={index}>
              <td>{item.connectionId}</td>
              <td>{item.id}</td>
              <td>{item.exists ? (
                  <span>Ja</span>
              ) : (
                  <span>Nej {item.id && <CreateConnectedUnit unitId={item.id} />}</span>
              )}</td>
              <td>
                <Moment local utc format="DD-MM-YYYY HH:mm:ss">
                  {item.lastReceive}
                </Moment>
              </td>
            </tr>
          )}
        />
      </tbody>
    </Table>
  );
};

export default ConnectedUnitTable;

import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, useState } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import User from "../../models/user";
import Centered from "./Centered";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const [done, setDone] = useState(false);

  if (done) {
    return (
      <div>
        <h4>
          En Email med nulstilling er afsendt, hvis emailen findes i vores
          system
        </h4>
      </div>
    );
  }

  const emailRegex =
    /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;

  const emailValid = !!email && emailRegex.test(email);
  const sendReset = async () => {
    try {
      if (!emailValid || !email) return;
      await User.ForgotPassword(email);
      setDone(true);
    } catch (error) {
      console.error(error);
    }
  };

  const formSave = async (event: FormEvent) => {
    event.preventDefault();
    await sendReset();
  };

  return (
    <Centered>
      <Link to="/login">
        <Button variant="secondary">
          <FontAwesomeIcon icon={faArrowCircleLeft} />
        </Button>
      </Link>
      <div className="mobile-login-form">
        <form onSubmit={formSave}>
          <h4>Indtast din email adresse</h4>
          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Email</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                onChange={(e) => setEmail(e.target.value)}
                isValid={emailValid}
              />
              <FormControl.Feedback type="invalid">
                Ugyldig email
              </FormControl.Feedback>
            </InputGroup>
          </Form.Group>
          <Button variant="success" type="submit">
            Send
          </Button>
        </form>
      </div>
    </Centered>
  );
};

export default ForgotPassword;

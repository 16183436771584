import { useMemo, useState } from "react";
import { SearchValue } from "../components/SearchableHeader";
import { SortValue } from "../components/SortableHeader";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { searchItems } from "./useSearch";
import { sortItems} from "./useSorting";


/**
 * A hook for searching and sorting items
 * @param items The items to search and sort through
 * @param initialSearch The initial search value
 * @param initialSort
 */
export const useSortedSearch = <T extends ISortable<TSortType> & ISearchable<TSortType>, TSortType>(
  items: T[],
  initialSearch?: SearchValue<TSortType>,
  initialSort?: SortValue<TSortType>
): [
  T[],
  SearchValue<TSortType> | undefined,
  SortValue<TSortType> | undefined,
  (sort?: SortValue<TSortType> | undefined) => void,
  (search?: SearchValue<TSortType> | undefined) => void
] => {

  const [sort, setSort] = useState<SortValue<TSortType> | undefined>(initialSort);
  const [search, setSearch] = useState<SearchValue<TSortType> | undefined>(initialSearch);

  //Get the resulting items, memorized so they only change when items, search or sort change
  const resultItems = useMemo(() => searchItems(sortItems(items, sort), search), [items, search, sort]);

  return [resultItems, search, sort, setSort, setSearch];
};

export default useSortedSearch;

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface Props {
  value: boolean;
  onChange: (shown: boolean) => void;
  disabled?: boolean;
}

const SelectedControl: React.FC<Props> = ({ value, onChange, disabled }) => {
  if (value) {
    return (
      <div>
        <b>Skjul alle køretøjer</b>
        <Button
        disabled={disabled}
          size="sm"
          variant="primary"
          title="Skjul alle køretøjer"
          onClick={() => onChange(false)}
        >
          <FontAwesomeIcon icon={faCheckSquare} />
        </Button>
      </div>
    );
  }

  return (
    <div>
      <b>Vis alle køretøjer</b>
      <Button
      disabled={disabled}
        size="sm"
        variant="secondary"
        title="Vis alle køretøjer"
        onClick={() => onChange(true)}
      >
        <FontAwesomeIcon icon={faSquare} />
      </Button>
    </div>
  );
};

export default SelectedControl;

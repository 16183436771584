import Profile, { ProfileIndex, MyProfile } from "./Profile";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Login from "./Login";
import LoginAttempts, { LoginAttemptsIndex, LoginAttemptsSummary } from "./LoginAttempts";
import LoginNavItem from "./LoginNavItem";
import Logout from "./Logout";
import JwtAuthenticator from "./JwtAuthenticator";
import FinishRegistration from "./FinishRegistration";
import ChangeName from "./ChangeName";
import ChangePassword from "./ChangePassword";

class Accounts {
  static Profile = Profile;
  static ProfileIndex = ProfileIndex;
  static MyProfile = MyProfile;
  static ForgotPassword = ForgotPassword;
  static ResetPassword = ResetPassword;
  static Login = Login;
  static LoginAttempts = LoginAttempts;
  static LoginIndex = LoginAttemptsIndex;
  static LoginSummary = LoginAttemptsSummary;
  static LoginNav = LoginNavItem;
  static Logout = Logout;
  static JwtAuthenticator = JwtAuthenticator;
  static FinishRegistration = FinishRegistration;
  static ChangeName = ChangeName;
  static ChangePassword = ChangePassword;
}

export default Accounts;

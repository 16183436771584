
import React, {
  Fragment, useContext,
  useEffect,
  useRef, useState
} from "react";
import { Spinner } from "react-bootstrap";
import UserContext from "../../contexts/userContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import usePageVisibility from "../../hooks/usePageVisibility";
import useSessionStorage from "../../hooks/useSessionStorage";
import { jwtResponse, JwtResponse } from "../../models/jwtResponse";
import { IJWTUser, JWTUser } from "../../models/jwtUser";

/**
 * Reauthenticates the user when logged in
 * @param param0
 */
const JwtAuthenticator: React.FC = ({ children }) => {
  const timeout = useRef<number>();
  const { user, setUser } = useContext(UserContext);
  const [ready, setReady] = useState(false);

  const [, setSesUser] = useSessionStorage<jwtResponse | undefined>("user", undefined);
  const [, setLocUser] = useLocalStorage<jwtResponse | undefined>("user", undefined);

  usePageVisibility((visible) => {
    if (visible) {
      handleTabReOpen();
    }
  });

  const handleTabReOpen = () => {
    if (timeout.current) {
      window.clearTimeout(timeout.current);
    }
    reauthenticate();
  };
  /**
   * Saves the user to the appropriate storage depending on whether or not the user should be remembered
   */
  const saveUser = (tokens: jwtResponse, remember: boolean) => {
    if (remember) {
      setSesUser(undefined);
      setLocUser(tokens);
    } else {
      setLocUser(undefined);
      setSesUser(tokens);
    }
  };

  const immediateReauth = async (tokens: jwtResponse, remember: boolean) => {
    try {
      const response = await JwtResponse.Reauthenticate(tokens);
      if (response) {
        const identity = parseJwtPayload(response.access_token);
        if (!identity) return;

        setUser({ identity, tokens: response, remember });
        console.log("Successfully reauthenticated");
        saveUser(response, remember);
      } else console.warn("Could not reauthenticate");
    } catch (error) {
      console.warn("Error during reauthentication");
      setUser(undefined);
      console.error(error);
    }
  };

  /**
   * Reauthenticates the user
   */
  const reauthenticate = async () => {
    if (!user) return;
    const now = Date.now() / 1000;
    const clockSkew = user.identity.iat - now;
    const acceptableDiff = 1 * 60; // Allow 1 minute of time difference
    // Ensure system time is correct
    if(clockSkew > acceptableDiff){
      // When system time is incorrect
      console.error(`System clock is skewed by ${clockSkew} seconds. Unable to authenticate`);
      window.alert("System uret på denne computer passer ikke. Ret det og prøv igen");
      setUser(undefined);
      return;
    }
    //Calculate the time until expiry
    const expiry = JWTUser.GetTimeUntilExpiry(user.identity);

    //Reauthenticate before the token expires
    const jitterPercent = (Math.random() + 3) / 100;
    const secondsBefore = expiry * jitterPercent;
    const timeUntilExpire = Math.max(0, expiry - secondsBefore);
    
    console.log(`Reauthenticating in ${timeUntilExpire} seconds`);

    timeout.current = window.setTimeout(async () => {
      try {
        //Check if there is a newer token in localstorage, this should allow multiple tabs
        const storedJson = localStorage.getItem("user");
        if (storedJson) {
          const storedUser: jwtResponse = JSON.parse(storedJson);
          if (storedUser) {
            const storedIdentity = parseJwtPayload(storedUser.access_token);
            if (storedIdentity && storedIdentity.exp > user.identity.exp) {
              setUser({
                identity: storedIdentity,
                tokens: storedUser,
                remember: true,
              });
              return;
            }
          }
        }
        const response = await JwtResponse.Reauthenticate(user.tokens);
        if (response) {
          const identity = parseJwtPayload(response.access_token);
          if (!identity) return;

          setUser({ ...user, identity, tokens: response });
          console.log("Successfully reauthenticated");
          saveUser(response, user.remember);
        } else console.warn("Could not reauthenticate");
      } catch (error) {
        console.warn("Error during reauthentication");
        setUser(undefined);
        console.error(error);
      }
    }, timeUntilExpire * 1000);
  };

  useEffect(() => {
    if (!user) {
      const userJson =
        sessionStorage.getItem("user") || localStorage.getItem("user");
      if (userJson) {
        const loadedTokens: jwtResponse = JSON.parse(userJson);
        const loadedUser = parseJwtPayload(loadedTokens.access_token);
        if (!loadedUser) return;
        const fromLocal = localStorage.getItem("user") !== null;

        //Get time until expiry
        const expiry = JWTUser.GetTimeUntilExpiry(loadedUser);

        if (expiry <= 0) {
          immediateReauth(loadedTokens, fromLocal);
        } else {
          setUser({
            identity: loadedUser,
            tokens: loadedTokens,
            remember: fromLocal,
          });
        }
      }
    }
    setReady(true);

    return () => {
      if (user) {
        saveUser(user.tokens, user.remember);
      }
    };
  }, []);

  useEffect(() => {
    if (user) {
      reauthenticate();
    } else {
      clearTimeout(timeout.current);
    }
  }, [user]);

  if (ready) {
    return <Fragment>{children}</Fragment>;
  } else return <Spinner animation="border" />;
};

export default JwtAuthenticator;

export const parseJwtPayload = (token: string) => {
  if (token === null) return null;
  let payloadBase64 = token.split(".")[1];
  let payloadDecoded = b64DecodeUnicode(payloadBase64);
  return JSON.parse(payloadDecoded, camelCaseReviver) as IJWTUser;
};
const camelCaseReviver = (key: string, value: any) => {
  if (value && typeof value === "object") {
    for (var k in value) {
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        delete value[k];
      }
    }
  }
  return value;
};

const b64DecodeUnicode = (str: string) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ITrackingUnitResource } from "../../models/trackingUnit";
import QR from "./Index";

interface Props {
  trackingUnit: ITrackingUnitResource;
}

const QRModal: React.FC<Props> = ({ trackingUnit }) => {
  const [shown, setShown] = useState(false);

  if (!shown) {
    return (
      <button
        title="QR-kode"
        onClick={() => setShown(true)}
        className="blank-button"
      >
        <FontAwesomeIcon icon={faQrcode} />
      </button>
    );
  }

  return (
    <Modal size="lg" show={shown} onHide={() => setShown(false)}>
      <Modal.Title>Enheds QR kode</Modal.Title>
      <Modal.Body>
        <QR.UnitQR trackingUnit={trackingUnit} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShown(false);
          }}
        >
          Luk
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export default QRModal;
import useLocalStorage from "hooks/useLocalStorage";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Badge, Col, Row } from "react-bootstrap";
import UserContext from "../../contexts/userContext";
import { getDays } from "../../helpers/utils";
import useSortedSearch from "../../hooks/useSortedSearch";
import TimeSpan from "../../models/timespan";
import User from "../../models/user";
import Vehicle, {
  IVehicleResource,
  VehicleProps,
  VehicleResource,
} from "../../models/vehicle";
import Companies from "../Companies/Index";
import Coordinates from "../Coordinates/Index";
import HistoryDateSelector, { SavedOptions } from "./HistoryDateSelector";
import HistoryVehicleSelector from "./HistoryVehicleSelector";
import VehiclePeriodicHistory from "./VehiclePeriodicHistory";

const fiveDays = () => {
  const date = new Date();
  date.setDate(date.getDate() - 5);
  return date;
};

interface Props {
  from?: Date;
  to?: Date;
  vehicle?: IVehicleResource;
}

const VehicleHistoryTable: React.FC<Props> = ({
  from: iFrom,
  to: iTo,
  vehicle: veh,
}) => {
  const { user } = useContext(UserContext);

  const [vehicles, setVehicles] = useState<IVehicleResource[]>([]);

  const [sorted, , , , setSearch] = useSortedSearch<
    VehicleResource,
    VehicleProps
  >(
    vehicles.map((v) => new VehicleResource(v)),
    undefined,
    {
      type: "alias",
      fallback: "licensePlate",
      asc: true,
    }
  );

  const [vehicle, setVehicle] = useState<IVehicleResource | undefined>(veh);

  const [from, setFrom] = useState(iFrom || fiveDays());
  const [to, setTo] = useState(iTo || new Date());
  const [saved] = useLocalStorage<SavedOptions | undefined>("History-Options", undefined);
  const [minDuration, setMinDuration] = useState(saved ? new TimeSpan(saved.minDuration) : new TimeSpan(0, 1));
  const [maxDistance, setMaxDistance] = useState(saved?.maxDistance ?? 50);

  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        if (!user) return;
        const vehicles = user.identity.admin
          ? await Vehicle.ReadAll()
          : await User.ReadVehicles(user.identity.id);
        setVehicles(vehicles);
      } catch (error) {
        console.error(error);
      }
    };
    fetchVehicles();
  }, [user]);

  const changeShowAll = (show: boolean) => {
    if (show) {
      const d = getDays(from, to);
      if (d > 2) {
        from.setDate(from.getDate() - (d - 2));
        setFrom(from);
      }
    }
    setShowAll(show);
  };

  const fromMax = useCallback(() => {
    const d = getDays(from, to);
    if (d > 2) {
      const newFrom = new Date(from);
      newFrom.setDate(newFrom.getDate() + (d - 2));
      return newFrom;
    }
    return from;
  }, [from, to]);

  return (
    <div className="history-table">
      <Row>
        <Col md={3}>
          {user && (user.identity.admin || user.identity.companies.length > 1) && (
            <Companies.Selector
              onChange={(company) =>
                setSearch({
                  types: ["company.id"],
                  value: company && company.id,
                })
              }
            />
          )}
          <HistoryVehicleSelector
            vehicles={sorted}
            value={vehicle}
            onChange={setVehicle}
          />
        </Col>
        <Col md={9}>
          <HistoryDateSelector
            from={from}
            to={to}
            minDuration={minDuration}
            maxDistance={maxDistance}
            onChange={(from, to, minDuration, maxDistance) => {
              setFrom(from);
              setTo(to);
              setMinDuration(minDuration);
              setMaxDistance(maxDistance);
            }}
          />

          <label>
            Vis alle koordinater:
            <input
              type="checkbox"
              checked={showAll}
              onChange={(e) => changeShowAll(e.target.checked)}
            />
          </label>
          {showAll && (
            <Badge variant="warning">
              Viser maks tilbage 2 dage fra til-datoen
            </Badge>
          )}

          {vehicle && (
            <Fragment>
              {showAll ? (
                <Coordinates.History
                  vehicle={vehicle}
                  from={fromMax()}
                  to={to}
                />
              ) : (
                <VehiclePeriodicHistory vehicle={vehicle} from={from} to={to} minDuration={minDuration} maxDistance={maxDistance} />
              )}
            </Fragment>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default VehicleHistoryTable;

import { useContext, useEffect } from "react";
import UserContext from "../contexts/userContext";
import useSignalRConnection from "./useSignalRConnection";
import { HubConnection } from "@microsoft/signalr";

export interface IStatus {
  id: string;
  isConnected: boolean;
}

const useLiveTrackerStatus = (onStatusChange: (status: IStatus) => void) => {
  const [connection, started] = useLiveTrackerConnection();
  useLiveTrackerStatusFromConnection(connection, started, onStatusChange);
};

export default useLiveTrackerStatus;

export const useLiveTrackerStatusFromConnection = (
  connection: HubConnection | undefined,
  started: boolean,
  onStatusChange: (status: IStatus) => void
) => {
  useEffect(() => {
    if (connection) {
      connection.on("TrackerStatus", onStatusChange);
      return () => {
        connection.off("TrackerStatus");
      };
    }
  }, [connection, started, onStatusChange]);
};

export const useLiveTrackerConnection = (): [HubConnection | undefined, boolean]=> {
  const [connection, started] = useSignalRConnection("/hubs/tracker");

  return [connection, started];
};

import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useCallback } from "react";
import { Button, InputGroup } from "react-bootstrap";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyResource } from "../../models/company";
import User, { IUserResource } from "../../models/user";
import Companies from "../Companies/Index";
interface Props {
  user: IUserResource;
  onUpdated: (user: IUserResource) => void;
}

const EditUserCompanies: React.FC<Props> = ({ user, onUpdated }) => {
  const request = useCallback(
    (signal: AbortSignal) => User.ReadCompanies(user.id, signal),
    [user.id]
  );
  const [loading, setLoading] = useLoading();
  const [companies, , setCompanies] = usePromise(request, [], setLoading);
  
  const [company, setCompany] = useState<ICompanyResource | undefined>();

  const deleteUserCompany = async (company: ICompanyResource) => {
    try {
      await Company.UnassignUser(company, user);
      const comp = companies.filter((c) => c.id !== company.id);
      setCompanies(comp);
      onUpdated({ ...user, companies: comp });
    } catch (error) {
      console.error(error);
    }
  };

  const addUserCompany = async () => {
    if (company) {
      try {
        await Company.AssignUser(company, user);
        const comp = [...companies, company];
        setCompanies(comp);
        setCompany(undefined);
        onUpdated({ ...user, companies: comp });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <InputGroup>
        <InputGroup.Prepend>
          <Companies.Selector
            except={user.companies}
            value={company}
            onChange={setCompany}
          />
        </InputGroup.Prepend>
        <Button
          title="Tilføj virksomhed til bruger"
          variant="success"
          disabled={!company}
          onClick={() => addUserCompany()}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </InputGroup>

      {companies.map((company, index) => (
        <div key={index}>
          <Button
            size="sm"
            onClick={() => deleteUserCompany(company)}
            variant="warning"
          >
            <FontAwesomeIcon icon={faMinus} />
            {company.name}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default EditUserCompanies;

import { createFetch } from "../helpers/fetchers";
import { jwtResponse } from "./jwtResponse";
export interface IAccountLogin {
  grant_type: "password" | "refresh_token";
  username: string;
  password: string;
}

export default class Account {
  static Logout = async (abort?: AbortSignal): Promise<void> =>
    await createFetch("api/Account/logout", undefined, abort);
  static Login = async (credentials: IAccountLogin, abort?: AbortSignal): Promise<jwtResponse> =>
    (await createFetch("api/Account/login", credentials, abort)) as jwtResponse;
}

import React, { useCallback, useContext } from "react";
import { Spinner } from "react-bootstrap";
import UserContext from "../../contexts/userContext";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyResource } from "../../models/company";
import TableError from "../TableError";
import TrackingUnits from "../TrackingUnits/Index";

interface Props {
  company: ICompanyResource;
}
const CompanyTrackingUnits: React.FC<Props> = ({ company }) => {
  const { user } = useContext(UserContext);
  const request = useCallback(
    (signal: AbortSignal) => Company.ReadTrackingUnits(company, signal),
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [units, error] = usePromise(request, [], setLoading);
  if (loading) return <Spinner animation="border" />;
  if (error) return <TableError />;
  if (user && user.identity.admin)
    return <TrackingUnits.Table coordinateStarted={false} trackerStarted={false} company={company} trackingUnits={units} />;
  return <TrackingUnits.CompanyTable coordinateStarted={false} trackerStarted={false} trackingUnits={units} />;
};

export default CompanyTrackingUnits;

import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Alert, Button, Tab, Table, Tabs } from "react-bootstrap";
import Moment from "react-moment";
import useLoading from "../../hooks/useLoading";
import { ITachographParametersResource } from "../../models/tachoSafe/tachographParameters";
import TimeSpan from "../../models/timespan";
import Vehicle, { IVehicleResource } from "../../models/vehicle";
import Centered from "../Centered";
import ContentLoading from "../ContentLoading";
import Countdown from "../Countdown";
import TimeLabel from "../TimeLabel";

interface Props {
  value: ITachographParametersResource;
}

const TachoDisplay = ({ value }: Props) => {
  const clampExceed = Math.max(
    0,
    value.driver1NumberOfTimes9hDailyDrivingTimesExceeded
  );
  return (
    <div style={{ fontWeight: "bold" }}>
      <Tabs defaultActiveKey="driver1" id="tacho-drivers">
        <Tab eventKey="driver1" title={`1. ${value.driver1Name}`}>
          <Table size="sm" bordered striped>
            <tbody>
              <tr>
                <td>
                  Resterende køreperiode (4:30) <br />
                  Påbegynd pause senest
                </td>
                <td>
                  <TimeLabel
                    format="hours-minutes-seconds"
                    value={
                      new TimeSpan(
                        270 * 60 - value.driver1ContinuousDrivingTime * 60
                      )
                    }
                  />
                  <br />
                  <Moment local utc format="HH:mm DD-MM-YYYY">
                    {value.driver1BeginBreak}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>Afholdt pause (4:30)</td>
                <td>
                  <TimeLabel
                    format="hours-minutes-seconds"
                    value={new TimeSpan(value.driver1CumulativeBreakTime * 60)}
                  />
                </td>
              </tr>
              <tr>
                <td>Køredøgn start</td>
                <td>
                  <Moment local utc format="HH:mm DD-MM-YYYY">
                    {value.driver1EndOfLastDailyRestPeriod}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>
                  Køreuge start
                  <br />
                </td>
                <td>
                  <Moment local utc format="HH:mm DD-MM-YYYY">
                    {value.driver1EndOfLastWeeklyRestPeriod}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table className="table-plain">
                    <tr>
                      <td>Tid ind til daglig hvil</td>
                      <td>
                        <TimeLabel
                          format="hours-minutes-seconds"
                          value={
                            new TimeSpan(
                              value.driver1TimeLeftUntilNewDailyRestPeriod * 60
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Påbegynd hvil senest</td>
                      <td>
                        <Moment local utc format="HH:mm DD-MM-YYYY">
                          {value.driver1BeginDailyRest}
                        </Moment>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>Daglig køretid</td>
                <td>
                  <TimeLabel
                    format="hours-minutes-seconds"
                    value={
                      new TimeSpan(value.driver1CurrentDailyDrivingTime * 60)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Ugentlig køretid</td>
                <td>
                  <TimeLabel
                    format="hours-minutes-seconds"
                    value={
                      new TimeSpan(value.driver1CurrentWeeklyDrivingTime * 60)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Påbegynd ugehvil senest</td>
                <td>
                  <Moment local utc format="HH:mm DD-MM-YYYY">
                    {value.driver1BeginWeeklyRest}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>Brugte 10 timers perioder</td>
                <td>{clampExceed}</td>
              </tr>
            </tbody>
          </Table>
        </Tab>
        <Tab
          eventKey="driver2"
          title={
            value.driver2Name === "" ? "Ingen chauffør" : value.driver2Name
          }
          disabled={value.driver2Name === ""}
        >
          <Table size="sm" bordered striped>
            <tbody>
              <tr>
                <td>Navn</td>
                <td>{value.driver2Name}</td>
              </tr>
              <tr>
                <td>
                  Resterende køretid (4:30) <br />
                  Påbegynd pause senest
                </td>
                <td>
                  <TimeLabel
                    format="hours-minutes-seconds"
                    value={
                      new TimeSpan(
                        270 * 60 - value.driver2ContinuousDrivingTime
                      )
                    }
                  />
                  <br />
                  <Moment>{value.driver2BeginBreak}</Moment>
                </td>
              </tr>
            </tbody>
          </Table>
        </Tab>
      </Tabs>
      <p>
        <b>Data fra: </b>{" "}
        <Moment local utc format="DD-MM-YYYY HH:mm:ss">
          {value.updatedAt}
        </Moment>
      </p>
    </div>
  );
};

export default TachoDisplay;

interface VehicleProps {
  value: IVehicleResource;
}

export const VehicleDisplay = ({ value }: VehicleProps) => {
  const request = useCallback(() => Vehicle.ReadTacho(value), [value]);
  const [loading, setLoading] = useLoading();
  // const [tacho] = usePromise(request, undefined, setLoading);
  const [tacho, setTacho] = useState<ITachographParametersResource>();

  const [duration, setDuration] = useState(0);

  const [lockRefresh, setLockRefresh] = useState(false);
  const [refreshFailed, setRefreshFailed] = useState(false);

  const loadTacho = async () => {
    try {
      setLoading(true);
      const tacho = await request();
      setTacho(tacho);
      setDuration(tacho.secondsTillRefresh);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTacho();
  }, []);

  

  useEffect(() => {
    if (duration) {
      setLockRefresh(duration > 0);
    }
  }, [duration]);

  const refresh = async () => {
    try {
      setLoading(true);
      setLockRefresh(true);
      const tachograph = await request();
      if (tachograph) {
        setTacho(tachograph);
        setDuration(tachograph.secondsTillRefresh);
        setRefreshFailed(false);
      }
    } catch (error) {
      console.log(error);
      setDuration(5 * 60);
      setRefreshFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const spinnerClass = useMemo(
    () => (loading ? "loading-spinner active" : "loading-spinner"),
    [loading]
  );

  const unlockRefresh = () => {
    setLockRefresh(false);
    setRefreshFailed(false);
  };

  const buttonVariant = useMemo(() => {
    if (lockRefresh) {
      return refreshFailed ? "warning" : "secondary";
    } else return "primary";
  }, [lockRefresh, refreshFailed]);

  return (
    <ContentLoading loading={loading}>
      <span>Remote box montereret: <b>Ja</b></span>
      {tacho ? (
        <Fragment>
          <TachoDisplay value={tacho} />
          <Centered>
            <Button
              disabled={lockRefresh}
              size="sm"
              variant={buttonVariant}
              onClick={refresh}
            >
              <div className={spinnerClass}>
                <FontAwesomeIcon icon={faSync} />
              </div>
            </Button>
            <Countdown
              value={duration}
              onTick={setDuration}
              onComplete={unlockRefresh}
            />
          </Centered>
        </Fragment>
      ) : (
        <Alert variant="warning">
          Kunne ikke hente oplysninger fra takografen. <br />
          Dette kan skyldes at der ikke er forbindelse lige nu <br />
          eller at den er i gang med at downloade. <br />
          Prøv igen senere.
        </Alert>
      )}
    </ContentLoading>
  );
};

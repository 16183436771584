import React, { useCallback, useMemo } from "react";
import usePromise from "../../hooks/usePromise";
import useTitle from "../../hooks/useTitle";
import TrackingUnit from "../../models/trackingUnit";
import TrackingUnits from "./Index";
import { useLiveCoordinatesConnection } from "../../hooks/useLiveCoordinates";
import { useLiveTrackerConnection } from "../../hooks/useLiveTrackerStatus";
import TableError from "../TableError";
import useLoading from "../../hooks/useLoading";

const All: React.FC = () => {
  const request = useCallback(
    (signal: AbortSignal) => TrackingUnit.ReadAll(signal),
    []
  );
  const [loading, setLoading] = useLoading();
  const [trackingUnits, error] = usePromise(request, [], setLoading);
  // const coordinateConnection = useLiveCoordinatesConnection();
  // const [trackerConnection, started] = useLiveTrackerConnection();
  useTitle("DigiFleet - Tracking enheder");
  if (loading) return <em>Loading...</em>;
  else if (error) return <TableError />;
  return (
    <TrackingUnits.Table
      // coordinateConnection={coordinateConnection}
      // trackerConnection={trackerConnection}
      trackingUnits={trackingUnits}
      trackerStarted={false}
      coordinateStarted={true}
    />
  );
};

export default All;

export const ActiveAll = (): React.ReactElement => {
  const request = useCallback(
    (signal: AbortSignal) => TrackingUnit.ReadAll(signal),
    []
  );
  const [loading, setLoading] = useLoading();
  const [trackingUnits, error] = usePromise(request, [], setLoading);
  // const coordinateConnection = useLiveCoordinatesConnection();
  // const [trackerConnection, started] = useLiveTrackerConnection();
  useTitle("DigiFleet - Tracking enheder");
  if (loading) return <em>Loading...</em>;
  else if (error) return <TableError />;
  return (
    <TrackingUnits.ActiveTable
      // coordinateConnection={coordinateConnection}
      // trackerConnection={trackerConnection}
      trackingUnits={trackingUnits}
      trackerStarted={false}
      coordinateStarted={true}
    />
  );
}

import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, Fragment, useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from "react-bootstrap";
import TrackingUnit, { ITrackingUnitResource } from "../../models/trackingUnit";

interface Props {
  trackingUnit: ITrackingUnitResource;
  onUpdated: (trackingUnit: ITrackingUnitResource) => void;
}

const UnassignCompany: React.FC<Props> = ({
  trackingUnit,
  onUpdated,
}) => {
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);

  const saveChanges = async () => {
    try {
      setLoading(true);
      await TrackingUnit.UnassignCompany(trackingUnit);
      setLoading(false);
      setShown(false);
      if (onUpdated) {
        onUpdated({ ...trackingUnit, company: undefined });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  const formSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await saveChanges();
  };

  return (
    <Fragment>
      <button
        title="Fjern fra virksomhed"
        className="blank-button"
        onClick={() => setShown(true)}
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <Modal show={shown} onHide={() => setShown(false)}>
        <form onSubmit={formSubmit}>
          <Modal.Header>Fjern enhed fra virksomhed</Modal.Header>
          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Virksomhed</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly={true}
                  defaultValue={trackingUnit.company?.name}
                />
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" disabled={loading} type="submit">
              Fjern {loading && <Spinner animation="border" size="sm" />}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShown(false);
              }}
            >
              Luk
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default UnassignCompany;

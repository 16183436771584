import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import UserContext from "../../contexts/userContext";
import useSorting from "../../hooks/useSorting";
import Company, { CompanyProps, CompanyResource, ICompanyResource } from "../../models/company";
import User from "../../models/user";

interface Props {
  value?: ICompanyResource;
  onChange: (company: ICompanyResource) => void;
  except?: ICompanyResource[];
}
const CompanySelector: React.FC<Props> = ({ value, onChange, except }) => {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<ICompanyResource[]>([]);
  const [sorted] = useSorting<CompanyResource, CompanyProps>(companies.map(c => new CompanyResource(c)), {
    type: "name",
    asc: true
  })
  const { user } = useContext(UserContext);

  const userId = useMemo(() => user ? user.identity.id : undefined, [user]);
  const userAdmin = useMemo(() => user ? user.identity.admin : false, [user]);
  
  useEffect(() => {
    const readCompanies = async () => {
      try {
        if (!userId) {
          console.log("Not authenticated");
          return;
        }
        //Read all or user companies depending on if user is admin or not
        var cmp = userAdmin
          ? await Company.ReadAll()
          : await User.ReadCompanies(userId);
        setCompanies(cmp);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    readCompanies();
  }, [userId, userAdmin]);

  const filtered = useMemo(
    () =>
      except
        ? sorted.filter((c) => except.findIndex((f) => f.id === c.id) === -1)
        : sorted,
    [except, sorted]
  );

  const selectorClass = useMemo(() => value ? "form-control w-auto selected" : "form-control w-auto", [value]);

  return (
    <Fragment>
      <select
        className={selectorClass}
        onChange={(e) => {
          const val = +e.target.value;
          onChange(filtered[val]);
        }}
      >
        <option>
          {loading ? "Loader virksomheder..." : "Vælg en virksomhed"}
        </option>
        {filtered.map((company, index) => (
          <option
            key={company.id}
            value={index}
            selected={value && value.id === company.id}
          >
            {company.name}
          </option>
        ))}
      </select>
    </Fragment>
  );
};

export default CompanySelector;

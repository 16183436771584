import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { convertBlobToBase64 } from "../../helpers/fetchers";
import TrackingUnitQR, { ITrackingUnitQR } from "../../models/trackingUnitQR";
import { NotFoundError } from "../../HttpErrors";

interface Props {
  qr: ITrackingUnitQR;
}

const printImage = (url: string, unitId: string) => {
  var win = window.open("");
  if (win) {
    win.document.write("<figure>");
    win.document.write(
      '<img alt="QR kode" style="width:8em; height:8em;" src="' +
        url +
        '" onload="window.print();window.close()" />'
    );
    win.document.write(`<figcaption>Enhed: ${unitId}</figcaption>`);
    win.document.write("</figure>");
    win.focus();
  }
};

export const printManyImages = (unitQrs: string[][]) => {
  var win = window.open("");
  if (win) {
    win.document.write("<div>");
    win.document.write(
      "<script>setTimeout(() => {window.print();window.close()}, 1000)</script>"
    );
    for (var qr of unitQrs) {
      const url = qr[0];
      const unitId = qr[1];
      win.document.write("<div>");
      win.document.write("<figure>");
      win.document.write(
        '<img alt="QR kode" style="width:8em; height:8em;" src="' + url + '"  /><br />'
      );
      win.document.write(`<figcaption>Enhed: ${unitId}</figcaption>`);
      win.document.write("<figure><hr />");
      win.document.write("</div>");
    }
    win.document.write("</div>");
  }
};

const QRViewer: React.FC<Props> = ({ qr }) => {
  const [image, setImage] = useState<string | ArrayBuffer>();
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    const abort = new AbortController();
    try {
      const readBlob = async () => {
        const blob = await TrackingUnitQR.ReadImage(qr.id, abort.signal);
        const base64 = await convertBlobToBase64(blob);
        if (base64) {
          setImage(base64);
        }
      };
      readBlob();
    } catch (error) {
      setError(error);
      console.error(error);
    }

    return () => {
      abort.abort();
    };
  }, [qr]);

  if (error && error instanceof NotFoundError) {
    return <Badge variant="warning">QR koden blev ikke fundet</Badge>;
  }

  const imageUrl = `${image}`;

  return (
    <div>
      <img alt="QR kode" className="qr-code-image" src={imageUrl} />

      <Button
        variant="primary"
        title="Udskriv"
        onClick={() => printImage(imageUrl, qr.id)}
      >
        <FontAwesomeIcon icon={faPrint} />
      </Button>
      <br />
      <span>
        URL: <b>{`${window.location.origin}/unit/register/${qr.token}`}</b>
      </span>
    </div>
  );
};

export default QRViewer;

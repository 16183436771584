import React from "react";
import { Table as ReactTable } from "react-bootstrap";
import useSortedSearch from "../../../hooks/useSortedSearch";
import SEGESVehicle, {
  ISEGESVehicleResource,
  SEGESVehicleProps,
  SEGESVehicleResource,
} from "../../../models/segesVehicle";
import PassivePagination from "../../Pagination";
import SearchableHeader from "../../SearchableHeader";
import SortableHeader from "../../SortableHeader";
import Create from "./Create";
import Row from "./Row";

interface Props {
  values: ISEGESVehicleResource[];
  onDeleted: (value: ISEGESVehicleResource) => void;
  onCreated: (value: ISEGESVehicleResource) => void;
}

const Table = ({ values, onDeleted, onCreated }: Props) => {
  const [sorted, search, sort, setSort, setSearch] = useSortedSearch<
    SEGESVehicleResource,
    SEGESVehicleProps
  >(
    values.map((v) => new SEGESVehicleResource(v)),
    undefined,
    {
      type: "vehicleId",
      asc: true,
    }
  );

  return (
    <ReactTable striped responsive bordered>
      <thead>
        <tr>
          <SearchableHeader
            types={["vehicle.alias", "vehicle.licensePlate"]}
            value={search}
            onSearch={setSearch}
          />
          <th></th>
          <th></th>
        </tr>
        <tr>
          <SortableHeader type="vehicle.alias" fallback="vehicle.licensePlate" value={sort} onChange={setSort}>
            Køretøj
          </SortableHeader>
          <SortableHeader
            type="type"
            value={sort}
            onChange={setSort}
          >
            Type
          </SortableHeader>
          <th>
            <Create onCreated={onCreated} />
          </th>
        </tr>
      </thead>
      <tbody>
        <PassivePagination
          items={sorted}
          sizeSelector={true}
          id="seges-vehicles"
          renderItem={(item) => <Row value={item} onDeleted={onDeleted} />}
        />
      </tbody>
    </ReactTable>
  );
};

export default Table;

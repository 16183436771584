import React, { useState } from "react";
import Companies from "../Companies/Index";
import { ICompanyResource } from "../../models/company";
import ExportTabs from "./ExportTabs";

const UserExportIndex: React.FC = () => {
  const [company, setCompany] = useState<ICompanyResource | undefined>();

  return (
    <div className="export-index navigation-selector">
      <b>Vælg virksomhed</b>
      <Companies.Selector value={company} onChange={setCompany} />

      {company && (
        <div className="export navigation-result">
          <ExportTabs company={company} />
        </div>
      )}
    </div>
  );
};

export default UserExportIndex;

import React, { useCallback } from "react";
import { Spinner } from "react-bootstrap";
import usePromise from "../../hooks/usePromise";
import Vehicle, { IVehicleResource } from "../../models/vehicle";
import Coordinates from "./Index";
import { VehicleCoordinateExport } from "../DataExports/VehicleCoordinateExport";
import useLoading from "../../hooks/useLoading";

interface Props {
  vehicle: IVehicleResource;
  from: Date;
  to: Date;
}

const CoordinateHistory: React.FC<Props> = ({ vehicle, from, to }) => {
  const request = useCallback(
    (signal: AbortSignal) =>
      Vehicle.ReadTrackingHistorySpeeds(vehicle, from, to, signal),
    [vehicle, from, to]
  );
  const [loading, setLoading] = useLoading();
  const [coordinates] = usePromise(request, [], setLoading);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div>
      <Coordinates.Table coordinates={coordinates} />
      <div>
        <VehicleCoordinateExport vehicle={vehicle} from={from} to={to} />
      </div>
    </div>
  );
};

export default CoordinateHistory;

import { useRef, useEffect } from "react";

/**
 * Sets the document title.
 * @param title The title to set
 * @param restore Whether or not the title should be restored to its previous value, on un mount
 */
const useTitle = (title: string, restore?: boolean) => {
  const restoreRef = useRef(document.title);
  useEffect(() => {
    document.title = title;
    if (restore) {
      const restoreTitle = restoreRef.current;
      return () => {
        document.title = restoreTitle;
      };
    }
  }, [restore, title]);
};

export default useTitle;

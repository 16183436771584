import { basicSearch, basicSecSort } from "../helpers/utils";
import ISearchable from "../ISearchable";
import ISortable from "../ISortable";
import { ITimedCoordinate } from "./gpsCoordinate";
import { IVehicleResource } from "./vehicle";

export interface ICloseEncounter {
  coordinate: ITimedCoordinate;
  vehicle: IVehicleResource;
  distance: number
}
type CoordinateProps = "coordinate.timestamp";
type VehicleProps = "vehicle.alias" | "vehicle.licensePlate";
export type CloseEncounterProps = "distance" | CoordinateProps | VehicleProps;
export class CloseEncounter implements ICloseEncounter, ISortable<CloseEncounterProps>, ISearchable<CloseEncounterProps>{
  coordinate: ITimedCoordinate;
  vehicle: IVehicleResource;
  distance: number;
  constructor(value: ICloseEncounter){
    this.coordinate = value.coordinate;
    this.vehicle = value.vehicle;
    this.distance = value.distance;
  }
  search = (value: any, type: CloseEncounterProps): number | undefined => 
    basicSearch(this, value, type, false);
  compare = (value: ISortable<CloseEncounterProps>, primary: CloseEncounterProps, secondary?: CloseEncounterProps): number => 
    basicSecSort(this, value, primary, secondary);
}

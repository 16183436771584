import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import Control from "react-leaflet-control";

interface Props {
  show: boolean;
  position: "topleft" | "topright" | "bottomright" | "bottomleft";
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "dark"
    | "light"
    | "link"
    | "outline-primary"
    | "outline-secondary"
    | "outline-success"
    | "outline-danger"
    | "outline-warning"
    | "outline-info"
    | "outline-dark"
    | "outline-light";
  icon: IconDefinition;
  onClick?: () => void;
  dontUnmount?: boolean;
}

const ButtonControl: React.FC<Props> = ({
  show,
  position,
  icon,
  variant,
  onClick,
  children,
  dontUnmount,
}) => {
  const containerClass = useMemo(
    () => (show ? "button-control active" : "button-control"),
    [show]
  );
  if (dontUnmount) {
    return (
      <Control position={position}>
        <div className={containerClass}>
          <div className="button-control-container">
            <Button
              title={show ? "Luk" : "Åben"}
              variant={variant}
              onClick={onClick}
            >
              <FontAwesomeIcon icon={icon} />
            </Button>
          </div>
          <div style={{display: show ? "block" : "none"}}>
            {children}
          </div>
        </div>
      </Control>
    );
  }
  return (
    <Control position={position}>
      <div className={containerClass}>
        <div className="button-control-container">
          <Button
            title={show ? "Luk" : "Åben"}
            variant={variant}
            onClick={onClick}
          >
            <FontAwesomeIcon icon={icon} />
          </Button>
        </div>
        {show && children}
      </div>
    </Control>
  );
};

export default ButtonControl;

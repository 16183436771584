import React, { useCallback } from "react";
import usePromise from "../../hooks/usePromise";
import useTitle from "../../hooks/useTitle";
import EmailTemplate from "../../models/emailTemplate";
import EmailTemplates from "./Index";
import TableError from "../TableError";
import useLoading from "../../hooks/useLoading";

const AllEmailTemplates: React.FC = () => {
  const request = useCallback(
    (signal: AbortSignal) => EmailTemplate.ReadAll(signal),
    []
  );
  const [loading, setLoading] = useLoading();
  const [templates, error] = usePromise(request, [], setLoading);

  useTitle("DigiFleet - Emails");
  if (loading) return <em>Loading...</em>;
  if (error) return <TableError />;
  return <EmailTemplates.Table emailTemplates={templates} />;
};

export default AllEmailTemplates;

import React, { useCallback, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import useLiveCoordinates from "../../hooks/useLiveCoordinates";
import useLoading from "../../hooks/useLoading";
import usePromise from "../../hooks/usePromise";
import Company, { ICompanyIdentifiable } from "../../models/company";
import { IGPSCoordinate } from "../../models/gpsCoordinate";
import { IVehicleResource } from "../../models/vehicle";

interface Props {
  company: ICompanyIdentifiable;
}

export const Vehicles24OnlineChart: React.FC<Props> = ({ company }) => {
  const request = useCallback(
    (signal: AbortSignal) => Company.ReadOnlineVehicles(company, signal),
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [onlineOffline] = usePromise(request, {
    online: 0,
    offline: 0,
  }, setLoading);

  if (loading) {
    return <Spinner animation="border" />;
  }

  const data = {
    labels: ["Tændt", "Slukket"],
    datasets: [
      {
        data: [onlineOffline.offline, onlineOffline.online],
        backgroundColor: ["#befc03", "#FC4903"],
        hoverBackgroundColor: ["#befc03", "#FC4903"],
      },
    ],
  };

  return (
    <div>
      <Doughnut data={data} />
    </div>
  );
};

export const CompanyVehiclesOnlineChart: React.FC<Props> = ({ company }) => {
  const request = useCallback(
    (signal: AbortSignal) => Company.ReadVehicles(company, signal),
    [company]
  );
  const [loading, setLoading] = useLoading();
  const [vehicles] = usePromise(request, [], setLoading);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return <VehiclesOnlineChart vehicles={vehicles} />;
};

interface VehicleProps {
  vehicles: IVehicleResource[];
}

const VehiclesOnlineChart: React.FC<VehicleProps> = ({ vehicles: veh }) => {
  const [vehicles, setVehicles] = useState(veh);

  // const handleCoordinate = useCallback(
  //   (coordinate: IGPSCoordinate) => {
  //     const index = vehicles.findIndex((v) => v.id === coordinate.vehicleId);
  //     let newArr = [...vehicles];
  //     if (index > -1 && newArr[index]) {
  //       newArr[index].online = coordinate.accOn;
  //       setVehicles(newArr);
  //     }
  //   },
  //   [vehicles]
  // );

  // useLiveCoordinates(handleCoordinate);
  const online = useMemo(() => vehicles.filter((v) => v.online).length, [
    vehicles,
  ]);

  const offline = useMemo(() => vehicles.length - online, [vehicles, online]);

  const data = {
    labels: ["Tændt", "Slukket"],
    datasets: [
      {
        data: [online, offline],
        backgroundColor: ["#befc03", "#FC4903"],
        hoverBackgroundColor: ["#befc03", "#FC4903"],
      },
    ],
  };

  return (
    <div>
      <Doughnut data={data} />
    </div>
  );
};

export default VehiclesOnlineChart;

import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Collapse, Spinner } from "react-bootstrap";
import { createFetch } from "./helpers/fetchers";
import packageJson from "../package.json";

type ErrorState = {
  error?: Error;
  errorInfo?: React.ErrorInfo;
};
export default class ErrorBoundary extends React.Component<{}, ErrorState> {
  constructor(props: any) {
    super(props);
    this.state = { error: undefined, errorInfo: undefined };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ error, errorInfo: info });
  }

  render() {
    if (this.state.error && this.state.errorInfo) {
      return (
        <ErrorDisplay
          error={this.state.error}
          errorInfo={this.state.errorInfo}
        />
      );
    }

    return this.props.children;
  }
}
interface ErrorProps {
  error: Error;
  errorInfo: React.ErrorInfo;
}
const ErrorDisplay = ({ error, errorInfo }: ErrorProps): React.ReactElement => {
  const [open, setOpen] = useState(false);

  const [canSend, setCanSend] = useState(false);
  useEffect(() => {
    const userJson =
      sessionStorage.getItem("user") || localStorage.getItem("user");
    if (userJson) {
      setCanSend(true);
    }
  }, []);
  return (
    <div className="error-description-container">
      <h3>Der skete en fejl!</h3>

      <Alert variant="primary">
        Det kan skyldes at der imens du har brugt siden, er sket en opdatering af siden.
        <b>Du kan prøve at holde CTRL tasten og trykke F5 for at opdatere</b>
      </Alert>
      <hr />
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="error-description-text"
        aria-expanded={open}
      >
        {open ? "Skjul detaljer" : "Vis detaljer"}
      </Button>
      <Collapse in={open}>
        <div id="error-description-text">
          <Alert variant="danger">{error.toString()}</Alert>
          <Alert variant="warning">{errorInfo.componentStack}</Alert>
          {error.stack && (
            <Alert variant="warning">{error.stack.toString()}</Alert>
          )}
        </div>
      </Collapse>
      <hr />
      {canSend && <SendError errorInfo={errorInfo} error={error} />}
    </div>
  );
};
interface ErrorModel {
  description: string;
  stack?: string;
  componentStack: string;
  version: string;
  path: string;
}
const SendError = ({ error, errorInfo }: ErrorProps): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState<Error>();
  const send = async () => {
    try {
      const model: ErrorModel = {
        componentStack: errorInfo.componentStack,
        description: error.toString(),
        stack: error.stack?.toString(),
        version: packageJson.version,
        path: window.location.href,
      };
      setLoading(true);
      await createFetch("api/Error/senderror", model);
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } catch (error) {
      setLoading(false);
      setErr(error);
    }
  };
  if (success) {
    return <Badge variant="success">Beskeden blev sent! ...</Badge>;
  } else if (loading) return <Spinner animation="border" />;
  else if (err)
    return <Alert variant="danger">Fejl under sending af fejl besked</Alert>;

  return (
    <Button variant="success" onClick={send}>
      Send til DigiTach
    </Button>
  );
};

import {
  faArrowRight,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { Button, Spinner } from "react-bootstrap";
import { LayersControl, Map, TileLayer } from "react-leaflet";
import Control from "react-leaflet-control";
import UserContext from "../../contexts/userContext";
import useSortedSearch from "../../hooks/useSortedSearch";
import { ICoordinate } from "../../models/gpsCoordinate";
import User from "../../models/user";
import Vehicle, { IVehicleResource, VehicleProps } from "../../models/vehicle";
import Companies from "../Companies/Index";
import ButtonControl from "./MapControls/ButtonControl";
import ControlContent from "./MapControls/ControlContent";
import MapDateFilter from "./MapDateFilter";
import MapVehicleSelector, { ISelectedVehicle, SelectedVehicle } from "./MapVehicleSelector";
import TrackingMap from "./TrackingMap";
import VehicleHistoricTrack from "./VehicleHistoricTrack";


type Update = {
  readonly type: "UPDATE";
  vehicle: ISelectedVehicle;
};
type Replace = {
  readonly type: "REPLACE";
  vehicles: ISelectedVehicle[];
};
type ShowAll = {
  readonly type: "SHOWALL";
};
type HideAll = {
  readonly type: "HIDEALL";
};
type ShowAllPaths = {
  readonly type: "SHOWALLPATHS";
};
type HideAllPaths = {
  readonly type: "HIDEALLPATHS";
};

type Actions =
  | Update
  | Replace
  | ShowAll
  | HideAll
  | ShowAllPaths
  | HideAllPaths;

const reducer = (
  vehicles: ISelectedVehicle[],
  action: Actions
): ISelectedVehicle[] => {
  switch (action.type) {
    case "UPDATE":
      const index = vehicles.findIndex(
        (v: ISelectedVehicle) => v.id === action.vehicle.id
      );
      return [
        ...vehicles.slice(0, index),
        action.vehicle,
        ...vehicles.slice(index + 1),
      ];
    case "REPLACE":
      return action.vehicles;
    case "SHOWALL":
      return vehicles.map((vehicle) => ({ ...vehicle, shown: true }));
    case "HIDEALL":
      return vehicles.map((vehicle) => ({
        ...vehicle,
        shown: false,
        pathShown: false,
      }));
    case "SHOWALLPATHS":
      return vehicles.map((vehicle) => ({ ...vehicle, pathShown: true }));
    case "HIDEALLPATHS":
      return vehicles.map((vehicle) => ({ ...vehicle, pathShown: false }));
  }
};

const yesterday = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
};

interface Props {
  isFullscreen: boolean;
  fullscreenChange: (fullscreen: boolean) => void;
  /**
   * An initial vehicle to be shown
   */
  initialVehicle?: IVehicleResource;
  /**
   * Initial date from
   */
  initialFrom?: string;
  /**
   * Initial date to
   */
  initialTo?: string;
}
/**
 * Shows a map where vehicle's historical driving can be shown
 * @param param0
 */
const HistoricMap: React.FC<Props> = ({
  isFullscreen,
  fullscreenChange,
  initialVehicle,
  initialFrom,
  initialTo,
}) => {
  const [from, setFrom] = useState(
    initialFrom ? new Date(initialFrom) : yesterday()
  );
  const [to, setTo] = useState(initialTo ? new Date(initialTo) : new Date());

  const [vehicles, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(true);

  const [vehiclesOpen, setVehiclesOpen] = useState(false);

  const [userPosition, setUserPosition] = useState<ICoordinate>({
    latitude: 55.403756,
    longitude: 10.40237,
  });

  const [zoom, setZoom] = useState(10);

  const { user } = useContext(UserContext);

  const [sorted, , , , changeSearch] = useSortedSearch<SelectedVehicle, VehicleProps>(vehicles.map(v => new SelectedVehicle(v)), undefined, {
    asc: true,
    type: "alias",
    fallback: "licensePlate",
  });
  /**
   * Get the vehicle's position within the selected timeframe
   * @param vehicle
   */
  const getVehicleLocation = async (vehicle: IVehicleResource) => {
    try {
      const coordinates = await Vehicle.ReadTrackingHistory(vehicle, from, to);
      if (coordinates.length > 0) {
        const position = coordinates[0];
        setUserPosition(position);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const userId = useMemo(() => (user ? user.identity.id : undefined), [user]);
  const userAdmin = useMemo(() => (user ? user.identity.admin : false), [user]);

  useEffect(() => {
    const readVehicles = async () => {
      try {
        if (!userId) {
          console.warn("Not authenticated");
          return;
        }
        //Read all the vehicles or the user's vehicles
        const vehicles = userAdmin
          ? await Vehicle.ReadAll()
          : await User.ReadVehicles(userId);
        dispatch({
          type: "REPLACE",
          vehicles: vehicles.map((vehicle) => ({
            ...vehicle,
            shown: false,
            pathShown: false,
          })),
        });
        //Show the vehicle and from/to passed in through props
        if (initialVehicle && initialFrom && initialTo) {
          //Find the full vehicle in the fetched list, props vehicle is missing company
          const existing = vehicles.find((v) => v.id === initialVehicle.id);
          if (existing) {
            //Show the vehicle
            dispatch({
              type: "UPDATE",
              vehicle: { ...existing, shown: true, pathShown: true },
            });
            //Map is weird as fuck, so there needs to be a delay before going to vehicle's position
            setTimeout(async () => {
              const pos = await Vehicle.ReadLocation(initialVehicle);
              if(!pos) return;
              setUserPosition(pos);
            }, 1000);
          }
        } else {
          //Otherwise go to the user's position
          navigator.geolocation.getCurrentPosition((position) => {
            setUserPosition(position.coords);
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    readVehicles();
  }, [initialFrom, initialTo, initialVehicle, userId, userAdmin]);

  //Whether or not any vehicle is shown on the map
  const anyShown = useMemo(() => vehicles.some((v) => v.shown), [vehicles]);
  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <TrackingMap
      center={userPosition}
      zoom={zoom}
      onZoom={setZoom}
      onPan={setUserPosition}
    >
      {vehicles
        .filter((v) => v.shown)
        .map((vehicle) => (
          <VehicleHistoricTrack
            key={vehicle.id}
            vehicle={vehicle}
            zoom={zoom}
            from={from}
            to={to}
          />
        ))}

      <Control position="topright">
          <MapDateFilter
            from={from}
            to={to}
            onChange={(from, to) => {
              setFrom(from);
              setTo(to);
            }}
          />
      </Control>
      <ButtonControl
        icon={faMapMarkedAlt}
        show={vehiclesOpen}
        variant={vehiclesOpen ? "secondary" : "success"}
        onClick={() => setVehiclesOpen(!vehiclesOpen)}
        position="topright"
        dontUnmount={true}
      >
        <ControlContent corner="top-right">
          <div className="map-row-right">
            <Button
              size="sm"
              variant="secondary"
              title="Luk"
              onClick={() => setVehiclesOpen(false)}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
          <hr />
          {anyShown && (
            <Button onClick={() => dispatch({ type: "HIDEALL" })}>
              Skjul alle
            </Button>
          )}
          <Companies.Selector
            onChange={(company) =>
              changeSearch(
                company
                  ? {
                      types: ["company.id"],
                      value: company.id,
                      exact: true,
                    }
                  : undefined
              )
            }
          />
          <MapVehicleSelector
            values={sorted}
            onChange={(vehicle) => dispatch({ type: "UPDATE", vehicle })}
            onZoom={(vehicle) => getVehicleLocation(vehicle)}
            hidePath={true}
          />
        </ControlContent>
      </ButtonControl>

      <Control position="bottomleft">
        <button
          title={isFullscreen ? "Luk fuld skærm" : "Åben fuld skærm"}
          className="btn btn-default"
          style={{ backgroundColor: "white" }}
          onClick={() => fullscreenChange(!isFullscreen)}
        >
          <FontAwesomeIcon
            icon={isFullscreen ? faCompressArrowsAlt : faExpandArrowsAlt}
          />
        </button>
      </Control>
    </TrackingMap>
  );
};

export default HistoricMap;
